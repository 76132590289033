import React, { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../utils/useAuthAxios';
import Message from '../Ui/Message';
import Progress from '../Ui/Progress';
import { FaRegCopy } from 'react-icons/fa';
import '../UploadThumb/UploadPicture.css';

import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../store/bespokeProperty-slice';

export default function UploadImageComponent({ env, accountId, setIsClick, }) {

	const dispatch = useDispatch();
	const {
		isNewBespoke,
		campaignMode
	} = useSelector((state) => state.bespoke);
	const [selectedFile, setSelectedFile] = useState([]);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');
	const [imagePreview, setImagePreview] = useState();

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		open
	} = useDropzone({
		accept: 'image/*',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFile(
				acceptedFiles[0]

			);
		}
	});
	const baseStyle = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out'
	};
	const activeStyle = {
		borderColor: '#2196f3'
	};
	const acceptStyle = {
		borderColor: '#00e676'
	};
	const rejectStyle = {
		borderColor: '#ff1744'
	};
	const img = {
		display: 'block',
		margin: 'auto',
		width: '25%',
		height: '25%'
	};
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	const filepath = acceptedFiles.map(file => (
		<p key={file.path}>
			{file.path} - {file.size} bytes
		</p>
	));
	const uploadHandler = (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append(
			'file',
			selectedFile
		);
		if (!isNewBespoke && isAuthenticated || isAuthenticated && campaignMode) {
			authAxios.post(`asset/account/${env}/${accountId}`, formData, {
				onUploadProgress: progressEvent => {
					setUploadPercentage(Math.round(progressEvent.loaded / progressEvent.total) * 100);
					dispatch(bespokeActions.handleValuesUrlUpload());
					// SetValuesUrlUpload()
				}
			}, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
				setdisplayUpload(res.data.url);
				setMessage('Fichier Uploadé !');
				setColor('alert-success');
				setTimeout(() => {
					setMessage();
				}, 2000);
			}).catch(e => {
				console.error(e);
				setMessage('Un problème est survenu pendant le chargement verifiez le format (.png / .jpeg)');
				setColor('alert-danger');
				setTimeout(() => {
					setMessage();
				}, 4000);
			});
		}
	};
	const [displayUpload, setdisplayUpload] = useState();
	const handleCopy = () => {
		dispatch(bespokeActions.handleCopyPictureUrl(displayUpload));
		setTimeout(() => {
			dispatch(bespokeActions.handleStopMessageCopy());
		}, 1000);
	};
	return (
		<div className="upload-field">
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p className="upload-dragdrop">Drag 'n' drop a file</p>
				<div className="upload-open-folder" onClick={open}>
                    Choose a file
				</div>
			</div>
			{message ?
				<>
					<Message msg={message} color={color} />
					<Progress percentage={uploadPercentage} />
				</> :
				null
			}
			{filepath.length > 0 ?
				<>
					<div className="upload-temp-file"><span>Fichier séléctionné :</span> {filepath}</div>
					<input
						type='submit'
						value='Upload'
						className='upload-btn-confirm'
						onClick={uploadHandler}
					/>
				</> :
				null
			}

			{displayUpload &&
                <div className="upload-result-container">
                	<img src={displayUpload} alt="" />
                	<p className="upload-result-link" onClick={
                		(e) => handleCopy()}>
                		{displayUpload} <FaRegCopy /></p>
                	<button className="upload-result-close" onClick={() => { setIsClick(); setdisplayUpload(); }}>Fermer</button>
                </div>
			}
		</div>
	);
}