import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteForever } from 'react-icons/md';
import './SimplifyAccount.css';
import useAuthAxios from '../../../utils/useAuthAxios';
import Modal from './../../Ui/Modal';
import Btn from './../../Ui/Btn';
export default function SimplifyAccountItem({
	setIsExistantSetup,
	dataAccount,
	changeStatus }) {
	let envColor =
    {
    	dev: 'colorEnvDev',
    	prod: 'colorEnvProd',
    	preprod: 'coloEnvPreProd'
    };
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [domainListToPut, setDomainListToPut] = useState([]);
	const [valueDomainList, setValueDomainList] = useState('');

	useEffect(() => {
		setDomainListToPut(dataAccount.domain && dataAccount.domain);
		setValueDomainList(dataAccount.domain && dataAccount.domain.join('\n'));
	}, [dataAccount]);

	const changeTheDomainList = (e) => {
		setValueDomainList(e.target.value);
		const domainList = e && e.target.value.split(/[;,\n]/).filter(x => x.length > 0);
		setDomainListToPut(domainList);
	};
	const { authAxios } = useAuthAxios();

	return (
		<section className="simplify-item ">
			{modalIsOppen &&
                <Modal
                	isOpen={modalIsOppen}
                	width="550"
                	height="auto"
                	minHeight="196"
                	onClose={() => setModalIsOppen(false)}
                >
                	<div className="modal_header">
                		<h2>Edit domains</h2>
                	</div>
                	<div className="modal_body">
                		<textarea
                			name="domain"
                			style={{ display: 'flex', width: '80%', height: '80px', margin: 'auto' }}
                			value={valueDomainList}
                			placeholder="You can add a domain (use comma separator or one entry by line)"
                			onChange={(e) => changeTheDomainList(e)}
                		/>
                	</div>
                	<div className="modal_section_bottom modal_footer_center ">
                		<Btn
                			color="alert"
                			message="Cancel"
                			onClickFunction={() => setModalIsOppen(false)}
                		/>
                		<Btn
                			color="primary"
                			message="Enregistrer"
                			onClickFunction={() => {
                				authAxios.put(`websitesetup/${dataAccount.env}/accounts/${dataAccount.id}/status/${dataAccount.status}`, domainListToPut)
                					.then(res => {
                						setTimeout(() => {
                							//setReload(true)
                							window.location.reload();
                						}, 200);
                					})
                					.catch(e => {
                						console.error('error', e);
                					});
                			}}
                		/>
                	</div>
                </Modal>
			}
			<span className={`simplify-item-env ${dataAccount && envColor[dataAccount.env.toLowerCase()]}`}>@{dataAccount.env}</span>
			{/* <span className={`simplify-status-account`}>Status: {x.status}</span> */}
			<Link className="noLink"
				to={{ pathname: `/simplifySetup/${dataAccount.id}/${dataAccount.env}/${true}/${dataAccount.status}/` }}>
				<h2 className="simplify-name">{dataAccount.name}</h2>
			</Link>
			<div className="select-status">
				<label htmlFor="status"> Status</label>
				<select name="status" value={dataAccount.status} onChange={(e) => changeStatus(e, dataAccount)} >
					<option  > Draft</option>
					<option  > Published</option>
				</select>
			</div>

			<div>
				<ul className="domain_list">
					<div className="domaine_list_container" onClick={() => setModalIsOppen(true)}><h2>Domains</h2>
						<i className="fas fa-pen" ></i>
					</div>
					{
						dataAccount && dataAccount.domain.length > 0 ? dataAccount.domain.map((domain, i) =>
							<li key={i}>{domain}</li>
						) :
							'La liste de domaine est vide'
					}
				</ul>
			</div>
			<div className="simplify-item-btn">
				<button className="edit-simplify">
					<Link className="noLink"
						to={{ pathname: `/simplifySetup/${dataAccount.id}/${dataAccount.env}/${true}/${dataAccount.status}/` }}>
                        Edit <FiEdit />
					</Link>
				</button>
				{/* <button className="delete-simplify" >
                    Supprimer <MdDeleteForever />
                </button> */}
			</div>
		</section>
	);
}
