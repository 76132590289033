import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'
import { Link, NavLink } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import './AccountItem.css'
import BespokeItemHome from './BespokeItemHome'
import Modal from './../../Components/Ui/Modal';
import Btn from './../../Components/Ui/Btn';
import SpinnerOverlay from './../Ui/SpinnerOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { actionUI } from '../../store/ui-reducer';
import Score from '../SetUp/Score';
export default function AccountItem({ dataAccount }) {
    const dispatch = useDispatch();
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
    let envColor =
    {
        dev: "colorEnvDev",
        prod: "colorEnvProd",
        preprod: "coloEnvPreProd"
    }
    const [isReloading, setIsRealoading] = useState()
    const [modalIsOppen, setModalIsOppen] = useState(false)
    const [bespokeList, setBespokeList] = useState()
    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios();
    const [accountEnvErrors, setAccountEnvErrors] = useState();
    useEffect(() => {
        if (modalIsOppen) {
            const fetchData = async () => {
                setIsRealoading(true)
                authAxios.get(`bespoke/account/${dataAccount.env}/${dataAccount.id}`)
                    .then(resBespoke => {
                                    setBespokeList(resBespoke.data);
                                    setIsRealoading(false); 
                    }).catch(err => {
                        setIsRealoading(false)
                    })
            }
            isAuthenticated && fetchData();
        }
    }, [isAuthenticated, modalIsOppen]);
    useEffect(() => {
        if (bespokeList) {
            const fetchDataerr = async () => {
                authAxios.get(`status/${dataAccount.env}/bespoke/`)
                    .then(resErrors => {
                        setAccountEnvErrors(resErrors.data);
                    });
                }
                fetchDataerr();   
        }
    }, [bespokeList]);

    return (
        <section className="account-item ">
            {modalIsOppen &&
                <Modal
                    isOpen={modalIsOppen}
                    width="550"
                    height="auto"
                    minHeight="196"
                    onClose={() => { setModalIsOppen(false); setBespokeList() }}
                >
                    <div className="modal_header">
                        <h2>Edit SaaS Format for account {dataAccount.name}</h2>
                    </div>

                    <div className="list_bespoke_container">
                        {!isReloading &&
                            <>
                                {
                                    bespokeList && !bespokeList.length > 0 ?
                                        <>
                                            <div className="list_bespoke_empty">
                                                <p>This account does not have any SaaS Format </p>
                                                <NavLink exact to="/creation" className="btnUi btn_primary">Create a SaaS Format</NavLink>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {bespokeList && bespokeList.map((x, id) =>
                                                <article key={id} className="bespoke-item">
                                                    <BespokeItemHome
                                                        data={x}
                                                        name={x.item1.name}
                                                        accountEnvErrors={accountEnvErrors}
                                                    />
                                                </article>)}
                                        </>
                                }

                            </>
                        }
                        {
                            isReloading &&
                            <SpinnerOverlay />
                        }
                    </div>
                    {/* <div className="modal_footer modal_footer_center ">
                        <Btn
                            color="alert"
                            message="Cancel"
                            onClickFunction={() => { setModalIsOppen(false); setBespokeList() }}
                        />
                    </div> */}
                </Modal>
            }
            {isBeyableAccount &&
                <span className={`account-item-env ${dataAccount && envColor[dataAccount.env.toLowerCase()]}`}>@{dataAccount.env}</span>}
            <h2 className="account-name">{dataAccount.name !== null ? dataAccount.name : "(Empty)"}</h2>
            <div className='d-flex justify-center'>
                    {dataAccount?.score && 
                    <>
                    <div class="text-center">
                     <Score score={dataAccount?.score.score} tailleEtoile="18px"  />
                     <p className="font-weight-bold pl-2 mb-0" style={{ fontSize: '.85rem'}}>Scoring : {(dataAccount?.score.score / 40 * 100).toFixed(0)}%</p> {/* Affichage en pourcentage */}
                     </div>
                    </>
                    }
            </div>
            {/* <span className={`simplify-status-account`}>Status: {x.status}</span> */}
            {
                <div className="account-item-btn">
                    <Link className="edit-account noLink"
                        to={{ pathname: `/campaign/${dataAccount.id}/${dataAccount.env}/home` }}>
                        Campaigns <FiEdit />
                    </Link>
                </div>
            }
            {
                dataAccount.statusSimplify[0] && isBeyableAccount &&
                <div className="account-item-btn">
                    {/* <Link className="noLink"
                        to={{ pathname: `/setupSimplify/${dataAccount.id}/${dataAccount.env}/${dataAccount.name}/${dataAccount.statusSimplify[0].status}` }}>
                        Edit Simplify setup <FiEdit />
                    </Link> */}
                    <Link className="edit-account noLink"
                        to={{ pathname: `/simplifySetup/${dataAccount.id}/${dataAccount.env}/${true}/${dataAccount.statusSimplify[0].status}/` }}>
                        Simplify setup <FiEdit />
                    </Link>
                </div>
            }
            {
                !dataAccount.statusSimplify[0] && isBeyableAccount &&
                <div className="account-item-btn">
                    <Link className="edit-account noLink"
                        to={{ pathname: `/creationSimplifySetup` }}>
                        Simplify setup <FiEdit />
                    </Link>
                </div>
            }
            <div className="account-item-btn">
                <a className="edit-account" onClick={() => setModalIsOppen(true)} >SaaS Formats </a>
            </div>
            {isBeyableAccount &&
                <div className="account-item-btn">
                    <Link className="edit-account noLink"
                        to={{ pathname: `/accountApi/${dataAccount.env}/${dataAccount.id}` }}>
                        Setup script <FiEdit />
                    </Link>
                </div>
            }
            {isBeyableAccount &&
                <div className="account-item-btn">
                    <Link className="edit-account noLink"
                        to={{ pathname: `/setupConfiguration/${dataAccount.env}/${dataAccount.id}/${dataAccount.name}` }}>
                        Configuration <FiEdit />
                    </Link>
                </div>
            }
        </section>
    )
}
