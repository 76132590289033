import React, { useContext } from 'react';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';

import './SimplifyEditionDataContainer.css';
import uuid from 'react-uuid';
import Btn from '../../Ui/Btn';
export default function SimplifyEditionDataContainer() {
	const {
		setScreenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		setdeviceSelected,
		isNewScreenShot,
		arrayOfZones,
		setPanelTypeIsOpen,
	} = useContext(creationSetupSimplify);

	const handleSelectDevice = (device) => {
		if (!isNewScreenShot) {
			setdeviceSelected(device);
			let newId = screenShotToChange.find(x => x.device === device);
			setScreenShotId(newId.screenshotId);
			// register()
		} else {
			setdeviceSelected(device);
			let newId = screenShotToChange.find(x => x.device === device);
			if (newId) {
				setScreenShotId(newId.screenshotId);
			} else {
				const newScreenShot = {
					accountId: screenShotToChange[0].accountId,
					device: device,
					imagePath: '',
					name: screenShotToChange[0].name,
					pageType: screenShotToChange[0].pageType,
					screenshotExtensions: { width: '', height: '' },
					thumbnailPath: '',
					zones: [],
					screenshotId: uuid(),
					isChange: true,
					isNewScreenShot: true
				};
				setscreenShotToChange([...screenShotToChange, newScreenShot]);
				setScreenShotId(newScreenShot.screenshotId);
			}
		}
	};
	return (
		<div>
			<div className="devicesContainer">
				<div>
					{arrayOfZones && arrayOfZones.length !== 0 ? ( arrayOfZones.length > 1 ? arrayOfZones.length + ' zones' : arrayOfZones.length + ' zone') : ''}

				</div>
				<div className={deviceSelected === 'mobile' ? 'isDeviceSelected' : ''} onClick={() => {
					// register();
					handleSelectDevice('mobile');
				}}>
					<p >Mobile</p>
				</div>
				<div className={deviceSelected === 'desktop' ? 'isDeviceSelected' : ''} onClick={() => {
					// register();
					handleSelectDevice('desktop');
				}}>
					<p >Desktop</p>
				</div>
				< Btn
                        	message=''
                        	color='primary'
                        	icon='fas fa-pen'
                        	onClickFunction={() => setPanelTypeIsOpen(true)}
				/>
			</div>

		</div>
	);
}
