import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import BespokeCreationContainerAdmin from './BespokeCreationAdmin';
import BespokeCreationNonAdmin from './BespokeCreationNonAdmin';


export default function BespokeCreationContainer() {

	const {
		isBeyableAccount
	} = useSelector((state) => state.ui);

	return (
		<>
			{isBeyableAccount &&
                <BespokeCreationContainerAdmin />
			}
			{!isBeyableAccount &&
                <BespokeCreationNonAdmin />
			}
		</>
	);
}
