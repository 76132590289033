import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import style from './Sidebar.module.css';
import Switch from './Switch';
import MenuSelection from '../Campaign/MenuSelection';
import BespokeEditionDescriptionCard from '../Bespokes/Edition/BespokeEditionDescriptionCard';
import BespokeCreationDescriptionCard from '../Bespokes/Creation/BespokeCreationDescriptionCard';
import { FaExternalLinkSquareAlt, FaImage, FaInfoCircle, FaBan, FaDesktop, FaMobileAlt, FaExchangeAlt, FaTrash, FaCog, FaCogs } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { bespokeActions } from '../../store/bespokeProperty-slice';
export default function Sidebar({
	selectDevice,
	deviceSelected,
	isDifferent = false,
	onClickDiff = false,
	seeDiff = false,
	reloadData = false,
	openUploadPicture,
	dataCampaign = false,
	setDataCampaign = false,
	setDataCampaigns = false,
	linkToBo = false,
	datasSlidesOfCampaignSelected = false,
	env,
	isNewBespoke,
	accountId,
	handleResponsive,
	isResponsiveValue = false,
	setisOpen,
	isOpen,
	isBespokePage = false,
	openUrlCloseModale,
	accountName,
	modificationDate,
	creationDate,
	envColor,
	valuesBespokeDescription,
	handleChangeStatusForBespoke,
	toggleConfiguration,
	oppenCloseModaleDelete,
	isLiquid
}) {

	const idTool = uuidv4();
	const [responsiveToggle, setResponsiveToggle] = useState(<></>);
	const dispatch = useDispatch();
	const {
		pannelIsOpen
	} = useSelector((state) => state.bespoke);
	const { isEmptyState } = useSelector(state => state.campaign);
	const isSelected = (device) => {
		return deviceSelected === device && !seeDiff ? style.selected : '';
	};
	const changeDevice = (device) => {
		if (device === 'mobile' && isResponsiveValue === true) {
			return;
		}
		if (seeDiff) {
			onClickDiff();
		}
		if (deviceSelected !== device) {
			selectDevice(device);
		}
	};
	const toggleMenuInformations = () => {
		if (!isBespokePage) {
			setisOpen(!isOpen);
		} else {
			dispatch(bespokeActions.handlePanelIsOpen(pannelIsOpen ? false : true));
		}
	};

	useEffect(() => {
		if (!isBespokePage) {
			if (isResponsiveValue) {
				changeDevice('desktop');
			}
			setResponsiveToggle(
				<div className={`${style.items} ${style.responsive}`}>
					<span className={style.title}>Responsive</span>
					<Switch
						forcedChecked={isResponsiveValue === true}
						onChange={(e) => handleResponsive(e)}
					/>
				</div>
			);
		} else if (isBespokePage) {
			if (isResponsiveValue) {
				changeDevice('desktop');
			}
			setResponsiveToggle(
				<div className={`${style.items} ${style.responsive}`}>
					<span className={style.title}>Responsive</span>
					<Switch
						forcedChecked={isResponsiveValue === true}
						onChange={(e) => handleResponsive(e)}
					/>
				</div>
			);
		} else {
			setResponsiveToggle(
				<></>
			);
		}
	}, [isResponsiveValue, isBespokePage]);

	return (
		<div className={`${style.sidebar_container} flex_item_fix flex flex_align_stretch flex_justify_start`}>
			<div className={style.sidebar}>
				<IconContext.Provider
					value={{
						color: '#5D606F',
						size: '16px',
						className: `${style.icons}`,
					}}
				>
					{responsiveToggle}

					<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />

					<div className={`${style.items} ${style.device}`}>
						<span className={style.title}>Device</span>
						<div className={style.device_container}>
							<div
								className={`${style.device_items} ${isSelected(
									'desktop'
								)}`}
								onClick={() => { changeDevice('desktop'); }}
							>
								<FaDesktop data-for={idTool} data-tip={'Switch to desktop version'} />
							</div>

							<div
								className={`${style.device_items} ${isSelected('mobile')} ${isResponsiveValue ? style.disabled : ''} `}
								onClick={() => {
									changeDevice('mobile');
								}}
							>
								<FaMobileAlt data-for={idTool} data-tip={'Switch to mobile version'} />
							</div>

							{isDifferent &&
                                !isResponsiveValue && (
								<div
									className={`${style.device_items} ${seeDiff ? style.selected : ''}`}
									onClick={onClickDiff}
								>
									<FaExchangeAlt data-for={idTool} data-tip={'Compare desktop and mobile'} />
								</div>
							)}
						</div>
					</div>
					{reloadData && !isEmptyState && (
						<div
							className={`${style.items} ${style.reload}`}
							onClick={reloadData}
						>
							<FaBan data-for={idTool} data-tip={'Cancel changes (reset to initial state)'} />
                            Cancel
						</div>
					)}
					{!isBespokePage && !isEmptyState && <div
						className={`${style.items} ${style.info} ${isOpen ? style.isOpen : ''
						}`}
						onClick={() => toggleMenuInformations()}
					>
						<FaInfoCircle data-for={idTool} data-tip={'Preview the format'} />
                        Info
					</div>}
					{isBespokePage && <div
						className={`${style.items} ${style.info} ${isOpen ? style.isOpen : ''
						}`}
						onClick={() => toggleMenuInformations()}
					>
						<FaCog data-for={idTool} data-tip={'Format configuration'} />
                        Config
					</div>}
					<div
						className={`${style.items} ${style.gallery}`}
						onClick={openUploadPicture}
					>
						<FaImage data-for={idTool} data-tip={'Open the image gallery'} />
                        Gallery
					</div>
					{isBespokePage && <>
						{!isLiquid && <div
							className={`${style.items} ${style.prev} `}
							onClick={openUrlCloseModale}
						>
							<FaExternalLinkSquareAlt data-for={idTool} data-tip={'Preview the format'} />
                            Preview
						</div>}
						<div
							className={`${style.items} ${style.delete} `}
							onClick={() => oppenCloseModaleDelete()}
						>
							<FaTrash data-for={idTool} data-tip={'Delete format'} />
                            Delete
						</div>
						{
							!isLiquid
                            &&
                            <div
                            	className={`${style.items} ${style.delete} `}
                            	onClick={toggleConfiguration}
                            >
                            	<FaCogs data-for={idTool} data-tip={'Format default properties'} />
                                Properties
                            </div>}
						<div className={`${style.items} ${style.responsive}`}>
							<span className={style.title}>Publish</span>
							<div data-for={idTool} data-tip={'Publish format to backoffice (make it visible)'}>
								<Switch
									forcedChecked={valuesBespokeDescription.publicationState ===
                                        1
										? true
										: false}
									onChange={handleChangeStatusForBespoke}
								/>
							</div>
						</div>
					</>}
				</IconContext.Provider>
			</div>
			<div className={style.sidebar_campaign_information}>
				{!isBespokePage && dataCampaign && isOpen && (
					<MenuSelection
						dataCampaign={dataCampaign}
						setDataCampaign={() => setDataCampaign()}
						setDataCampaigns={() => setDataCampaigns()}
						linkToBo={linkToBo}
						datasSlidesOfCampaignSelected={datasSlidesOfCampaignSelected}
						env={env}
						accountId={accountId}
					/>
				)}
				{isBespokePage && isNewBespoke && pannelIsOpen && (
					<>
						<BespokeCreationDescriptionCard
							envColor={envColor}
							accountId={accountId}
							env={env}
						/>
					</>
				)}

				{isBespokePage && !isNewBespoke && pannelIsOpen && (
					<>
						<BespokeEditionDescriptionCard
							envColor={envColor}
							env={env}
							creationDate={creationDate}
							modificationDate={modificationDate}
							accountId={accountId}
							accountName={accountName}
						/>
					</>
				)}
			</div>
		</div>

	);
}