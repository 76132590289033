import React from 'react';
import { DiffEditor as MonacoDiffEditor } from '@monaco-editor/react';
import options from './options';
export default function MergingCodeContainer({ val, theme }) {

	return (
		<div>
			<MonacoDiffEditor
				height="80vh"
				options={options}
				language="javascript"
				original={val.original}
				modified={val.apiPreview}
				theme={theme}
			/>
		</div>
	);
}
