import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../utils/useAuthAxios';
import Message from '../Ui/Message';
import Progress from '../Ui/Progress';
import './UploadPicture.css';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../store/bespokeProperty-slice';
import { bespokeFormActions } from '../../store/bespokeForm-slice';
const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};
const activeStyle = {
	borderColor: '#2196f3'
};
const acceptStyle = {
	borderColor: '#00e676'
};
const rejectStyle = {
	borderColor: '#ff1744'
};
const img = {
	display: 'block',
	margin: 'auto',
	width: '25%',
	height: '25%'
};
function UploadContainer({ env, accountId, code, changetoggleUpload, bespokeId }) {

	const { valuesUrl, isNewBespoke,

	} = useSelector((state) => state.bespoke);
	const dispatch = useDispatch();
	const [selectedFile, setSelectedFile] = useState([]);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');
	const [imagePreview, setImagePreview] = useState();
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();

	let urlToFetch = '';

	if (!isNewBespoke) {
		urlToFetch = `displaylibrary/account/${env}/${accountId}/thumbnail/${code}`;
	}
	else {
		urlToFetch = `displaylibrary/account/${env}/${accountId}/thumbnail`;
	}

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		open
	} = useDropzone({
		accept: 'image/*',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFile(
				acceptedFiles[0]

			);
		}
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	const filepath = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));
	const uploadHandler = (e) => {
		e.preventDefault();
		if (!isAuthenticated)
			return;
		let formData = new FormData();
		formData.append(
			'file',
			selectedFile
		);
		if (!isNewBespoke) {
			authAxios.post(`${urlToFetch}`, formData, {
				onUploadProgress: progressEvent => {
					setUploadPercentage(Math.round(progressEvent.loaded / progressEvent.total) * 100);
					// SetValuesUrlUpload()
					dispatch(bespokeActions.handleValuesUrlUpload());
				}
			}, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
				setMessage('Upload succeeded!');
				setColor('alert-success');
				dispatch(
					bespokeActions.handleValuesBespokeDescription({
						thumbnailUrl: res.data.path,
					})
				);

				dispatch(
					bespokeActions.handleValuesUrlUpload(res.data.url));
				setTimeout(() => {
					dispatch(bespokeActions.handleValuesUrlUpload());
				}, 2500);
				setImagePreview(res.data.url);
				setTimeout(() => {
					setMessage();
					changetoggleUpload();
				}, 2000);
			}).catch(e => {
				console.error(e);
				setMessage('A problem occured, check file format (accepted .png, .jpeg)');
				setColor('alert-danger');
				setTimeout(() => {
					setMessage();
					changetoggleUpload();
				}, 4000);
			});
		}
		else {
			authAxios.post(`${urlToFetch}`, formData, {
				onUploadProgress: progressEvent => {
					setUploadPercentage(Math.round(progressEvent.loaded / progressEvent.total) * 100);
				}
			}, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
				setMessage('Upload succeeded!');
				setColor('alert-success');
				dispatch(bespokeActions.handleValuesUrl([res.data.path, res.data.url]));
				// SetValuesUrl(
				//     [res.data.path, res.data.url]
				// )
				setImagePreview(res.data.url);
				setTimeout(() => {
					setMessage();
					changetoggleUpload();
				}, 2000);
			}).catch(e => {
				console.error(e);
				setMessage('A problem occured, check file format (accepted .png, .jpeg)');
				setColor('alert-danger');
				setTimeout(() => {
					setMessage();
					changetoggleUpload();
				}, 4000);
			});
		}
	};
	// extract from context for redux
	useEffect(() => {
		const changeUrlValues = () => {
			dispatch(bespokeFormActions.handleNewBespokeValues({ thumbnailUrl: valuesUrl && valuesUrl[0] }));
		};
		changeUrlValues();
	}, [valuesUrl]);

	return (

		<>
			<div className="border p-4">

				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop un fichier</p>
					<div className="btn btn-dark" onClick={open}>
                        Choose a file
					</div>
				</div>
				{message ? <Message msg={message} color={color} /> : null}
				<Progress percentage={uploadPercentage} />
				<img src={imagePreview} style={img} alt='' />
				<aside>
					<h5 className="text-center"></h5>
					<p>{filepath}</p>
				</aside>
				<input
					type='submit'
					value='Upload'
					className='btn btn-dark btn-block mt-1 mb-2'
					onClick={uploadHandler}
				/>
			</div>

		</>
	);
}

export default UploadContainer;
