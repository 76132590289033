import React, { useEffect, useState } from 'react'
import Modal from '../../Ui/Modal';
import { campaignActions } from '../../../store/campaign-reducer';
import { useDispatch, useSelector } from 'react-redux';
import Btn from '../../Ui/Btn';
import { v4 as uuidv4 } from 'uuid';
import classes from './EditCampaignTestMode.module.css';
import useAlert from '../../../Hooks/useAlert';
export default function EditCampaignTestMode({ campaignService, reloadCampaignList }) {

    const { createAlerte } = useAlert();

    const dispatch = useDispatch();
    const { modalIpsIsOpen: modalTestModeIsOpen, campaignSelected: currentCampaign
    } = useSelector(state => state.campaign);
    const openCloseModalTestMode = (value) => {
        dispatch(campaignActions.openCloseModalIps(value))
    }
    const [testModeIpAddressesArray, setTestModeIpAddressesArray] = useState([]);
    const [arrayOfIpForActualCampaign, setarrayOfIpForActualCampaign] = useState([]);
    const [addnewIpAdresse, setaddnewIpAdresse] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [adressToDelete, setadressToDelete] = useState()
    const [isEditionMode, setisEditionMode] = useState(false);
    const [currentIpMessage, setcurrentIpMessage] = useState(<></>);
    const [errorRegisterChange, seterrorRegisterChange] = useState(false);
    const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
    const [editingAdress, seteditingAdress] = useState({
        index: '',
        ipAddress: '',
        label: ''
    });
    const [currentIP, setCurrentIp] = useState();
    const getCurrentIp = () => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(function (data) {
                setCurrentIp(data.ip);
            })
    };
    const transformIps = () => {
        let arrayIp = currentCampaign?.testIps.map((x, i) => ({ ...x, index: i }))
        const arrayIpSelected = arrayIp && arrayIp.filter(c => !c.isDisabled).map(el => {
            const getIndex = arrayIp.find(ipAdd => ipAdd.IpAddress === el.ipAddress && ipAdd.Label === el.label)
            if (getIndex) {
                el.index = getIndex.index;
            }
            return el
        })
        return arrayIpSelected
    }
    useEffect(() => {
        getCurrentIp()
        setTestModeIpAddressesArray(transformIps())
    }, [currentCampaign, modalTestModeIsOpen])
    const handleChangeIpAdresseByCopy = (e) => {
        seteditingAdress(c => ({
            ...c, ipAddress: e
        }))
    }
    const editIpAdresse = (ipAdress) => {
        seteditingAdress({ ...ipAdress });
        setisEditionMode(true);
    }
    const handleChangeIpAdresse = (e) => {
        console.log();
        let newObj = { ...editingAdress }
        newObj[e.target.name] = e.target.value
        seteditingAdress(newObj)
    }
    const createNewAdresse = () => {
        setaddnewIpAdresse(true);
        setisEditionMode(true);
        seteditingAdress({
            ipAddress: "",
            label: "",
            isDisabled: false,
            index: uuidv4()
        })
    }
    const cancel = () => {
        setaddnewIpAdresse(false);
        setisEditionMode(false);
        seteditingAdress({
            ipAddress: "",
            label: "",
            isDisabled: false
        })
    }
    const ValidNewIpAdress = () => {
        const actualIpArray = [...testModeIpAddressesArray];
        setTestModeIpAddressesArray([editingAdress, ...actualIpArray]);
        setaddnewIpAdresse(false);
        setisEditionMode(false);
        seteditingAdress({
            ipAddress: "",
            label: "",
            isDisabled: false
        })
    }
    const ValidExistantIpAdress = () => {
        const actualIpAdressArray = [...testModeIpAddressesArray];
        let addressToChange = actualIpAdressArray.find(el => el.index === editingAdress.index);
        const indexOfItem = actualIpAdressArray.indexOf(addressToChange);
        function replaceAt(array, index, value) {
            const ret = array.slice(0);
            ret[index] = value;
            return ret;
        }
        const newArrayOfCampaign = replaceAt(actualIpAdressArray, indexOfItem, editingAdress);
        setTestModeIpAddressesArray(newArrayOfCampaign);
        setisEditionMode(false);
        seteditingAdress({
            ipAddress: "",
            label: "",
            isDisabled: false
        })
    }
    const deleteIp = (key) => {
        const newArr = testModeIpAddressesArray.filter(ip => ip.index !== key);
        setTestModeIpAddressesArray(newArr)
    }
    const register = () => {
        setisLoading(true);
        seterrorRegisterChange(false);
        const newIpForAccount =
            testModeIpAddressesArray.map(ip => ({
                ipAddress: ip.ipAddress,
                label: ip.label,
                isDisabled: ip.isDisabled
            }));


        campaignService.updateIps(newIpForAccount,
            success => {
                setisLoading(false);
                createAlerte("success", "The ips has been successfully changed", {
                    removeAllBeforeShow: false,
                });
                openCloseModalTestMode(false);
                reloadCampaignList();
            }, err => {
                createAlerte("error", "Error !", {
                    removeAllBeforeShow: false,
                });
                setisLoading(false);
            })
    }
    const handleChangeAdressIpToList = (e, ip) => {
        const target = e.target;
        const value = target.checked;
        const item = testModeIpAddressesArray.find(el => el.index === ip.index);
        item.isDisabled = !value;
        const newArrofIps = testModeIpAddressesArray.filter(el => el.index !== ip.index);
        setTestModeIpAddressesArray([...newArrofIps, item]);

    }
    const sortAsc = (a, b) => {
        if (a && b) {
            if (a.index > b.index) return -1;
            if (a.index < b.index) return 1;
        }
        return 0;
    }

    return (
        <Modal
            isOpen={modalTestModeIsOpen}
            width="700"
            // height='700'
            onClose={() => openCloseModalTestMode(false)}
        >
            <div className="modal_header has_border">
                Starting options
                <div className="modal_header_desc">{currentCampaign?.name}</div>
            </div>
            <div className={`modal_body ${classes.modal_content}`}>


                {<>
                    <div className="flex flex_align_end mb_20 mt_20">
                        <div className="flex_item_full">
                            <p>This campaign will be visible by only those IP addresses.</p>
                            {currentIpMessage}
                        </div>
                        <div className="flex_item_fix ml_20">
                            <button className={classes.starting_options_action} onClick={createNewAdresse}>
                                <i className="fas fa-plus"></i>
                                Add new address
                            </button>
                        </div>
                    </div>
                    <div className="ip_address_table">
                        <div className="flex flex_justify_between table-header">
                            <p>IP Address</p>
                            <p>Name</p>
                            <p></p>
                        </div>
                        {testModeIpAddressesArray && testModeIpAddressesArray.length == 0 && < div className="starting_options_no_ip">
                            No IP address is set
                        </div>}
                        {addnewIpAdresse && <div className={`${classes.table_row} ${classes.table_body} ${classes.editing}`} >
                            <p style={{ display: 'flex', flexDirection: 'column' }}>
                                <input type="text" id="ipAddressName"
                                    name="ipAddress"
                                    value={editingAdress.ipAddress}
                                    onChange={(e) => handleChangeIpAdresse(e)}
                                    autoFocus=""
                                    className={classes.ip_input}/>
                                {editingAdress.ipAddress !== currentIP &&
                                    <span className={`${classes.containerIP} flex flex_v`}>
                                        <span>Your current IP address</span>
                                        <button className={classes.ip_button} onClick={(e) => handleChangeIpAdresseByCopy(currentIP)}>{currentIP}< i className="fas fa-level-up-alt"></i></button>
                                    </span>}
                            </p>
                            <p><input type="text"
                                name="label"
                                value={editingAdress.label}
                                onChange={(e) => handleChangeIpAdresse(e)}
                                autoFocus=""
                                id="ipAddressLabel"
                                className={classes.ip_input}/>
                                {editingAdress.ipAddress !== currentIP && <span className={classes.containerIP}></span>}
                            </p>
                            <p className="al_right">
                                <a className="starting_options_icon" title="Save"
                                    onClick={ValidNewIpAdress}>
                                    <i className="fas fa-save"></i>
                                </a>
                                <a className="starting_options_icon" title="Cancel"
                                    onClick={cancel}>
                                    <i className="fas fa-times"></i>
                                </a>
                            </p>
                        </div>}
                        {testModeIpAddressesArray && testModeIpAddressesArray.sort(sortAsc).map(ipAdresse =>
                            <div className={ipAdresse.ipAddress == currentIP ? `${classes.table_body} ${classes.table_row} fw_medium` : `${classes.table_body} ${classes.table_row}`} key={ipAdresse.index}>
                                {editingAdress.index === ipAdresse.index ? <>
                                    <p>
                                        <input type="text" id="ipAddressName"
                                            name="ipAddress"
                                            value={editingAdress.ipAddress}
                                            onChange={(e) => handleChangeIpAdresse(e)}
                                            autoFocus="" />
                                    </p>
                                    <p>
                                        <input type="text" name="label"
                                            value={editingAdress.label}
                                            onChange={(e) => handleChangeIpAdresse(e)}
                                            autoFocus=""
                                            id="ipAddressLabel" />
                                    </p>
                                    <p className="al_right">
                                        <a className="starting_options_icon" title="Save" onClick={ValidExistantIpAdress}>
                                            <i className="fas fa-save"></i>
                                        </a>
                                        <a className="starting_options_icon" title="Cancel" onClick={cancel}>
                                            <i className="fas fa-times"></i>
                                        </a>
                                    </p>
                                </>
                                    :
                                    <>
                                        <p>
                                            <label className="cb_label flex_inline">
                                                <input
                                                    type="checkbox"
                                                    name="ipEnabled"
                                                    checked={!ipAdresse.isDisabled}
                                                    onChange={(e) => handleChangeAdressIpToList(e, ipAdresse)} />
                                                <span className="cb_label_icon flex_item_fix flex_item_align_start"></span>
                                                <span className="cb_label_text flex_item_auto">
                                                    {ipAdresse.ipAddress}
                                                </span>
                                            </label>
                                        </p>
                                        <p>
                                            {ipAdresse.label}
                                        </p>
                                        <p className="al_right">
                                            {!isEditionMode && !addnewIpAdresse && <>
                                                <a className="starting_options_icon" ng-show="ipAddress.Source === 'CAMPAIGN'"
                                                    onClick={() => editIpAdresse(ipAdresse)} title="Edit">
                                                    <i className="fas fa-pen"></i>
                                                </a>
                                                <a className="starting_options_icon" ng-show="ipAddress.Source === 'CAMPAIGN'"
                                                    onClick={() => deleteIp(ipAdresse.index)} title="Cancel">
                                                    <i className="fas fa-trash-alt"></i>
                                                </a> </>
                                            }
                                        </p>
                                    </>
                                }
                            </div>)}
                    </div> </>}
            </div>
            <div className="modal_footer modal_footer_center">
                <Btn
                    onClickFunction={() => openCloseModalTestMode(false)}
                    message="Cancel"
                    color="secondary"
                    size="l"
                    style="outline"
                    className="mr_10"
                />
                {
                    <Btn
                        disabledBtn={isEditionMode}
                        onClickFunction={!isEditionMode ? () => register() : () => { }
                        }
                        message="Confirm"
                        size="l"
                        color="primary"
                    />
                }
            </div>

            {isLoading &&
                <div className="spinner_overlay">
                    <div className="spinner">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                        <div className="rect4"></div>
                        <div className="rect5"></div>
                    </div>
                </div>
            }
        </Modal>
    )
}
