import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
// import envConfig from '../env.js';
// import { REACT_APP_API_URL } from '../env';
const useAuthAxios = () => {
	//const API_URL = process.env.REACT_APP_API_URL;
	const API_URL = window.envConfig.API_URL;

	const { getAccessTokenSilently } = useAuth0();

	const buildOptions = async (Options) => {
		const token = await getAccessTokenSilently();
		var options = Options || {};
		options.headers = options.headers || {};
		options.headers.Authorization = `Bearer ${token}`;
		return options;
	};
	const buildOptionsV2 = async (Options) => {
		const token = await getAccessTokenSilently();
		var options = Options || {};
		options.headers = options.headers || {};
		options.headers.Authorization = `Bearer ${token}`;
		options.headers['X-Version'] = '2.0';
		return options;
	};

	return {
		authAxios: {
			get: async (url, options) => {
				options = await buildOptions(options);
				return await axios.get(`${API_URL}${url}`, options);
			},
			put: async (url, data, options) => {
				options = await buildOptions(options);
				return await axios.put(`${API_URL}${url}`, data, options);
			},
			post: async (url, data, options) => {
				options = await buildOptions(options);
				return await axios.post(`${API_URL}${url}`, data, options);
			},
			delete: async (url, options) => {
				options = await buildOptions(options);
				return await axios.delete(`${API_URL}${url}`, options);
			}
		},
		authAxiosV2: {
			get: async (url, options) => {
				options = await buildOptionsV2(options);
				return await axios.get(`${API_URL}${url}`, options);
			},
			put: async (url, data, options) => {
				options = await buildOptionsV2(options);
				return await axios.put(`${API_URL}${url}`, data, options);
			},
			post: async (url, data, options) => {
				options = await buildOptionsV2(options);
				return await axios.post(`${API_URL}${url}`, data, options);
			},
			delete: async (url, options) => {
				options = await buildOptionsV2(options);
				return await axios.delete(`${API_URL}${url}`, options);
			}
		}
	};
};


export default useAuthAxios;
