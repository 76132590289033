import React, { useContext, useEffect, useState } from 'react';
import './NavSetup.css';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';
import uuid from 'react-uuid';
import Btn from '../../Ui/Btn';

export default function NavSetup({ accountId, addNewPageType , isNewScreenShot}) {
	const {
		screenShots,
		setScreenShots,
		setScreenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		register,
		setchangeIsSaved,
		setNewPageTypeIsOppen
		
	} = useContext(creationSetupSimplify);

	const [arrayOfTypes, setarrayOfTypes] = useState([]);

	const sortScreenShots = () => {
		let array = [];
		const sortArray = () => {
			const setupScreens = [...screenShots];

			setupScreens.forEach(
				x => {
					if (!array.includes(x.name) && x.name !== '') {
						array.push(x.name);
					}
				});
			return array;
		};
		sortArray();
		setarrayOfTypes(array);
	};

	useEffect(() => {
		sortScreenShots();
	}, [screenShots]);

	function sortArrayDesc(a, b) {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	}

	const handleChangeScreenShotId = (page) => {
		let changeActualScreenShotMobile = screenShots.find(screenShot =>
			screenShot.name === page && screenShot.device === 'mobile');
		let changeActualScreenShotDesktop = screenShots.find(screenShot =>
			screenShot.name === page && screenShot.device === 'desktop');
		if (!changeActualScreenShotMobile) {
			changeActualScreenShotMobile = {
				accountId: accountId,
				device: 'mobile',
				imagePath: '',
				name: changeActualScreenShotDesktop.name,
				pageType: changeActualScreenShotDesktop.pageType,
				screenshotExtensions: { width: '', height: '' },
				thumbnailPath: '',
				zones: [],
				screenshotId: uuid(),
				isChange: true,
				isNewScreenShot: true
			};
		}
		if (!changeActualScreenShotDesktop) {
			changeActualScreenShotDesktop = {
				accountId: accountId,
				device: 'desktop',
				imagePath: '',
				name: changeActualScreenShotMobile.name,
				pageType: changeActualScreenShotMobile.pageType,
				screenshotExtensions: { width: '', height: '' },
				thumbnailPath: '',
				zones: [],
				screenshotId: uuid(),
				isChange: true,
				isNewScreenShot: true
			};
		}
		let newScreenShot = screenShots.filter(screens => screens.name !== screenShotToChange[0].name);
		newScreenShot.push(...screenShotToChange);
		setScreenShots(newScreenShot.sort(sortArrayDesc));
		const id = deviceSelected === 'mobile' ? changeActualScreenShotMobile.screenshotId : changeActualScreenShotDesktop.screenshotId;
		setScreenShotId(id);
		changeActualScreenShotMobile.isChange = true;
		changeActualScreenShotDesktop.isChange = true;
		setscreenShotToChange([changeActualScreenShotMobile, changeActualScreenShotDesktop]);
		register();
		setchangeIsSaved(true);
	};
	return (
		<nav className="navigation thumbail-nav">
			<div>
				<ul className={isNewScreenShot ? 'pageType-hide-menu' : ''}>
					{arrayOfTypes && arrayOfTypes.sort(sortArrayDesc).map((screenShot, i) =>
						<li className={screenShotToChange[0].name === screenShot ? 'navLink pageTypeActive' : 'navLink'}
							onClick={() =>
								handleChangeScreenShotId(screenShot)
							} key={i}><div>{screenShot} </div></li>
					)}
				</ul>
				{!isNewScreenShot &&
                < Btn
                	message='Add a page type'
                	color='primary'
                	icon='fas fa-plus-square'
                	onClickFunction={() => addNewPageType()}
                />}
				{screenShotToChange && screenShotToChange.length > 0 && <Btn
      		message="Editing tags"
      		onClickFunction={() => setNewPageTypeIsOppen(true)} />}
			</div>
		</nav>
	);
}
