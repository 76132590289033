import { useState, useEffect } from 'react';
import moment from 'moment';

export default function useSearch(data) {
	const [search, setSearch] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();

	useEffect(() => {
		setDataFiltered(data);
	}, [data]);

    const handleChangeSearchBar = (e, btn_status) => {
		setSearch(newVal => e);
		handleSearch(data, e, btn_status);
	};

	const handleBtnFilter = (filtres) => {
		if (!data) return;
        handleSearch(data, search, filtres);
	};

	const checkStatus = (campaign, btn_status) => {
		const today = moment();
		let status = '';

		if (campaign.isStopped) {
			status = 'Stopped';
		}
		else if (campaign.isTest === 2) {
			status = 'Draft';
		}
		else if (moment(campaign.endDate) < today) {
			status = 'Past';
		}
		else if (moment(campaign.startDate) > today && !campaign.isStopped) {
			status = 'Scheduled';
		}
		else {
			status = 'Ongoing';
		}
		if (btn_status[status.toLowerCase()] === true) return campaign;
		return false;
	};

    const data_treatment = (result) => {
        let finalData = [
            ...result
        ];
        let uniq1 = [...new Set(finalData)];
        setDataFiltered(uniq1);
        if (finalData.length === 0) {
            let resultend = 'Il n\'y a pas de résultats pour cette recherche';
            setNoData(resultend);
        }
        setNoData();
    }

	// fonction de recherche dans le tableau
    const handleSearch = (table, word, btn_status) => {

		if (word.length > 0) {
			let resultName = table.filter(
				line =>
					line.name !== null && line.name.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let resultDesc = table.filter(
				line =>
					line.description !== null && line.description.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let resultId = table.filter(
				line =>
					line.id !== null && line.id.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
            
			let dataFinal = [
				...resultName,
				...resultDesc,
				...resultId,
			];

            let result_search = dataFinal.filter(
                campaign => checkStatus(campaign, btn_status)
            );

            data_treatment(result_search);

		} else {
            let result_search = table.filter(
                campaign => checkStatus(campaign, btn_status)
            );

            data_treatment(result_search);

		}
	};
	return { noData, dataFiltered, search, handleChangeSearchBar, handleBtnFilter };
}
