import React, { useState } from 'react'
import Btn from './../Ui/Btn';
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'
import './EditorCodeContainer.css'
export default function FormCreateScript({ setIsRealoading, env, accountId, setRegisterChange, setIsCreationScript }) {
    const [scriptName, setScriptName] = useState('')
    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios()
    const handleChangeForm = (e) => {
        e.persist()
        const newState = e.target.value
        setScriptName(newState)
    }
    const createAsnewScript = () => {
        if (isAuthenticated) {
            setIsRealoading(true)
            const AccountApiCreate = { scriptNameSuffix: scriptName }
            authAxios.post(`/accountapi/${env}/${accountId}`, AccountApiCreate)
                .then(res => {
                    setIsRealoading(false)
                    setIsCreationScript(false)
                    setRegisterChange(true)
                }).catch(err => {
                    console.log("err", err)
                    setIsRealoading(false)
                }
                )
        }
    }

    return (
        <div className="form_create_script">
            <form>
                <div className="input-goupe-pagetype">
                    <label htmlFor="SenderEmail">Enter the suffix</label>
                    <div className="suffix_input">
                        <div>
                            <input
                                type="text"
                                className="input-text_create_script"
                                name="scriptName"
                                onChange={(e) => handleChangeForm(e)}
                                value={scriptName}
                            />
                        </div>
                    </div>


                </div>
                <div className="container_btn_create_script">
                    <Btn
                        message='Cancel'
                        color="alert"
                        onClickFunction={() => { setIsCreationScript(false) }}
                    />
                    {!scriptName.length == 0 ?
                        <Btn
                            message='Save'
                            color={scriptName.length > 0 ? "primary" : "outline"}
                            icon='fas fa-save'
                            onClickFunction={() => { createAsnewScript() }}
                        />
                        :
                        <Btn
                            message='Save'
                            color={"disabled"}
                            disabledBtn={true}
                            icon='fas fa-save' />
                    }

                </div>
            </form>

        </div >
    )
}
