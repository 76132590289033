import React, { useRef, useState, useEffect } from 'react';

import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/monokai';

//import { set } from "date-fns";
import { get } from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';

import { ControlledEditor } from '@monaco-editor/react';
import options from './options';
import { FillSpinner as Loader } from 'react-spinners-kit';
export default function BespokeLiquidPropertiesList(props) {

	const { propertiesBespokeLiquid } = useSelector((state) => state.bespoke);
	const dispatch = useDispatch();
	//exemple => https://codesandbox.io/s/8wp9w?file=/src/App.js:908-915

	const [json, setJson] = useState();
	const [firtRender, setfirtRender] = useState(true);

	const [isNotValidJSON, setIsNotValidJSON] = useState();

	function isJSON(jsonStr) {
		try {
			JSON.parse(jsonStr);
			return true;
		} catch (e) {

			return false;
		}
	}

	const handleEditorChange = (value, event) => {
		setJson(event);
	};
	useEffect(() => {
		if (isJSON(json)) {
			setIsNotValidJSON();
			dispatch(bespokeActions.handlePropertiesBespokeLiquid(JSON.parse(json)));
			dispatch(bespokeActions.JsonIsValidHandler(true));
			setfirtRender(false);
		} else if (json) {
			setIsNotValidJSON('Warning, the Json format is invalid!');
			dispatch(bespokeActions.JsonIsValidHandler(false));
		}
	}, [json]);

	useEffect(() => {
		if (firtRender && propertiesBespokeLiquid && propertiesBespokeLiquid.length !== 0) {
			setJson(JSON.stringify(propertiesBespokeLiquid, null, 2));
			setfirtRender(false);
		}
	}, [propertiesBespokeLiquid]);

	return (
		<div className='ideContainer flex_item_full flex flex_v' style={{display: props.isActive ? '' : 'none'}}>
			<div className="flex_item_fix">
				{isNotValidJSON &&
                    <div className="editorMessage editorMessageError">
                    	<i className="fas fa-times mr_10"></i>
                    	{isNotValidJSON}
                    </div>
				}
				{
					!isNotValidJSON &&
                    <div className="editorMessage editorMessageSuccess">
                    	<i className="fas fa-check mr_10"></i>
                        Json is valid
                    </div>

				}
			</div>
			<div className="flex_item_full editor_wrapper">
				<ControlledEditor
					height="600px"
					theme={'vs-dark'}
					language={'json'}
					loading={<Loader />}
					value={json && json}
					onChange={handleEditorChange}
					options={options}
				/>
			</div>
		</div>
	);
}
