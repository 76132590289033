import React, { useState, useEffect } from 'react';
import { FaUpload } from 'react-icons/fa';
import UploadContainer from '../../UploadThumb/UploadContainer';
import IconsContainer from '../../IconsContainer';
import { fetchDataAccount } from '../../../store/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import { bespokeActions } from '../../../store/bespokeProperty-slice';
import { bespokeFormActions } from '../../../store/bespokeForm-slice';
import SelectTemplateBespoke from './SelectTemplateBespoke';

export default function BespokeEditionDescriptionCard({ envColor, env, accountId, modificationDate, creationDate }) {
	const accountWorkingInformations = useSelector(state => state.ui.accountWorkingInformations);
	const { name: accountName } = accountWorkingInformations;
	const [toggleUpload, setToggleUpload] = useState(false);

	const { valuesBespokeDescription,
		selectedOptionCss,
		mobileVersionEnabled,
		valuesUrlUpload,
		desktopTemplate,
		wysiwygValues,
	} = useSelector((state) => state.bespoke);
	const {
		isBeyableAccount
	} = useSelector((state) => state.ui);
	const dispatch = useDispatch();
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const changetoggleUpload = () => {
		setToggleUpload(!toggleUpload);
	};

	useEffect(() => {
		// SetValuesUrlUpload()
		dispatch(bespokeActions.handleValuesUrlUpload());
	}, []);
	const setAccountWorkingDatas = () => {
		dispatch(fetchDataAccount(authAxios, env, accountId));
	};
	useEffect(() => {
		if (isAuthenticated) {
			setAccountWorkingDatas();
		}
	}, [isAuthenticated]);
	const handleChangeFormatTemplate = (e) => {
		dispatch(bespokeActions.handleChangeTemplateTypeAllDevices(parseInt(e.target.value)));
	};

	const handleChangeFormatWysigiwyg = (e) => {
		const value = e.target.value;
		const objectIsLiquid = {
			1: false,
			2: true
		};
		const newValuIsLiquid = objectIsLiquid[value];
		dispatch(bespokeActions.handleWysiwygValuesTypeAllDevices(parseInt(value)));
		dispatch(bespokeActions.handleIsLiquid(newValuIsLiquid));

	};
	return (
		<>
			<div className="bespoke-detail-inner">
				<div className="bespoke-detail-inner-content">

					<div className="bespoke-detail">
						<label htmlFor="bespokeName">Format name</label>
						<input
							type="text"
							className="form-control" id="bespokeName"
							name="name"
							placeholder="SaaS Format Name"
							value={valuesBespokeDescription.name || ''}
							onChange={(e) => { dispatch(bespokeActions.modifyvaluesBespokeDescription(e)); }}
							required />
					</div>
					<div className="bespoke-detail">
						<label htmlFor="envValue">Identifier / Code</label>

						<input
							type="text"
							className={'form-control text-center'}
							id="code"
							readOnly
							value={valuesBespokeDescription.code || ''}
							placeholder="code" />
					</div>
					<div className="bespoke-detail">
						<label htmlFor="bespokeAccountName">Account</label>
						<input
							type="text"
							className="form-control text-center" id="bespokeAccountName"
							value={accountName || ''}
							readOnly
						/>
					</div>
					<div className="bespoke-detail">
						<label htmlFor="bespokeDescription">Description</label>
						<textarea
							className="form-control"
							rows="3"
							id="exampleFormControlTextarea1"
							name="description"
							onChange={(e) => { dispatch(bespokeActions.modifyvaluesBespokeDescription(e)); }}
							value={valuesBespokeDescription.description || ''}
						>
						</textarea>
					</div>
					<div className="bespoke-detail-2">
						{isBeyableAccount && wysiwygValues.desktopTemplate.templateType === 1 &&
                            <>
                            	<div className="bespoke-detail mb_10">
                            		<label htmlFor="customCssEnabled">Css custom</label>
                            		<select
                            			className="form-control mb-2 border  rounded-right selectionCustom"
                            			name="customCssEnabled"
                            			id="customCssEnabled"
                            			onChange={(e) => dispatch(bespokeActions.handleFilter(e))}
                            			value={wysiwygValues.desktopTemplate.templateType === 2 || selectedOptionCss}
                            		>
                            			<option value="true">Activated</option>
                            			<option value="false">Desactivated</option>
                            		</select>
                            	</div>
                            	<div className="bespoke-detail mb_10">
                            		<label htmlFor="customCssEnabled">Mobile version</label>
                            		<select
                            			className="form-control mb-2 border  rounded-right selectionCustom"
                            			name="mobileVersionEnabled"
                            			id="mobileVersionEnabled"
                            			onChange={(e) => { dispatch(bespokeFormActions.handleFilterMobile(e)); dispatch(bespokeActions.handleFilterMobile(e)); }}
                            			value={wysiwygValues.desktopTemplate.templateType === 2 || mobileVersionEnabled}
                            		>
                            			<option value="true">Activated</option>
                            			<option value="false">Desactivated</option>
                            		</select>
                            	</div>
                            </>
						}
						{isBeyableAccount &&
                            <>
                            	<div className="bespoke-detail mb_10">
                            		<label htmlFor="Template format">Template format</label>
                            		<select
                            			className="form-control mb-2 border  rounded-right selectionCustom"
                            			name="mobileVersionEnabled"
                            			id="mobileVersionEnabled"
                            			onChange={(e) => { handleChangeFormatTemplate(e); }}
                            			value={desktopTemplate.contentTemplateType}
                            		>
                            			<option value={1}>XSLT</option>
                            			<option value={2}>Liquid</option>
                            		</select>
                            	</div>
                            	<div className="bespoke-detail mb_10">
                            		<label htmlFor="Wysiwyg format">Wysiwyg format</label>
                            		<select
                            			className="form-control mb-2 border  rounded-right selectionCustom"
                            			name="mobileVersionEnabled"
                            			id="mobileVersionEnabled"
                            			onChange={(e) => { handleChangeFormatWysigiwyg(e); }}
                            			value={wysiwygValues.desktopTemplate.templateType}
                            		>
                            			<option value={1}>Angular</option>
                            			<option value={2}>Liquid</option>
                            		</select>
                            	</div> </>}
					</div>
					<div className="bespoke-detail">
						<div className="embed-responsive embed-responsive-16by9 w-80 m-auto">
							<img className="card-img-top embed-responsive-item" src={valuesUrlUpload ? valuesUrlUpload : valuesBespokeDescription.thumbnailDisplayUrl} alt="SaaS Format thumbnail" style={{ width: '100%', height: '100%' }} />
						</div>
					</div>

					<div className="bespoke-detail">
						<label htmlFor="thumbnailUrl">Thumbnail</label>
                        &nbsp; &nbsp; <span className="uploadLogo" onClick={changetoggleUpload}>
							<IconsContainer color="black">
								<FaUpload />
							</IconsContainer>
						</span>
						<input
							type="text"
							name="thumbnailUrl"
							className="form-control text-center"
							id="thumbnailUrl"
							readOnly
							value={valuesBespokeDescription.thumbnailUrl || ''}
							// onChange={ModifyvaluesBespokeDescription}
							placeholder="Thumbnail" />

						{toggleUpload &&
                            <UploadContainer
                            	env={env}
                            	accountId={accountId}
                            	code={valuesBespokeDescription.code}
                            	changetoggleUpload={changetoggleUpload}
                            	bespokeId={valuesBespokeDescription.bespokeId}

                            />}
					</div>

					<div className="bespoke-detail">
						<label htmlFor="refBespoke">SaaS Format key</label>
						<input
							type="text"
							className="form-control text-center"
							id="refBespoke"
							readOnly
							value={valuesBespokeDescription.bespokeId || ''}
							placeholder="Reference" />
					</div>
					<div className="bespoke-detail">
						<label htmlFor="envValue">Format</label>
						<input
							type="text"
							className={'form-control text-center'}
							id="format"
							readOnly
							value={valuesBespokeDescription.format || ''}
							placeholder="format" />
					</div>
					<div className="bespoke-detail-2">
						<div className="bespoke-detail">
							<label htmlFor="datecreation">Creation date</label>
							<input
								type="text"
								className="form-control text-center"
								id="datecreation"
								readOnly
								value={`${creationDate}` || ''}
								placeholder="date creation" />
						</div>
						<div className="bespoke-detail">
							<label htmlFor="datecreation">Last modification</label>
							<input
								type="text"
								className="form-control text-center"
								id="datemodification"
								readOnly
								value={`${modificationDate}` || ''}
								placeholder="datecreation" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}