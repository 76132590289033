
import React from 'react';
const styleMsg = {
	position: 'fixed',
	right: '0',
	zIndex: '2000',
	width: '100%',
	margin: 'auto',
	textAlign: 'center'
};
const badStyle = {
	position: 'fixed',
	right: '0',
	zIndex: '2000',
	width: '100%',
	marginTop: '10px',
	textAlign: 'center'
};
const Message = ({ msg, color }) => {
	return (
		<div className={`alert ${color} alert-dismissible fade show `} role='alert'
			style={msg === ' alert-success' ? styleMsg : badStyle}>
			{msg}
			{/* <button
                type='button'
                className='close'
                data-dismiss='alert'
                aria-label='Close'
            >
                <span aria-hidden='true'>&times;</span>
            </button> */}
		</div>
	);
};

export default Message;