import React, { useEffect, useState } from 'react';
import Panel from '../../Ui/Panel';
import SearchBar from '../../Ui/SearchBar';
// import useSearch from '../useSearch';
import classes from './BespokePanel.module.css';
import ListItem from '../../Ui/ListItem';
import useAuthAxios from '../../../utils/useAuthAxios';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import BespokeItemPanel from '../Edition/BespokeItemPanel';
import SpinnerOverlay from '../../Ui/SpinnerOverlay';
import Btn from '../../Ui/Btn';
import { useDispatch, useSelector } from 'react-redux';
import { createBespoke } from '../../../store/create-bespoke-reducer';
import useSearchBespokePanel from './useSearchBespokePanel';
export default function BespokePanel({
	dataBespoke,
	handleSelectBespoke,
	bespokeSelected,
	env,
	accountId,
}) {
	// const { noData, dataFiltered, search, handleChangeSearchBar } = useSearch(dataBespoke);
	const dispatch = useDispatch();
	const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
	const {
		panelBespokeIsOpen,
		arrayOfBespokeOfAccount,
		isReloading
	} = useSelector((state) => state.createBespoke);
	const { noData, dataFiltered, search, handleChangeSearchBar } = useSearchBespokePanel(arrayOfBespokeOfAccount);
	const onCloseThePanel = () => {
		dispatch(createBespoke.openClosePanelBespoke(false));
	};
	const getArrayOfBespokes = (list) => {
		dispatch(createBespoke.setArrayOfBespokeOfAccount(list));
	};
	const accountWorkingInformations = useSelector(
		(state) => state.ui.accountWorkingInformations
	);
	const handleIsLoading = (bool) => {
		dispatch(createBespoke.setIsReLoading(bool));
	};
	const { name } = accountWorkingInformations;

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	useEffect(() => {
		const timer = setTimeout(() => {
			const fetchData = async () => {
				handleIsLoading(true);
				authAxios
					.get(`bespoke/account/${env}/${accountId}`)
					.then((res) => {
						getArrayOfBespokes(res.data);
						handleIsLoading(false);
					})
					.catch((err) => {
						handleIsLoading(false);
					});
			};
			isAuthenticated && fetchData();
		}, 700);
		return () => {
			clearTimeout(timer);
		};
	}, [isAuthenticated]);

	return (
		<Panel
			isOpen={panelBespokeIsOpen}
			onClose={() => onCloseThePanel()}
			width={400}
			side={'left'}
			closeOnOverlay={true}
		>
			<>
				<div className={classes.nav_container}>
					{isBeyableAccount && <div className={`${classes.breadcrumbItem}`}>#{env}</div>}
					<div className={`${classes.breadcrumbItem}`}>{name}</div>
				</div>
				<div className={classes.search_container}>
					<SearchBar
						placeholder="Search"
						valueInSearchBar={search}
						onChange={(value) => handleChangeSearchBar(value)}
						autoFocus={true}
						selectOnFocus={true}
						style="grey"
					/>
				</div>
				<div className="modal_header">
					{/* <h2>Edit a SaaS Format account {dataAccount.name}</h2> */}
				</div>

				{!isReloading && arrayOfBespokeOfAccount ? (
					<div className="list_bespoke_container">
						<>
							{dataFiltered && dataFiltered.map((x, id) => (
								<article
									key={id}
									className="bespoke-item flex_v"
								>
									<BespokeItemPanel
										data={x}
										name={x.item1.name}
									/>
								</article>
							))}
						</>
					</div>
				) : (
					<SpinnerOverlay />
				)}
			</>
		</Panel>
	);
}
