import React from 'react';
import classes from './ListItem.module.css';
import moment from 'moment';
export default function ListItem({
	title,
	description,
	clickHandler,
	isSelected,
	isStopped,
	isTest,
	endDate,
	startDate
}) {
	const today = moment();

	const statusOperation = () => {
		let status = '';

		if (isStopped) {
			status = 'Stopped';
		}
		else if (isTest === 2) {
			status = 'Draft';
		}
		else if (moment(endDate) < today) {
			status = 'Past';
		}
		else if (moment(startDate) > today && !isStopped) {
			status = 'Scheduled';
		}
		else {
			status = 'Ongoing';
		}
		return status;
	};
	return (
		<li className={`${classes.cardContainer} ${isSelected ? classes.selected : ''}`} onClick={clickHandler}>
			<div className={classes.content}>
				<div className={classes.header}>
					<p className={classes.title}>{title}</p>
					<div
						className={`btnUi ${classes.status} ${classes[statusOperation()]}`}>  {statusOperation()}</div>
				</div>
				<p className={classes.description}>{description}</p>

			</div>
		</li>
	);
}
