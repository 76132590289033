const Score = ({ score, tailleEtoile = '22px' }) => {
    let nombreEtoiles;

    // Attribuer les étoiles en fonction du score
    if (score >= 37) {
        nombreEtoiles = 3;
    } else if (score >= 25) {
        nombreEtoiles = 2;
    } else if (score >= 12) {
        nombreEtoiles = 1;
    } else {
        nombreEtoiles = 0;
    }

    const etoilesRemplies = Array.from({ length: nombreEtoiles }, (_, i) => (
        <i className="fa fa-star" style={{ color: '#0279b7', fontSize: tailleEtoile, margin : '2px' }} key={i}></i>
    ));

    const etoilesVides = Array.from({ length: 3 - nombreEtoiles }, (_, i) => (
        <i className="fa fa-star" style={{ color: 'lightgrey', fontSize: tailleEtoile, margin : '2px' }} key={i}></i>
    ));

    return (
        <div>
            {etoilesRemplies}
            {etoilesVides}
        </div>
    );
};

export default Score;
