import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../../utils/useAuthAxios'

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import { format } from 'date-fns'
import { MdDeleteForever, MdClose } from 'react-icons/md'
import { IoMdWarning } from 'react-icons/io'
import ConfirmModal from '../../Modale/ConfirmModal'
import { FaEyeSlash } from "react-icons/fa"
import { createBespoke } from '../../../store/create-bespoke-reducer';
import { useDispatch, useSelector } from "react-redux";
export default function BespokeItemPanel({ data }) {
    const dispatch = useDispatch();
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
    const [idChooseToDelete, setIdChooseToDelete] = useState()
    const [toggleModales, setToggleModales] = useState(false)
    const [toggleErrors, setToggleError] = useState(false)
    const [errorArray, setErrorArray] = useState([])
    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios()

    // useEffect(() => {
    //     const fetchData = async () => {
    //         authAxios.get(`status/${data.item3}/bespoke/`)
    //             .then(res => {
    //                 const resultWithErrorsDev = res.data.filter(y => y.bespokeId === data.item1.bespokeId)[0]
    //                 setErrorArray(resultWithErrorsDev.errors)

    //             })
    //     }
    //     isAuthenticated && fetchData();
    // }, [isAuthenticated])
    let envColor = null
    if (data && data.item3 === 'Dev') {
        envColor = 'colorEnvDev'
    }
    if (data && data.item3 === 'Prod') {
        envColor = 'colorEnvProd'
    }
    if (data && data.item3 === 'PreProd') {
        envColor = 'coloEnvPreProd'
    }
    const clickOnDelete = (e) => {
        setIdChooseToDelete(e)
        setToggleModales(!toggleModales)
    }
    const oppenCloseModale = (e) => {
        setToggleModales(!toggleModales)
    }
    const stopPropa = (e) => {
        e.stopPropagation()
    }
    const ChangetoggleErrors = () => {
        setToggleError(!toggleErrors)
    }
    const closePanel = () => {
        dispatch(createBespoke.openClosePanelBespoke(false))
    }
    const dataError = errorArray && errorArray.map((x, i) =>
        <div key={i} className='errors'>
            <div className='errors-close' onClick={ChangetoggleErrors}>
                <MdClose />
            </div>
            <p><strong>Identifier :</strong> {x.identifier}</p>
            <p><strong>Message :</strong> {x.message}</p>
        </div>
    )

    return (
        <>
            <p className="bespoke-item-code flex flex_justify_center">
                {isBeyableAccount && <span className={`bespoke-item-env ${envColor}`}>{data && data.item3}</span>}

                <Link className="noLink"
                    onClick={closePanel}
                    to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                    {data.item1.code}
                </Link>
            </p>
            <div className="flex flex_align_start mb_10">
                <div className="bespoke-item-img">
                    <Link className="noLink"
                        onClick={closePanel}
                        to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                        <img style={{ width: '170px', height: '100px' }} src={data && data.item1.thumbnailDisplayUrl ? data.item1.thumbnailDisplayUrl : 'https://via.placeholder.com/350'} alt="" />
                    </Link>
                </div>
                <div className="bespoke-item-container">
                    <div className="bespoke-item-detail">
                        {data && data.item1.publicationState === 1 ?
                            '' :
                            <span className="bespoke-item-visibility">
                                <FaEyeSlash />
                            </span>
                        }
                    </div>

                    <p className="bespoke-item-description mb_15">{data.item1.name}</p>
                    {data.item1.description ? <p className="bespoke-item-description"> Description : {data.item1.description}</p> : ''}
                    {errorArray && errorArray.length > 0 &&
                        <>
                            <div className="bespoke-item-errors" onClick={ChangetoggleErrors}>
                                <IoMdWarning />
                                <span className="numberErrors">
                                    {errorArray && errorArray.length}
                                </span>
                            </div>
                        </>
                    }

                    <div className={toggleErrors ? 'errorsBespoke' : 'none'}>
                        {toggleErrors && dataError}
                    </div>
                    {toggleModales &&
                        <ConfirmModal
                            close={oppenCloseModale}
                            stopPropa={stopPropa}
                            idChooseToDelete={idChooseToDelete}
                            oppenCloseModale={oppenCloseModale}
                            id={data.item1.bespokeId}
                            env={data.item3}
                            name={data.item1.name}
                        />}
                </div>
            </div>
            <div className="bespoke-item-btn flex flex_justify_center" >
                <button className="edit-bespoke">
                    <Link className="noLink"
                        onClick={closePanel}
                        to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                        Edit <FiEdit />
                    </Link>
                </button>
                <button className="delete-bespoke" onClick={() => clickOnDelete(data && data.item1.bespokeId)} >
                    Delete <MdDeleteForever />
                </button>
            </div>
        </>
    )
}
