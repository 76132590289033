import { ControlledEditor } from '@monaco-editor/react';
import React, { useState } from 'react';

const JsonEditor = ({ jsonValue, setJsonValue, options }) => {
    const [isValid, setIsValid] = useState(true);

    const handleEditorChange = (ev, newValue) => {
        setJsonValue(newValue);
        setIsValid(isValidJson(newValue));
    };

    function isValidJson(jsonString) {
        try {
            JSON.parse(jsonString);
            console.log('JSON is valid'); // Ajouter ce log
            return true;
        } catch (error) {
            console.log('Invalid JSON:', error); // Ajouter ce log
            return false;
        }
    }

    return (
        <>
            {!isValid && (
                <div style={{ color: 'red' }}>Le JSON est invalide.</div>
            )}
            <ControlledEditor
                width="440px"
                height="500px"
                language="json"
                theme="vs-dark"
                value={jsonValue}
                options={options}
                onChange={handleEditorChange}
            />
        </>
    );
};

export default JsonEditor;