import React, { useState } from 'react';
import './AddNewProperty.css';
import IconsContainer from '../../IconsContainer';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';



//clicked
export default function AddNewProperty() {
	const [newProperty, setNewProperty] = useState(
		{
			propertyName: '',
			defaultValue: '',
			description: '',
			editorType: ''
		}
	);
	const dispatch = useDispatch();
	const handleChange = event => {
		event.persist();
		setNewProperty(prevValues => ({
			...prevValues,
			[event.target.name]: event.target.value
		})
		);
	};

	return (
		<div className="overlay">
			<div className="contenu rounded mb-3 ">

				<div className="form text-center  my-auto col-md-12 ">
					<IconsContainer color="red">
                        &nbsp;   <span className="btn-close " onClick={() => dispatch(bespokeActions.toggleClicked())}>
							<AiOutlineClose />
						</span>
					</IconsContainer>
					<h5 className="mb-4">Ajouter une propriété</h5>
					<div className="form-group  m-auto col-md-12">
						<input type="text" className="form-control col-md-12 mb-4 " name="propertyName" value={newProperty.propertyName} placeholder="Entrez un nom de propriété"
							onChange={handleChange} />
						<input type="text" className="form-control col-md-12 mb-4  " name="defaultValue" value={newProperty.defaultValues} placeholder="Entrez une valeur de propriété"
							onChange={handleChange} />
						<input type="text" className="form-control col-md-12 mb-4  " name="description" value={newProperty.description} placeholder="Entrez une description pour le champ"
							onChange={handleChange} />
						<input type="text" className="form-control col-md-12 mb-4  " name="editorType" value={newProperty.editorType} placeholder="Entrez le type d'éditeur"
							onChange={handleChange} />
						<div className="btn-normal mx-auto" onClick={() => dispatch(bespokeActions.addNewPropretiesToBespokeInput(newProperty))}>Validate</div>
					</div>
				</div>
			</div>

		</div>
	);
}
