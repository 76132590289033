import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	token: '',
	socket: null
};
const socket = createSlice({
	name: 'socket',
	initialState: initialState,
	reducers: {
		instanciateSocket(state, action) {
			state.socket = action.payload;
		}
	}
});

export const actionSocket = socket.actions;

export default socket;