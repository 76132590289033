import React, { } from 'react';
import { useDispatch } from 'react-redux';

import { bespokeActions } from '../../store/bespokeProperty-slice';
export default function GalleryImage({ pictures, darkBackground }) {

	const dispatch = useDispatch();
	const handleCopy = (image) => {
		dispatch(bespokeActions.handleCopyPictureUrl(image.cdnPath));
		setTimeout(() => {
			dispatch(bespokeActions.handleStopMessageCopy());
		}, 1000);
	};
	return (
		<div className={`gallery-container ${darkBackground? 'dark' : ''}`}>
			{
				pictures && pictures.map((image, i) =>
					<div className="gallery-img" key={i}>
						<img
							className=""
							src={image.cdnPath} alt={`thumbnail ${i}`}
							style={{ maxWidth: '200px', maxHeight: '200px' }} />
						<button className="gallery-copy-btn" onClick={(e) => handleCopy(image)}>Copy</button>
					</div>
				)
			}
		</div>

	);
}
