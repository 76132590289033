import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Btn from '../Ui/Btn';
import './Authentication.css';

const LoginButton = ({style='', size='m', color='primary'}) => {
	const { loginWithRedirect } = useAuth0();
	return (
		<Btn
			onClickFunction={() => loginWithRedirect()}
			message='Login'
			style={style}
			color={color}
			size={size}
		/>
	);
};

export default LoginButton;