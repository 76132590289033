import React, { useState, useEffect } from 'react';
import './PublicationContainer.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import IconsContainer from '../../IconsContainer';
import { MdClose, MdCloudUpload } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';
import { useSelector } from 'react-redux';
export default function ConfirmModal({ close, stopPropa, env, accountId }) {
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const { valuesInBespokeProperties, valuesBespokeDescription,

	} = useSelector((state) => state.bespoke);
	const [formIsNotEmpty, setFormIsNotEpty] = useState(false);
	const [valuesInBespokePropertiesCopy, setValuesInBespokePropertiesCopy] = useState(valuesInBespokeProperties);
	const [valueToPublish, setValueToPublish] = useState({
		IpAddress: '',
		TemplateCode: valuesBespokeDescription.code,
		TestToken: '',
		TestOnUrl: '',
		TemplateFormat: valuesBespokeDescription.format,
		Properties: valuesInBespokePropertiesCopy
	});
	const alertgood = document.querySelector('#alertSuccessPublish');
	const alertBad = document.querySelector('#alertBadPublish');
	const [chargeClick, setChargeClick] = useState(false);
	//modifier la valeur d'une propriété
	const modifyBespokePropertiesCopy = (index, e) => {
		const element = valuesInBespokeProperties[index];
		element.defaultValue = e.target.value;
		const arrayBeforeModify = valuesInBespokePropertiesCopy;
		arrayBeforeModify.splice(index, 1, element);
		setValuesInBespokePropertiesCopy([...arrayBeforeModify]);
	};

	const changeValueInputToPublish = (event) => {
		event.persist();
		setValueToPublish(prevValues => ({
			...prevValues,
			[event.target.name]: event.target.value
		}));
	};
	useEffect(() => {
		const fetchIpAdress = async () => {
			const result = await axios.get('https://api.ipify.org?format=json');
			setValueToPublish(prevValues => ({
				...prevValues,
				IpAddress: result.data.ip
			}));
		};

		fetchIpAdress();
	}, []);
	const chargeClickAction = () => {
		setChargeClick(true);
	};
	useEffect(() => {
		if (chargeClick && isAuthenticated) {
			setChargeClick(false);
			const fetchCharg = async () => {
				authAxios.put(`onsitepreview/${env}/${accountId}`, valueToPublish)
					.then(res => {
						alertgood.style.display = 'block';
						setTimeout(() => {
							alertgood.style.display = 'none';
						}, 2000);
					}).catch(e => {
						alertBad.style.display = 'block';
						setTimeout(() => {
							alertBad.style.display = 'none';
						}, 2000);
					});
			};
			fetchCharg();
		}
	}, [chargeClick, isAuthenticated]);

	useEffect(() => {
		const validation = (values) => {
			if (values.IpAddress.length > 0 && values.TestToken.length > 0 && values.TestOnUrl.length > 0) {
				setFormIsNotEpty(true);
			}
			else {
				setFormIsNotEpty(false);
			}
		};
		validation(valueToPublish);
	}, [valueToPublish]);
	return (
		<>
			<div onClick={(e) => close(e)} className="containerConfirmModal">
				<div onClick={(e) => stopPropa(e)} className="contenuModal">
					<div className="btn-closeModal"
					>

						<span className="deleteProperty btn-closeModalBespokeDelete" onClick={(e) => close(e)}>
                            Close<IconsContainer color="red" >
								<MdClose />
							</IconsContainer>
						</span>
					</div>
					<h4 className="confirmModalTitle">Publication Preview</h4>
					<div className="confirmModalSection">
						<label htmlFor="bespokeName">Target URL</label>
						<input type="text" className="form-control" id="TestOnUrl"
							name="TestOnUrl"
							value={valueToPublish.TestOnUrl}
							onChange={(e) => changeValueInputToPublish(e)}
							placeholder="Target URL"
							required />
					</div>
					<div className="confirmModalSection">
						<label htmlFor="bespokeName">Your ID</label>
						<input type="text" className="form-control" id="TestToken"
							name="TestToken"
							value={valueToPublish.TestToken}
							onChange={(e) => changeValueInputToPublish(e)}
							placeholder="Your ID"
							required />
					</div>
					<div className="confirmModalSection">
						<label htmlFor="bespokeName">Your IP</label>
						<input type="text" className="form-control" id="IpAddress"
							name="IpAddress"
							value={valueToPublish.IpAddress}
							onChange={(e) => changeValueInputToPublish(e)}
							placeholder="Your IP address"
							required />
					</div>
					<h4 className="confirmModalTitle">Properties to set</h4>
					<div className="publicationPropertiesContainer">
						<ul className="publicationPropertiesInner">
							{valuesInBespokePropertiesCopy.map((x, index) =>
								<li className="publicationProperty" key={index}>
									<p>{x.propertyName}</p>
									<input type="text" className="form-control " value={x.DefaultValue || ''}
										onChange={(index, e) => modifyBespokePropertiesCopy(index, e)}
										placeholder="" />
								</li>)
							}
						</ul>

					</div>
					<div className='btnGroup mt-4'>
						{
							formIsNotEmpty ?
								<>
									<div className="btnCharger" onClick={chargeClickAction}>
										<IconsContainer >
											<MdCloudUpload />
										</IconsContainer>
                                        Load</div>

									<a className="btnGo" target="_blank" rel="noopener noreferrer" href={valueToPublish.TestOnUrl}>
										<span  >
											<IconsContainer>
												<FaLink />
											</IconsContainer>
                                            Go Live
										</span>
									</a>
								</>
								:
								null
						}
						<div className="alert alert-success" id="alertSuccessPublish" role="alert">
                            Successfuly loaded.
						</div>
						<div className="alert alert-danger" id="alertBadPublish" role="alert">
                            An error occured, check the console!
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
