/* eslint-disable no-inner-declarations */
/* eslint-disable no-loop-func */
import React, { useState, useEffect, useContext } from 'react';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';
import HeaderEdition from '../../Ui/HeaderEdition';
import useAlert from '../../../Hooks/useAlert';
import {
	useParams
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import uuid from 'react-uuid';
import Btn from '../../../Components/Ui/Btn';
import NavSetup from './NavSetup';
import './SimplifyEdition.css';
import SimplifyEditionDataContainer from './SimplifyEditionDataContainer';
import SimplifyScreenShot from './SimplifyScreenShot';
import SimplifyZonesContainer from './SimplifyZonesContainer';
import Message from '../../Ui/Message';
import { Redirect } from 'react-router-dom';
import { fetchDataAccount } from '../../../store/uiActions';
import { useDispatch } from 'react-redux';
import PanelPageTypeEdition from './PanelPageTypeEdition';
import Modal from '../../Ui/Modal';
import PageTypeEditionContainer from './PageTypeEditionContainer';
export default function SimplifyEditionSetupContainer() {
	const {
		screenShots,
		setScreenShots,
		setScreenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		setdeviceSelected,
		isNewScreenShot,
		setisNewScreenShot,
		panelTypeIsOpen,
		setPanelTypeIsOpen,
		setaccountIdParams,
		newPageTypeIsOppen,
		setNewPageTypeIsOppen,
		listPageTypes,
	} = useContext(creationSetupSimplify);
	const dispatch = useDispatch();
	const { createAlerte } = useAlert();
	let { accountId, name, env, isExistantSetup, status } = useParams();
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const [isLoading, setIsLoading] = useState(false);
	const [numberOfScreenShots, setnumberOfScreenShots] = useState(0);

	const [reloadPut, setReloadPut] = useState(false);
	const [reloadPost, setReloadPost] = useState(false);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');

	const setAccountWorkingDatas = () => {
		dispatch(fetchDataAccount(authAxios, env, accountId));
	};
	useEffect(() => {
		if (isAuthenticated) {
			const timer = setTimeout(() => {
				setAccountWorkingDatas();
			}, 700);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [isAuthenticated]);
	useEffect(() => {
		if (isAuthenticated) {
			
			if (isExistantSetup == 'true') {
				setIsLoading(true);
				setNewPageTypeIsOppen(false);
				setisNewScreenShot(false);
				setdeviceSelected('mobile');
				const fetchDataSetup = async () => {
					const screenShotsAccount = await authAxios.get(`websitesetup/${env.toUpperCase()}/screenshot/${accountId}`);
					screenShotsAccount && screenShotsAccount.status === 200 ? setIsLoading(false) : setIsLoading(false);
					// make new object with existants screenshots 
					const screenshotsWithId = screenShotsAccount.data.map(screen => ({ ...screen, isExistantScreenshot: true }));
					function sortArrayDesc(a, b) {
						if (a.pageType < b.pageType) return -1;
						if (a.pageType > b.pageType) return 1;
						return 0;
					}
					setScreenShots([...screenshotsWithId.sort(sortArrayDesc)]);
					if (screenshotsWithId.length > 0) {
						let getFirstPageType = screenshotsWithId[0];
						let getSecondScreenShot = screenshotsWithId.find(screenShot =>
							screenShot.name === getFirstPageType.name && screenShot.device !== getFirstPageType.device);
						if (getSecondScreenShot) {
							setScreenShotId(getFirstPageType.screenshotId);
							setscreenShotToChange([getFirstPageType, getSecondScreenShot]);
							setdeviceSelected(getFirstPageType.device);
						} else {
							const device = getFirstPageType.device === 'mobile' ? 'desktop' : 'mobile';
							const newScreenShot = {
								accountId: accountId,
								device: device,
								imagePath: '',
								name: getFirstPageType.name,
								pageType: getFirstPageType.pageType,
								screenshotExtensions: { width: '', height: '' },
								thumbnailPath: '',
								zones: [],
								screenshotId: uuid(),
								isChange: true,
								isNewScreenShot: true
							};
							let newScreenShots = screenshotsWithId.filter(screens => screens.name !== getFirstPageType.name);
							newScreenShots.push(newScreenShot);
							newScreenShots.push(getFirstPageType);
							newScreenShots.sort(sortArrayDesc);
							setScreenShots(newScreenShots);
							setScreenShotId(newScreenShot.screenshotId);
							setscreenShotToChange([getFirstPageType, newScreenShot]);
							setdeviceSelected(device);
						}
					}
				};
				if (isAuthenticated) {
					fetchDataSetup();
					setaccountIdParams(accountId);
				}

			} else if (isExistantSetup == 'false') {
				setScreenShots([]);
				setScreenShotId([]);
				setscreenShotToChange([]);
				setNewPageTypeIsOppen(false);
				setisNewScreenShot(false);
				setdeviceSelected('mobile');
				setIsLoading(false);
				addNewPageType();
			}
		}

	}, [isAuthenticated]);

	const addNewPageType = () => {
		setPanelTypeIsOpen(true);
		const data = [
			{
				accountId: accountId,
				device: 'mobile',
				imagePath: '',
				name: '',
				pageType: '',
				screenshotExtensions: { width: '', height: '' },
				thumbnailPath: '',
				zones: [],
				screenshotId: uuid(),
				isChange: true,
				isNewScreenShot: true
			},
			{
				accountId: accountId,
				device: 'desktop',
				imagePath: '',
				name: '',
				pageType: '',
				screenshotExtensions: { width: '', height: '' },
				thumbnailPath: '',
				zones: [],
				screenshotId: uuid(),
				isChange: true,
				isNewScreenShot: true
			},
		];
		setScreenShotId(data[0].screenshotId);
		setscreenShotToChange(data);
		setisNewScreenShot(true);
	};

	useEffect(() => {
		if (screenShots) {
			let numb = [];
			screenShots.forEach(
				x => {
					if (!numb.includes(x.pageType) && x.pageType !== '' && x.pageType !== undefined) {
						numb.push(x.pageType);
					}
					return numb;
				});
			setnumberOfScreenShots(numb.length);
		}
	}, [screenShots]);
	const cancelChange = () => {
		if (screenShots.length > 0) {
			const getFirstPageType = screenShots[0];
			const getSecondScreenShot = screenShots.find(screenShot =>
				screenShot.pageType === getFirstPageType.pageType && screenShot.device !== getFirstPageType.device);
			if (getSecondScreenShot) {
				setScreenShotId(getFirstPageType.screenshotId);
				setscreenShotToChange([getFirstPageType, getSecondScreenShot]);
				setdeviceSelected(getFirstPageType.device);
				setisNewScreenShot(false);
			} else {
				const device = getFirstPageType.device === 'mobile' ? 'desktop' : 'mobile';
				const newScreenShot = {
					accountId: accountId,
					device: device,
					imagePath: '',
					name: getFirstPageType.name,
					pageType: getFirstPageType.pageType,
					screenshotExtensions: { width: '', height: '' },
					thumbnailPath: '',
					zones: [],
					screenshotId: uuid(),
					isChange: true,
					isNewScreenShot: true
				};
				setScreenShotId(newScreenShot.screenshotId);
				setscreenShotToChange([getFirstPageType, newScreenShot]);
				setdeviceSelected(device);
				setisNewScreenShot(false);
			}
		} else {
			setScreenShotId();
			setscreenShotToChange();
			setisNewScreenShot(false);
		}
	};
	const saveNewScreenShot = () => {
		const newScreens = [...screenShots, ...screenShotToChange];
		setisNewScreenShot(false);
		setScreenShots(newScreens);
		setScreenShotId(screenShotToChange.find(screen => screen.device === deviceSelected).screenshotId);
	};

	const saveNewScrennShot = () => {
		// register() 
		setIsLoading(true);
		let newScreenShot = screenShots.filter(screens => screens.name !== screenShotToChange[0].name);
		newScreenShot.push(...screenShotToChange);
		const screenshotsToSave = newScreenShot.filter(screen =>
			screen.isNewScreenShot)
			.filter(x => x.name !== '' && x.thumbnailPath !== '');
		const screenshotsToPut = newScreenShot.filter(screen => screen.isExistantScreenshot)
			.filter(x => x.name !== '' && x.pageType !== undefined);
		screenshotsToSave.length === 0 && setReloadPost(true);
		screenshotsToPut.length === 0 && setReloadPut(true);
		// console.log("screenshotsToPut", screenshotsToPut);
		// console.log("screenshotsToSave", screenshotsToSave);
		let hasErrors = false;
		if (screenshotsToSave.length > 0) {
			function CreateScreenShots(formData) {
				return new Promise((resolve, reject) => {
					authAxios.post(`websitesetup/${env.toLowerCase()}/screenshot/${accountId}`, formData)
						.then(res => {
							resolve(res);
						}).catch(e => {
							reject(e);
							console.error(e);
						});
				});
			}
			const requests = screenshotsToSave.map(screen => {
				let formData = new FormData();
				let data = screen;
				const zones = [];
				data.zones.forEach(x => {
					delete x.toggle;
					delete x.zoneId;
					delete x.ratio;
					delete x.style;
					zones.push(x);
				});

				// Créer le screenshot
				let command = {};
				command.Command = {};
				command.Command.Screenshot = {};
				command.Command.Screenshot.Name = data.name;
				command.Command.Screenshot.Device = data.device;
				command.Command.Screenshot.PageType = data.pageType;
				command.Command.Screenshot.ImagePath = '';
				command.Command.Screenshot.ThumbnailPath = '';
				command.Command.Screenshot.AccountId = accountId;
				command.Command.Screenshot.ScreenshotExtensions = ''; // ne pas renseigner pour un POST   
				command.Command.Screenshot.Zones = zones;
				formData.append('command', JSON.stringify(command));
				// Si fichier screenshot
				formData.append('File', data.imagePath);
				// Si fichier vignette
				formData.append('FileThumbnail', data.thumbnailPath);
				return CreateScreenShots(formData);

			});
			if (isExistantSetup == 'false') {
				authAxios.put(`websitesetup/${env.toLowerCase()}/accounts/${accountId}/status/Draft`, [])
					.then(() => {
						Promise.all(requests).then(
							() => {
								hasErrors = false;
								// console.log(res.data)
								setMessage('Création du setup réussie');
								setColor('alert-success');
								setTimeout(() => {
									setMessage();
									setIsLoading(true);
									setReloadPost(true);
									// window.location.reload()
								}, 2000);
							}
						).catch(e => {
							hasErrors = true;
							console.error('e', e.response);
							setMessage('Une erreur est survenue pendant la création du screenshot');
							setColor('alert-danger');
							setIsLoading(false);
							setReloadPost(false);
							setTimeout(() => {
								// setMessage()
							}, 5000);
						});
					});
			} else {
				Promise.all(requests).then(
					() => {
						hasErrors = false;
						// console.log(res.data)
						setMessage('Création du setup réussie');
						setColor('alert-success');
						setTimeout(() => {
							setMessage();
							setIsLoading(true);
							setReloadPost(true);
							// window.location.reload()
						}, 2000);
					}
				).catch(e => {
					hasErrors = true;
					console.error('e', e.response);
					setMessage('Une erreur est survenue pendant la création du screenshot');
					setColor('alert-danger');
					setIsLoading(false);
					setReloadPost(false);
					setTimeout(() => {
						// setMessage()
					}, 5000);
				});
			}

		}
		if (screenshotsToPut.length > 0) {
			function modifyScreenShot(formData) {
				return new Promise((resolve, reject) => {
					authAxios.put(`websitesetup/${env.toLowerCase()}/screenshot/${accountId}`, formData)
						.then(res => {
							resolve(res);
						}).catch(e => {
							reject(e);
							console.error(e);
						});
				});
			}
			const requests = screenshotsToPut.map(screen => {
				let formData = new FormData();
				let data = screen;
				const zones = [];
				data.zones.forEach(x => {
					delete x.toggle;
					delete x.ratio;
					delete x.style;
					if (x.newZone) {
						delete x.zoneId;
					}
					zones.push(x);
				});

				// Créer le screenshot
				let command = {};
				command.Command = {};
				command.Command.Screenshot = {};
				command.Command.Screenshot.ScreenshotId = data.screenshotId;
				command.Command.Screenshot.Name = data.name;
				command.Command.Screenshot.Device = data.device;
				command.Command.Screenshot.PageType = data.pageType;
				command.Command.Screenshot.ImagePath = '';
				command.Command.Screenshot.ThumbnailPath = '';
				command.Command.Screenshot.AccountId = accountId;
				command.Command.Screenshot.ScreenshotExtensions = data.screenshotExtensions; // ne pas renseigner pour un POST   
				command.Command.Screenshot.Zones = zones;
				formData.append('command', JSON.stringify(command));
				// Si fichier screenshot
				formData.append('File', data.imagePath);
				// Si fichier vignette
				formData.append('FileThumbnail', data.thumbnailPath);
				return modifyScreenShot(formData);
			});
			Promise.all(requests).then(
				() => {
					!hasErrors && setReloadPut(true);
					createAlerte('success', 'Modification du setup réussie', { removeAllBeforeShow: false, });
				}
			).catch(e => {
				console.error('e', e.response);
				createAlerte('error', 'An error occured', { removeAllBeforeShow: false, });
				setIsLoading(false);
				setReloadPut(false);
				setIsLoading(false);
			});
		}

	};

	if (reloadPut && reloadPost) {
		if (isExistantSetup == 'true') {
			setTimeout(() => {
				return window.location.reload();
			}, 1000);
		}
		else {
			return <Redirect to="/simplifyaAccountsList" />;
		}
	}

	return (
		<>
			{
				isLoading &&
                <div className="spinner spinner_overlayed">
                	<div className="rect1"></div>
                	<div className="rect2"></div>
                	<div className="rect3"></div>
                	<div className="rect4"></div>
                	<div className="rect5"></div>
                </div>
			}
			{message &&
                <Message
                	msg={message}
                	color={color}
                />
			}
			<HeaderEdition
				env={env}
				status={status}
				message="Setup edit of  "
			/>
			<div className="headSetup">
				<div className="number-of-page">Types of page({numberOfScreenShots})</div>
				<div className="btn-save-pagetype">
					
					{isNewScreenShot &&
                        < Btn
                        	message='Cancel'
                        	color='primary'
                        	icon='fas fa-window-close'
                        	onClickFunction={() => {
                        		cancelChange();
                        	}}
                        />
					}
					{isNewScreenShot &&
                        < Btn
                        	message='Save new page'
                        	color='primary'
                        	icon='far fa-share-square'
                        	onClickFunction={() => {
                        		saveNewScreenShot();
                        	}}
                        	disabled={screenShotToChange && screenShotToChange[0].pageType !== '' ? false : true}
                        />
					}
					{screenShots && screenShots.length !== 0 && !isNewScreenShot && <Btn
						message='Save'
						color='primary'
						icon='fas fa-save'
						onClickFunction={() => { saveNewScrennShot(); }}
					/>}
					

				</div>
			</div>
     
			{screenShotToChange &&
                screenShotToChange.length > 0 && <>
				<div style={{ height: '100vh', display: 'flex' }}>
					<NavSetup
						isNewScreenShot={isNewScreenShot}
						addNewPageType={addNewPageType}
						accountId={accountId}
					/>
					<div>
						<SimplifyEditionDataContainer />
						<div className="screenshotDataContainer" >
							<div className="elementZoning">
								<SimplifyScreenShot />
							</div>
							<div className="elementZoning">
								<SimplifyZonesContainer
								/>
							</div>
						</div>
					</div>
				</div>
			</>}
			<PanelPageTypeEdition
				panelTypeIsOpen={panelTypeIsOpen}
				setPanelTypeIsOpen={setPanelTypeIsOpen}
				env={env}
				accountId={accountId}
				name={name}
				isExistantSetup={isExistantSetup}
				newPageTypeIsOppen={newPageTypeIsOppen}
				setNewPageTypeIsOppen={setNewPageTypeIsOppen}
				screenShotToChange={screenShotToChange}
			/>

			<Modal
				isOpen={newPageTypeIsOppen}
				onClose={() => setNewPageTypeIsOppen(false)}
				height="500"
			>
				<div className="modal_section">
					<PageTypeEditionContainer
						closeTheCreationForm={(e) => setNewPageTypeIsOppen(e)}
						listPageTypes={listPageTypes}
						env={env}
						accountId={accountId}
					/>
				</div>


			</Modal>  

		</>
	);
}
