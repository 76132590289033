export const typesOfZones = [
	{
		key: 0,
		label: 'Image'
	},
	{
		key: 1,
		label: 'Caroussel'
	},
	{
		key: 2,
		label: 'Slider'
	},
	{
		key: 3,
		label: 'Video Player'
	},
	{
		key: 10,
		label: 'Image Or Video Player'
	},
];