export default class BespokeService {
	constructor(authAxios, match) {
		this.authAxios = authAxios;
		this.params = match?.params;
	}
	async getBespokesList(callbackError) {
		try {
			const result = await this.authAxios.get('bespoke');
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async getBespokesListWithInactive(callbackSuccess, callbackError) {
		try {
			const result = await this.authAxios.get(
				'bespoke?includeInactive=true'
			);
			return callbackSuccess(result);
		} catch (err) {
			return callbackError(err);
		}
	}
	async getBespokesStatusByEnv(env, callbackError) {
		try {
			const result = await this.authAxios.get(`status/${env}/bespoke/`);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async getAccountList(callbackError) {
		try {
			const result = await this.authAxios.get('account');
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}
	async getBespokePropertyById(env, accountId, bespokeId, callbackError) {
		try {
			let result = await this.authAxios.get(
				`templateproperties/account/${env}/${accountId}/template/${bespokeId}`
			);
			return result;
		} catch (err) {
			callbackError(err);
		}
	}
}

export class CreationBespokeService {
	constructor(authAxios, match) {
		this.authAxios = authAxios;
		this.params = match?.params;
	}

	async updateBespokeByEnv(env, data, callbackError) {
		try {
			const result = await this.authAxios.put(`bespoke/${env}`, data);
			return result.data;
		} catch (err) {
			return callbackError(err);
		}
	}

	// async getBespokePropertyById(
	//     env,
	//     accountId,
	//     bespokeId,
	//     callbackError
	// ) {
	//     try {
	//        let result =  await this.authAxios.get(
	//             `templateproperties/account/${env}/${accountId}/template/${bespokeId}`
	//         );
	//         return result
	//     } catch (err) {
	//         callbackError(err);
	//     }
	// }
	async getAccountPropertiesCopy(env, accountId, callbackError) {
		try {
			let result = await this.authAxios.get(
				`templateproperties/account/${env}/${accountId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}
}

export class EditionBespokeService {
	constructor(authAxios, match) {
		this.authAxios = authAxios;
		this.params = match?.params;
	}

	async updateTemplateProperty(
		bespokeId,
		data,
		callbackSuccess,
		callbackError
	) {
		try {
			const res = await this.authAxios.put(
				`templateProperties/account/${this.params.env}/${this.params.accountId}/template/${bespokeId}`,
				data
			);
			callbackSuccess(res);
			return res;
		} catch (err) {
			return callbackError(err);
		}
	}

	async updateTemplatePropertyByEnv(
		env,
		accountId,
		bespokeId,
		data,
		callbackSuccess,
		callbackError
	) {
		try {
			const res = await this.authAxios.put(
				`templateProperties/account/${env}/${accountId}/template/${bespokeId}`,
				data
			);
			callbackSuccess(res);
			return res;
		} catch (err) {
			return callbackError(err);
		}
	}

	async updateBespokeProperty(
		bespokeId,
		data,
		callbackSuccess,
		callbackError
	) {
		try {
			const res = await this.authAxios.put(
				`bespoke/${this.params.env}/${bespokeId}`,
				data
			);
			callbackSuccess(res);
			return res;
		} catch (err) {
			return callbackError(err);
		}
	}

	async updateBespokePropertByEnv(
		env,
		bespokeId,
		data,
		callbackSuccess,
		callbackError
	) {
		try {
			const res = await this.authAxios.put(
				`bespoke/${env}/${bespokeId}`,
				data
			);
			callbackSuccess(res);
			return res;
		} catch (err) {
			return callbackError(err);
		}
	}
	async getBespokeProperty(env, bespokeId, callbackError) {
		try {
			let result = await this.authAxios.get(
				`bespoke/${env}/${bespokeId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}
	async getBespokePropertyFromUrl(callbackError) {
		try {
			let result = await this.authAxios.get(
				`bespoke/${this.params.env}/${this.params.bespokeId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async createBespoke(env, data, callbackSuccess, callbackError) {
		try {
			const result = await this.authAxios.post(`bespoke/${env}`, data);
			return callbackSuccess(result);
		} catch (err) {
			return callbackError(err);
		}
	}

	async getTemplateAccountProperty(env, accountId, callbackError) {
		try {
			let result = await this.authAxios.get(
				`templateproperties/account/${env}/${accountId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async getTemplatePropertyFromUrl(callbackError) {
		try {
			let result = await this.authAxios.get(
				`templateproperties/account/${this.params.env}/${this.params.accountId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async getTemplatePropertyById(bespokeId, callbackError) {
		try {
			const result = await this.authAxios.get(
				`templateproperties/account/${this.params.env}/${this.params.accountId}/template/${bespokeId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}
	async getTemplatePropertyByIdWhithoutUrl(env, accountId, bespokeId, callbackError) {
		try {
			const result = await this.authAxios.get(
				`templateproperties/account/${env}/${accountId}/template/${bespokeId}`
			);
			return result;
		} catch (err) {
			return callbackError(err);
		}
	}

	async updatePublicationState(
		bespokeId,
		status,
		data,
		callbackSuccess,
		callbackError
	) {
		try {
			await this.authAxios.put(
				`bespoke/${this.params.env}/${bespokeId}/${status}`,
				data
			);
			return callbackSuccess();

		} catch (err) {
			return callbackError(err);
		}
	}
}
