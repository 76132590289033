import { useState } from 'react';

export default function useSocket() {
	const [message, setMessage] = useState();
	const [roomInfo, setRoomInfo] = useState();
	const socketWithEvent = (socket, event, data) => {
		if (event === 'joinRoom') {
			socket.emit('joinRoom', data);
		}
		if (event === 'message') {
			socket.on('message', (message) => {
				setMessage(message);
			});
		}
		if (event === 'register-campaign') {
			socket.emit('register-campaign', `${data.displayName} has just updated the campaign.
             Warning : by saving now, you will erase this last update. We advise you to leave this campaign without saving, and then start over`);
		}
		if (event === 'roomUsers') {
			socket.on('roomUsers', ({ room, users }) => {
				setRoomInfo(users);
			});
		}
		if (event === 'leave-room') {
			socket.emit('leave-room', data);
		}
	};
	return { message, roomInfo, socketWithEvent };
}
