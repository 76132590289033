import React from 'react';
import { Panel } from 'ui-beyable';

export default function PanelZoneEditionContainer({
	panelZoneIsOpen,
	setpanelZoneIsOpen,
	children
}) {
	return (
		<Panel
			isOpen={panelZoneIsOpen}
			onClose={() => setpanelZoneIsOpen(false)}
			width={800}
			side={'right'}
			hasClose={false}
			closeOnOverlay={true}
		>
			<>
				<div className="modal_header has_border flex flex_justify_between" >
					<div>
Page type 
					</div>
					{children}
				</div>
	
			</>
		</Panel>
	);
}
