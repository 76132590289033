/* eslint-disable no-inner-declarations */
import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import { withRouter } from 'react-router-dom';

import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';

import './PageType.css';

function PageType({ accountId, newPageTypeIsOppen, env }) {

	const {
		screenShots,
		setScreenShots,
		screenShotId,
		screenShotToChange,
		setscreenShotToChange,
		isNewScreenShot,
		listPageTypes,
		setListPageTypes
	} = useContext(creationSetupSimplify);
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const [listFilteredByPageTypeAlreadyExistant, setlistFilteredByPageTypeAlreadyExistant] = useState([]);

	const [labelPageType, setlabelPageType] = useState('');


	useEffect(() => {
		const fetchPageTypes = async () => {
			await authAxios.get(`/websitesetup/${env.toUpperCase()}/pagetype/${accountId}`)
				.then(res => {
					setListPageTypes(res.data);
				}
				).catch(e => {
					console.error(e);
				});
		};
		isAuthenticated && !newPageTypeIsOppen && fetchPageTypes();
	}, [newPageTypeIsOppen, accountId, isNewScreenShot, screenShotId]);

	const changeName = (e) => {
		if (isNewScreenShot) {
			let newNameMobile = screenShotToChange.find(screen => screen.device === 'mobile');
			newNameMobile.name = e.target.value;
			let newNameDesktop = screenShotToChange.find(screen => screen.device === 'desktop');
			newNameDesktop.name = e.target.value;
			setscreenShotToChange([newNameMobile, newNameDesktop]);
		} else {
			let newNameMobile = screenShotToChange.find(screen => screen.device === 'mobile');
			newNameMobile.name = e.target.value;
			newNameMobile.isChange = true;
			let newNameDesktop = screenShotToChange.find(screen => screen.device === 'desktop');
			newNameDesktop.name = e.target.value;
			newNameDesktop.isChange = true;

			let findScreenShotMobile = screenShots.find(screen => screen.screenshotId === newNameMobile.screenshotId);
			if (findScreenShotMobile) {
				findScreenShotMobile.name = e.target.value;
				findScreenShotMobile.isChange = true;
			}
			let findScreenShotDesktop = screenShots.find(screen => screen.screenshotId === newNameDesktop.screenshotId);
			if (findScreenShotDesktop) {
				findScreenShotDesktop.name = e.target.value;
				findScreenShotMobile.isChange = true;
			}

			const indexOfItemMobil = screenShots.indexOf(findScreenShotMobile);
			const indexOfItemDesktop = screenShots.indexOf(findScreenShotDesktop);
			function replaceAt(array, index, value) {
				const ret = array.slice(0);
				ret[index] = value;
				return ret;
			}
			let newArrayOfScreenShots = replaceAt(screenShots, indexOfItemMobil, findScreenShotMobile);
			newArrayOfScreenShots = replaceAt(screenShots, indexOfItemDesktop, findScreenShotDesktop);

			setScreenShots(newArrayOfScreenShots);
			setscreenShotToChange([newNameMobile, newNameDesktop]);
		}
	};
	const changeTag = (e) => {
		let newNameMobile = screenShotToChange.find(screen => screen.device === 'mobile');
		newNameMobile.pageType = e.target.value;
		let newNameDesktop = screenShotToChange.find(screen => screen.device === 'desktop');
		newNameDesktop.pageType = e.target.value;
		setscreenShotToChange([newNameMobile, newNameDesktop]);
	};
	useEffect(() => {
		if (screenShotToChange && listPageTypes.find(el => el.tag === screenShotToChange[0].pageType)) {
			setlabelPageType(listPageTypes.find(el => el.tag === screenShotToChange[0].pageType).label);
		} else {
			setlabelPageType('');
		}
	}, [listPageTypes, listFilteredByPageTypeAlreadyExistant, isNewScreenShot]);
	useEffect(() => {
		if (listPageTypes && screenShotToChange && screenShotToChange[0].isNewScreenShot) {
			let arrayToPut = [];
			let arrayOfTagOnSetup = screenShots.map(x => x.pageType);
			arrayOfTagOnSetup = [...new Set(arrayOfTagOnSetup)];
			listPageTypes.forEach(x => {
				if (arrayOfTagOnSetup.includes(x.tag) === false) {
					const elementToPush = listPageTypes.find(el => el.tag === x.tag);
					if (elementToPush) {
						arrayToPut.push(elementToPush);
					}
				}
			});
			arrayToPut.filter(x => x === undefined);
			setlistFilteredByPageTypeAlreadyExistant(arrayToPut);
		} else {
			setlistFilteredByPageTypeAlreadyExistant(listPageTypes);
		}
	}, [listPageTypes, newPageTypeIsOppen, isNewScreenShot]);

	return (
		<form className="pagetype-form">
			<>
				<div className="input-goupe-pagetype">
					<label htmlFor="name">Name</label>
					<input className="input-text" id="name" type="text" value={screenShotToChange ? screenShotToChange[0].name : ''} onChange={changeName} placeholder="name of page" />
				</div>
				<div className="input-goupe-pagetype">
					<label htmlFor="tag">Matching page tag</label>

					{screenShotToChange && !isNewScreenShot && <>
						<select className="select-tag" name="tag" id="tag"
							value={screenShotToChange ? screenShotToChange[0].pageType : ''} onChange={changeTag} >
							<option value={screenShotToChange[0].pageType} > {screenShotToChange[0].pageType} {labelPageType}</option>

						</select>
					</>}
					{screenShotToChange && isNewScreenShot && <>
						<select className="select-tag" name="tag" id="tag" value={screenShotToChange[0].pageType ? screenShotToChange[0].pageType : ''} onChange={changeTag} >
							<option key="select" disabled></option>
							{listFilteredByPageTypeAlreadyExistant && listFilteredByPageTypeAlreadyExistant.map((x, i) => <option key={i} value={x.tag}> {x.tag} {x.label}</option>)
							}
						</select>
					</>}
				</div>
			</>

			
		</form>
	);
}
export default withRouter(PageType);
