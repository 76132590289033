import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';
export default function SearchBarPropertyBespoke() {

	const {
		valuesInBespokeProperties
	} = useSelector((state) => state.bespoke);

	const dispatch = useDispatch();
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [noData, setNoData] = useState();
	// fonction de recherche dans le tableau
	const search = (table, word) => {
		if (word.length > 0) {
			dispatch(bespokeActions.handlePropertyBespokeFiltered([]));
			let resultName = table.filter(
				line =>
					line.propertyName.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				resultName = 'No data found for this search';
				setNoData(resultName);
			}
			else {
				setNoData();
				dispatch(bespokeActions.handlePropertyBespokeFiltered([...resultName])
				);
			}

		} else {
			dispatch(bespokeActions.handlePropertyBespokeFiltered());
		}
	};
	const handleChange = (event) => {
		setValueInSearchBar(event.target.value);
		if (valuesInBespokeProperties) {
			search(valuesInBespokeProperties, event.target.value);
		}
	};

	return (
		<div className="properties_search">
			<input type="text"
				className="form-control col-md-12 p-2 m-auto"
				value={valueInSearchBar}
				placeholder="Find property"
				onChange={handleChange}
			/>
		</div>
	);
}
