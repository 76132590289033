
import React, { useState, useEffect } from 'react';
import Dock from 'react-dock';
import { AiOutlineClose } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';
import { RotateSpinner as Loader } from 'react-spinners-kit';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../utils/useAuthAxios';

import IconsContainer from '../IconsContainer';
import GalleryImage from './GalleryImage';
import UploadImageComponent from './UploadImageComponent';
import Switch from '../Ui/Switch';

import './UploadImageContainer.css';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../store/bespokeProperty-slice';
import PaginationBoard from './PaginationImagesAccount';

export default function UploadImageContainer({ accountId, env }) {

	const dispatch = useDispatch();
	const {
		pictureUrlCopy
	} = useSelector((state) => state.bespoke);

	const styles = {
		backgroundColor: '#f1f1f1',
		height: '100%'
	};

	const { isAuthenticated } = useAuth0();
	const { authAxios, authAxiosV2 } = useAuthAxios();

	const dockVisible = true;
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [picturesAccount, setPicturesAccount] = useState(false);
	const [isClick, setIsClick] = useState(false);
	const [darkBackground, setDarkBackground] = useState(false);
	const changeClick = () => {
		setIsClick(!isClick);
	};
	const [offset, setOffset] = useState(0);
	const [pageSize, setpageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [numberOfData, setNumberOfData] = useState(null);
	useEffect(() => {
		if (valueInSearchBar === '') {
			const fetchPictures = async () => {
				setPicturesAccount(false);
				await authAxiosV2.get(`asset/account/${env}/${accountId}?limit=10&offset=${offset}`,).then(result => {
					if (numberOfData !== parseInt(result.headers['x-total-count'])) {
						setNumberOfData(parseInt(result.headers['x-total-count']));
					}
					setPicturesAccount(result.data);
				}
				).catch(err => {
					console.log('err', err);
					setNumberOfData(0);
					setPicturesAccount([]);
				});
			};
			isAuthenticated && fetchPictures();
		}
	}, [isClick, isAuthenticated, currentPage, valueInSearchBar]);
	useEffect(() => {
		const interval = setTimeout(() => {
			if (valueInSearchBar !== '') {
				const fetchPictures = async () => {
					setPicturesAccount(false);
					const myEncode = encodeURIComponent(valueInSearchBar);
					authAxiosV2.get(`asset/account/${env}/${accountId}?filter=${myEncode}&limit=10&offset=${offset}`,
					).then(result => {
						if (numberOfData !== parseInt(result.headers['x-total-count'])) {
							setNumberOfData(parseInt(result.headers['x-total-count']));
						}
						setPicturesAccount(result.data);
					}).catch(err => {
						console.log('err', err);
						setNumberOfData(0);
						setPicturesAccount([]);
					});
				};
				isAuthenticated && fetchPictures();
			}
		}, 1000);
		return () => {
			clearTimeout(interval);
		};
	}, [isClick, isAuthenticated, valueInSearchBar, currentPage]);



	const handleChange = (event) => {
		setValueInSearchBar(event.target.value);
	};
	return (
		<>
			<Dock
				position='left'
				isVisible={dockVisible}
				dimMode='none'
				size={.5}
				dockStyle={styles}
			>
				<div className="gallery-wrapper">
					<div className="gallery-header">
						<div className="flex flex_v">
							<h2 className="gallery-title">Account image gallery</h2>
							<div className="flex">
								<Switch
									forcedChecked={darkBackground}
									onChange={(e) => setDarkBackground(e.target.checked)}
								/>
								<p className="ml_5">Dark Background</p>
							</div>
						</div>
						<div className="gallery-upload-icon" onClick={changeClick}>
							<FaUpload /> <span>Upload an image</span>
						</div>
						<span className="close gallery-close">
							<IconsContainer color="red">
								<span onClick={() => dispatch(bespokeActions.toggleUploadPicture(false))}>
									<AiOutlineClose />
								</span>
							</IconsContainer>
						</span>
						{pictureUrlCopy &&
                            <span className='gallery-copy-success'>
                            	{pictureUrlCopy}
                            </span>
						}
					</div>

					<div className="gallery-search">
						<input type="text"
							className="gallery-search-input"
							value={valueInSearchBar}
							placeholder="Search an image"
							onChange={handleChange}
						/>
					</div>

					{isClick ?
						<UploadImageComponent
							accountId={accountId}
							env={env}
							setIsClick={setIsClick}
						/> :
						!picturesAccount ?
							<div className="spinner">
								<div className="rect1"></div>
								<div className="rect2"></div>
								<div className="rect3"></div>
								<div className="rect4"></div>
								<div className="rect5"></div>
							</div>
							:
							(
								<>
									<GalleryImage
										pictures={picturesAccount}
										darkBackground={darkBackground}
									/>
									<PaginationBoard
										totalCount={numberOfData}
										pageSize={pageSize}
										handleChangePageSize={(e) => {
											setpageSize(e);
											setOffset(0);
										}}
										paginate={(e) => setOffset(e >= 0 ? e : 0)}
										skipIdNumber={offset}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
									/>
								</>
							)
					}
				</div>
			</Dock>
		</ >
	);
}

