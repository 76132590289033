import React, { useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
export default function SelectFileContainer({ listOfScripts, setNewSelectedFiles, selectedFiles }) {
	const animatedComponents = makeAnimated();
	const newArray = [];
	listOfScripts && listOfScripts.forEach(val =>
		newArray.push({ value: val, label: val }));

	const defaultSelect = selectedFiles && selectedFiles.map(val =>
		({ value: val.accountApiFileName, label: val.accountApiFileName }));

	const handleSelectChange = (e) => {
		setNewSelectedFiles(e);
	};
	return (
		<div>
			<Select
				closeMenuOnSelect={true}
				components={animatedComponents}
				defaultValue={defaultSelect}
				menuPortalTarget={document.body}
				styles={{ menuPortal: base => ({ ...base, zIndex: 100001 }) }}
				menuPosition={'fixed'}
				isMulti
				options={newArray}
				placeholder={'Search a script'}
				onChange={handleSelectChange}
			/>
		</div>
	);
}
