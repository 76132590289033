import React from 'react';

import { useSelector } from 'react-redux';
export default function HeaderEdition({ env, message, status, children }) {
	const accountWorkingInformations = useSelector(state => state.ui.accountWorkingInformations);
	const { name } = accountWorkingInformations;
	let envColor =
    {
    	dev: 'colorEnvDev',
    	prod: 'colorEnvProd',
    	preprod: 'coloEnvPreProd'
    };

	return (

		<div className="bespoke-header">
			<p><span className={`${envColor[env.toLowerCase()]}`}>@{env}</span> {message}  <> {name}</> </p>
			{status && <>Status : {status} </>}
			<div>
				{children}
			</div>
		</div>
	);
}
