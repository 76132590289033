import React, { createContext, useState, useEffect } from 'react';
import uuid from 'react-uuid';
export const creationSetupSimplify = createContext();

const CreationSetupSimplifyProvider = (props) => {
	const [screenShots, setScreenShots] = useState([]);
	const [screenShotId, setScreenShotId] = useState();
	const [screenShotToChange, setscreenShotToChange] = useState();
	const [deviceSelected, setdeviceSelected] = useState('mobile');
	const [changeIsSaved, setchangeIsSaved] = useState(true);
	const [isNewScreenShot, setisNewScreenShot] = useState(false);
	const [panelTypeIsOpen, setPanelTypeIsOpen] = useState(false);
	const [newPageTypeIsOppen, setNewPageTypeIsOppen] = useState(false);
	const [arrayOfZones, setArrayofZones] = useState();
	const [accountIdParams, setaccountIdParams] = useState();
	const [listPageTypes, setListPageTypes] = useState([]);
	const [panelZoneIsOpen, setpanelZoneIsOpen] = useState(false);
	const [refreshScreenShotZoning, setrefreshScreenShotZoning] = useState(false);
	const [zoneSelected, setZoneSelected] = useState();
	const register = () => {

	};
	const addNewZone = () => {
		const array = [...arrayOfZones];
		let ids = [];
		array.forEach(x =>
			ids.push(x.ZoneId));
		let newId;
		const getNewId = () => {
			const number = uuid();
			if (ids.includes(number)) {
				getNewId();
			}
			else {
				newId = number;
			}
		};
		getNewId();
		const newZoneToAdd = {
			zoneId: newId,
			name: '',
			description: '',
			device: deviceSelected,
			pageType: screenShotToChange[0].pageType,
			accountId: accountIdParams,
			toggle: true,
			zoneType: 0,
			zoneExtensions: {
				carrouselSettings: {
					infinite: true,
					slidesToShow: 1,
					autoplaySpeed: 3000,
				},
				sliderSettings: {
					infinite: true,
					slidesToShow: 1,
					autoplaySpeed: 3000,
				},
				Coordinates: {
					height: '0',
					left: '0',
					top: '0',
					width: '0'
				}
			},
			script: '',
			html: '',
			css: '',
			newZone: true
		};
		let newDataToPutZone = [...screenShotToChange].find(x => x.screenshotId === screenShotId);
		if (newDataToPutZone) {
			newDataToPutZone.zones.push(newZoneToAdd);
			let dataWithoutOldZone = [...screenShotToChange].filter(x => x.screenshotId !== screenShotId);
			dataWithoutOldZone.isChange = true;
			setscreenShotToChange([newDataToPutZone, ...dataWithoutOldZone]);
			setpanelZoneIsOpen(true);
			setZoneSelected(newZoneToAdd);
		}
	};

	return (
		<creationSetupSimplify.Provider value={{
			screenShots,
			setScreenShots,
			setScreenShotId,
			screenShotId,
			screenShotToChange,
			setscreenShotToChange,
			deviceSelected,
			setdeviceSelected,
			register,
			changeIsSaved,
			setchangeIsSaved,
			isNewScreenShot,
			setisNewScreenShot,
			panelTypeIsOpen,
			setPanelTypeIsOpen,
			setArrayofZones,
			arrayOfZones,
			accountIdParams,
			setaccountIdParams,
			addNewZone,
			newPageTypeIsOppen,
			setNewPageTypeIsOppen,
			listPageTypes,
			setListPageTypes,
			panelZoneIsOpen,
			setpanelZoneIsOpen,
			setrefreshScreenShotZoning,
			refreshScreenShotZoning,
			zoneSelected,
			setZoneSelected
		}}>
			{props.children}
		</creationSetupSimplify.Provider>
	);
};
export default CreationSetupSimplifyProvider;