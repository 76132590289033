export default function changeSlideCampaignHook(
	associateTemplate,
	datasSlidesOfCampaignSelected,
	slideId,
	displayIdentifier,
	then,
	screenEnvironment,
	parameterToChange,
	originalCampaign,
	handleIsSaveCp
) {

	const compareOriginalCampaignInfosAndCurrentForApplyChanges = (ori, current) => {
		const stringifyNewObj = JSON.stringify(current[0]);
		const compareIfIsSave = stringifyNewObj === ori;
		handleIsSaveCp(compareIfIsSave);
	};
	let dataSlideComplete = [...datasSlidesOfCampaignSelected];
	let dataSlideToChange = dataSlideComplete.find(slide => slide.slideId === slideId);

	if (dataSlideToChange) {
		dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier)[screenEnvironment][parameterToChange] = associateTemplate;
		dataSlideComplete = dataSlideComplete.filter(el => el.slideId !== slideId);
		compareOriginalCampaignInfosAndCurrentForApplyChanges(originalCampaign, datasSlidesOfCampaignSelected);
		then([...dataSlideComplete, dataSlideToChange]);
	}
}
