import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
// import envConfig from '../../env.js'
// import { REACT_APP_AUTH0PROVIDERDOMAIN, REACT_APP_AUTH0PROVIDERCLIENTID, REACT_APP_AUTH0PROVIDERAUDIENCE } from "../../env.js"
const Auth0ProviderWithHistory = ({ children }) => {
	const history = useHistory();
	const onRedirectCallback = (appState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};
	// const auth0ProviderDomain = process.env.REACT_APP_AUTH0PROVIDERDOMAIN;
	// const auth0ProviderClientId = process.env.REACT_APP_AUTH0PROVIDERCLIENTID;
	// const auth0ProviderAudience = process.env.REACT_APP_AUTH0PROVIDERAUDIENCE;
	const auth0ProviderDomain = window.envConfig.AUTH0PROVIDERDOMAIN;
	const auth0ProviderClientId = window.envConfig.AUTH0PROVIDERCLIENTID;
	const auth0ProviderAudience = window.envConfig.AUTH0PROVIDERAUDIENCE;
	return (
		<Auth0Provider
			domain={auth0ProviderDomain}
			clientId={auth0ProviderClientId}
			redirectUri={window.location.origin}
			// onRedirectCallback={onRedirectCallback}
			audience={auth0ProviderAudience}
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;
