import React, { useState, useEffect } from 'react';

import moment from 'moment';
export default function useCampaignStatus(campaign, statusCampaign) {
	if (!campaign) return {};

	const formatDateToLocal = (dateToFormat) => {
		return moment(moment.utc(dateToFormat).toDate()).local();
	};
	const now = moment();
	const startDate = getStartDate();
	const endDate = getEndDate();
	const isTestingMode = getIsTestingMode();

	function getStartDate() {
		const date = campaign.startDate || campaign.StartDateUtc;
		return formatDateToLocal(date);
	}

	function getEndDate() {
		const date = campaign.endDate || campaign.EndDateUtc;
		return formatDateToLocal(date);
	}

	function getIsTestingMode() {
		if (campaign.isTest == 2) {
			return true;
		}
		return false;
	}

	function getStatusKey() {
		if (campaign.isStopped || campaign.IsStopped) {
			return 'stopped';
		}
		if (isTestingMode) {
			return 'testing';
		}
		if (endDate < now) {
			return 'past';
		}
		if (startDate > now) {
			return 'planned';
		}
		return 'ongoing';
	}

	const status = {};
	status.Key = getStatusKey();

	switch (status.Key) {
		case 'stopped':
			status.Label = 'Stopped';
			status.Parent = status.Key;
			break;
		case 'testing':
			status.Label = 'Draft';
			status.Parent = status.Key;
			break;
		case 'past':
			status.Label = 'Past';
			status.Parent = 'started';
			break;
		case 'planned':
			status.Label = 'Scheduled';
			status.Parent = 'started';
			break;
		case 'ongoing':
			status.Label = 'Ongoing';
			status.Parent = 'started';
			break;
	}

	return status;
}
