import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'
import SpinnerOverlay from './../Ui/SpinnerOverlay';
import HeaderEdition from './../Ui/HeaderEdition';
import Btn from './../Ui/Btn';
import './accountApiContainer.css'
import EditorCodeContainer from './EditorCodeContainer';
import Modal from './../Ui/Modal';
import SelectFileContainer from './SelectFileContainer';
import FormCreateScript from './FormCreateScript';
import { fetchDataAccount } from '../../store/uiActions';
import { useDispatch, useSelector } from 'react-redux';
export default function AccountApiContainer({ match }) {
    const dispatch = useDispatch();
    const { name } = useSelector(state => state.ui.accountWorkingInformations);
    const { isAuthenticated } = useAuth0();
    const { authAxios } = useAuthAxios();
    const [isReloading, setIsRealoading] = useState(true)

    const [listOfScripts, setListOfScripts] = useState([]);
    const [scriptSelected, setSciptSelected] = useState();
    const [etag, setEtag] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [newSelectedFiles, setNewSelectedFiles] = useState([]);
    const [modalIsOppen, setModalIsOppen] = useState(false);
    const [isCreationScript, setIsCreationScript] = useState(false);
    const [registerChange, setRegisterChange] = useState(false);
    const [val, setValue] = useState("Choose a script");
    const [etagForApi, setEtagForApi] = useState("");
    const [hasSwipp, setHasSwipp] = useState();
    const [seeDiff, setSeeDiff] = useState(false);
    const [theme, setTheme] = useState("vs-dark");
    const setAccountWorkingDatas = () => {
        dispatch(fetchDataAccount(authAxios, match.params.env, match.params.id))
    }

    useEffect(() => {
        if (isAuthenticated) {
            const timer = setTimeout(() => {
                setAccountWorkingDatas();
            }, 700)
            return () => {
                clearTimeout(timer);
            }
        }
    }, [isAuthenticated])
    useEffect(() => {
        if (match.params.env && !hasSwipp) {
            setIsRealoading(true)
            const fetchData = async () => {
                authAxios &&   authAxios.get(`/accountapi/${match.params.env}/${match.params.id}`)
                    .then(res => {
                        setIsRealoading(false)
                        setListOfScripts(res.data.allApiFileNames)
                        setSelectedFiles(res.data.accountApis && res.data.accountApis)
                        if (res.data.eTag) {
                            setEtag(res.data.eTag)
                        }
                    }).catch(err => console.log("err", err))
            }
            isAuthenticated && fetchData();
        }
    }, [isAuthenticated])
    useEffect(() => {
        if (registerChange && authAxios) {
            setIsRealoading(true)
            const fetchData = async () => {
                authAxios &&  authAxios.get(`/accountapi/${match.params.env}/${match.params.id}`)
                    .then(res => {
                        setIsRealoading(false)
                        setListOfScripts(res.data.allApiFileNames)
                        setSelectedFiles(res.data.accountApis && res.data.accountApis)
                        if (res.data.eTag) {
                            setEtag(res.data.eTag)
                            setRegisterChange(false)
                        }
                        selectAScriptToPut(res.data.accountApis.filter(item => item.accountApiFileName === hasSwipp)[0])
                    }).catch(err => {
                        console.log("err", err)
                        setRegisterChange(false)
                    })
            }
            isAuthenticated && fetchData();
        }
    }, [isAuthenticated, registerChange])

    useEffect(() => {
        if (match.params.env && hasSwipp && authAxios) {
            setIsRealoading(true)
            const fetchData = async () => {
                authAxios.get(`/accountapi/${match.params.env}/${match.params.id}`)
                    .then(res => {
                        setIsRealoading(false)
                        setListOfScripts(res.data.allApiFileNames)
                        setSelectedFiles(res.data.accountApis && res.data.accountApis)
                        setEtag(res.data.eTag)
                        setRegisterChange(false)
                        setSeeDiff(true)
                        selectAScriptToPut(res.data.accountApis.filter(item => item.accountApiFileName === hasSwipp)[0])
                    }).catch(err => {
                        console.log("err", err)
                        setRegisterChange(false)
                    }
                    )
            }
            isAuthenticated && fetchData();
        }
    }, [isAuthenticated, hasSwipp])
    const selectAScriptToPut = (e) => {
        setSciptSelected(e.accountApiFileName)
        setValue({ original: e.api, apiPreview: e.apiPreview })
        setEtagForApi({ accountApiETag: e.apiETag, accountApiPreviewETag: e.apiPreviewETag })
        setHasSwipp()
    }
    useEffect(() => {
        if (!hasSwipp && selectedFiles && selectedFiles.length !== 0) {
            setSciptSelected(selectedFiles[0].accountApiFileName)
            setValue({ original: selectedFiles[0].api, apiPreview: selectedFiles[0].apiPreview })
            setEtagForApi({ accountApiETag: selectedFiles[0].apiETag, accountApiPreviewETag: selectedFiles[0].apiPreviewETag })
        }
    }, [selectedFiles])

    const putTheAccountApis = (e) => {
        e.preventDefault()
        setIsRealoading(true)
        const selectFiles = newSelectedFiles !== null ? newSelectedFiles.map(x => x.value) : []
        let accountApiCOnfig = {
            etag: etag,
            accountApiFileNames: selectFiles
        }
        authAxios.put(`/accountapi/${match.params.env}/${match.params.id}/configuration`, accountApiCOnfig)
            .then(res => {
                setIsRealoading(false)
                setRegisterChange(true)
            }).catch(err => {
                console.log("err", err)
                setIsRealoading(false)
            })
        setNewSelectedFiles([])
    }

    return (
        <div>
            <HeaderEdition
                env={match.params.env}
                message="Editing the Script of "
            />
            {
                isCreationScript &&
                <Modal
                    isOpen={isCreationScript}
                    width="800"
                    onClose={() => setIsCreationScript(false)}
                >
                    <div className="modal_body">
                        <h1>Create a script account {name && name} </h1>
                        <FormCreateScript
                            isCreationScript={isCreationScript}
                            accountId={match.params.id}
                            env={match.params.env}
                            setIsRealoading={(e) => setIsRealoading(e)}
                            setRegisterChange={(e) => setRegisterChange(e)}
                            setIsCreationScript={(e) => setIsCreationScript(e)}
                        />
                    </div>

                </Modal>
            }
            {!isReloading &&

                <div className="container_script_view">
                    {modalIsOppen &&
                        <Modal
                            isOpen={modalIsOppen}
                            width="800"
                            onClose={() => { setModalIsOppen(false) }}
                        >
                            <div className="modal_body">
                                <h1>Choose the scripts for this account </h1>
                                < SelectFileContainer
                                    listOfScripts={listOfScripts}
                                    selectedFiles={selectedFiles}
                                    setNewSelectedFiles={setNewSelectedFiles}
                                />
                            </div>
                            <div className="modal_footer">
                                <Btn
                                    onClickFunction={() => setModalIsOppen(false)}
                                    message="Cancel"
                                    color="alert"
                                />
                                <Btn
                                    onClickFunction={(e) => {
                                        putTheAccountApis(e);
                                        setModalIsOppen(false)
                                    }}
                                    icon="fas fa-save"
                                    message="Save"
                                    color="primary"
                                />
                            </div>
                        </Modal>
                    }

                    <div className="script_parameter_container">
                        <Btn
                            message="New script"
                            onClickFunction={() => setIsCreationScript(true)}
                            icon='fas fa-plus'
                        />

                        <Btn
                            onClickFunction={() => setModalIsOppen(true)}
                            icon="fas fa-edit"
                            message="Add a script"
                            color="primary" />
                    </div>
                    {selectedFiles && selectedFiles.length !== 0 ?
                        <>
                            <nav className="script_selection_container">
                                {selectedFiles && selectedFiles.map(
                                    (script, i) =>
                                        <Btn
                                            key={i}
                                            onClickFunction={(e) => selectAScriptToPut(script)}
                                            message={script.accountApiFileName}
                                            style="outline"
                                            color={scriptSelected === script.accountApiFileName ? "primary" : "secondary"}
                                            icon={scriptSelected === script.accountApiFileName ? "far fa-eye" : ""}
                                        />
                                )}
                            </nav>
                            <EditorCodeContainer
                                val={val}
                                accountId={match.params.id}
                                env={match.params.env}
                                setIsRealoading={(e) => setIsRealoading(e)}
                                setRegisterChange={(e) => setRegisterChange(e)}
                                etagForApi={etagForApi}
                                scriptSelected={scriptSelected}
                                setHasSwipp={(e) => setHasSwipp(e)}
                                seeDiff={seeDiff}
                                setSeeDiff={(e) => setSeeDiff(e)}
                                theme={theme}
                                setTheme={(e) => setTheme(e)}
                            />
                        </> :
                        <div className="script_parameter_empty">
                            <p>This account does not have a script yet, you can create one or associate an existing script</p>
                        </div>
                    }
                </div>
            }

            {
                isReloading &&
                <SpinnerOverlay />
            }
        </div >
    )
}
