import React, { useState, useEffect } from 'react';
import BespokePropertiesList from './BespokePropertiesList';

import './BespokePropertyContainer.css';
import IconsContainer from '../../IconsContainer';
import { GoDiffAdded } from 'react-icons/go';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import BespokeAccountPropertiesList from './BespokeAccountPropertiesList';
import Dock from 'react-dock';
import AddNewProperty from './AddNewProperty';

import BespokeLiquidPropertiesList from './BespokeLiquidPropertiesList';
import BespokeLiquidPropertiesListV2 from './BespokeLiquidPropertiesListV2';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';

export default function BespokePropertyContainer({ screenEnvironment }) {

	const dispatch = useDispatch();
	const {
		wysiwygValues,
		isCliked,
		seeConfiguration,
		propertiesBespokeLiquid,
	} = useSelector((state) => state.bespoke);
	const [isLiquid, setIsLiquid] = useState(false);
	const dockVisible = true;
	useEffect(() => {
		if (wysiwygValues && wysiwygValues[screenEnvironment === 'desktop' ? 'desktopTemplate' : 'mobileTemplate'].templateType === 2) {
			setIsLiquid(true);
		} else {
			setIsLiquid(false);
		}
	}, [wysiwygValues]);

	const styles = {
		backgroundColor: '#f1f1f1'
	};

	if (!seeConfiguration) return <></>;
	return (
		<>
			<Dock
				position='bottom'
				isVisible={dockVisible}
				dimMode='none'
				size={.8}
				dockStyle={styles}
			>
				<div className="d-flex justify-content-around sticky-top titleOfConfig p-2 properties_title">
					<span className="close">
						<IconsContainer color="red">
							<span onClick={() => dispatch(bespokeActions.toggleConfiguration(false))
							}>
								<AiOutlineClose />
							</span>
						</IconsContainer>
					</span>

					{!isLiquid &&
                        <div className="">
                        	<h5 className="font-weight-bold text-secondary"> SaaS Format properties
                        		<span className="mb-4">
                        			<IconsContainer color="green">&nbsp; &nbsp;
                        				<span onClick={() => dispatch(bespokeActions.toggleClicked())}>
                        					<GoDiffAdded />

                        				</span>
                        			</IconsContainer>
                        		</span>
                        	</h5>
                        </div>
					}
					{!isLiquid &&
                        <div className="">
                        	<h5 className="font-weight-bold text-secondary">Account properties</h5>
                        </div>
					}
				</div>
				{isCliked && !isLiquid && <AddNewProperty />}
				{/* {isCliked && isLiquid === true && <AddNewPropertyLiquid />} */}

				{!isLiquid &&
                    <div className="all_properties">
                    	<BespokePropertiesList />
                    	<BespokeAccountPropertiesList />
                    </div>
				}
				{/* {isLiquid === true && <BespokeLiquidPropertiesList />} */}
				{isLiquid === true &&
                    <BespokeLiquidPropertiesListV2 />}
			</Dock >
		</ >
	);
}
