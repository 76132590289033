import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import filterList from './filterList';
import CreationNewBespoke from './CreationNewBespoke';
import IconsContainer from '../../IconsContainer';
import { IoIosCreate, IoIosCopy } from 'react-icons/io';
import BespokeService from '../../../Services/BespokeService';
import useAlert from '../../../Hooks/useAlert';
import SpinnerOverlay from '../../Ui/SpinnerOverlay';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataAccount } from '../../../store/uiActions';
import { actionUI } from '../../../store/ui-reducer';
import {
	bespokeActions,
	handleEnv,
} from '../../../store/bespokeProperty-slice';
import { bespokeFormActions } from '../../../store/bespokeForm-slice';
export default function BespokeCreationContainerAdmin() {
	const [accounts, setAccounts] = useState();
	const [listOfAccountByEnv, setListOfAccountByEnv] = useState([]);
	const [toggleState, setToggleState] = useState(1);
	const [creationMode, setCreationMode] = useState();
	const [redirect, setRedirect] = useState(false);
	const { createAlerte } = useAlert();
	const {
		env,
		valuesUrl,
		accountSelect,
		newDataBespokeCopy,
		idAccountOfBespokeToCopy,
	} = useSelector((state) => state.bespoke);

	const {
		newBespokeValues,
	} = useSelector((state) => state.bespokeForm);
	const {
		isBeyableAccount
	} = useSelector((state) => state.ui);

	const dispatch = useDispatch();

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const bespokeService = new BespokeService(authAxios);
	useEffect(() => {
		dispatch(
			bespokeActions.initCreationBespoke()
		);
	}, []);

	// extract from context for redux
	useEffect(() => {
		const changeUrlValues = () => {
			dispatch(
				bespokeFormActions.handleNewBespokeValues({
					...newBespokeValues,
					thumbnailUrl: valuesUrl && valuesUrl[0],
				})
			);
		};
		changeUrlValues();
	}, [valuesUrl]);
	function insensible_a_la_casse(a, b) {
		if (a.account.name && b.account.name) {
			if (a.account.name.toUpperCase() < b.account.name.toUpperCase())
				return -1;
			if (a.account.name.toUpperCase() > b.account.name.toUpperCase())
				return 1;
		}
		if (a.account.name === null) return 1;
		if (b.account.name === null) return -1;
		return 0;
	}
	useEffect(() => {
		const fetchData = async () => {
			const result = await bespokeService.getAccountList((err) =>
				createAlerte('error', 'An error occurred', {
					removeAllBeforeShow: false,
				})
			);
			// const result = await authAxios.get("account")
			const resultSort = result.data;
			resultSort && resultSort.sort(insensible_a_la_casse);
			setAccounts(resultSort);
		};
		if (!isAuthenticated) return;
		fetchData();

		dispatch(bespokeActions.handleAccountList(authAxios));
	}, [isAuthenticated, isBeyableAccount]);

	useEffect(() => {
		const onFilterChange = () => {
			setListOfAccountByEnv([]);
			setCreationMode();
			dispatch(bespokeActions.onFilterChange());
			dispatch(bespokeFormActions.onFilterChange());

			let filteredRowsByEnv =
                accounts && accounts.filter((ac) => ac.env === env);

			setListOfAccountByEnv(filteredRowsByEnv);
		};
		onFilterChange();
	}, [env]);

	if (redirect) {
		//Affichage de la redirection
		// return <Redirect to={`/bespokeEdition/${accountId}/${dataResult}/${env}`} />;
		return <Redirect to={'/bespokeEdition/'} />;
	}

	let envColor = null;
	if (env === 'Dev') {
		envColor = 'colorEnvDev';
	}
	if (env === 'Prod') {
		envColor = 'colorEnvProd';
	}
	if (env === 'PreProd') {
		envColor = 'coloEnvPreProd';
	}
	const creationAll = () => {
		setCreationMode(1);
		dispatch(bespokeActions.handleIsCopy(false));
		setRedirect(true);
		dispatch(fetchDataAccount(authAxios, env, accountSelect));
		dispatch(actionUI.handleChangeEnv(env));
	};
	const CreationCopy = (evt) => {
		evt.preventDefault();
		setCreationMode(2);
		dispatch(bespokeActions.handleIsCopy(true));
		dispatch(actionUI.handleChangeEnv(env));
	};

	return (
		<div className="col-sm-10 m-auto">
			<div
				className="alert  alert-success"
				id="alertSuccess"
				role="alert"
			>
                Your SaaS Format "{newBespokeValues && newBespokeValues.name}" has been successfully created!
			</div>
			<div className="alert  alert-danger" id="alertBad" role="alert">
                An error occured while creating your SaaS Format "{newBespokeValues && newBespokeValues.name}". Check the console!
			</div>
			<div className="card shadow mb-4 ">
				<div
					className={`card-header py-3 d-flex flex-row align-items-center justify-content-center bg-lightgray  ${envColor}`}
				>
					<h1 className="title-h1"> SaaS Format Creation </h1>
				</div>
				{toggleState === 1 && (
					<div className="card-body overflow-auto ">
						<div className="form-group m-auto col-md-6 ">
							<label htmlFor="account">Environment </label>
							<select
								className="form-control mb-2 border rounded-right "
								id="inputFilteredByEnv"
								onChange={(e) =>
									dispatch(bespokeActions.handleFilterEnv(e))
								}
							>
								{filterList.map((x) => (
									<option key={x.label} value={x.value}>
										{' '}
										{x.label}
									</option>
								))}
							</select>
						</div>

						{env !== 'choose' && (
							<div className="form-group m-auto col-md-6 ">
								<label htmlFor="account">Account </label>
								{listOfAccountByEnv?.length > 0 ? (
									<select
										className="form-control mb-2 border  rounded-right "
										id="account"
										name="account"
										onChange={(e) => {
											dispatch(
												bespokeFormActions.handleFilterAccount(e));
											dispatch(
												bespokeActions.handleFilterAccount(e
												));
										}}
									>
										<option value="">
                                            select an account
										</option>
										{listOfAccountByEnv &&
                                            listOfAccountByEnv.map((x, i) => (
                                            	<option
                                            		key={i}
                                            		value={[
                                            			x.account.id,
                                            			x.account.name,
                                            		]}
                                            	>
                                            		{' '}
                                            		{x.account.name === null
                                            			? x.account.id
                                            			: x.account.name}
                                            	</option>
                                            ))}
									</select>
								) : (
									<SpinnerOverlay />
								)}
							</div>
						)}

						{newBespokeValues.accountId !== '' && (
							<>
								<div className="d-flex justify-content-around mt-4">
									<div
										className="ChooseModeCreation row "
										onClick={creationAll}
									>
										<h6 className="m-auto text-center font-weight-bold ">
                                            New SaaS Format
										</h6>
										<IconsContainer color="black">
											<IoIosCreate className="ChooseCreate" />
										</IconsContainer>
									</div>
									<div
										className=" ChooseModeCreation row "
										onClick={(e) => CreationCopy(e)}
									>
										<h6 className="m-auto text-center font-weight-bold ">
                                            Copy a SaaS Format
										</h6>
										<IconsContainer color="black">
											<IoIosCopy className="ChooseCopy" />
										</IconsContainer>
									</div>
								</div>

								{creationMode === 2 && (
									<CreationNewBespoke env={env} />
								)}
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
