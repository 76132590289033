import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Authentication.css';
import NewDropdown from '../Ui/NewDropdown';

const Profile = ({ children }) => {
	const { user, isAuthenticated, isLoading } = useAuth0();
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	if (isLoading) {
		return <div className="profileNav">Loading ...</div>;
	}

	if (!isAuthenticated) {
		return <div></div>;
	}
	const btn = <a className="userAuthBtn flex">
		<div className="userAuthName">{user?.name || user?.email}</div>
	</a>;
	return (
		isAuthenticated && (
			<div className="userAuth">

				<NewDropdown
					isOpen={dropdownIsOpen}
					setIsOpen={() => setDropdownIsOpen(!dropdownIsOpen)}
					onHide={() => setDropdownIsOpen(!dropdownIsOpen)}
					button={btn}
				>
					<ul className="listbox">
						<li className="listbox_item">{children}</li>
					</ul>
				</NewDropdown>
			</div>
		)
	);
};

export default Profile;
