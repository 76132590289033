import React from 'react';
import './spinnerOverlay.css';
export default function SpinnerOverlay() {
	return (
		<div className="spinner_overlay">
			<div className="spinner">
				<div className="rect1"></div>
				<div className="rect2"></div>
				<div className="rect3"></div>
				<div className="rect4"></div>
				<div className="rect5"></div>
			</div>
		</div>
	);
}
