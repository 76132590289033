

import { StatusAlertService } from 'react-status-alert';

export default function campaignUtils() {
	let templateType = [
		{ value: 0, label: 'XSLT' },
		{ value: 1, label: 'XSLT' },
		{ value: 2, label: 'LIQUID' }
	];
	const arrayOfLanguages = [
		{ key: 1, value: 'xml' },
		{ key: 2, value: 'css' },
		{ key: 3, value: 'javascript' },
		{ key: 4, value: 'displayConditionJavascript' }
	];

	const LinkCampaignToBackOffice = (match, idOfCampaignSelected,
		then) => {
		const listOfKu = [{ env: 'Prod', ku: '3699ADF4-9B05-449F-818C-4FEEAD2D4FBD' },
			{ env: 'PreProd', ku: 'EA131593-8478-4119-BDA3-E3D641811C0D' },
			{ env: 'Dev', ku: 'C433DF2D-190A-4A2C-9553-4ADA62B74CC1' }];
		const listOfEnv = [{ env: 'Prod', link: 'https://app2.beyable.com/' },
			{ env: 'PreProd', link: 'https://webapp-front-preprod.azurewebsites.net/' },
			{ env: 'Dev', link: 'https://backoffice-ui-beyable-dev.azurewebsites.net/' }];
		const res = `${listOfEnv.find(env => env.env === match.params.env).link}/CampaignReporting/Home/?dimension=onSite&id=${idOfCampaignSelected}&ka=${match.params.accountId}&ku=${listOfKu.find(env => env.env === match.params.env).ku}`;
		then(res);
	};

	const getTypeMessage = (typeMsg) => {
		switch (typeMsg) {
			case 'join':
				return 'info';
			case 'left':
				return 'info';
			case 'edit':
				return 'edit';
			default: return '';
		}
	};

	const createUrlToShare = (e) => {
		StatusAlertService.showSuccess('Le lien vers la campagne a été copié dans le presse papier');
		navigator.clipboard.writeText(window.location);
	};
	const handleChangesWithCopy = async (datasSlidesOfCampaignSelected, slideId, screenEnvironment, displayIdentifier) => {
		let dataSlideComplete = [...datasSlidesOfCampaignSelected];
		let dataSlideToChange = dataSlideComplete.find(slide => slide.slideId === slideId);
		if (dataSlideToChange && screenEnvironment === 'desktop') {
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.css = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.css;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.javascript = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.javascript;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.contentTemplate = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.contentTemplate;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.contentTemplateType = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.contentTemplateType;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.displayConditionJavascript = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.displayConditionJavascript;
			dataSlideComplete = dataSlideComplete.filter(el => el.slideId !== slideId);
			dataSlideComplete = [...dataSlideComplete, dataSlideToChange];
		} else if (dataSlideToChange && screenEnvironment === 'mobile') {
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.css = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.css;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.javascript = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.javascript;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.contentTemplate = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.contentTemplate;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.contentTemplateType = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.contentTemplateType;
			dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).desktop.displayConditionJavascript = dataSlideToChange.slideTemplates.find(slide => slide.displayIdentifier === displayIdentifier).mobile.displayConditionJavascript;
			dataSlideComplete = dataSlideComplete.filter(el => el.slideId !== slideId);
			dataSlideComplete = [...dataSlideComplete, dataSlideToChange];
		}
		let slideModels = [...dataSlideComplete];
		return slideModels;
	};
	const transformArrayOfSlideIdentifier = (data) => {

		const dataRes = data.map(elem => ({
			value: elem.displayIdentifier, label: elem.displayIdentifier === '' ? 'original' : elem.displayIdentifier
		}));

		return dataRes;
	};



	return {
		LinkCampaignToBackOffice,
		getTypeMessage,
		templateType,
		createUrlToShare,
		arrayOfLanguages,
		handleChangesWithCopy,
		transformArrayOfSlideIdentifier,

	};
}