import React from 'react';
import IconsContainer from '../../IconsContainer';
import { FaCopy } from 'react-icons/fa';
import { AiFillInfoCircle } from 'react-icons/ai';
export default function BespokeAccountPropertiesItem({ data, onClick }) {
	return (
		<div className="property-container">
			<span onClick={onClick}><FaCopy /></span>
			<p className="">{data.knownValues[0].propertyName} {data.knownValues[0].description !== '' && <AiFillInfoCircle title={data.knownValues[0].description} />}</p>
		</div>
	);
}