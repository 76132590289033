
import React, { useContext, useState, useEffect, useRef } from 'react';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';
import './SimplifyScreenShot.css';
import Btn from '../../Ui/Btn';
export default function SimplifyScreenShot() {
	const {
		screenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		screenShots,
		setScreenShots,
		addNewZone,
		refreshScreenShotZoning,
	} = useContext(creationSetupSimplify);


	const [thumb, setThumb] = useState();
	const [file, setFile] = useState();

	const fileInput = useRef(null);
	const [zonesToMaped, setZonesToMaped] = useState([]);

	const [screen, setscreen] = useState();
	useEffect(() => {
		const screenToLoad = screenShotToChange.find(screenShot => screenShot.device === deviceSelected);
		setscreen(screenToLoad);
		setFile();
	}, [screenShotToChange, deviceSelected, screenShotId]);

	const changeThumNailDataToPut = () => {
		let dataToPutToMaj = [...screenShotToChange];
		let newData = dataToPutToMaj.find(x => x.device === deviceSelected);

		newData.previewImgPath = thumb;
		newData.imagePath = file;
		let finalData = [...dataToPutToMaj.filter(x => x.device !== deviceSelected), newData];
		setscreenShotToChange(finalData);
	};
	useEffect(() => {
		if (file) {
			changeThumNailDataToPut();
		}
	}, [file]);
	const checkIfThumbExist = () => {
		const dataPut = screenShotToChange.find(x => x.device === deviceSelected);
		if (dataPut && dataPut.previewImgPath) {
			setThumb(dataPut.previewImgPath);
		}
		else {
			setThumb();
		}
		// register()
	};
	useEffect(() => {
		checkIfThumbExist();
		setFile();
		fileInput.current.value = '';
	}, [screenShotToChange, deviceSelected, screenShotId]);

	// const handleChange = (e) => {
	// 	let prevState = [...screenShotToChange];
	// 	let dataToChange = prevState.find(x => x.device === deviceSelected);
	// 	let dataNotChange = prevState.find(x => x.device !== deviceSelected);
	// 	if (e.target.name === 'height') {
	// 		dataToChange.screenshotExtensions.height = e.target.value;
	// 	}
	// 	else {
	// 		dataToChange.screenshotExtensions.width = e.target.value;
	// 	}
	// 	setscreenShotToChange([dataToChange, dataNotChange]);
	// };
	const defineZones = () => {
		if (deviceSelected) {
			let newZone = screenShotToChange.find(x => x.screenshotId === screenShotId && x.device === deviceSelected.toLowerCase());
			if (newZone) {
				for (let i = 0; i < newZone.zones.length; i++) {
					let zone = newZone.zones[i];
					const top = zone.zoneExtensions.Coordinates.top / newZone.screenshotExtensions.height * 100 + '%';
					const left = zone.zoneExtensions.Coordinates.left / newZone.screenshotExtensions.width * 100 + '%';
					const width = zone.zoneExtensions.Coordinates.width / newZone.screenshotExtensions.width * 100 + '%';
					const height = zone.zoneExtensions.Coordinates.height / newZone.screenshotExtensions.height * 100 + '%';

					newZone.zones[i].style = {
						top: top,
						left: left,
						width: width,
						height: height
					};
					let ratio = zone.zoneExtensions.Coordinates.width / zone.zoneExtensions.Coordinates.height;
					zone.zoneExtensions.Coordinates.ratio = ratio;
				}
				setZonesToMaped(newZone.zones);
			} else {
				setZonesToMaped([]);
			}
		}

	};
	useEffect(() => {
		defineZones();
	}, [screenShotToChange, deviceSelected]);

	const resfreshZones = () => {
		let newNameMobile = screenShotToChange.find(screen => screen.device === 'mobile');
		let newNameDesktop = screenShotToChange.find(screen => screen.device === 'desktop');

		let findScreenShotMobile = screenShots.find(screen => screen.screenshotId === newNameMobile.screenshotId);
		let findScreenShotDesktop = screenShots.find(screen => screen.screenshotId === newNameDesktop.screenshotId);

		const indexOfItemMobil = screenShots.indexOf(findScreenShotMobile);
		const indexOfItemDesktop = screenShots.indexOf(findScreenShotDesktop);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		let newArrayOfScreenShots = replaceAt(screenShots, indexOfItemMobil, findScreenShotMobile);
		newArrayOfScreenShots = replaceAt(screenShots, indexOfItemDesktop, findScreenShotDesktop);

		setScreenShots(newArrayOfScreenShots);
		setscreenShotToChange([newNameMobile, newNameDesktop]);
		defineZones();
	};
	useEffect(()=>{
		resfreshZones();
	},[refreshScreenShotZoning]);

	return (
		<div className="screenShotContainer " >
			<div className="screenshotUploadContainer" >
				<input ref={fileInput} type="file"  />
			</div>

			{/* <form >
				<div className="input-goupe-pagetype">
					<input className="input-text-screenshot" type="number" onChange={handleChange} value={screen && screen.screenshotExtensions.width ? screen.screenshotExtensions.width : ''} name="width" placeholder="Width" />
				</div>
				<div className="input-goupe-pagetype">
					<span className="screenshot-width-screen">x</span>
				</div>
				<div className="input-goupe-pagetype">
					<input className="input-text-screenshot" type="number" onChange={handleChange} value={screen && screen.screenshotExtensions.height ? screen.screenshotExtensions.height : ''} name="height" placeholder="Height" />
				</div>

			</form> */}
			<Btn
				message="Add zone"
				onClickFunction={() => addNewZone()}
				icon='fas fa-plus'
			/>

			<div className="customizePreview">
				<div className="customizePreviewInner">
					<img className="customizePreviewImg"
						src={screen && screen.previewImgPath ? screen.previewImgPath :
							screen && screen.imagePath.length > 0 ? screen.imagePath : 'https://via.placeholder.com/470x600'} alt="screenshot of the pageType" />
					{zonesToMaped.map((zone, zoneKey) =>
						<div
							key={zoneKey}
							className={`${'customizeZone'}`}
							style={zone.style}
						>
							<div className="customizeZone-name">{zone.name}</div>
						</div>
					)}
				</div>
			</div>
		</div >
	);
}
