import React, { useState } from 'react'

import IconsContainer from '../../IconsContainer';
import { MdDeleteForever } from 'react-icons/md';
import { AiFillCopy } from 'react-icons/ai';

export default function BespokePropertiesItem({ data, onClick, onChange }) {

    const [toggleInfos, setToggleInfos] = useState(false);
    const copyValueToCurrentInput = (e) => {
        const target = {};
        target.target = {};
        target.target.value = e;
        setToggleInfos(false);
        onChange(target);
    }

    return (
        <>
            <div className="bespoke-property">
                <p>{data.propertyName}</p>
                <span className="deleteProperty" onClick={onClick}>
                    <IconsContainer color="red">
                        <MdDeleteForever />
                    </IconsContainer>
                </span>
            </div>
            <div className="col-xs-4 .w-2">
                <input type="text"
                    className="form-control"
                    value={data.defaultValue.defaultValue || data.defaultValue || ''}
                    placeholder=""
                    onChange={onChange} />
            </div>
            <div className="list-information-property">
                {!toggleInfos ? <h3 onClick={() => setToggleInfos(!toggleInfos)}> Détails <i className="fas fa-chevron-right"></i></h3> : <h3 onClick={() => setToggleInfos(!toggleInfos)}>Détails <i className="fas fa-chevron-up"></i></h3>}
                {toggleInfos &&
                    <>
                        <h4>Description</h4>
                        <p>{data.knownValues && data.knownValues[0].description}</p>
                        <p>{data.description !== 'undefined' && data.description}</p>
                        {data.knownValues && <div className="list-known-values">
                            <label htmlFor="">Valeurs connues :</label>
                            {data.knownValues.map((val, i) =>
                                <span key={i} className={data.defaultValue === val.defaultValue ? 'list-item-property active' : 'list-item-property '}>
                                    {val.defaultValue} <AiFillCopy onClick={(e) => copyValueToCurrentInput(val.defaultValue)} /></span>
                            )}
                        </div>}
                    </>
                }

            </div>
        </>
    )
}
