import React from 'react';
import './Avatar.css';
import useDisplayName from '../../Hooks/useDisplayName';
function Avatar({ user, size = 'm', onClickFunction }) {
	const cssClass = ['avatar'];
	const colors = ['red', 'orange', 'yellow', 'green', 'lightblue', 'blue', 'purple'];
	const { getTheDisplayNameOfUser } = useDisplayName();

	const setUserColor = function (user) {
		if (!user || user.color) return;
		const id = parseInt(user.id.replace(/^\D+/g, '') || 42);
		const nb = colors.length;
		const index = id % nb;
		user.color = colors[index];
	};

	getTheDisplayNameOfUser(user);
	setUserColor(user);

	// INITIAL
	const initial = user.displayName.slice(0, 1).toUpperCase();

	// SIZE
	if (size) {
		cssClass.push('size_' + size);
	}

	// COLOR
	if (user.color) {
		cssClass.push('color_' + user.color);
	}
	return (
		<div className={cssClass.join(' ')} title={user.firstName} onClick={onClickFunction}>
			{initial}
		</div>
	);
}

export default Avatar;
