

export default class CampaignService {
	constructor(authAxios, match) {
		this.authAxios = authAxios;
		this.params = match.params;
	}
	getCampaignSelectList(callbackSuccess, callbackError) {
		const opts =  {
			headers : 
            {'X-Version' : '1.0'} ,
		};
		this.authAxios
			.get(
				`campaign/account/${this.params.env}/${this.params.accountId}`,
				opts
			)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	getOneCampaignWithParams(callbackSuccess, callbackError) {
		this.authAxios.get(`campaign/account/${this.params.env}/${this.params.accountId}/${this.params.idCampaign}`)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	getOneCampaignById(idCampaign, callbackSuccess, callbackError) {
		this.authAxios.get(`campaign/account/${this.params.env}/${this.params.accountId}/${idCampaign}`)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	updateValidateCampaign(data, callbackSuccess, callbackError) {
		this.authAxios.put('campaign/validate', data)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	updateCampaign(idCampaign, data, callbackSuccess, callbackError) {
		this.authAxios.put(`campaign/account/${this.params.env}/${this.params.accountId}/${idCampaign}`, data)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	updateStatus(data, callbackSuccess, callbackError) {
		let idCampaign = window.location.href.split(`${this.params.env}`);

		this.authAxios.put(`campaign/account/${this.params.env}/${this.params.accountId}${idCampaign[1]}/execution`, data)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}
	updateIps(data, callbackSuccess, callbackError) {
		let idCampaign = window.location.href.split(`${this.params.env}`);
		this.authAxios.put(`campaign/account/${this.params.env.toLowerCase()}/${this.params.accountId}${idCampaign[1]}/testmodeips `, data)
			.then((res) => callbackSuccess(res))
			.catch((err) => callbackError(err));
	}

}
