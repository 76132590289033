import React from 'react';
import './InputCopyToClipBoard.css';
export default function InputCopyToClipBoard({
	value,
	label,
	clipBoardContain,
	onClickFunction,
	fullSize = false,
	width = 240
}) {
	const innerClass = [];
	const inputClass = [];
	const style = {};

	if (fullSize) {
		innerClass.push('flex');
		inputClass.push('full');
	} else {
		innerClass.push('flex_inline');
		style.width = width + 'px';
	}
	if (clipBoardContain === value) {
		inputClass.push('code_is_Copy');
	}
	return (
		<div className="input_copy_container">
			{label &&
                <label className="form_block_label">{label}</label>
			}
			<div className={innerClass.join(' ')}>
				<div className="flex_item_full">
					<input type="text" style={style} className={inputClass.join(' ')} value={value ? value : ''} readOnly />
				</div>
				<div className="flex_item_fix">
					<a className="icon_btn icon_btn_color" onClick={(e) => onClickFunction(value)} title="Copy to clipboard">
						<i className="far fa-clipboard"></i>
					</a>
				</div>
			</div>
		</div>
	);
}
