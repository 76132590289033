import React, { useState, useEffect, useRef } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'
import options from './options'
import Editor from '@monaco-editor/react';

import './EditorCodeContainer.css'
import Btn from './../Ui/Btn';
import MergingCodeContainer from './MergingCodeContainer';
import PopinConfirm from './../Ui/PopinConfirm';

export default function EditorCodeContainer({ val, accountId, env, setIsRealoading, setRegisterChange, etagForApi, scriptSelected, setHasSwipp, seeDiff, setSeeDiff, theme, setTheme }) {

    const [showPopin, setShowPopin] = useState(false)
    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios()
    const swipConfigApi = () => {
        if (isAuthenticated) {
            setShowPopin(false);
            setIsRealoading(true)
            const SwapAccountApi = { accountApiFileName: scriptSelected, accountApiETag: etagForApi.accountApiETag, accountApiPreviewETag: etagForApi.accountApiPreviewETag }
            authAxios.post(`/accountapi/${env}/${accountId}/swap`, SwapAccountApi)
                .then(res => {
                    setHasSwipp(scriptSelected)
                    setIsRealoading(false)
                    setRegisterChange(true)
                }).catch(err => {
                    console.log("err", err)
                    setIsRealoading(false)
                })
        }
    }
    function toggleTheme() {
        setTheme(theme === "" ? "vs-dark" : "");
    }
    return (
        <div className="container_editor_account_api">
            {
                showPopin &&
                <PopinConfirm
                    cancel={() => setShowPopin(false)}
                    title={`Do you want to swap the two scripts?`}
                >
                    <Btn
                        onClickFunction={(e) => { setShowPopin(false) }}
                        message="Non"
                        color="alert"
                    />
                    <Btn
                        onClickFunction={(e) => swipConfigApi()}
                        message="Oui"
                        color="primary"
                    />

                </PopinConfirm>
            }

            {/* <Btn
                onClickFunction={() => toggleTheme()}
                message={theme === "" ? "Black" : "Light"}
            /> */}
            <Btn
                onClickFunction={() => setSeeDiff(!seeDiff)}
                message={!seeDiff ? "Show Preview Mode" : "Display the simple mode"}
                color="secondary"
                style="outline"
            />
            {seeDiff &&
                <Btn
                    onClickFunction={() => setShowPopin(true)}
                    message="Swapper the scripts"
                    icon="fas fa-exchange-alt"
                    color="secondary"
                    style="outline"
                />}
            {
                !seeDiff ?
                    <Editor
                        height="80vh"
                        options={options}
                        language="javascript"
                        value={val.original}
                        theme={theme}
                    />
                    :
                    <>
                        <div className="title_editor_code_preview">
                            <p>Script Original</p>
                            <p>Script Preview</p>
                        </div>
                        <MergingCodeContainer
                            val={val}
                            theme={theme}
                        />
                    </>
            }

        </div>
    )
}
