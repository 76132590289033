/* eslint-disable default-case */
import React from 'react';
import './Notification.css';
import Btn from '../Ui/Btn';
import moment from 'moment';
export default function Notification({ type, onClickFunction, title, message, picto, timeOut, data }) {
	switch (type) {
		case 'info':
			return (
				<div className="notification-container notif-infos">
					{<div className="header-notification"> <h4 className="title">{data.time ? moment(data.time).format('hh:mm A') : ''}</h4><i className="fas fa-times" onClick={() => onClickFunction()}> </i></div>}
					<div className="notification-message">
						<p>{message}</p>
					</div>
				</div>
			);
		case 'edit':
			return (
				<div className="notification-container notif-danger">
					{<h4 className="title">{title} {moment(data.time).format('hh:mm A')}</h4>}

					<div className="notification-message">
						<p>{message}</p>
						<div className="flex flex_justify_center">
							<Btn
								onClickFunction={() => document.location.reload()}
								message="Refresh the page"
								size="l"
								color="warning"
								style="outline"
							/>
						</div>
					</div>
				</div>
			);

		case 'success':
			return (
				<div>

				</div>
			);
		case 'warning':
			return (
				<div>

				</div>
			);
		case 'error':
			return (
				<div>

				</div>
			);
	}

}
