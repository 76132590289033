import React, { useState, useEffect } from 'react';
import Dropdown from '../../Ui/NewDropdown';
import { useDispatch, useSelector } from 'react-redux';
import useCampaignStatus from './useCampaignStatus';
import './RestartCampaign.css';
import moment from 'moment';
import useAlert from '../../../Hooks/useAlert';
import { campaignActions } from '../../../store/campaign-reducer';
export default function RestartCampaign({ btn, campaignService, reloadCampaignList }) {
	const campaign = useSelector(state => state.campaign.campaignSelected);
	const status = useCampaignStatus(campaign);
	const dispatch = useDispatch();

	const { createAlerte } = useAlert();

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [modalTestModeIsOpen, setModalTestModeIsOpen] = useState(false);
	const [startDateLong, setStartDateLong] = useState('');
	const [endDateLong, setEndDateLong] = useState('');
	const closeDropdown = () => {
		setDropdownIsOpen(false);
	};
	const openThePanel = () => {
		dispatch(campaignActions.openCloseModalIps(true));
	};

	const openCloseModalTestMode = (bool) => {
		if (bool) {
			dispatch(campaignActions.openCloseModalIps(true));
		} else {
			dispatch(campaignActions.openCloseModalIps(false));
		}
	};

	const getDateLongFormat = (date) => {
		date = moment(date);
		const six_months_ago = moment().subtract(6, 'months');
		const in_six_months = moment().add(6, 'months');

		if (date.isBefore(six_months_ago)) {
			return date.format('D MMM YYYY');
		}
		if (date.isAfter(in_six_months)) {
			return date.format('D MMM YYYY');
		}
		return date.format('D MMM [at] HH:mm');
	};

	useEffect(() => {
		const start = getDateLongFormat(campaign?.startDate);
		const end = getDateLongFormat(campaign?.endDate);
		setStartDateLong(start);
		setEndDateLong(end);
	}, [campaign,
		status]);

	const statusMap = {
		'testing': 'StartWithTestMode',
		'stopped': 'Stop',
	};

	const changeParentStatus = (newParentStatus) => {
		closeDropdown();

		if (newParentStatus == status.Parent) {
			return;
		}

		const data = {
			'status': statusMap[newParentStatus]
		};

		campaignService.updateStatus(
			data,
			(success) => {
				reloadCampaignList();
				createAlerte('success', 'The status of the campaign has been successfully changed', {
					removeAllBeforeShow: false,
				});
			},
			(err) => {
				createAlerte('error', 'Error during process', {
					removeAllBeforeShow: false,
				});
			}
		);
	};

	const ipStatus = () => {
		return (<>
			<div className="c_turquoise mt_3" onClick={openThePanel} >
                Edit starting options
			</div></>);
	};
	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={btn}
		>
			<ul className="listbox">
				<li>
					<a
						className="listbox_item campaign_status_dropdown_header"
						onClick={(e) => {
							closeDropdown();
							openCloseModalTestMode(true);
						}}
					>
						<div className="flex">
							<span
								className={'flex_item_fix campaign_status_tag_color ' + status.Key}
							></span>
							<div className="flex_item_full">
								<div className="s_16 fw_medium">{status.Label}</div>
								<div className="listbox_item_desc">
									{status.Key == 'testing' && (
										<>
											<div className="c_turquoise mt_3" onClick={() => openCloseModalTestMode(true)}>
                                                Set who can view the campaign
											</div>
										</>
									)}
									{status.Key == 'ongoing' && (
										<>
											{campaign.endDate ? (
												<>This campaign will end on {endDateLong}</>
											) : (
												<>This campaign has no end date</>
											)}
											{ipStatus()}
										</>
									)}
									{status.Key == 'past' && (
										<>
                                            This campaign ended on {endDateLong}
											{ipStatus()}
										</>
									)}
									{status.Key == 'planned' && (
										<>
                                            The campaign will start on {startDateLong}

											{ipStatus()}
										</>
									)}
									{status.Key == 'stopped' && <>The campaign is stopped
										{ipStatus()}
									</>}
								</div>
							</div>
						</div>
					</a>
				</li>
				<li className="listbox_sep"></li>
				<li>
					<a
						className={
							status.Parent == 'testing' ? 'selected listbox_item' : 'listbox_item'
						}
						onClick={(e) => {
							changeParentStatus('testing');
						}}
					>
						<span className="flex">
							<span className="flex_item_fix campaign_status_tag_color"></span>
							<span className="flex_item_full">Draft</span>
							{status.Parent == 'testing' && (
								<span className="listbox_item_check">
									<i className="fas fa-check"></i>
								</span>
							)}
						</span>
					</a>
				</li>
				<li>
					<a
						className={
							status.Parent == 'stopped' ? 'selected listbox_item' : 'listbox_item'
						}
						onClick={(e) => {
							changeParentStatus('stopped');
						}}
					>
						<span className="flex">
							<span className="flex_item_fix campaign_status_tag_color"></span>
							<span className="flex_item_full">Stopped</span>
							{status.Parent == 'stopped' && (
								<span className="listbox_item_check">
									<i className="fas fa-check"></i>
								</span>
							)}
						</span>
					</a>
				</li>
			</ul>

		</Dropdown>
	);
}
