
/* eslint no-use-before-define: 0 */  //
import { FillSpinner as Loader } from 'react-spinners-kit';
import options from '../../Bespokes/Edition/options';
import { ControlledEditor } from '@monaco-editor/react';
import TestTs from './TestTs';
export default function CurrentZoneContainer({toggleLanguage, settoggleLanguage, zone, handleChangeEditor, handleEditorDidMount}:any) {

	if(!zone ){
		return <></>;
	}
 
	return (
		<div>
			<TestTs
				name='John'
				age={12}
			/>   
			<div style={{ display: 'flex', }}>
				<div className="input-goupe-zone selectorLanguageZone" onClick={() => settoggleLanguage('css')}
					style={toggleLanguage === 'css' ? { color: '#006fff', borderBottom: '1px solid #006fff' } : { color: 'black' }}
				>
					<label htmlFor="css">CSS</label>
				</div>
				<div className="input-goupe-zone selectorLanguageZone" onClick={() => settoggleLanguage('javascript')}
					style={toggleLanguage === 'javascript' ? { color: '#006fff', borderBottom: '1px solid #006fff' } : { color: 'black' }}
				>
					<label htmlFor="bespokeDescription">Javascript</label>
				</div>

			</div>
			{<>
				{toggleLanguage === 'css' &&
        <div style={{ marginBottom: '10px' }}>
        	<ControlledEditor
        		height="450px"
        		width="100%"
        		theme={'vs-dark'}
        		language='css'
        		loading={<Loader />}
        		value={zone.css ? zone.css : ''}
        		onChange={(value, event) => { handleChangeEditor(value, event, 'css', zone.zoneId); }}
        		editorDidMount={handleEditorDidMount}
        		// @ts-ignore: Unreachable code error
        		options={options}
        	/>
        </div>
				}

				{toggleLanguage === 'javascript' &&
        <div style={{ marginBottom: '10px' }}>
        	<ControlledEditor
        		height="450px"
        		width="100%"
        		theme={'vs-dark'}
        		language='javascript'
        		loading={<Loader />}
        		value={zone.script ? zone.script : ''}
        		onChange={(value, event) => { handleChangeEditor(value, event, 'script', zone.zoneId); }}
        		editorDidMount={handleEditorDidMount}
        		// @ts-ignore: Unreachable code error
        		options={options}
        	/>
        </div>
				}</>}

		</div>
	);
}
