import React, { useState, useEffect } from 'react';
import Panel from '../../Ui/Panel';
import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from '../../../store/campaign-reducer';
import SearchBar from '../../Ui/SearchBar';
import useSearch from '../useSearch';
import classes from './PanelCampaign.module.css';
import ListItem from '../../Ui/ListItem';
import { Btn, BtnGroup } from 'ui-beyable';
export default function PanelCampaign({ dataCampaigns, handleSelectCampaign, campaignSelected, env }) {
	const { noData, dataFiltered, search, handleChangeSearchBar, handleBtnFilter } = useSearch(dataCampaigns);
	const dispatch = useDispatch();
	const panelCampaignIsOpen = useSelector(state => state.campaign.panelCampaignIsOpen);
	const onCloseThePanel = () => {
		dispatch(campaignActions.openClosePanelCampaign(false));
	};
	const accountWorkingInformations = useSelector(state => state.ui.accountWorkingInformations);
	const { name } = accountWorkingInformations;
	const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
	const [filtres, setFiltres] = useState({
		ongoing: true,
		draft: true,
		stopped: true,
		past: true,
		scheduled: true
	});

	useEffect(() => {
		handleBtnFilter(filtres);
	  }, [filtres]);

	return (
		<Panel
			isOpen={panelCampaignIsOpen}
			onClose={() => onCloseThePanel()}
			width={400}
			side={'left'}
			closeOnOverlay={true}
		>
			<>
				<div className={classes.nav_container}>
					{isBeyableAccount && <div className={`${classes.breadcrumbItem}`}>#{env}</div>}
					<div className={`${classes.breadcrumbItem}`}>{name}</div>
				</div>
				<div className={classes.search_container}>
					<SearchBar
						placeholder="Search"
						valueInSearchBar={search}
                        onChange={(value) => handleChangeSearchBar(value, filtres)}
						autoFocus={true}
						selectOnFocus={true}
						style="grey"
					/>

					<BtnGroup gap="none">
						<Btn 
							message={'Ongoing'}
							style="outline"
							color="secondary"
							isActive={filtres.ongoing}
							onClick={() => {
								setFiltres({
									...filtres,
									ongoing: !filtres.ongoing
								});
							}}
							size="s"
							horizontalSize='xs'
							light={true}
						/>
						<Btn 
							message={'Draft'}
							style="outline"
							color="secondary"
							isActive={filtres.draft}
							onClick={() => {
								setFiltres({
									...filtres,
									draft: !filtres.draft
								});
							}}
							size="s"
							horizontalSize='xs'
							light={true}
						/>
						<Btn 
							message={'Stopped'}
							style="outline"
							color="secondary"
							isActive={filtres.stopped}
							onClick={() => {
								setFiltres({
									...filtres,
									stopped: !filtres.stopped,
								});
							}}
							size="s"
							horizontalSize='xs'
							light={true}
							/>
						<Btn 
							message={'Past'}
							style="outline"
							color="secondary"
							isActive={filtres.past}
							onClick={() => {
								setFiltres({
									...filtres,
									past: !filtres.past,
								});
							}}
							size="s"
							horizontalSize='xs'
							light={true}
						/>
						<Btn 
							message={'Scheduled'}
							style="outline"
							color="secondary"
							isActive={filtres.scheduled}
							onClick={() => {
								setFiltres({
									...filtres,
									scheduled: !filtres.scheduled,
								});
							}}
							size="s"
							horizontalSize='xs'
							light={true}
						/>
					</BtnGroup>

				</div>
				<ul className={classes.search_results}>
					{dataFiltered && dataFiltered.map(campaign =>
						<ListItem
							key={campaign.id}
							title={campaign.name}
							description={campaign.description}
							id={campaign.id}
							clickHandler={() => handleSelectCampaign(campaign.id)}
							isSelected={campaignSelected && campaignSelected.id === campaign.id}
							isStopped={campaign.isStopped}
							isTest={campaign.isTest}
							endDate={campaign.endDate}
							startDate={campaign.startDate}
						/>
					)}
				</ul>

			</>
		</Panel>
	);
}
