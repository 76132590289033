import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { unwrapResult } from '@reduxjs/toolkit';
import useAuthAxios from '../../../utils/useAuthAxios';
import BespokeService, {
	CreationBespokeService,
} from '../../../Services/BespokeService';

import useAlert from '../../../Hooks/useAlert';
import SpinnerOverlay from '../../Ui/SpinnerOverlay';
import { useSelector, useDispatch } from 'react-redux';
import { BespokeThunk } from '../../../store/services/bespokeThunk';
import { bespokeActions } from '../../../store/bespokeProperty-slice';
import { bespokeFormActions } from '../../../store/bespokeForm-slice';
export default function CreationNewBespoke() {

	const {
		env,
		newDataBespokeCopy,
		idAccountOfBespokeToCopy,
	} = useSelector((state) => state.bespoke);
	const {
		newBespokeValues,
	} = useSelector((state) => state.bespokeForm);
	const dispatch = useDispatch();
	const [allBespokes, setAllBespokes] = useState();
	const [bespokeByEnvSelected, setbespokeByEnvSelected] = useState();
	const [bespokesOfAccount, setBespokesOfAccount] = useState();
	const [copyMode, setCopyMode] = useState('');
	const [copyEnv, setCopyEnv] = useState('Prod');
	const [bespokeToCopy, setBespokeToCopy] = useState('');

	const accountId = newBespokeValues && newBespokeValues.accountId;
	const [redirect, setRedirect] = useState(false);
	const [copyReady, setCopyReady] = useState(false);

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const { createAlerte } = useAlert();
	const creationBespokeService = new CreationBespokeService(authAxios);
	const bespokeService = new BespokeService(authAxios);
	function insensible_a_la_casse(a, b) {
		if (a.account.name === null || b.account.name === null) return 0;
		if (a.account.name.toUpperCase() < b.account.name.toUpperCase())
			return -1;
		if (a.account.name.toUpperCase() > b.account.name.toUpperCase())
			return 1;
		return 0;
	}
	useEffect(() => {
		dispatch(
			bespokeActions.handleNewDataBespokeCopy({
				SourceEnvironment: 'Prod',
			}));
	}, []);
	const fetchDataPropertiesAccount = () => {
		dispatch(
			BespokeThunk.getAccountPropertiesCopy({ authAxios, env, accountId })
		);
	};
	const fetchDataPropertyBespokeCopy = () => {
		dispatch(
			BespokeThunk.getBespokePropertyById({
				authAxios,
				copyEnv,
				idAccountOfBespokeToCopy,
				bespokeToCopy,
			})
		).then(() => setCopyReady(true));
	};

	useEffect(() => {
		const fetchBespokes = async () => {
			const result = await bespokeService.getBespokesList((err) => {
				createAlerte('error', 'An error occurred', {
					removeAllBeforeShow: false,
				});
				console.log(err);
			});
			setAllBespokes(result.data);

			//   await dispatch(BespokeThunk.getBespokesList(authAxios)).then(res => {  setAllBespokes(res.payload)})
			let filterByEnv = result.data.filter((be) => be.env === copyEnv);
			filterByEnv.sort(insensible_a_la_casse);
			let filtereByAccountByEnv =
                filterByEnv &&
                filterByEnv.filter((ac) => ac.account.id === accountId);
			dispatch(bespokeActions.handleidAccountOfBespokeToCopy(accountId));
			setBespokesOfAccount(filtereByAccountByEnv);
			setbespokeByEnvSelected(filterByEnv);
		};
		if (isAuthenticated) fetchBespokes();
	}, [isAuthenticated, accountId]);



	useEffect(() => {
		if (bespokeToCopy && isAuthenticated) {
			const updateDataPropertyBespokeCopy = async () => {
				const res = await creationBespokeService.updateBespokeByEnv(env,
					newDataBespokeCopy,
					(err) => {
						createAlerte('error', 'An error occurred', {
							removeAllBeforeShow: false,
						});
					});
				dispatch(bespokeActions.updateBespokeTemplate(res));
				dispatch(bespokeFormActions.handleNewBespokeValues({
					name: res.name,
					description: res.description,
					code: res.code + 'Copy',
					accountId: accountId,
					// accountId: state.newBespokeValues.accountId,
					format: res.format,
					customCssEnabled: res.customCssEnabled,
					mobileVersionEnabled: res.mobileVersionEnabled,
					thumbnailUrl: res.thumbnailUrl,
					thumbnailDisplayUrl: res.thumbnailDisplayUrl,
				}
				));

				fetchDataPropertiesAccount();
				fetchDataPropertyBespokeCopy();
			};

			updateDataPropertyBespokeCopy();
		}
	}, [bespokeToCopy, idAccountOfBespokeToCopy, accountId, isAuthenticated]);

	const handleFilterCopyMode = (e) => {
		setCopyMode(e.target.value);
	};


	const handleCopyBespoke = async (e) => {
		console.log('e', e);
		const arrayOfValues = e.target.value.split(',');
		setBespokeToCopy(arrayOfValues[0]);
		dispatch(
			bespokeActions.handleidAccountOfBespokeToCopy(arrayOfValues[1])
		);

		dispatch(
			bespokeActions.handleNewDataBespokeCopy({
				TargetAccountId: arrayOfValues[1],

				SourceBespokeId: arrayOfValues[0],
			})
		);
	};

	const create = (e) => {
		e.preventDefault();
		setRedirect(true);
	};
	if (redirect) {
		return <Redirect to={'/bespokeEdition/'} />;
	}
	return (
		<div className="col-sm-10 m-auto">
			<div className="form-group m-auto col-md-6 ">
				<label htmlFor="account">Copy from </label>
				<select
					className="form-control mb-2 border  rounded-right "
					id="copyMode"
					name="copyMode"
					onChange={handleFilterCopyMode}
					value={copyMode}
				>
					<option value=""> </option>
					<option value="thisAccount"> This account </option>
					<option value="anotherAccount">Another account </option>
				</select>
			</div>
			{copyMode === 'thisAccount' && (
				<>
					<div className="form-group m-auto col-md-6 ">
						<label htmlFor="account">Account SaaS Formats  </label>
						{bespokesOfAccount ? (
							<select
								className="form-control mb-2 border  rounded-right "
								id="account"
								name="account"
								// value={bespokeToCopy}
								onChange={handleCopyBespoke}
							>
								{bespokesOfAccount &&
                                    bespokesOfAccount.map((x, i) => (
                                    	<option
                                    		key={i}
                                    		value={[
                                    			x.bespoke.bespokeId,
                                    			x.bespoke.accountId,
                                    		]}
                                    	>
                                    		{' '}
                                    		{x.bespoke.code === null
                                    			? x.bespokeId.id
                                    			: x.bespoke.code}
                                    	</option>
                                    ))}
								<option value=""> </option>
							</select>
						) : (
							<SpinnerOverlay />
						)}

						{bespokeToCopy !== '' && (
							<div
								className="btn-normal mt-4 mx-auto"
								onClick={(e) => create(e)}
							>
                                copy
							</div>
						)}
					</div>
				</>
			)}

			{copyMode === 'anotherAccount' && (
				<>

					<div className="form-group m-auto col-md-6 ">
						<label htmlFor="account">
                            SaaS Formats BEYABLE accounts{' '}
						</label>
						{allBespokes ? (
							<select
								className="form-control mb-2 border  rounded-right "
								id="account"
								name="account"
								// value={bespokeToCopy}
								onChange={handleCopyBespoke}
							>
								{bespokeByEnvSelected &&
                                    allBespokes &&
                                    bespokeByEnvSelected.map((x, i) => (
                                    	<option
                                    		key={i}
                                    		value={[
                                    			x.bespoke.bespokeId,
                                    			x.bespoke.accountId,
                                    		]}
                                    	>
                                    		{x.account.name} -{' '}
                                    		{x.bespoke.code === null
                                    			? x.bespokeId.id
                                    			: x.bespoke.code}
                                    	</option>
                                    ))}
								<option value=""> </option>
							</select>
						) : (
							<SpinnerOverlay />
						)}

						{bespokeToCopy !== '' ? (
							copyReady ? (
								<div
									className="btn-normal  mt-4  mx-auto"
									onClick={(e) => create(e)}
								>
                                    Copy
								</div>
							) : (
								<SpinnerOverlay />
							)
						) : (
							<></>
						)}
					</div>
				</>
			)}
		</div>
	);
}
