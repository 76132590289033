import React, { useEffect, useState } from 'react';
import Header from '../Ui/Header/Header';
import { useAuth0 } from '@auth0/auth0-react';
const Layout = ({ children }) => {
	const { isAuthenticated, isLoading } = useAuth0();
	return (
		<>
			{isAuthenticated && <Header />}
			{children}
		</>
	);
};

export default Layout;
