import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';

import filterList from './filterList';
import Btn from '../../Ui/Btn';
import SimplifyAccountItem from './SimplifyAccountItem';
import StatusAlert, { StatusAlertService } from 'react-status-alert';
import { useDispatch, useSelector } from 'react-redux';
import { actionUI } from '../../../store/ui-reducer';

export default function SimplifyAccounts() {

	const { loginWithRedirect } = useAuth0();
	const [data, setData] = useState();
	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const [searchBarValue, setSearchBarValue] = useState('');
	const [noData, setNoData] = useState();
	const [DataFiltered, setDataFiltered] = useState();
	const [DataFilteredByEnv, setDataFilteredByEnv] = useState();
	const [selectedOption, setSelectedOption] = useState('All');
	const [statusvalue, setStatusValue] = useState();
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');
	// const [envSelected, setenvSelected] = useState('prod');


	const dispatch = useDispatch();
	const envSelected = useSelector(state => state.ui.env);

	const toggleEnvSelector = (value) => {
		dispatch(actionUI.handleChangeEnv(value));
	};

	const changeStatus = (e, x) => {
		let valueStatus = '';
		if (typeof e !== 'string') {
			setStatusValue(e.target.value);
			valueStatus = e.target.value;
		} else {
			valueStatus = e;
		}
		if (valueStatus !== x.status)
			authAxios.put(`websitesetup/${x.env}/accounts/${x.id}/status/${valueStatus}`, x.domain)
				.then(res => {
					StatusAlertService.showSuccess('Modification réussie');
					setTimeout(() => {
						window.location.reload();
					}, 500);
				})
				.catch(e => {
					setMessage('an error occured');
					setColor('alert-danger');
					StatusAlertService.showError('Une erreur est survenue pendant le changement du status .');
					console.error(e);
					setTimeout(() => {
						setMessage();
					}, 2000);

				});
	};

	useEffect(() => {
		const fetchData = async () => {
			if (envSelected) {
				const result = await authAxios.get(`websitesetup/${envSelected}/accounts `);
				const res = result.data.map(x => ({
					id: x.accountId,
					isActive: x.isActive,
					name: x.name,
					status: x.status,
					env: envSelected,
					domain: x.urls ? x.urls : []
				}));
				const dataToMap = [...res];
				setData(dataToMap);
			}
		};
		if (isAuthenticated) {
			const timer = setTimeout(() => {
				fetchData();
			}, 700);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [isAuthenticated, envSelected]);


	const search = (table, word) => {
		if (word.length > 0) {
			setDataFiltered();
			let resultName = table.filter(
				line =>
					line?.name?.toUpperCase().match(`.*${word.toUpperCase()}.*`)

			);
			let resultRef = table.filter(
				line =>
					line.id.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let dataFinal = [
				...resultName,
				...resultRef,
			];
			let uniq = [...new Set(dataFinal)];
			setDataFiltered(uniq);
			if (dataFinal.length === 0) {
				let result = 'Il n\'y a pas de résultats pour cette recherche';
				setNoData(result);
			}
			else {
				setNoData();
			}
		} else {
			setDataFiltered();
		}
	};
	let results = null;
	let envColor =
    {
    	dev: 'colorEnvDev',
    	prod: 'colorEnvProd',
    	preprod: 'coloEnvPreProd'
    };


	if (!DataFiltered) {
		results =
            data && data.map((x, id) =>
            	<SimplifyAccountItem
            		key={id}
            		dataAccount={x}
            		changeStatus={(e) => changeStatus(e, x)}
            	/>
            );

	}
	if (DataFiltered) {
		results =
            DataFiltered && DataFiltered.map((x, id) =>
            	<SimplifyAccountItem
            		key={id}
            		dataAccount={x}
            		changeStatus={(e) => changeStatus(e, x)}
            	/>
            );

	}
	if (DataFilteredByEnv) {
		results =
            DataFilteredByEnv && DataFilteredByEnv.map((x, id) =>
            	<SimplifyAccountItem
            		key={id}
            		dataAccount={x}
            		changeStatus={(e) => changeStatus(e, x)}
            	/>
            );
	}
	if (DataFilteredByEnv && DataFiltered) {
		results =
            DataFiltered && DataFiltered.map((x, id) =>
            	<SimplifyAccountItem
            		key={id}
            		dataAccount={x}
            		changeStatus={(e) => changeStatus(e, x)}
            	/>
            );
	}
	if (!isAuthenticated) {
		results = 'noConnect';
	}

	useEffect(() => {
		const onFilterChange = () => {
			let filteredRowsByEnv = data && data.filter(row => row.env === selectedOption.toLowerCase());
			if (selectedOption === 'All') {
				filteredRowsByEnv = data;
			}
			//setDataFilteredByEnv(filteredRowsByEnv)
		};
		onFilterChange();
	}, [selectedOption]);
	const handleChange = (e) => {
		setSearchBarValue(e.target.value);
		if (!DataFilteredByEnv) {
			search(data, e.target.value);
		}
		else {
			search(data, e.target.value);
		}
	};
	useEffect(() => {
		setSearchBarValue('');
		setDataFiltered();
	}, [selectedOption]);
	return (
		<div>
			<div className='headerBar' >
				<div className="input-group search_bar">
					<input
						className="form-control"
						onChange={handleChange}
						value={searchBarValue}
						type="text"
						placeholder="Find an account"
						aria-label="Large"
						aria-describedby="basic-addon2"
					/>
					<select
						className="form-controlmb-2 border rounded-right selectionCustom"
						id="inputFilteredByEnv"
						onChange={(e) => toggleEnvSelector(e.target.value)}
						value={envSelected}
					>
						{filterList.map(x => <option key={x.label} value={x.value}> {x.label}</option>)}
					</select>
				</div>
			</div>
			<h1 className="title-h3 title-list-simplify">Simplify Accounts</h1>
			{!data && results !== 'noConnect' ?
				<div className="spinner spinner_overlayed">
					<div className="rect1"></div>
					<div className="rect2"></div>
					<div className="rect3"></div>
					<div className="rect4"></div>
					<div className="rect5"></div>
				</div>
				:
				<>
					{
						results !== 'noConnect' ?
							noData ?

								<div className="noData">
									{noData}
								</div> :
								<section className="list-account-simplify">
									{results}
								</section>
							:
							<div className="connection-message">
								<div>
									<p> You must connect to use BEYABLE Studio</p>
									<Btn onClickFunction={() => loginWithRedirect()} style="">Connect</Btn>

								</div>

							</div>
					}

				</>}
		</div>
	);
}
