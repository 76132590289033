/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import NewDropdown from '../Ui/NewDropdown';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function SelectVersion({
	listofDisplayIdentifier,
	handleSelectVersion,
	displayIdentifier }) {
	const [versionSelected, setversionSelected] = useState();
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	useEffect(() => {

		let value = listofDisplayIdentifier && listofDisplayIdentifier.find(el => el.value === displayIdentifier) ?
			listofDisplayIdentifier.find(el => el.value === displayIdentifier).value : '';
		if (value === '') {
			value = 'original';
		}
		setversionSelected(value);
	}, [displayIdentifier, listofDisplayIdentifier]);
	const idTool = uuidv4();
	const button = (
		<div className="analytics_range">
			<div className="flex">
				<div className="flex_item_full">
					{listofDisplayIdentifier && <>
						<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
						<div style={{ cursor: 'pointer' }} data-for={idTool} data-tip={'select version'}>
							{versionSelected}
						</div>
					</>
					}
				</div>
			</div>
		</div>
	);
	const handleSelectNewTemplateValue = (val) => {
		handleSelectVersion(val);
		setDropdownIsOpen(false);
	};

	return (
		<>
			<NewDropdown
				isOpen={dropdownIsOpen}
				setIsOpen={() => setDropdownIsOpen(true)}
				onHide={() => setDropdownIsOpen(false)}
				button={button}
			>

				<ul className="listbox" >
					{listofDisplayIdentifier && listofDisplayIdentifier.map((value) => {
						const isSelected =
                            (versionSelected &&
                                versionSelected) === value.value;
						return (
							<li key={value.value} style={{ cursor: 'pointer' }}>
								<a
									className={
										isSelected
											? 'listbox_item selected'
											: 'listbox_item'
									}
									onClick={() =>
										handleSelectNewTemplateValue(value.value)
									}
								>
									{value.label}
									{isSelected && (
										<span
											className={'styles.indicator'}
										></span>
									)}
								</a>
							</li>
						);
					})}
				</ul>

			</NewDropdown >
		</>
	);
}
