import React from 'react';
import AccountIdeEditorDesktop from './AccountIdeEditorDesktop';
import AccountIdeEditorMobile from './AccountIdeEditorMobile';
import { useDispatch, useSelector } from 'react-redux';

export default function IdeContainer({ 
	screenEnvironment,
	toggleState,
	handleEditorChangeTemplateDesktop,
	handleEditorChangeCssDesktop,
	slidTemplatesOfSlideModelSelected, 
	handleEditorChangeCssMobile, 
	handleEditorChangeTemplateMobile, 
	handleEditorChangeJsMobile, 
	handleEditorChangeJsDesktop, 
	handleEditorChangeDisplayConditionMobile,
	handleEditorChangeDisplayConditionDesktop,
	theme, 
	env, 
	slideName, 
	isOpen,
	handleEditorReady,
	isEditorReady
}) {

	const { isReloading } = useSelector(state => state.campaign);

	let templateType =
    {
    	0: 'XSLT',
    	1: 'XSLT',
    	2: 'LIQUID'
    };
	return (
		<>
			{
				screenEnvironment === 'desktop' && !isReloading &&
                <>
                	
                        <AccountIdeEditorDesktop
                        	title={slidTemplatesOfSlideModelSelected && templateType[slidTemplatesOfSlideModelSelected[screenEnvironment].contentTemplateType]}
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='xml'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeTemplateDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.contentTemplate}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 1}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	
                	
                        <AccountIdeEditorDesktop
                        	title='CSS'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='css'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeCssDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.css}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 2}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	
                        <AccountIdeEditorDesktop
                        	title='JAVASCRIPT'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeJsDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.javascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 3}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	
                        <AccountIdeEditorDesktop
                        	title='DISPLAY CONDITION'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='desktop'
                        	changeValue={handleEditorChangeDisplayConditionDesktop}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.desktop.displayConditionJavascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 4}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	

                </>}
			{
				screenEnvironment === 'mobile' &&
                <>
                        <AccountIdeEditorMobile
                        	title={slidTemplatesOfSlideModelSelected && templateType[slidTemplatesOfSlideModelSelected[screenEnvironment].contentTemplateType]}
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='xml'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeTemplateMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.contentTemplate}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 1}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	
                        <AccountIdeEditorMobile
                        	title='CSS'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='css'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeCssMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.css}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 2}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                	

                        <AccountIdeEditorMobile
                        	title='JAVASCRIPT'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeJsMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.javascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 3}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />

                        <AccountIdeEditorMobile
                        	title='DISPLAY CONDITION'
                        	format={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.format}
                        	slideName={slideName}
                        	language='javascript'
                        	screenEnvironment='mobile'
                        	changeValue={handleEditorChangeDisplayConditionMobile}
                        	value={slidTemplatesOfSlideModelSelected && slidTemplatesOfSlideModelSelected.mobile.displayConditionJavascript}
                        	theme={theme}
                        	env={env}
                        	isOpen={isOpen}
							isActive={toggleState === 4}
							handleEditorReady={handleEditorReady}
							isEditorReady={isEditorReady}
                        />
                </>
			}
		</>
	);
}
