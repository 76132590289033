import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	dropDownIsOpen: false,
	notification: null,
	env: 'Prod',
	accountWorkingInformations: {},
	isAuthenticated: false,
	isBeyableAccount: false,
	inputSearch: ''
};
const uiSlice = createSlice({
	name: 'ui',
	initialState: initialState,
	reducers: {
		toggle(state) {
			state.dropDownIsOpen = !state.dropDownIsOpen;
		},
		toggleDropDownIsOpen(state, action) {
			state.dropDownIsOpen = action.payload;
		},
		handleChangeEnv(state, action) {
			state.env = action.payload;
		},
		handleChangeAccountWorkingInformations(state, action) {
			state.accountWorkingInformations = action.payload;
		},
		setIsAuthenticated(state, action) {
			state.isAuthenticated = action.payload;
		},
		handleIsBeyableAccount(state, action) {
			state.isBeyableAccount = action.payload;
		},
		handleChangeInputSearch(state, action) {
			state.inputSearch = action.payload;
		},
	}
});

export const actionUI = uiSlice.actions;

export default uiSlice;