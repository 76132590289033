import React, { useRef, useState, useEffect } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import { FillSpinner as Loader } from 'react-spinners-kit';
import { FaMobileAlt } from 'react-icons/fa';
export default function AccountIdeEditorMobile({
	isActive,
	theme,
	language,
	value,
	changeValue,
	isEditorReady,
	handleEditorReady
}) {
	const valueGetter = useRef();

	function handleEditorDidMount(event) {
		handleEditorReady(true);
		valueGetter.current = event;
	}

	return (
		<div className='flex_item_full editor_wrapper' style={{display: isActive ? '' : 'none'}}>
			{isEditorReady && 
				<ControlledEditor
					theme={theme}
					language={language}
					loading={<Loader />}
					value={value}
					onChange={changeValue}
					editorDidMount={handleEditorDidMount}
					options={options}
					automaticLayout={true}
				/>
			}
		</div>
	);
}
