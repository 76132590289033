import React, {useState, useEffect} from 'react';
import {useLocation, Redirect, useHistory} from 'react-router-dom';
import {format} from 'date-fns';
import {FaSave} from 'react-icons/fa';

import {bespokeActions} from '../../../store/bespokeProperty-slice';

import {useAuth0} from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';

import Nav from '../../Ui/Nav';
import Btn from '../../Ui/Btn';
import BespokePropertyContainer from './BespokePropertyContainer';

import PublicationContainer from '../Publication/PublicationContainer';
import ImagesAccountContainer from '../../ImagesAccount/ImagesAccountContainer';
import ConfirmModal from '../../Modale/ConfirmModal';
import Sidebar from '../../Ui/Sidebar';
import BespokeService, {
	EditionBespokeService,
} from '../../../Services/BespokeService';
import './ToggleCheckbox.css';
import useAlert from '../../../Hooks/useAlert';
import {useDispatch, useSelector} from 'react-redux';
import BespokeEditionIdeContainer from './BespokeEditionIdeContainer';
import BespokePanel from './BespokePanel';
import {fetchDataAccount} from '../../../store/uiActions';
import MonacoDiffEditorCampaign from '../../Campaign/Edition/MonacoDiffEditorCampaign';

export default function BespokeEditionContainer({match}) {
	const location = useLocation();
	let history = useHistory();
	const [bespokeValues, setBespokeValues] = useState('none');

	const [screenEnvironment, setScreenEnvironment] = useState('desktop');
	const [creationDate, setCreationDate] = useState();
	const [modificationDate, setModificationDate] = useState();
	const [redirect, setRedirect] = useState(false);
	const [loading, setLoading] = useState(true);
	const [toggleUrlModales, setToggleUrlModales] = useState(false);
	const [toggleModalesDelete, setToggleModalesDelete] = useState(false);
	const [isOpen, setisOpen] = useState(false);
	const {createAlerte} = useAlert();
	const [idToRedirect, setidToRedirect] = useState(null);
	const [isResponsive, setIsResponsive] = useState(true);

	const [isDifferent, setIsDifferent] = useState(false);
	const [seeDiff, setSeeDiff] = useState(false);
	const [valuesEditor, setValuesEditor] = useState();
    
	const {
		isNewBespoke,
		publicationState,
		isDeleteBespoke,
		seeUploadPicture,
		valuesAccountProperties,
		propertiesBespokeLiquid,
		env,
		newDataBespokeCopy,
		isCopy,
		formIsNotEpty,
		valuesAccountPropertiesCopy,
		idAccountOfBespokeToCopy,
		desktopTemplate,
		mobileTemplate,
		wysiwygValues,
		valuesInBespokeProperties,
		valuesBespokeDescription,
		seeConfiguration,
		isLiquid,
		JsonIsValid,
		pannelIsOpen
	} = useSelector((state) => state.bespoke);

	const {
		newBespokeValues,
	} = useSelector((state) => state.bespokeForm);

	const {isAuthenticated} = useAuth0();
	const {authAxios} = useAuthAxios();

	const editionBespokeService = new EditionBespokeService(authAxios, match);
	const bespokeService = new BespokeService(authAxios);
	const dispatch = useDispatch();
	useEffect(() => {
		//init
		dispatch(bespokeActions.toggleConfiguration(false));
		dispatch(bespokeActions.toggleUploadPicture(false));
		if (!isNewBespoke) {
			dispatch(fetchDataAccount(authAxios, match.params.env, match.params.accountId));
		}

	}, []);

	useEffect(() => {
		if (location.pathname === '/bespokeEdition/') {
			dispatch(bespokeActions.handlePanelIsOpen(!pannelIsOpen));
			if (isCopy === false) {
				dispatch(bespokeActions.handleIsNewBespoke(true));
				dispatch(bespokeActions.handleDesktopTemplate({
					contentTemplate: '',
					contentTemplateType: 2,
					css: '',
					javascript: '',
				}));

				dispatch(bespokeActions.handleMobileTemplate({
					contentTemplate: '',
					contentTemplateType: 2,
					css: '',
					javascript: '',
				}));

				dispatch(bespokeActions.handleWysiwygValues({
					desktopTemplate: {
						template: '',
						templateType: 2,
					},
					mobileTemplate: {
						template: '',
						templateType: 2,
					},
				}));
			}
		} else {
			dispatch(bespokeActions.handleIsNewBespoke(false));
			dispatch(bespokeActions.handleIsLiquid(false));
			dispatch(bespokeActions.handlePropertiesBespokeLiquid([]));
		}
	}, []);
	let envColor = null;
	if (!isNewBespoke) {
		if (match.params.env === 'Dev') {
			envColor = 'colorEnvDev';
		}
		if (match.params.env === 'Prod') {
			envColor = 'colorEnvProd';
		}
		if (match.params.env === 'PreProd') {
			envColor = 'coloEnvPreProd';
		}
	}
	if (isNewBespoke) {
		if (env === 'Dev') {
			envColor = 'colorEnvDev';
		}
		if (env === 'Prod') {
			envColor = 'colorEnvProd';
		}
		if (env === 'PreProd') {
			envColor = 'coloEnvPreProd';
		}
	}
	const openUrlCloseModale = (e) => {
		setToggleUrlModales(!toggleUrlModales);
	};
	const stopPropa = (e) => {
		e.stopPropagation();
	};

	function ascBespokeProperty(a, b) {
		if (a.propertyName && b.propertyName) {
			if (a.propertyName.toUpperCase() < b.propertyName.toUpperCase())
				return -1;
			if (a.propertyName.toUpperCase() > b.propertyName.toUpperCase())
				return 1;
		}
		return 0;
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isNewBespoke && bespokeValues === 'none' && isAuthenticated) {
				const fetchDataTemplateBespoke = async () => {
					setLoading(true);
					const result =
                        await editionBespokeService.getBespokePropertyFromUrl(
                        	(err) => {
                        		setLoading(false);
                        		createAlerte(
                        			'error',
                        			'An error occured',
                        			{
                        				removeAllBeforeShow: false,
                        			}
                        		);
                        		console.log(err);
                        	}
                        );
					//             recupérer template d'un bespoke
 
					setBespokeValues(result?.data);
					const datas = result?.data?.activationTemplateModel;
					const objectToLoadOnDiffEditor = [
						{
							id: 'xslt',
							original: datas.desktopTemplate.contentTemplate,
							preview: datas.mobileTemplate.contentTemplate,
						},
						{
							id: 'css',
							original: datas.desktopTemplate.css,
							preview: datas.mobileTemplate.css,
						},
						{
							id: 'javascript',
							original: datas.desktopTemplate.javascript,
							preview: datas.mobileTemplate.javascript,
						},
						{
							id: 'wysiwig',
							original: result?.data?.wysiwygValues?.desktopTemplate.template,
							preview: result?.data?.wysiwygValues?.mobileTemplate.template,
						},
					];
                
					let isResponsiveTest = true;
            
					objectToLoadOnDiffEditor.forEach(elem => {
						if (JSON.stringify(elem.original) !== JSON.stringify(elem.preview)) {
							isResponsiveTest = false;
						}
					});
					if (!isResponsiveTest) {
						setIsDifferent(true);
						setIsResponsive(false);
						setSeeDiff(false);
					} else {
						setIsDifferent(false);
						setIsResponsive(true);
						setSeeDiff(false);
					}
                    
					dispatch(bespokeActions.handleIsLiquid(false));
					if (result?.data.wysiwyg.desktopTemplate.templateType === 2 ||
                        result?.data.wysiwyg.mobileTemplate.templateType === 2
					) {
						dispatch(bespokeActions.handleIsLiquid(true));
					} else {
						dispatch(bespokeActions.handleIsLiquid(false));
					}
					dispatch(bespokeActions.handleValuesBespokeDescription(result?.data));
					dispatch(bespokeActions.handleSelectedOptionCss(result?.data.customCssEnabled));
					dispatch(bespokeActions.handleMobileVersionEnabled(result?.data.mobileVersionEnabled));
					dispatch(bespokeActions.handleDesktopTemplate(result?.data.activationTemplateModel?.desktopTemplate));
					dispatch(bespokeActions.handleMobileTemplate(result.data.activationTemplateModel?.mobileTemplate));
					dispatch(bespokeActions.handleWysiwygValues(result.data?.wysiwyg));
					setCreationDate(
						format(new Date(result?.data.creationDate), 'dd/MM/yyyy')
					);
					setModificationDate(
						format(
							new Date(result.data.modificationDate),
							'dd/MM/yyyy à hh:mm'
						)
					);
					dispatch(bespokeActions.setPublicationState(result.data?.publicationState));
					dispatch(bespokeActions.handlePropertyBespokeFiltered());
					dispatch(bespokeActions.handleValuesAccountPropertiesFiltered());
				};
				dispatch(bespokeActions.toggleSearchProperty());
				fetchDataTemplateBespoke();
			} else if (isNewBespoke) {
				if (wysiwygValues) {
					setLoading(false);
					setisOpen(true);
					dispatch(bespokeActions.handlePanelIsOpen(pannelIsOpen ? false : true));
					if (wysiwygValues?.desktopTemplate?.templateType === 2 ||
                        wysiwygValues?.mobileTemplate?.templateType === 2) {
						dispatch(bespokeActions.handleIsLiquid(true));
					} else {
						dispatch(bespokeActions.handleIsLiquid(false));
					}
				}
			}
		}, 700);
		return () => {
			clearTimeout(timer);
		};

	}, [isAuthenticated, isNewBespoke]);

	useEffect(() => {

		if (!isAuthenticated) return;
		if (!isNewBespoke) {
			const fetchDataPropertiesAccount = async () => {
				const result =
                    await editionBespokeService.getTemplatePropertyFromUrl(
                    	(err) => {
                    		createAlerte(
                    			'error',
                    			'An error occured',
                    			{
                    				removeAllBeforeShow: false,
                    			}
                    		);
                    		console.log(err);
                    	}
                    );
				let finalArray = result?.data;
				finalArray && finalArray.sort(ascBespokeProperty);
				dispatch(bespokeActions.handleValuesAccountProperties(finalArray));
			};
			fetchDataPropertiesAccount();
		} else if (
			isNewBespoke &&
            env !== 'choose' &&
            !valuesAccountProperties
		) {
			const fetchDataPropertiesAccount = async () => {
				const result =
                    await editionBespokeService.getTemplateAccountProperty(
                    	env,
                    	isCopy
                    		? idAccountOfBespokeToCopy
                    		: newBespokeValues.accountId,
                    	(err) => {
                    		createAlerte(
                    			'error',
                    			'An error occured',
                    			{
                    				removeAllBeforeShow: false,
                    			}
                    		);
                    		console.log(err);
                    	}
                    );
				let finalArray = [...result.data];
				finalArray.forEach((element) => {
					element.defaultValue =
                        element.knownValues[0].defaultValue.toString();
				});
				finalArray && finalArray.sort(ascBespokeProperty);
				dispatch(bespokeActions.handleValuesInBespokeProperties([]));
				dispatch(bespokeActions.handleValuesAccountProperties(finalArray));
			};
			fetchDataPropertiesAccount();
		}

		async function getProperty() {
			const result2 =
                await editionBespokeService.getTemplatePropertyByIdWhithoutUrl(
                	newDataBespokeCopy.SourceEnvironment,
                	newDataBespokeCopy.TargetAccountId,
                	newDataBespokeCopy.SourceBespokeId,
                	(err) => {
                		createAlerte(
                			'error',
                			'An error occured',
                			{
                				removeAllBeforeShow: false,
                			}
                		);
                		console.log(err);
                	}
                );
			let response = await result2.data[0];
			let finalArray = [...response.knownValues];
			if (
				wysiwygValues.desktopTemplate.templateType === 2 ||
                wysiwygValues.mobileTemplate.templateType === 2
			) {
				dispatch(bespokeActions.handlePropertiesBespokeLiquid(response.length === 0
					? []
					: JSON.parse(
						response.defaultValue
							.fullContentAlternative
					)));
			} else {
				finalArray.forEach((element) => {
					element.defaultValue =
                        element.defaultValue.toString();
					delete element.defaultValues;
				});
				finalArray && finalArray.sort(ascBespokeProperty);
				dispatch(bespokeActions.handleValuesBespokeProperties(finalArray));
			}
			setLoading(false);
		}

		if (isCopy) {
			getProperty();
		}

	}, [isAuthenticated]);

	useEffect(() => {
		if (isNewBespoke === false && bespokeValues !== 'none' && isAuthenticated) {
			const fetchDataPropertiesBespoke = async () => {
				if (bespokeValues) {
					setLoading(false);
					const result =
                        await editionBespokeService.getTemplatePropertyById(
                        	bespokeValues.bespokeId,
                        	(err) => {
                        		setLoading(false);
                        		createAlerte(
                        			'error',
                        			'An error occured',
                        			{
                        				removeAllBeforeShow: false,
                        			}
                        		);
                        		console.log(err);
                        	}
                        );
					// Probleme : le result.data n'affiche pas les bonnes valeurs, et donc impossible de recuperer le fullContentAlternative
					if (bespokeValues.wysiwyg) {
						if (
							bespokeValues.wysiwyg.desktopTemplate.templateType === 2 ||
                            bespokeValues.wysiwyg.mobileTemplate.templateType === 2
						) {
							let response = await result?.data;
							if (!response) {
								return;
							}

							// dispatch(bespokeActions.handleValuesBespokeProperties(response));
							dispatch(bespokeActions.handlePropertiesBespokeLiquid(response?.length === 0
								? []
								:
								JSON.parse(response[0].defaultValue.fullContentAlternative)));
						} else {
							// Récupération des propriétés d'un bespoke
							let finalArray = result?.data;
							finalArray && finalArray.forEach((element) => {
								element.defaultValue = element.defaultValue.defaultValue.toString();
								delete element.defaultValues;
							});
							finalArray && finalArray.sort(ascBespokeProperty);
							dispatch(bespokeActions.handleValuesBespokeProperties(finalArray));
						}
					}
				}
			};
			fetchDataPropertiesBespoke();
		}
	}, [bespokeValues, isNewBespoke]);


	useEffect(() => {
		const objectToLoadOnDiffEditor = [
			{
				id: 'xslt',
				original: desktopTemplate.contentTemplate,
				preview: mobileTemplate.contentTemplate,
			},
			{
				id: 'css',
				original: desktopTemplate.css,
				preview: mobileTemplate.css,
			},
			{
				id: 'javascript',
				original: desktopTemplate.javascript,
				preview: mobileTemplate.javascript,
			},
			{
				id: 'wysiwig',
				original: wysiwygValues.desktopTemplate.template,
				preview: wysiwygValues.mobileTemplate.template,
			},
		];
		setValuesEditor(objectToLoadOnDiffEditor);
		if (!isResponsive) {
			objectToLoadOnDiffEditor.forEach(elem => {
				if (JSON.stringify(elem.original) !== JSON.stringify(elem.preview)) {
					setIsDifferent(true);
				}
			});
		}
		if (isResponsive) {
			setSeeDiff(false);
		}

	}, [desktopTemplate,
		mobileTemplate,
		wysiwygValues,
		isResponsive]);


	const bespokeId = bespokeValues.bespokeId;
	const modifyTheProperties = async () => {
		const bespokeDescriptionTemplateValues = {...valuesBespokeDescription};
		delete bespokeDescriptionTemplateValues?.accountId;
		delete bespokeDescriptionTemplateValues?.activationTemplateModel;
		delete bespokeDescriptionTemplateValues?.code;
		delete bespokeDescriptionTemplateValues?.creationDate;
		delete bespokeDescriptionTemplateValues?.modificationDate;
		delete bespokeDescriptionTemplateValues?.publicationState;
		delete bespokeDescriptionTemplateValues?.wysiwyg;

		if (isResponsive) {
			if (screenEnvironment === 'desktop') {
				bespokeDescriptionTemplateValues.wysiwygDesktopTemplate =
                    wysiwygValues.desktopTemplate;
				bespokeDescriptionTemplateValues.wysiwygMobileTemplate =
                    wysiwygValues.desktopTemplate;
				bespokeDescriptionTemplateValues.DesktopTemplate = desktopTemplate;
				bespokeDescriptionTemplateValues.MobileTemplate = desktopTemplate;
			}
			if (screenEnvironment === 'mobile') {
				bespokeDescriptionTemplateValues.wysiwygDesktopTemplate =
                    wysiwygValues.mobileTemplate;
				bespokeDescriptionTemplateValues.wysiwygMobileTemplate =
                    wysiwygValues.mobileTemplate;
				bespokeDescriptionTemplateValues.DesktopTemplate = mobileTemplate;
				bespokeDescriptionTemplateValues.MobileTemplate = mobileTemplate;
			}

		} else {
			bespokeDescriptionTemplateValues.wysiwygDesktopTemplate =
                wysiwygValues.desktopTemplate;
			bespokeDescriptionTemplateValues.wysiwygMobileTemplate =
                wysiwygValues.mobileTemplate;
			bespokeDescriptionTemplateValues.DesktopTemplate = desktopTemplate;
			bespokeDescriptionTemplateValues.MobileTemplate = mobileTemplate;
		}

		if (wysiwygValues) {
			if (
				wysiwygValues.desktopTemplate.templateType === 2 ||
                wysiwygValues.mobileTemplate.templateType === 2
			) {
				let data = [
					{
						propertyName: 'CTA_TEXT',
						defaultValue: 'hello',
						description: '',
						editorType: '',
						friendlyPropertyName: '',
						fullContentAlternative: JSON.stringify(
							propertiesBespokeLiquid || []
						),
					},
				];

				await editionBespokeService.updateTemplateProperty(
					bespokeValues.bespokeId,
					data,
					(res) => {
						// createAlerte("success", "Successfully registered changes", {
						//     removeAllBeforeShow: false,
						// });
					},

					(err) => {
						createAlerte('error', 'An error occurred during the backup', {
							removeAllBeforeShow: false,
						});
						console.log(err);
					}
				);

				await editionBespokeService.updateBespokeProperty(
					bespokeValues.bespokeId,
					bespokeDescriptionTemplateValues,
					(res) => {
						createAlerte('success', 'Successfully registered SaaS Format Property changes', {
							removeAllBeforeShow: false,
						});
					},

					(err) => {
						console.error(err);
						createAlerte('error', 'An error occurred during the backup', {
							removeAllBeforeShow: false,
						});
					}
				);
			} else {
				await editionBespokeService.updateTemplateProperty(
					bespokeValues.bespokeId,
					valuesInBespokeProperties,
					(res) => {
						// createAlerte("success", "Successfully registered Template changes", {
						//     removeAllBeforeShow: false,
						// });
					},

					(err) => {
						createAlerte('error', 'An error occurred during the Template backup', {
							removeAllBeforeShow: false,
						});
						console.log(err);
					}
				);
				await editionBespokeService.updateBespokeProperty(
					bespokeValues.bespokeId,
					bespokeDescriptionTemplateValues,
					(res) => {
						createAlerte('success', 'Successfully registered SaaS Format Property changes', {
							removeAllBeforeShow: false,
						});
					},

					(err) => {
						createAlerte('error', 'An error occurred during the SaaS Format Property backup', {
							removeAllBeforeShow: false,
						});
						console.log(err);
					}
				);

			}
		}
	};
	const validateBespoke = (values) => {
		if (values?.name?.length >
            0 && values.description.length >
            0 && values.code.length > 0
            && values.format.length > 1) {
			dispatch(bespokeActions.handleChangeFormIsNotEmpty(true));
		} else {
			dispatch(bespokeActions.handleChangeFormIsNotEmpty(false));
		}
	};

	useEffect(() => {
		validateBespoke(newBespokeValues);
	}, [newBespokeValues]);

	const createBespoke = async (evt) => {
		evt.preventDefault();
		setLoading(true);
		const id = await editionBespokeService.createBespoke(
			env,
			newBespokeValues,
			(res) => {
				createAlerte('success', 'Successfully registered creation of bespoke', {
					removeAllBeforeShow: false,
				});
				setidToRedirect(res.data.id);
				return res.data.id;
			},
			(err) => {
				createAlerte('error', 'An error occurred during the backup', {
					removeAllBeforeShow: false,
				});
				console.error(err);
			});
		updateTemplateById(id);
	};
	const updateTemplateById = async (id) => {
		const bespokeDescriptionTemplateValues = {...newBespokeValues};
		bespokeDescriptionTemplateValues.wysiwygDesktopTemplate =
            wysiwygValues.desktopTemplate;
		bespokeDescriptionTemplateValues.wysiwygMobileTemplate =
            wysiwygValues.mobileTemplate;
		bespokeDescriptionTemplateValues.DesktopTemplate = desktopTemplate;
		bespokeDescriptionTemplateValues.MobileTemplate = mobileTemplate;
		let templateToSaveInBespokeProperties = [];
		if (
			wysiwygValues.desktopTemplate.templateType === 2 ||
            wysiwygValues.mobileTemplate.templateType === 2
		) {
			templateToSaveInBespokeProperties = [
				{
					propertyName: 'CTA_TEXT',
					defaultValue: 'hello',
					description: '',
					editorType: '',
					friendlyPropertyName: '',
					fullContentAlternative: JSON.stringify(
						propertiesBespokeLiquid || []
					),
				},
			];
		} else {
			templateToSaveInBespokeProperties = valuesInBespokeProperties;
		}
		await editionBespokeService.updateBespokePropertByEnv(
			env,
			id,
			bespokeDescriptionTemplateValues,
			() => {
				createAlerte('success', 'Successfully registered changes of template of bespoke', {
					removeAllBeforeShow: false,
				});
				setTimeout(() => {
					setRedirect(true);
				}, 4000);
			},
			(err) => {
				createAlerte('error', 'An error occurred during the backup', {
					removeAllBeforeShow: false,
				});
				console.error(err);
			}
		);

		await editionBespokeService.updateTemplatePropertyByEnv(
			env,
			newBespokeValues.accountId,
			id,
			templateToSaveInBespokeProperties,
			() => {
				createAlerte('success', 'Successfully registered changes properties of bespoke', {
					removeAllBeforeShow: false,
				});

				setTimeout(() => {
					setRedirect(true);
				}, 4000);
			},
			(err) => {
				createAlerte('error', 'An error occurred during the backup', {
					removeAllBeforeShow: false,
				});
				console.error(err);
			}
		);


	};

	const changePublicationState = async () => {
		if (publicationState === 1) {
			await editionBespokeService.updatePublicationState(
				bespokeId,
				'NotPublished',
				publicationState,
				() => {
					createAlerte('success', 'SaaS Format not visible on the Back Office', {
						removeAllBeforeShow: false,
					});

					setTimeout(() => {
						document.location.reload(true);
					}, 2000);
				},
				(err) => {
					createAlerte('error', 'An error occurred during the backup', {
						removeAllBeforeShow: false,
					});
					setTimeout(() => {
						document.location.reload(true);

					}, 4000);
					console.log(err);
				}
			);


		}
		if (publicationState === 0) {
			await editionBespokeService.updatePublicationState(
				bespokeId,
				'published',
				publicationState,
				() => {
					createAlerte('success', 'SaaS Format is visible on the Back Office', {
						removeAllBeforeShow: false,
					});
					setTimeout(() => {
						document.location.reload(true);
					}, 2000);
				},
				(err) => {
					console.error(...err.response.data.error);
					const erreur = [...err.response.data.error];
					createAlerte('error', `${erreur.toString()}`, {
						removeAllBeforeShow: false,
					});
					setTimeout(() => {
						document.location.reload(true);
					}, 4000);
				}
			);

		}
	};

	if (redirect && idToRedirect !== null) {
		history.push({
			pathname: `/bespokeEdition/${newBespokeValues.accountId}/${idToRedirect}/${env}`,

		});
	}

	function toggleScreenEnvironnement(e) {
		setScreenEnvironment(e
			// screenEnvironment === "desktop" ? "mobile" : "desktop"
		);
	}

	const oppenCloseModaleDelete = () => {
		setToggleModalesDelete(!toggleModalesDelete);
	};
	const clickOnDelete = () => {
		setToggleModalesDelete(!toggleModalesDelete);
	};

	function handleChangeStatusForBespoke() {
		// handleChangePublicationState();
		dispatch(bespokeActions.handleChangePublicationState());
		changePublicationState();
	}

	return (
		<>
			{loading ? (
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			) : (
				<>
					{!isNewBespoke && <BespokePanel
						env={match.params.env}
						accountId={match.params.accountId}
					/>}
					<div className="page_body flex flex_v">
						{toggleModalesDelete && !isNewBespoke && (
							<ConfirmModal
								close={oppenCloseModaleDelete}
								stopPropa={stopPropa}
								id={bespokeId}
								env={match.params.env}
								name={valuesBespokeDescription.name}
							/>
						)}

						<div className='flex_item_fix flex flex_justify_between nav'>
							{!isNewBespoke && <Nav
								env={match.params.env}
								isBespokePage={true}
							/>}
							{!isDeleteBespoke && (
								<>
									{isNewBespoke &&
                                        <Btn
                                        	color='primary'
                                        	disabledBtn={!formIsNotEpty}
                                        	onClickFunction={formIsNotEpty
                                        		? (e) =>
                                        			createBespoke(e)
                                        		: () => console.log('')}
                                        	message={'Save'}
                                        	messageToolTip="Save"
                                        	isReactIcon={true}
                                        	reactIcon={() => <FaSave/>}
                                        />
									}
									{!isNewBespoke && <>
										<Btn
											onClickFunction={isLiquid && JsonIsValid ?
												(e) => {
													e.preventDefault();
													modifyTheProperties();
												} :
												(e) => {
													e.preventDefault();
													modifyTheProperties();
												}}
											disabledBtn={isLiquid
												? JsonIsValid ? false : true : false}
											message={'Save'}
											messageToolTip="Save"
											isReactIcon={true}
											reactIcon={() => <FaSave/>}
										/></>
									}</>
							)}
						</div>

						<div className="flex_item_full flex flex_justify_start flex_align_stretch">
							<Sidebar
								seeDiff={seeDiff}
								deviceSelected={screenEnvironment}
								selectDevice={(e) => toggleScreenEnvironnement(e)}
								openUploadPicture={() => dispatch(bespokeActions.toggleUploadPicture(true))}
								isNewBespoke={isNewBespoke}
								isDeleteBespoke={isDeleteBespoke}
								isResponsiveValue={isResponsive}
								isDifferent={isDifferent}
								handleResponsive={(e) => setIsResponsive(e.target.checked)}
								env={isNewBespoke
									? env
									: match.params.env}
								accountId={isNewBespoke
									? newBespokeValues.accountId
									: match.params.accountId}
								isOpen={isOpen}
								setisOpen={setisOpen}
								isBespokePage={true}
								openUrlCloseModale={openUrlCloseModale}
								envColor={envColor}
								creationDate={creationDate}
								modificationDate={modificationDate}
								accountName={match.params.name}
								valuesBespokeDescription={valuesBespokeDescription}
								handleChangeStatusForBespoke={() => handleChangeStatusForBespoke()}
								toggleConfiguration={() => {
									dispatch(bespokeActions.toggleConfiguration(true));
								}}
								oppenCloseModaleDelete={oppenCloseModaleDelete}
								isLiquid={isLiquid}
								onClickDiff={() => setSeeDiff(!seeDiff)}
							/>

							<BespokeEditionIdeContainer
								screenEnvironment={screenEnvironment}
								desktopTemplate={desktopTemplate}
								mobileTemplate={mobileTemplate}
								bespokeActions={bespokeActions}
								match={match}
								wysiwygValues={wysiwygValues}
								loading={loading}
								isOpen={isOpen}
								seeDiff={seeDiff}
								valuesEditor={valuesEditor}
							/>

						</div>
						{seeConfiguration && <BespokePropertyContainer screenEnvironment={screenEnvironment}/>}
						{seeUploadPicture && (
							<ImagesAccountContainer
								accountId={
									isNewBespoke
										? newBespokeValues.accountId
										: match.params.accountId
								}
								env={isNewBespoke ? env : match.params.env}
								// accountName={}
							/>
						)}
						{toggleUrlModales && (
							<PublicationContainer
								close={openUrlCloseModale}
								stopPropa={stopPropa}
								env={isNewBespoke ? env : match.params.env}
								accountId={
									isNewBespoke
										? newBespokeValues.accountId
										: match.params.accountId
								}
							/>
						)}
					</div>
				</>
			)
			}
		</>
	);
}
