import { createAsyncThunk } from '@reduxjs/toolkit';

const BespokeThunk = {
	getBespokesList: createAsyncThunk(
		'bespoke/getAccountList',
		async (authAxios) => {
			const response = await authAxios.get('bespoke');
			return response.data;
		}
	),
	getBespokesListWithInactive: createAsyncThunk(
		'bespoke/getAccountList',
		async (authAxios) => {
			const response = await authAxios.get(
				'bespoke?includeInactive=true'
			);
			return response.data;
		}
	),
	getBespokesStatusByEnv: createAsyncThunk(
		'bespoke/getAccountList',
		async ({ authAxios, env }) => {
			const response = await authAxios.get(`status/${env}/bespoke/`);
			return response.data;
		}
	),
	getAccountList: createAsyncThunk(
		'bespoke/getAccountList',
		async (authAxios) => {
			const response = await authAxios.get('account');
			return response.data;
		}
	),
	getBespokePropertyById: createAsyncThunk(
		'bespoke/getBespokePropertyById',
		async ({
			authAxios,
			copyEnv,
			idAccountOfBespokeToCopy,
			bespokeToCopy,
		}) => {
			const response = await authAxios.get(
				`templateproperties/account/${copyEnv}/${idAccountOfBespokeToCopy}/template/${bespokeToCopy}`
			);
			return response.data;
		}
	),
	//creation

	getAccountPropertiesCopy: createAsyncThunk(
		'bespoke/creation/getAccountPropertiesCopy',
		async ({ authAxios, env, accountId }) => {
			let result = await authAxios.get(
				`templateproperties/account/${env}/${accountId}`
			);
			return result.data;
		}
	),
	updateBespokeByEnv: createAsyncThunk(
		'bespoke/creation/updateBespokeByEnv',
		async ({ authAxios, env, newDataBespokeCopy }) => {

			const result = await authAxios.put(
				`bespoke/${env}`,
				newDataBespokeCopy
			);
			return result.data;
		}
	),
};

export { BespokeThunk };
