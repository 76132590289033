import React from 'react';
import Modal from '../../Ui/Modal';
import Btn from '../../Ui/Btn';

export default function ModalErrors({
    modalErrorsIsOppen,
    numberOdErrors,
    setModalErrorsIsOppen,
    setModalIsOppen,
    listErrors,
    hasBloquantError,
    isDifferent,
    idOfCampaignSelected,
    registerTheChanges
}) {
    return <>
        <Modal
            isOpen={modalErrorsIsOppen}
            width="800"
            minHeight="250"
            onClose={() => { setModalErrorsIsOppen(false) }}
        >
            <div className="modal_header">
                <h1 style={{ fontWeight: 'bold' }}> La campagne contient {numberOdErrors && numberOdErrors > 1 ? numberOdErrors + " erreurs" : numberOdErrors + " erreur"}</h1>
            </div>
            <div className="container_errors modal_body flex flex_v flex_item_auto flex_justify_evenly">
                {listErrors && listErrors.map((err, i) => <div className="flex" key={i}>
                    {err.isWarning ? <i className="fas fa-exclamation-triangle orange_warning"></i> : <i className="fas fa-exclamation-triangle red_warning"></i>}  La {err.identifier === "" ? "slide original" : "slide"}  {err.identifier} {err.device}  {err.message}
                </div>)}
            </div>
            <div className="modal_footer modal_footer_center">
                {!hasBloquantError ?
                    <>
                        <Btn
                            onClickFunction={() => setModalErrorsIsOppen(false)}
                            message="Retour"
                        />
                        <Btn
                            onClickFunction={
                                !isDifferent ? (e) => { setModalErrorsIsOppen(false); setModalIsOppen(true) } : (e) => { setModalErrorsIsOppen(false); registerTheChanges(idOfCampaignSelected); setModalIsOppen(true) }}
                            icon="fas fa-save"
                            message="Sauvegarder"
                            color="primary"
                        />
                    </>
                    :
                    <Btn
                        onClickFunction={() => setModalErrorsIsOppen(false)}
                        message="Retour"
                    />
                }
            </div>
        </Modal>
    </>
}
