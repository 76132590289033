/* eslint-disable no-prototype-builtins */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';
import './SimplifyZonesContainer.css';


import { typesOfZones } from './simplifyZonesUtils';
import PanelZoneEditionContainer from './PanelZoneEditionContainer';
import Btn from '../../Ui/Btn';
import NewDropdown from '../../Ui/NewDropdown';
import CurrentZoneContainer from './CurrentZoneContainer.tsx';
export default function SimplifyZonesContainer() {
	const {
		screenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		setArrayofZones,
		arrayOfZones,
		panelZoneIsOpen,
		setpanelZoneIsOpen,
		setrefreshScreenShotZoning,
		refreshScreenShotZoning,
		zoneSelected,
		setZoneSelected
	} = useContext(creationSetupSimplify);
	// const [zoneToPut, setZoneToPut] = useState();
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [toggleLanguage, settoggleLanguage] = useState('javascript');
	const [btn, setBtn] = useState(<></>);
	const refreshArrayOfZones = () => {
		let zones = screenShotToChange.find(x => x.device === deviceSelected);
		if (zones && zones.zones.length > 0) {
			setArrayofZones([...zones.zones]);
		} else {
			setZoneSelected();
			setArrayofZones([]);
		}
	};
	useEffect(() => {
		if (screenShotId && deviceSelected && screenShotToChange) {
			refreshArrayOfZones();
		} else {
			setArrayofZones([]);
		}
	}, [screenShotToChange, screenShotId, deviceSelected]);
	useEffect(()=>{
		let zones = screenShotToChange.find(x => x.device === deviceSelected);
		setZoneSelected(zones.zones[0]);
	},[screenShotId,deviceSelected]);

	// const selectZoneToPut = (e) => {
	// 	let zoneToToggle = arrayOfZones.filter(x => x.zoneId === e);
	// 	zoneToToggle[0].toggle = true;
	// 	let newArrayOfZones = arrayOfZones.filter(x => x.zoneId !== e);
	// 	setArrayofZones([...newArrayOfZones, ...zoneToToggle]);
	// };
	// const unSelectZoneToPut = (e) => {
	// 	let zoneToToggle = arrayOfZones.filter(x => x.zoneId === e);
	// 	delete zoneToToggle[0].toggle;
	// 	let newArrayOfZones = arrayOfZones.filter(x => x.zoneId !== e);
	// 	setArrayofZones([...newArrayOfZones, ...zoneToToggle]);
	// };
	const handleChangeZoneExtensions = (e, name) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === name)[0];
		dataToChange.zoneExtensions.Coordinates[e.target.name] = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== name);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	const handleChange = (e, name) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === name)[0];
		dataToChange[e.target.name] = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== name);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	// function getKeyEditor(key) {
	// 	setZoneToPut(key);
	// }
	const handleChangeEditor = (value, event, name, id) => {
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === id)[0];
		dataToChange[name] = event;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== id);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};



	const deleteAZone = () => {
		const dataWithoutOldZone = [...arrayOfZones].filter(x => x.zoneId !== zoneSelected.zoneId);
		let dataNews = screenShotToChange.find(x => x.screenshotId === screenShotId);
		dataNews.zones = [...dataWithoutOldZone];
		dataNews.isChange = true;
		let dataToPutNew = [...screenShotToChange].filter(x => x.screenshotId !== screenShotId);
		setscreenShotToChange([dataToPutNew, dataNews]);
		setZoneSelected(dataNews.zones[0]);
	};

	// function sortArray(a, b) {
	// 	if (a.zoneId < b.zoneId) return -1;
	// 	if (a.zoneId > b.zoneId) return 1;
	// 	return 0;
	// }
	const valueGetter = useRef();
	function handleEditorDidMount(event) {
		valueGetter.current = event;
	}
	const handleFilter = (e, idZone) => {
		const newBannerType = e.target.value;
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === idZone)[0];
		dataToChange.bannerType = newBannerType;
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		if (newBannerType == 1) {
			if (!dataToChange.zoneExtensions.hasOwnProperty('carrouselSettings')) {
				dataToChange.zoneExtensions.carrouselSettings = {
					infinite: true,
					slidesToShow: 1,
					autoplaySpeed: 3000,
				};
			}
		}
		if (newBannerType == 2) {
			if (!dataToChange.zoneExtensions.hasOwnProperty('sliderSettings')) {
				dataToChange.zoneExtensions.sliderSettings = {
					infinite: true,
					slidesToShow: 1,
					autoplaySpeed: 3000,
				};
			}
		}
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== idZone);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	const handleChangeInfinite = (e, idZone) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === idZone)[0];
		dataToChange.zoneExtensions.carrouselSettings.infinite = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== idZone);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	const handleChangeOptionsOfCarrousel = (e, idZone) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === idZone)[0];
		dataToChange.zoneExtensions.carrouselSettings[e.target.name] = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== idZone);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	const handleChangeInfiniteOfSlider = (e, idZone) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === idZone)[0];
		dataToChange.zoneExtensions.sliderSettings.infinite = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== idZone);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	const handleChangeOptionsOfSlider = (e, idZone) => {
		setrefreshScreenShotZoning(!refreshScreenShotZoning);
		let dataToPutToMaj = [...arrayOfZones];
		let dataToChange = dataToPutToMaj.filter(x => x.zoneId === idZone)[0];
		dataToChange.zoneExtensions.sliderSettings[e.target.name] = e.target.value;
		let dataWhithoutItem = dataToPutToMaj.filter(x => x.zoneId !== idZone);
		setArrayofZones([dataToChange, ...dataWhithoutItem]);
	};
	// TODO : refacto with new components split

	function handleSelectAZone(idZone){
		setZoneSelected(arrayOfZones.find(z => z.zoneId === idZone));
		setDropdownIsOpen(false);
	}
	useEffect(()=>{
		const btnNew = <a className="userAuthBtn flex">
			<div className="userAuthName">{zoneSelected && zoneSelected.name !== '' ? zoneSelected.name : zoneSelected ? zoneSelected.zoneId : '' }</div> <i className='fas fa-arrow-down'></i></a>;
		setBtn(btnNew);
	},[zoneSelected,panelZoneIsOpen,arrayOfZones]);
	return (
		<>
			<PanelZoneEditionContainer 
				setpanelZoneIsOpen={setpanelZoneIsOpen}
				panelZoneIsOpen={panelZoneIsOpen}
				zoneSelected={zoneSelected}
			>
				{ zoneSelected &&
			 <div >
			 	<form>
			 		<div style={{ display: 'flex' }}>
			 			<div className="input-goupe-zone">
			 				<label htmlFor="bannerType">Type de zone</label>
			 				<select style={{ maxWidth: '120px', height: '42px' }}
			 					className="form-control rounded-right"
			 					id="bannerType"
			 					onChange={(e) => handleFilter(e, zoneSelected.zoneId)}
			 					value={zoneSelected.bannerType}>
			 					{typesOfZones.map(x => <option key={x.label} value={x.key}> {x.label}</option>)}
			 				</select>
			 			</div>
			 			<div className="input-goupe-zone">
			 				<label htmlFor="name">Nom</label>
			 				<input className="input-text-name-zone" id="name" name="name" type="text" value={zoneSelected.name ? zoneSelected.name : ''}
			 					onChange={(e) => handleChange(e, zoneSelected.zoneId)}
			 				/>
			 			</div>
			 		</div>

			 		<h2 className="titleSection">Zone dimensions</h2>
			 		<div className="input-groupe-size-zone">
			 			<div className="input-groupe-container-size-zone">
			 				<label htmlFor="width">Width</label>
			 				<input id="width" name="width" type="number" className="input-groupe-size-zone-number"
			 					value={zoneSelected.zoneExtensions.Coordinates.width ? zoneSelected.zoneExtensions.Coordinates.width : ''}
			 					onChange={(e) => handleChangeZoneExtensions(e, zoneSelected.zoneId)}
			 				/>
			 			</div>
			 			<div className="input-groupe-container-size-zone">
			 				<label htmlFor="height">Height</label>
			 				<input id="height" name="height" type="number" className="input-groupe-size-zone-number"
			 					value={zoneSelected.zoneExtensions.Coordinates.height ? zoneSelected.zoneExtensions.Coordinates.height : ''}
			 					onChange={(e) => handleChangeZoneExtensions(e, zoneSelected.zoneId)}
			 				/>
			 			</div>
			 			<div className="input-groupe-container-size-zone">
			 				<label htmlFor="left">Left</label>
			 				<input id="left" name="left" type="number" className="input-groupe-size-zone-number"
			 					value={zoneSelected.zoneExtensions.Coordinates.left ? zoneSelected.zoneExtensions.Coordinates.left : ''}
			 					onChange={(e) => handleChangeZoneExtensions(e, zoneSelected.zoneId)} />
			 			</div>
			 			<div className="input-groupe-container-size-zone">
			 				<label htmlFor="top">Top</label>
			 				<input id="top" name="top" type="number" className="input-groupe-size-zone-number"
			 					value={zoneSelected.zoneExtensions.Coordinates.top ? zoneSelected.zoneExtensions.Coordinates.top : ''}
			 					onChange={(e) => handleChangeZoneExtensions(e, zoneSelected.zoneId)} />
			 			</div>
			 		</div>
			 		{zoneSelected.bannerType == 1 &&
                                    <div className="groupSlider">
                                    	<h2 className="titleSection" style={{ textAlign: 'left', margin: '10px 0px' }}>Carrousel options</h2>
                                    	<div className="sliderOptions">
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="infinite">Infinite</label>
                                    			<select style={{ maxWidth: '120px', height: '42px' }}
                                    				className="form-control  rounded-right"
                                    				id="bannerType"
                                    				onChange={(e) => handleChangeInfinite(e, zoneSelected.zoneId)}
                                    				value={zoneSelected.zoneExtensions.carrouselSettings.infinite}>
                                    				<option key={1} value={true}>Oui</option>
                                    				<option key={2} value={false}>Non</option>
                                    			</select>
                                    		</div>

                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="slidesToShow">Slides to show</label>
                                    			<input id="slidesToShow" name="slidesToShow" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.carrouselSettings.slidesToShow ? zoneSelected.zoneExtensions.carrouselSettings.slidesToShow : ''}
                                    				onChange={(e) => handleChangeOptionsOfCarrousel(e, zoneSelected.zoneId)} />
                                    		</div>
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="autoplaySpeed">Speed</label>
                                    			<input id="autoplaySpeed" name="autoplaySpeed" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.carrouselSettings.autoplaySpeed ? zoneSelected.zoneExtensions.carrouselSettings.autoplaySpeed : ''}
                                    				onChange={(e) => handleChangeOptionsOfCarrousel(e, zoneSelected.zoneId)} />
                                    		</div>
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="slideWidth">Slide Width</label>
                                    			<input id="slideWidth" name="slideWidth" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.carrouselSettings.slideWidth ? zoneSelected.zoneExtensions.carrouselSettings.slideWidth : ''}
                                    				onChange={(e) => handleChangeOptionsOfCarrousel(e, zoneSelected.zoneId)} />
                                    		</div>
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="slideHeight">Slide Height</label>
                                    			<input id="slideHeight" name="slideHeight" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.carrouselSettings.slideHeight ? zoneSelected.zoneExtensions.carrouselSettings.slideHeight : ''}
                                    				onChange={(e) => handleChangeOptionsOfCarrousel(e, zoneSelected.zoneId)} />
                                    		</div>
                                    	</div>
                                    </div>
			 		}
			 		{zoneSelected.bannerType == 2 &&
                                    <div className="groupSlider">
                                    	<h2 className="titleSection" style={{ textAlign: 'left', margin: '10px 0px' }}>Slider options</h2>
                                    	<div className="sliderOptions">
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="infinite">Infinite</label>
                                    			<select style={{ maxWidth: '120px', height: '42px' }}
                                    				className="form-control  rounded-right"
                                    				id="bannerType"
                                    				onChange={(e) => handleChangeInfiniteOfSlider(e, zoneSelected.zoneId)}
                                    				value={zoneSelected.zoneExtensions.sliderSettings.infinite}>
                                    				<option key={1} value={true}>Oui</option>
                                    				<option key={2} value={false}>Non</option>
                                    			</select>
                                    		</div>

                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="slidesToShow">Slides to show</label>
                                    			<input id="slidesToShow" name="slidesToShow" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.sliderSettings.slidesToShow ? zoneSelected.zoneExtensions.sliderSettings.slidesToShow : ''}
                                    				onChange={(e) => handleChangeOptionsOfSlider(e, zoneSelected.zoneId)} />
                                    		</div>
                                    		<div className="input-groupe-container-size-zone">
                                    			<label htmlFor="autoplaySpeed">Speed</label>
                                    			<input id="autoplaySpeed" name="autoplaySpeed" type="number" className="input-groupe-size-zone-number"
                                    				value={zoneSelected.zoneExtensions.sliderSettings.autoplaySpeed ? zoneSelected.zoneExtensions.sliderSettings.autoplaySpeed : ''}
                                    				onChange={(e) => handleChangeOptionsOfSlider(e, zoneSelected.zoneId)} />
                                    		</div>
                                    	</div>
                                    </div>
			 		}
			 		<div>

			 		</div>
			 	</form>				
			 </div>}				
			</PanelZoneEditionContainer>
			<div className="container-zone-edition">


				{arrayOfZones && arrayOfZones.length > 0 && <Btn
					message="Edit zone"
					onClickFunction={() => setpanelZoneIsOpen(true)}
					icon='fas fa-pen'
				/>}
				{arrayOfZones && arrayOfZones.length > 0 && <Btn
					message=""
					onClickFunction={() => deleteAZone()}
					icon='fas fa-trash'
				/>}
				<NewDropdown
					isOpen={dropdownIsOpen}
					setIsOpen={() => setDropdownIsOpen(!dropdownIsOpen)}
					onHide={() => setDropdownIsOpen(!dropdownIsOpen)}
					button={btn}
				>
					<ul className="listbox">
						{arrayOfZones && arrayOfZones.map(z => <li key={z.zoneId}
							className="listbox_item"
							onClick={()=>handleSelectAZone(z.zoneId)}
						>
							{z.name !== '' ? z.name : z.zoneId}
						</li>)}
					</ul>
				</NewDropdown>
				<div>
					{arrayOfZones &&
						<CurrentZoneContainer
							toggleLanguage={toggleLanguage}
							settoggleLanguage={settoggleLanguage}
							zone={zoneSelected}
							handleChangeEditor={handleChangeEditor}
							handleEditorDidMount={handleEditorDidMount}
						/>
					}

				</div>

			</div>
		</>
	);
}
