import React, { useState, useEffect, Fragment } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'
import filterList from './filterList'
import AccountItem from './AccountItem';
import { useDispatch, useSelector } from 'react-redux';
import { actionUI } from '../../store/ui-reducer';
import Information from '../Ui/Information';
import LoginButton from '../Authentication/LoginButton';
import { Spinner } from 'ui-beyable';


export default function HomeContainer() {
    const { isAuthenticated, isLoading } = useAuth0();
    const isAuth = useSelector(state => state.ui.isAuthenticated);
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);

    const { authAxios } = useAuthAxios();
    const [DataAccounts, setDataAccounts] = useState();
    const [DataFiltered, setDataFiltered] = useState([]);
    const [DataFilteredByEnv, setDataFilteredByEnv] = useState();
    // const [searchBarValue, setSearchBarValue] = useState('');
    const [noData, setNoData] = useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useDispatch()
    const selectedOption = useSelector(state => state.ui.env)
    const searchBarValue = useSelector(state => state.ui.inputSearch)
  
    const [listOfScoresBY, setlistOfScoresBY] = useState([]);

    const handleInputSerach = (value) => {
        dispatch(actionUI.handleChangeInputSearch(value))
    }
    const toggleEnvSelector = (value) => {
        dispatch(actionUI.handleChangeEnv(value))
    }
    const fetchListScoresFromApi = async () => {
        const params = {
            fileName: 'ListScroring.json', // Remplacez par le nom de fichier souhaité

        };
        function makeAPICallUrls(endpoint) {
            const ApiUrlScoring = `account-setup/${endpoint}/00000000-0000-0000-0000-000000000000`;
           return ApiUrlScoring;
        }
        const arrayOfEnv = ['Dev', 'Prod', 'PreProd' ];
        const apiCalls =  arrayOfEnv.map((x, i) => makeAPICallUrls(arrayOfEnv[i]));
        async function makeMultipleAPICalls(endpoints) {
            const promises = endpoints.map(apiCallIdx => authAxios
                .get(apiCallIdx,{params}).then(res => res.data))
            const responses = await Promise.all(promises);
            return responses.flat(1);
        }
        const responsesApiCall = await makeMultipleAPICalls(apiCalls)

        setlistOfScoresBY(responsesApiCall);
    }
    useEffect(() => {
        function orderByASC(a, b) {
            const nameA = (a.account.name || "").toUpperCase();
            const nameB = (b.account.name || "").toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        }
        fetchListScoresFromApi();
        const fetchData = async () => {
            authAxios.get("account")
                .then(res => {
                    const resultSort = res.data.sort(orderByASC)
                    setDataAccounts(resultSort);
                    setIsButtonDisabled(false);
                })
        }

        if (isAuthenticated && !DataAccounts) {
            const timer = setTimeout(() => {
                fetchData()
            }, 700)
            return () => {
                clearTimeout(timer);
            }
        }
    }, [isAuthenticated]);

    const handleChange = (e) => {
        // setSearchBarValue(e.target.value);
        handleInputSerach(e.target.value);
        search(DataFilteredByEnv, e.target.value);
    }

    // fonction de recherche dans le tableau
    const search = (table, word) => {
        if (word.length > 0) {
            let resultName = table.filter(
                line =>
                    line.name !== null && line.name.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
            );

            setDataFiltered(resultName)
            if (resultName.length === 0) {
                let result = "No account matches this search"
                setNoData(result)
            }

            setNoData()

        } else {
            setDataFiltered()
        }
    };

    const onFilterChange = async () => {
        if (isAuthenticated) {
            const simplifyInfoByEnvReq = await authAxios.get(`websitesetup/${selectedOption}/accounts/`);
            let filteredRowsByEnv = DataAccounts && DataAccounts.filter((row) => row.env === selectedOption);
            const simplifyInfoByEnv = simplifyInfoByEnvReq && simplifyInfoByEnvReq.data;
            const dataWithSimplifyInfo = simplifyInfoByEnv && filteredRowsByEnv && filteredRowsByEnv.map(x =>
            ({
                id: x.account.id,
                isActive: x.account.isActive,
                name: x.account.name,
                env: selectedOption,
                score: listOfScoresBY.find(e => e.id === x.account.id),
                statusSimplify: simplifyInfoByEnv.filter(elem => elem.accountId === x.account.id)
            })
            );
            dataWithSimplifyInfo && setDataFilteredByEnv(dataWithSimplifyInfo);
            dataWithSimplifyInfo && search(dataWithSimplifyInfo, searchBarValue)
        }
    }
    useEffect(() => {
        if (DataAccounts) {
            // setSearchBarValue('');
            setDataFiltered();
            onFilterChange();
        }
    }, [selectedOption, DataAccounts]);

    let results = null;

    if (DataFilteredByEnv) {
        results =
            DataFilteredByEnv && DataFilteredByEnv.map((x, id) =>
                <AccountItem
                    dataAccount={x}
                    key={id} />
            )
    }
    if (DataFiltered) {
        results =
            DataFiltered && DataFiltered.map((x, id) =>
                <AccountItem
                    dataAccount={x}
                    key={id} />)

    }
    return (
        <div>
            {
                isLoading && 
                <Spinner/>
            }
            {isAuthenticated &&
                <div className='headerBar'>
                    <div className="input-group search_bar">
                        <input
                            className="form-control"
                            onChange={handleChange}
                            value={searchBarValue}
                            type="text"
                            placeholder="Find an account"
                            aria-label="Large"
                            aria-describedby="basic-addon2"
                            disabled={isButtonDisabled}
                        />
                        {isBeyableAccount &&

                            <select
                                className="form-controlmb-2 border rounded-right selectionCustom"
                                id="inputFilteredByEnv"
                                onChange={(e) => toggleEnvSelector(e.target.value)}
                                value={selectedOption}>
                                {filterList.map(x => <option key={x.label} value={x.value}> {x.label}</option>)}
                            </select>

                        }
                    </div>
                </div>
            }
            {isAuthenticated && !DataAccounts ?
                <Spinner/>
                :
                <>
                    <h3 className="title-h3 title-list-account"></h3>
                    {results !== null &&
                        noData ?
                        <div className="noData">
                            {noData}
                        </div> :
                        <section className="list-account">
                            {results}
                            {isAuthenticated && DataAccounts && DataAccounts.length === 0 &&
                                <div className='flex flex_justify_center'>
                                    <Information
                                        title='Informations'
                                        text='No account is available. Create an account from the BEYABLE Back Office or contact your organization administrator to grant you access.'
                                        color='success'
                                        loginBtn={false}
                                    />
                                </div>
                            }
                        </section>
                    }
                </>
            }
            {!isAuthenticated && !isLoading &&
                <div className="ob_page_center vscroll flex flex_v flex_justify_center flex_align_center">
                    <div className="ob_page_center_inner flex_fix">
                        <div className="ob_logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="99" height="88" viewBox="0 0 595 533"><defs><clipPath id="clip-Logo-beyable-vecto"><rect width="595" height="533"></rect></clipPath></defs><g id="Logo-beyable-vecto" clipPath="url(#clip-Logo-beyable-vecto)"><g id="Groupe_5" data-name="Groupe 5" transform="translate(-35 -71)"><g id="Groupe_4" data-name="Groupe 4" transform="translate(23.126 89.29)"><path id="Soustraction_1" data-name="Soustraction 1" d="M314.442,317.358H256.555a58.136,58.136,0,0,1-50.392-29.143l-53.755-93.433a58.136,58.136,0,0,1,0-57.987l53.755-93.433a57.987,57.987,0,0,1,12.6-15.19L314.454,198.32v119.03Z" transform="translate(0 10.914)" fill="#006fff"></path><path id="Soustraction_1-2" data-name="Soustraction 1" d="M243.927,144.72h-.868L178.3,24.71h78.687a58.135,58.135,0,0,1,40.554,16.482L243.931,144.715Z" transform="translate(106.057 0)" fill="#00D9FF"></path><path id="Soustraction_1-3" data-name="Soustraction 1" d="M201.249,267.266h0V150.475L261.549,43.5l26.4,45.882a58.137,58.137,0,0,1,0,57.987L234.187,240.8a57.981,57.981,0,0,1-32.934,26.464Z" transform="translate(178.393 59.237)" fill="#0079B7"></path></g><text id="BEYABLE" transform="translate(77 553)" fill="#006fff" fontSize="118" fontFamily="IBMPlexSans-Bold, IBM Plex Sans" fontWeight="700"><tspan x="0" y="0">BEYABLE</tspan></text></g></g></svg>
                        </div>
                        <div className="ob_title_1">Welcome to BEYABLE Studio!</div>
                        <div className="ob_title_3">Please login or contact you organization administrator for signup.</div>
                        <div className="ob_btn_wrapper">
                            <LoginButton size="xl" />
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}
