import React, { useEffect, useState } from 'react';
import Btn from '../../Components/Ui/Btn';
import Dropdown from '../../Components/Ui/Dropdown';
import styles from './Nav.module.css';
export default function PaginationBoard({ totalCount, pageSize, handleChangePageSize, paginate, skipIdNumber, currentPage, setCurrentPage }) {
	const [dropdownIsOpen, setDropDownIsOpen] = useState(false);
	const pageSizeList = [1, 5, 10, 20, 30, 40];
	const [paginateToDisplay, setPaginateToDisplay] = useState();
	const [pageNumbers, setPageNumbers] = useState([]);
	const totalPages = Math.floor(totalCount / pageSize) + (totalCount % pageSize == 0 ? 0 : 1);

	let lastidx = '';
	let paginationSize;
	let pageNumbersArray = [];
	let idx = 0;


	useEffect(() => {
		for (let i = 1; i <= Math.ceil(totalPages); i++) {
			pageNumbersArray.push({ nb: i, idx: idx });
			idx += pageSize;
		}
		setPageNumbers(pageNumbersArray);
		paginationSize = pageNumbersArray.length;
		if ((skipIdNumber, pageNumbersArray)) {
			const lastidxCalc =
                pageNumbersArray && pageNumbersArray[pageNumbersArray.length - 1];
			// eslint-disable-next-line no-unused-expressions
			lastidxCalc ? (lastidx = lastidxCalc.idx) : null;
		}
		if (paginationSize <= 5) {
			setPaginateToDisplay(pageNumbersArray);
			return;
		}
		if (currentPage < 3) {
			setPaginateToDisplay(pageNumbersArray.slice(0, 5));
			return;
		}
		if (currentPage === pageNumbersArray.length + 1) {
			setPaginateToDisplay(
				pageNumbersArray.slice(paginationSize - 5, paginationSize)
			);
			return;
		}
		if (
			currentPage < pageNumbersArray.length + 1 &&
            currentPage > pageNumbersArray.length - 3
		) {
			setPaginateToDisplay(
				pageNumbersArray.slice(paginationSize - 5, paginationSize)
			);
			return;
		} else if (currentPage > 2 && currentPage < pageNumbersArray.length - 2) {
			let test = pageNumbersArray.slice(currentPage - 3, currentPage + 2);
			if (test) {
				setPaginateToDisplay(test);
			} else {
				setPaginateToDisplay(
					pageNumbersArray.slice(currentPage - 3, paginationSize - 1)
				);
			}
			return;
		}
	}, [currentPage, pageSize]);
	if (totalCount?.length <= 1) {
		return <></>;
	}
	return (
		<nav className="flex mt_20 mb_5">
			<div className="flex_item_half"></div>
			<div className="flex_item_fix">
				<div className="btn_group">
					{currentPage > 1 ? (
						<Btn
							icon="fas fa-chevron-left"
							onClickFunction={() => {
								paginate(skipIdNumber - pageSize);
								setCurrentPage(currentPage - 1);
							}}
							style="ghost"
							color="secondary"
							size="s"
							className="btn_w_xs"
						/>
					) : (
						<Btn
							icon="fas fa-chevron-left"
							style="ghost"
							color="secondary"
							disabled={true}
							size="s"
							className="btn_w_xs"
							onClickFunction={() => { }}
						/>
					)}

					<div className="btn_switch">
						{pageNumbers &&
                            pageNumbers.length > 3 &&
                            currentPage > 5 && (
							<>
								<Btn
									onClickFunction={() => {
										setCurrentPage(1);
										paginate(0);
									}}
									style="outline"
									color="secondary"
									size="s"
									message={'1'}
								/>
								<Btn
									style="outline"
									color="secondary"
									size="s"
									message={'...'}
									onClickFunction={() => { }}
								/>
							</>
						)}
						{paginateToDisplay &&
                            paginateToDisplay.length > 0 &&
                            paginateToDisplay.map((number) => (
                            	<Btn
                            		key={number.nb}
                            		onClickFunction={() => {
                            			paginate(number.idx);
                            			setCurrentPage(number.nb);
                            		}}
                            		style="outline"
                            		color="secondary"
                            		size="s"
                            		message={number.nb}
                            		className={
                            			skipIdNumber === number.idx
                            				? 'active'
                            				: ''
                            		}
                            	/>
                            ))}

						{pageNumbers &&
                            pageNumbers.length > 4 &&
                            currentPage < pageNumbers.length - 2 && (
							<>
								<Btn
									style="outline"
									color="secondary"
									size="s"
									message={'...'}
									onClickFunction={() => { }}
								/>
								<Btn
									onClickFunction={() => {
										setCurrentPage(pageNumbers.length);
										paginate(currentPage * pageSize);
									}}
									style="outline"
									color="secondary"
									size="s"
									message={pageNumbers.length}
								/>

							</>
						)}
					</div>
					{currentPage < pageNumbers.length ? (
						<Btn
							icon="fas fa-chevron-right"
							onClickFunction={() => {
								paginate(skipIdNumber + pageSize);
								setCurrentPage(currentPage + 1);
							}}
							style="ghost"
							color="secondary"
							size="s"
							className="btn_w_xs"
						/>
					) : (
						<Btn
							icon="fas fa-chevron-right"
							style="ghost"
							color="secondary"
							disabled={true}
							size="s"
							className="btn_w_xs"
							onClickFunction={() => { }}
						/>
					)}
				</div>
			</div>
			<div className="flex_item_half al_right">
				<Dropdown
					isOpen={dropdownIsOpen}
					setIsOpen={() => setDropDownIsOpen(true)}
					onHide={() => setDropDownIsOpen(false)}
					button={
						<Btn
							message={pageSize}
							style="outline"
							color="secondary"
							size="s"
							className="btn_w_xxs"
							arrow={true}
							onClickFunction={() => { }}
						/>
					}
				>
					<ul className="listbox">
						{pageSizeList.map((value) => {
							const isSelected = (pageSize && pageSize) === value;
							return (
								<li key={value}>
									<a
										className={
											isSelected
												? 'listbox_item selected'
												: 'listbox_item'
										}
										onClick={() => {
											handleChangePageSize(value);
											setCurrentPage(1);
											paginate(0);
											setDropDownIsOpen(false);
										}}
									>
										{value}
										{isSelected && (
											<span
												className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</Dropdown>
			</div>
		</nav>
	);
}
