import React from 'react';
import './btn.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default React.memo(function Btn({
	children,
	onClickFunction = null,
	message = '',
	color = 'primary',
	style = '',
	disabled = false,
	size = '',
	icon,
	iconPosition = 'before',
	disabledBtn,
	isReactIcon = false,
	reactIcon,
	messageToolTip,
	className = '',
	href = '',
	arrow = false,
	strong = false,
	light = false,
	isActive = false,
	fontSize = '',
	fullWidth = false,
	fullHeight = false,
	rounded = false,
}) {
	const idTool = uuidv4();

	let cssClass = ['btnUi'];

	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Style and Color
	let styleClass = 'btn';
	if (style === 'outline' || style === 'ghost') {
		styleClass += '_' + style;
	}
	styleClass += '_' + color;
	cssClass.push(styleClass);

	// Rounded
	if (rounded) {
		cssClass.push('btn_rounded');
	}

	// Disabled
	if (disabledBtn) {
		cssClass.push('btn_disabled');
	}

	// Strong
	if (strong) {
		cssClass.push('btn_strong');
	}

	// Light
	if (light) {
		cssClass.push('btn_light');
	}

	// Arrow
	if (arrow) {
		cssClass.push('has_arrow');
	}

	// Size : xs, s, default, l, xl, xxl
	if (size) {
		cssClass.push('btn_' + size);
	}

	// Font size : s
	if (fontSize) {
		cssClass.push('btn_fs_' + fontSize);
	}

	// Icon
	if (icon) {
		cssClass.push('has_icon');
	}

	// Full width
	if (fullWidth) {
		cssClass.push('w_full');
	}

	// Full height
	if (fullHeight) {
		cssClass.push('h_full');
	}

	// Active
	if (isActive) {
		cssClass.push('active');
	}

	// Type
	let htmlTag = 'button';
	if (href) {
		htmlTag = 'a';
	}


	return (<>
		<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
		<button
			data-for={idTool} data-tip={messageToolTip}
			className={cssClass.join(' ')}
			onClick={(e) => onClickFunction(e)}>
			{isReactIcon && iconPosition !== 'after' &&
                reactIcon()
			}
			{icon && !isReactIcon && iconPosition !== 'after' &&
                <i className={`${icon}`}></i>
			}
			{message &&
                <span className="btn_txt">{message}</span>
			}
			{children &&
                <span className="btn_txt">{children}</span>
			}
			{icon && !isReactIcon && iconPosition === 'after' &&
                <i className={`${icon}`}></i>
			}
			{isReactIcon && iconPosition === 'after' &&
                reactIcon()
			}
		</button></>
	);
});
