import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Style from './Home.module.css';
import Btn from '../../Ui/Btn';
import { Link } from 'react-router-dom';

export default function Home() {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	return (
		<div className={`${Style.container}`}>
			<div className={`${Style.content} flex flex_v flex_justify_start flex_align_center mb_50`}>
				<svg id="BEYABLE_LOGO_H" data-name="BEYABLE LOGO H" xmlns="http://www.w3.org/2000/svg" width="398" height="100" viewBox="0 10 98 1">
					<path id="Tracé_1741" data-name="Tracé 1741" d="M15.071-26.869,10.189-41.88H6.146L1.2-26.869H4.512l1.075-3.57H10.6l1.075,3.57ZM9.8-33.213H6.361l1.656-5.7h.108Zm7.355,6.344h7.957a4.039,4.039,0,0,0,3.871-4.28,3.319,3.319,0,0,0-3.376-3.527V-34.8c1.527-.065,2.8-.925,2.8-3.226a3.632,3.632,0,0,0-3.957-3.849h-7.29Zm3.269-6.194h3.763a1.267,1.267,0,0,1,1.4,1.355v.731a1.267,1.267,0,0,1-1.4,1.355H20.426Zm0-6.065h3.247a1.266,1.266,0,0,1,1.376,1.376v.645a1.266,1.266,0,0,1-1.376,1.376H20.426ZM40.49-26.869v-2.9H34.77V-41.88H31.5v15.011Zm12.624,0v-2.9H46.168V-33.02h5.957V-35.9H46.168v-3.075h6.946v-2.9H42.9v15.011Z" transform="translate(44.886 42.825)" fill="#006fff" />
					<path id="Tracé_1740" data-name="Tracé 1740" d="M4.62-26.869h7.957a4.039,4.039,0,0,0,3.871-4.28,3.319,3.319,0,0,0-3.376-3.527V-34.8c1.527-.065,2.8-.925,2.8-3.226A3.632,3.632,0,0,0,11.91-41.88H4.62Zm3.269-6.194h3.763a1.267,1.267,0,0,1,1.4,1.355v.731a1.267,1.267,0,0,1-1.4,1.355H7.889Zm0-6.065h3.247a1.266,1.266,0,0,1,1.376,1.376v.645a1.266,1.266,0,0,1-1.376,1.376H7.889Zm21.29,12.258v-2.9H22.233V-33.02H28.19V-35.9H22.233v-3.075h6.946v-2.9H18.964v15.011Z" transform="translate(-4.62 42.825)" fill="#006fff" />
					<path id="Soustraction_1" data-name="Soustraction 1" d="M154.139,44.312h-3.231a3.245,3.245,0,0,1-2.813-1.627l-3-5.215a3.245,3.245,0,0,1,0-3.236l3-5.215a3.236,3.236,0,0,1,.7-.848l5.341,9.5v6.643Z" transform="translate(-117.322 -27.37)" fill="#006fff" />
					<path id="Soustraction_1-2" data-name="Soustraction 1" d="M181.967,31.408h-.048l-3.614-6.7H182.7a3.245,3.245,0,0,1,2.263.92l-2.992,5.778Z" transform="translate(-143.167 -24.71)" fill="#00d9ff" />
					<path id="Soustraction_1-3" data-name="Soustraction 1" d="M201.249,55.989h0V49.471l3.366-5.971,1.473,2.561a3.245,3.245,0,0,1,0,3.236l-3,5.215a3.236,3.236,0,0,1-1.838,1.477Z" transform="translate(-160.794 -39.145)" fill="#0079b7" />

				</svg>
				<h1 className='title-h1 mb_20'> Welcome to BEYABLE Studio !</h1>
				{!isAuthenticated && <>
					<h5 className='title-h5 mb_20'> You must login to start working</h5>
					<div className={`${Style.cta} mt_10`}>
						<Btn onClickFunction={() => loginWithRedirect()} style="">Login</Btn>
					</div>
				</>
				}
				{isAuthenticated &&
                    <div className={`${Style.cta} mt_10`}>
                    	<Link className="edit-account"
                    		to={{ pathname: '/Home' }} >
                            Go home
                    	</Link>
                    	{/* <Btn onClickFunction={() => {
                            window.location.href = '/Home'
                        }} style="">Start working !</Btn> */}
                    </div>
				}
			</div>
		</div>
	);
}
