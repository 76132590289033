import React, { useEffect } from 'react';
import Btn from './Btn';
import classes from './NotFound.module.css';

const NotFound = () => {

	return (
		<div className={classes.notFound}>
			<section className={classes.sectionNotFound} >
				<p className={classes.p404}>404</p>
			</section>
			<h2 className={classes.titleNotFound}>Oops, this page does not exist ...</h2>

			<Btn
				message="Return to homemage"
				onClickFunction={() => {
					window.location.href = '/';
				}}
			/>

		</div>
	);
};

export default NotFound;
