import React, { useState, useEffect } from 'react';
import {
    Checkbox,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Btn,
    Spinner,
    Select,
    Textfield,
    Section,
    Article
} from 'ui-beyable';
import moment from 'moment';
import useAuthAxios from '../../utils/useAuthAxios';
import { useAuth0 } from '@auth0/auth0-react';
import JsonEditor from './JsonEditor';
import Score from './Score';


export default function CheckListSetupHook({ match }) {
    const { authAxios } = useAuthAxios();
    const { user } = useAuth0();


    const { id, env } = match.params;
    const ApiUrlDate = `account/${env}/${id}`;
    const ApiUrl = `account-setup/${env}/${id}`;
    const ApiUrlScoring = `account-setup/${env}/00000000-0000-0000-0000-000000000000`;

    const [jsonValue, setJsonValue] = useState('{}'); // ou useState('{}') pour un JSON vide

    const options = { // Options pour l'éditeur Monaco
        selectOnLineNumbers: true,
        language: 'json',
        automaticLayout: true,
    };

        const [myForm,
            setMyForm] = useState({
                visitingData: {
                    ProductID: '',
                    Name: '',
                    Price: '',
                    PriceDiscounted: '',
                    ImgUrl: '',
                    Tags: ''
                },

                categoriesData: {
                    categorieID: '',
                    Name: '',
                    Level: ''
                },
                Csm: '',
                cartCompositionIdProduct: '',
                cartCompositionBuyIdProduct: '',
                cartCompositionProductPrice: '',
                cartCompositionBuyProductPrice: '',
                modificationDate: '',
                clientURL: '',
                fluxURL: '',
                Multitiple: '',
                audienceOutils: '',
                recoProduit: '',
                ranking: '',
                ShoppingCartRetrieval: '',
                shoppingCartMethod: '',
                productID: '',
                productName: '',
                rankingMethod: '',
                microObjectifs: '',
                APICodePromo: '',
                favoriteCat: '',
                sendCampagnOutils: '',
                sendCampagnData: '',
                sendMethodCampagn: '',
                geolocalisation: '',
                pageType: '',
                tagsType: '',
                commandTesting: '',
                installationChoice: '',
                otherInstallation: '',
                subDomaineChoice: '',
                functionRules: '',
                setupReport: '',
                TenantChoice: '',
                TenantChoiceInformation: '',
                dataMethod: '',
                cartAmount: '',
                cartAmountBuy: '',
                promoCode: '',
                dataMethodCollect: '',
                audienceMethod: '',
                audienceConnexion: '',
                CMPChoice: '',
                CPMTools: '',
                historicProductsView: '',
                environnement: '',
                defaultScripts: {
                    beyableAnalytics: false,
                    beyableAnalyticsEcommerce: false
                },
                defaultCheckboxes: {
                    beyableReady: false,
                    marketingScript: false,
                    functionnalScript: false,
                    patchCampaigns: false,
                    codePatchCampaigns: false,
                    ABTests: false,
                    shopifyApp: false,
                    emailRemarketing: false,
                    productMonitoring: false,
                    simplify: false,
                    visitorEngagement: false,
                    recommandation: false,
                    conversionActive: false,
                    basketActive: false,
                }
            })
        const [listOfScoresBY, setlistOfScoresBY] = useState([]);
        const [creationDate,
            setCreationDate] = useState("");

        const [clientName,
            setClientName] = useState("");
        
        const [clientId,
            setClientId] = useState("");
        
        const [clientKey,
            setClientKey] = useState("");

        const [authorizedUrls,
            setAuthorizedUrls] = useState("");

        const [isLoading,
            setIsLoading] = useState(true);
        const [score, setScore] = useState(null);
        const [comment,
            setComment] = useState("");

        const [isPopupOpen,
            setIsPopupOpen] = useState(false);
        const handleInputChange = (e) => {
            setMyForm(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }

        const handleVisitingDataChange = (e, field) => {
            let value;
            if (typeof e === 'string') {
                value = e
            } else {
                value = e.target.value;
            }
            setMyForm((prevmyForm) => ({
                ...prevmyForm,
                visitingData: {
                    ...prevmyForm.visitingData,
                    [field]: value
                }
            }));
        }

        const handlecategoriesDataChange = (e, field) => {
            let value;
            if (typeof e === 'string') {
                value = e
            } else {
                value = e.target.value;
            }
            setMyForm((prevmyForm) => ({
                ...prevmyForm,
                categoriesData: {
                    ...prevmyForm.categoriesData,
                    [field]: value
                }
            }));
        }

        const handleCheckboxChange = (e, folder) => {
            const name = e.target.name;
            const value = e.target.checked;
            const myPrevState = {
                ...myForm
            };
            myPrevState[folder][name] = value;
            setMyForm(myPrevState);
        }

        const handleChange = (e, myFormKey) => {
            let value;
            if (typeof e === 'string') {
                value = e
            } else {
                value = e.target.value;
            }
            setMyForm(prevState => ({
                ...prevState,
                [myFormKey]: value
            }));
        }

        const renderCheckboxes = (checkboxes, key) => {
            return checkboxes.map((checkbox, index) => (<Checkbox
                key={index}
                forcedChecked={myForm[key][checkbox.name]}
                onChange={(e) => handleCheckboxChange(e, key)}
                name={checkbox.name}
                label={checkbox.label}
                fullWidth={true} />));
        }

        const headerBar = [
            {
                name: 'headerBar',
                label: 'header Bar Configuré'
            },
        ]

        const conversionActive = [
            {
                name: 'conversionActive',
                label: 'Conversion activée'
            },
        ]

        const basketActive = [
            {
                name: 'basketActive',
                label: 'Mise au panier panier vérifiée'
            },
        ]

        const checkModules = [
            {
                name: 'marketingScript',
                label: 'OnSite Activation (Marketing script)'
            }, {
                name: 'functionnalScript',
                label: 'OnSite Activation (Functional script)'
            }, 
            {
                name: 'beyableReady',
                label: 'BEYABLE Ready'
            }, {
                name: 'patchCampaigns',
                label: 'Patch Campaigns'
            }, {
                name: 'codePatchCampaigns',
                label: 'Code Patch Campaigns'
            }, {
                name: 'ABTests',
                label: 'A/B Tests'
            }, {
                name: 'shopifyApp',
                label: 'Shopify App'
            }, {
                name: 'emailRemarketing',
                label: 'Email Remarketing'
            }, {
                name: 'productMonitoring',
                label: 'Product Monitoring'
            }, {
                name: 'simplify',
                label: 'Simplify'
            }, {
                name: 'visitorEngagement',
                label: 'Visitor Engagement'
            }, {
                name: 'recommandation',
                label: 'Recommandation'
            },
            // Ajoutez d'autres cases à cocher ici
        ];

        const checkScript = [
            {
                name: 'beyableAnalytics',
                label: 'BEYABLE Analytics'
            }, {
                name: 'beyableAnalyticsEcommerce',
                label: 'BEYABLE Analytics Ecommerce'
            },
            // Ajoutez d'autres cases à cocher ici
        ];

        const openPopup = () => {
            setIsPopupOpen(true);
        };

        const openDataPopup = () => {
            setOpenDataPopup(true);
        };

        const [isByDataOpen,
            setOpenDataPopup] = useState(false);

        const isByDataClose = () => {
            setOpenDataPopup(false);
        };

        const closePopup = () => {
            setIsPopupOpen(false);
        };

        const handleCommentChange = (e) => {
            setComment(e.target.value);
        };

        const handlePopUpData = (e) => {
            e.preventDefault();
            openDataPopup();
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            openPopup();
        };

        let lastSavedDate = null;

        // Ajout du state de score

        // Fonction pour calculer le score
        const calculerScoreReady = (myForm) => {
            // la veleur est à 100% dans le cadre d'un analytics.
            
            let score = 0;
        
            // Type de page (5 points)
            const pageTypes = ['H', 'C', 'FP', 'B', 'T'];
            pageTypes.forEach(type => {
                if (myForm.pageType.includes(type)) score += 1;
            });

            // Supposons que myForm.tagsType est le champ textarea ou un champ similaire que vous voulez vérifier
            if (myForm.tagsType && myForm.tagsType.trim() !== '') {
                score += 1; // Ajoute 1 au score si le champ est non vide et non constitué uniquement d'espaces blancs
            }
        
            // Supposons que myForm.commandTesting est le champ textarea ou un champ similaire que vous voulez vérifier
            if (myForm.commandTesting && myForm.commandTesting.trim() !== '') {
                score += 1; // Ajoute 1 au score si le champ est non vide et non constitué uniquement d'espaces blancs
            }
        
            // Script Marketing (5 points)
            if (myForm.defaultCheckboxes.marketingScript) score += 5;
        
            // Donnée du panier (7 points)
            if (myForm.shoppingCartMethod === 'allAlong') score += 1;
            if (myForm.cartAmount === "Oui") score += 2;
            if (myForm.cartCompositionIdProduct === "Oui") score += 2;
            if (myForm.cartCompositionProductPrice === "Oui") score += 2;
        
            // Panier acheté (5 points)
            if (myForm.defaultCheckboxes.conversionActive) score += 5;
            
            // Panier acheté (2 points)
            if (myForm.defaultCheckboxes.basketActive) score += 2;

            // Données de produits en FP (6 points)
            const visitingDataFields = ['ProductID', 'Name', 'Price', 'PriceDiscounted', 'ImgUrl', 'Tags'];
            visitingDataFields.forEach(field => {
                if (myForm.visitingData && myForm.visitingData[field] === "Oui") score += 1;
            });
            // Header Bar (3 points)
            if (myForm.defaultCheckboxes.headerBar) score += 3;

            if (myForm.defaultScripts.beyableAnalytics) {
                // Si aucun autre score n'a été ajouté (autres que beyableAnalytics), alors attribuez 32
                // Sinon, si d'autres éléments ont été cochés/décochés, ajustez le score de beyableAnalytics à 5
                score = score == 0 ? 40 : score + 5;
            }

            console.log('score :', score);
            return score;

        };

        // Mise à jour du score lorsque myForm change
        useEffect(() => {
            const scoreCalcule = calculerScoreReady(myForm);
            setScore(scoreCalcule);
        }, [myForm]);

         const handleSubmitAPIs = (e) => {
             e.preventDefault();
             const modificationDate = lastSavedDate
                 ? lastSavedDate
                 : moment().format('DD/MM/YYYY [à] HH:mm');
            
             let jsonData;
             try {
                 jsonData = JSON.parse(jsonValue); // Tentative de parsing du JSON
             } catch (error) {
                 console.error('Erreur lors du parsing du JSON :', error);
                 // Afficher un message d'erreur à l'utilisateur ou gérer l'erreur
                 return; // Arrêter l'exécution si le JSON est invalide
             }

            const modifiedBy = user
                 ?.name; // ou user?.email
            const dataToSend = {
                 "comment": comment,
                 "content": JSON.stringify({
                     "comment": comment,
                     modifiedBy,
                     byData: jsonData,
                     modificationDate: modificationDate,
                     installationChoice: myForm.installationChoice,
                     otherInstallation: myForm.otherInstallation,
                     defaultCheckboxes: {
                         ...myForm.defaultCheckboxes
                     },
                     defaultScripts: {
                         ...myForm.defaultScripts
                     },
                     clientURL: myForm.clientURL,
                     fluxURL: myForm.fluxURL,
                     Multitiple: myForm.Multitiple,
                     visitingData: {
                         ...myForm.visitingData
                     },
                     categoriesData: {
                         ...myForm.categoriesData
                     },
                     audienceOutils: myForm.audienceOutils,
                     recoProduit: myForm.recoProduit,
                     ranking: myForm.ranking,
                     ShoppingCartRetrieval: myForm.ShoppingCartRetrieval,
                     rankingMethod: myForm.rankingMethod,
                     microObjectifs: myForm.microObjectifs,
                     APICodePromo: myForm.APICodePromo,
                     favoriteCat: myForm.favoriteCat,
                     sendCampagnOutils: myForm.sendCampagnOutils,
                     sendCampagnData: myForm.sendCampagnData,
                     sendMethodCampagn: myForm.sendMethodCampagn,
                     geolocalisation: myForm.geolocalisation,
                     pageType: myForm.pageType,
                     tagsType: myForm.tagsType,
                     commandTesting: myForm.commandTesting,
                     subDomaineChoice: myForm.subDomaineChoice,
                     functionRules: myForm.functionRules,
                     setupReport: myForm.setupReport,
                     TenantChoice: myForm.TenantChoice,
                     TenantChoiceInformation: myForm.TenantChoiceInformation,
                     dataMethod: myForm.dataMethod,
                     shoppingCartMethod: myForm.shoppingCartMethod,
                     cartAmount: myForm.cartAmount,
                     cartAmountBuy: myForm.cartAmountBuy,
                     Csm: myForm.Csm,
                     cartCompositionIdProduct: myForm.cartCompositionIdProduct,
                     cartCompositionBuyIdProduct: myForm.cartCompositionBuyIdProduct,
                     cartCompositionProductPrice: myForm.cartCompositionProductPrice,
                     cartCompositionBuyProductPrice: myForm.cartCompositionBuyProductPrice,
                     promoCode: myForm.promoCode,
                     dataMethodCollect: myForm.dataMethodCollect,
                     audienceMethod: myForm.audienceMethod,
                     audienceConnexion: myForm.audienceConnexion,
                     CMPChoice: myForm.CMPChoice,
                     CPMTools: myForm.CPMTools,
                     historicProductsView: myForm.historicProductsView,
                     score: score,
                     environnement: myForm.environnement,
                 }, null, 3
                     // L'argument 2 indique une indentation de 2 espaces
                 ),
                 "fileName": "ClientConfiguration.json"
            };

             // URL de l'API

             setIsLoading(true);
                 authAxios
                     .post(ApiUrl, dataToSend)
                     .then((response) => {
                         // Gère la réponse de l'API ici (par exemple, affiche un message de succès)
                        //  setIsLoading(false);
                         lastSavedDate = moment().format('DD/MM/YYYY [à] HH:mm');
                         
                         handleSubmitAPIList();
                     })
                     .catch((error) => {
                         setIsLoading(false);
                            
                         // Gère les erreurs de l'API ici (par exemple, affiche un message d'erreur)
                         console.error('Erreur lors de l\'envoi à l\'API :', error);
                     });
             // Ferme la pop-up après avoir soumis le commentaire
             closePopup();
             isByDataClose();
        }

        const handleSubmitAPIList = () => {
            const modificationDate = lastSavedDate
                ? lastSavedDate
                : moment().format('DD/MM/YYYY [à] HH:mm');
           
                       
            const modifiedBy = user
                ?.name; // ou user?.email

            // est ce que le compte a un score ? oui => MAJ : CREATE 
            // Objects avec nom en id  
           const getScoresMaj = () => {
            const scoreIsExistOnFile = listOfScoresBY.find(account => account.id === id);
            if(scoreIsExistOnFile){
              return JSON.stringify(listOfScoresBY.reduce((acc, curr) => {                  
                    if (curr.id === id) {
                        const currentAccountScore = {
                            ...curr, 
                            score: score
                        }
                        acc = [...acc, currentAccountScore];
                    }else{
                        acc = [...acc, curr];
                    }
                   
                    return acc
       
                }, []));
            }else{
                const newAccountScore = {
                    id: id, 
                    score: score,
                }
                const cloneScores =  [...JSON.parse(JSON.stringify(listOfScoresBY)) ,newAccountScore ]
                return  JSON.stringify(cloneScores);
            }
         }
        
            const scoresMaj = getScoresMaj();
            const commentData =  JSON.stringify({
                "comment": comment,
                    modifiedBy,
                    modificationDate: modificationDate,

                }, null, 3
                     //L'argument 3 indique une indentation de 3 espaces
                );
            const dataToSend = {
               content: scoresMaj,
                comment : commentData,
                "fileName": "ListScroring.json"
            };

            // URL de l'API

            setIsLoading(true);
            try {
                authAxios
                    .post(ApiUrlScoring, dataToSend)
                    .then((response) => {
                        // Gère la réponse de l'API ici (par exemple, affiche un message de succès)
                        setIsLoading(false);
                        lastSavedDate = moment().format('DD/MM/YYYY [à] HH:mm');
                        fetchListScoresFromApi()
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        // Gère les erreurs de l'API ici (par exemple, affiche un message d'erreur)
                        console.error('Erreur lors de l\'envoi à l\'API :', error);
                    });
            } catch (error) {
                console.error('Erreur lors de l\'envoi à l\'API :', error);
            }


            // Ferme la pop-up après avoir soumis le commentaire
            closePopup();
            isByDataClose();
        }
        const fetchListScoresFromApi = (apiDataRes) => {
            const params = {
                fileName: 'ListScroring.json', // Remplacez par le nom de fichier souhaité

            };
            authAxios
            .get(ApiUrlScoring,{params})
            .then((responseListScores) => {
                if(apiDataRes){
                    const apiData = apiDataRes.data;
                    setMyForm(apiData);
                    setJsonValue(JSON.stringify(apiData.byData, null, 3));
                }
                setlistOfScoresBY(responseListScores.data);
            })
            .catch((error) => {
                setIsLoading(false)
                // Gère les erreurs de l'API ici (par exemple, affiche un message d'erreur)
                console.error('Erreur lors de l\'envoi à l\'API :', error);
            });
        }

        const fetchDataFromAPI = async () => {
            setIsLoading(false);
            try {
                // URL de l'API pour la récupération de données Paramètres de la requête GET, y
                // compris le nom du fichier souhaité
                const params = {
                    fileName: 'ClientConfiguration.json', // Remplacez par le nom de fichier souhaité

                };

                const response = await authAxios.get(ApiUrl, { params });
                response && fetchListScoresFromApi(response)
                
            } catch (error) {
                setIsLoading(false);
                fetchListScoresFromApi()
                console.error('Erreur lors de la récupération des données depuis l\'API :', error);
            }
        };

        const fetchDataFromAPIDate = async () => {
            setIsLoading(true);
            try {
                const response = await authAxios.get(ApiUrlDate);
                setIsLoading(false);
                if (response) {
                    const apiDataDate = response.data;
                    setCreationDate(apiDataDate.creationDateUtc); // Mise à jour de l'état avec la date de création
                    setClientName(apiDataDate.name); // Mise à jour de l'état avec la nom de création
                    setClientId(apiDataDate.id);
                    setClientKey(apiDataDate.clientKey);
                    setAuthorizedUrls(apiDataDate.authorizedUrls);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };


        useEffect(() => {
            // Appelez fetchDataFromAPI lorsque le composant est monté
            fetchDataFromAPIDate();
            fetchDataFromAPI();
        }, []);

        return (
            <Section>
                <Article>
                    <div className='main__content'>
                        {isLoading && <Spinner />}
                        <form className="main__content--form" onSubmit={handleSubmit}>
                            <div className='main__content--header'>
                                <div className='mb_20'>
                                    <p className="title-h3 title-list-simplify mb-0">Set-up Configuration</p>
                                    <div className='flex flex_justify_center w-100'>
                                        <Score score={score} />
                                    </div>
                                    <div className='flex flex_justify_center w-100'>
                                    <p className="font-weight-bold pl-2 mb-0">Scoring : {(score / 40 * 100).toFixed(0)}%</p> {/* Affichage en pourcentage */}
                                    </div>
                                    <p className="title-h4 al_center" style={{ fontWeight: 'bold', color: "#0279b7" }}>{clientName}</p>
                                    <p className="al_center mb-0">Date de création :
                                        <span className="font-weight-bold pl-1">
                                            le {moment(creationDate).format('DD-MM-YYYY')}
                                        </span>
                                    </p>
                                    {myForm.modificationDate
                                        ? (
                                            <p className="al_center mb-0 mt-0">
                                                Mise à jour effectuée le : {" "}
                                                <span className="font-weight-bold pl-1">
                                                    {myForm.modificationDate}
                                                    <span className="pl-1">par {myForm.modifiedBy}
                                                    </span>
                                                </span>
                                                <br />
                                            </p>
                                        )
                                        : (
                                            <p className="al_center pb-4 pt-0 ">Aucune configuration effectuée</p>
                                        )}
                                    <div className='flex flex_justify_center w-100 mt_10'>
                                        <Btn className="al_center" size={'xxs'} onClick={handlePopUpData}>
                                            by_data en FP
                                        </Btn>
                                    </div>
                                </div>
                                <div className='flex flex-column'>
                                    <label className='al_left w-100'>CSM</label>
                                    <input
                                        className='form-control mb-2 border rounded-right '
                                        type="text"
                                        name="Csm"
                                        value={myForm.Csm || ''}
                                        onChange={handleInputChange}
                                        placeholder="Renseigner le CSM responsable du compte client" />

                                    <label className='al_left w-100'>URL du client :</label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <a href={myForm.clientURL} target="_blank" rel="noopener noreferrer">
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </span>
                                        </div>
                                        <input
                                            className='form-control border rounded-right'
                                            type="text"
                                            name="clientURL"
                                            value={myForm.clientURL}
                                            onChange={handleInputChange}
                                            placeholder="Renseigner l'URL du client" />
                                    </div>
                                    <label className='al_left w-100'>Customer key</label>
                                    <Textfield
                                        disabled
                                        fullWidth={true}
                                        className='form-control mb-2 border rounded-right '
                                        type="text"
                                        value={clientKey} />

                                    <label className='al_left w-100'>Account ID</label>
                                    <Textfield
                                        disabled
                                        fullWidth={true}
                                        className='form-control mb-2 border rounded-right '
                                        type="text"
                                        name="clientId"
                                        value={id} />
                                    
                                    <label className='al_left w-100'>Authorized Urls</label>
                                    <Textfield
                                        disabled
                                        fullWidth={true}
                                        className='form-control mb-2 border rounded-right '
                                        type="text"
                                        name="authorizedUrls"
                                        value={authorizedUrls ? authorizedUrls : "Non renseigné"} />  
                                    <div className='w-100'>
                                    <Select
                                    fullWidth={true}
                                    label="Renseigner l'environnement Actuel"
                                    name="environnement"
                                    value={myForm.environnement}
                                    onChange={(e) => handleChange(e, "environnement")}
                                    placeholder="Choix de l'environnement"
                                    optionsList={[
                                        {
                                            label: 'PROD',
                                            value: 'Prod'
                                        }, {
                                            label: 'PREPROD',
                                            value: 'Preprod'
                                        },
                                    ]}></Select>
                                </div>
                                </div>
                            </div>
                            <hr className="tiret"></hr>
                            <div className='flex flex_justify_between flex_align_baseline' style={{ columnGap: '3%' }}>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Module(s) souscrit(s)</p>
                                    {renderCheckboxes(checkModules, 'defaultCheckboxes')}
                                </div>

                                <div className='w-100'>
                                    <Select
                                        label="Type d'installation"
                                        fullWidth={true}
                                        name="installationChoice"
                                        value={myForm.installationChoice}
                                        onChange={(e) => handleChange(e, "installationChoice")}
                                        placeholder="Choix de l'installation"
                                        optionsList={[
                                            {
                                                label: 'GTM',
                                                value: 'GTM'
                                            }, {
                                                label: 'Module Prestashop',
                                                value: 'prestashop'
                                            }, {
                                                label: 'Module Shopify',
                                                value: 'shopify'
                                            }, {
                                                label: 'Module Magento',
                                                value: 'magento'
                                            }, {
                                                label: 'hybride (module CMS + GTM)',
                                                value: 'hybride'
                                            }, {
                                                label: 'Autre',
                                                value: 'Other'
                                            }
                                        ]}></Select>
                                    {myForm.installationChoice === 'Other' && (
                                        <div>
                                            <div>
                                                <Textfield
                                                    fullWidth={true}
                                                    label={'Si Autre, quel type d\'installation'}
                                                    type="text"
                                                    name="otherInstallation"
                                                    value={myForm.otherInstallation || ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Renseigner le type d'installation" />
                                            </div>
                                        </div>
                                    )}

                                    <div className='w-100 mb-3'>
                                        <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Modules Analytics et Ecommerce</p>
                                        {renderCheckboxes(checkScript, 'defaultScripts')}
                                    </div>
                                    
                                    <div className='w-100'>
                                        {myForm.defaultCheckboxes.beyableReady && (
                                            <div className='w-100 mt-3'>
                                                <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Configuration supplémentaire (Ready)</p>
                                                {renderCheckboxes(headerBar, 'defaultCheckboxes')}
                                            </div>
                                            )
                                        }
                                    </div>
                                    <div className='w-100 mt-3'>
                                        <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Tag(s) utilisé(s)</p>
                                        <div>
                                            <Textfield
                                                fullWidth={true}
                                                name="tagsType"
                                                type='textarea'
                                                value={myForm.tagsType || ''}
                                                onChange={handleInputChange}
                                                placeholder="est, test1, test2" />
                                        </div>
                                    </div>
                                    <div className='w-100 mt-3'>
                                        <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Commande de test</p>
                                        <div>
                                            <Textfield
                                                fullWidth={true}
                                                autogrow={true}
                                                name="commandTesting"
                                                type='textarea'
                                                value={myForm.commandTesting || ''}
                                                onChange={handleInputChange}
                                                placeholder="Votre comande test à renseigné" />
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100'>
                                    <Select
                                        label="Sous-domaines"
                                        fullWidth={true}
                                        name="subDomaineChoice"
                                        value={myForm.subDomaineChoice || ''}
                                        onChange={(e) => handleChange(e, "subDomaineChoice")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}>
                                    </Select>

                                    <Select
                                        label="Tenant"
                                        fullWidth={true}
                                        name="TenantChoice"
                                        value={myForm.TenantChoice || ''}
                                        onChange={(e) => handleChange(e, "TenantChoice")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                        {myForm.TenantChoice === 'Oui' && (
                                        <div>
                                            <div>
                                                <Textfield
                                                    fullWidth={true}
                                                    label={'Si Oui, renseigner ce champ'}
                                                    type='textarea'
                                                    name="TenantChoiceInformation"
                                                    value={myForm.TenantChoiceInformation || ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Renseigner les tenants" />
                                            </div>
                                        </div>
                                    )}

                                    <Select
                                        label="CMP"
                                        fullWidth={true}
                                        name="CMPChoice"
                                        value={myForm.CMPChoice || ''}
                                        onChange={(e) => handleChange(e, "CMPChoice")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                    {myForm.CMPChoice === 'Oui' && (
                                        <div>
                                            <div>
                                                <Textfield
                                                    fullWidth={true}
                                                    label={'Si Oui, quel CMP'}
                                                    type="text"
                                                    name="CPMTools"
                                                    value={myForm.CPMTools || ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Renseigner le CMP" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='w-100'>
                                {myForm.defaultCheckboxes.recommandation && (
                                    <div className='w-100 mt_15'>
                                        <Textfield
                                            fullWidth={true}
                                            autogrow={true}
                                            label={'Fonctions installées'}
                                            type="textarea"
                                            name="functionRules"
                                            value={myForm.functionRules || ''}
                                            onChange={handleInputChange}
                                            placeholder="Renseigner les fonctions installées" />
                                    </div>
                                )
                                }
                            </div>

                            <hr className="tiret"></hr>

                            <div className='w-100'>
                                <div className='w-100'>
                                    <Select
                                        label="Collecte donnèes produits"
                                        fullWidth={true}
                                        name="dataMethodCollect"
                                        value={myForm.dataMethodCollect || ''}
                                        onChange={(e) => handleChange(e, "dataMethodCollect")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                        {myForm.dataMethodCollect === 'Oui' && (
                                            <div>
                                                <Select
                                                    label="Si Oui, méthode"
                                                    fullWidth={true}
                                                    name="dataMethod"
                                                    value={myForm.dataMethod || ''}
                                                    onChange={(e) => handleChange(e, "dataMethod")}
                                                    placeholder="Choix de la méthode"
                                                    optionsList={[
                                                        {
                                                            label: 'Flux produits du client via URL publique',
                                                            value: 'fluxViaUrl'
                                                        }, {
                                                            label: 'Flux produits du client via dépôt ftp',
                                                            value: 'fluxViaFtp'
                                                        }, {
                                                            label: 'Collecte BEYABLE via données OnSite',
                                                            value: 'collectBYOnSite'
                                                        }, {
                                                            label: 'Multiple',
                                                            value: 'collectMultiple'
                                                        },
                                                    ]}></Select>
                                                {myForm.dataMethod === 'fluxViaUrl' && (
                                                    <div>
                                                        <Textfield
                                                            fullWidth={true}
                                                            label={'URL du flux à renseigner'}
                                                            type="text"
                                                            name="fluxURL"
                                                            value={myForm.fluxURL || ''}
                                                            onChange={handleInputChange}
                                                            placeholder="Renseigner l'URL du client" />
                                                    </div>
                                                )}
                                                {myForm.dataMethod === 'collectMultiple' && (
                                                    <div>
                                                        <Textfield
                                                            fullWidth={true}
                                                            autogrow={true}
                                                            label={'Renseigner les méthodes'}
                                                            type="textarea"
                                                            name="Multitiple"
                                                            value={myForm.Multitiple || ''}
                                                            onChange={handleInputChange}
                                                            placeholder="OnSite, et flux mélangé" />
                                                    </div>
                                                )}

                                            </div>
                                        )}
                                </div>
                                <div className='w-100'>
                                    <Select
                                        label="Connexion audience CRM/DMP"
                                        fullWidth={true}
                                        name="audienceConnexion"
                                        value={myForm.audienceConnexion || ''}
                                        onChange={(e) => handleChange(e, "audienceConnexion")}
                                        placeholder="votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                    {myForm.audienceConnexion === 'Oui' && (
                                        <div>
                                            <Select
                                                label="si oui, méthode"
                                                fullWidth={true}
                                                name="audienceMethod"
                                                value={myForm.audienceMethod || ''}
                                                onChange={(e) => handleChange(e, "audienceMethod")}
                                                placeholder="Choix de la méthode"
                                                optionsList={[
                                                    {
                                                        label: 'Segment client dans le datalayer',
                                                        value: 'segmentDataLayer'
                                                    }, {
                                                        label: 'Connexion DMP',
                                                        value: 'conenxionDMP'
                                                    }
                                                ]}></Select>
                                            <div>
                                                <Textfield
                                                    label={'Si oui quel outils'}
                                                    type="text"
                                                    name="audienceOutils"
                                                    value={myForm.audienceOutils || ''}
                                                    onChange={handleInputChange}
                                                    placeholder="Renseigner la méthode utilisée" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <hr className="tiret"></hr>

                            <div className='flex flex_justify_between flex_align_baseline w-100' style={{ columnGap: '3%' }}>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Configuration</p>
                                    <div>
                                        <Textfield
                                            fullWidth={true}
                                            label={'Types de pages'}
                                            type="text"
                                            name="pageType"
                                            value={myForm.pageType || ''}
                                            onChange={handleInputChange}
                                            placeholder="H, C, FP, B, T, O, S" />
                                    </div>

                                    <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Ajout de mise au panier vérifiée</p>
                                    <div className='w-100 mt-2'>
                                        {renderCheckboxes(basketActive, 'defaultCheckboxes')}
                                    </div>
                                </div>
                                </div>
                                <div className='w-100'>
                                    <div>
                                        <Select
                                            label="Historique produits consultés"
                                            fullWidth={true}
                                            name="historicProductsView"
                                            value={myForm.historicProductsView || ''}
                                            onChange={(e) => handleChange(e, "historicProductsView")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>

                                        <Select
                                            label="Reco produits"
                                            fullWidth={true}
                                            name="recoProduit"
                                            value={myForm.recoProduit || ''}
                                            onChange={(e) => handleChange(e, "recoProduit")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>

                                        <Select
                                            label="Ranking server-side / client side"
                                            fullWidth={true}
                                            name="ranking"
                                            value={myForm.ranking || ''}
                                            onChange={(e) => handleChange(e, "ranking")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>

                                        {myForm.ranking === 'Oui' && (
                                            <div>
                                                <Select
                                                    label="si oui, méthode"
                                                    fullWidth={true}
                                                    name="rankingMethod"
                                                    value={myForm.rankingMethod || ''}
                                                    onChange={(e) => handleChange(e, "rankingMethod")}
                                                    placeholder="Choix de la méthode"
                                                    optionsList={[
                                                        {
                                                            label: 'Server Side',
                                                            value: 'serverSide'
                                                        }, {
                                                            label: 'Client Side',
                                                            value: 'clientSide'
                                                        }
                                                    ]}></Select>
                                            </div>
                                        )}
                                        <div>
                                            <Select
                                                label="Micro-objectifs"
                                                fullWidth={true}
                                                name="microObjectifs"
                                                value={myForm.microObjectifs || ''}
                                                onChange={(e) => handleChange(e, "microObjectifs")}
                                                placeholder="Votre choix"
                                                optionsList={[
                                                    {
                                                        label: 'Oui',
                                                        value: 'Oui'
                                                    }, {
                                                        label: 'Non',
                                                        value: 'Non'
                                                    }
                                                ]}></Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100'>
                                    <div>
                                        <Select
                                            label="Catégorie préférée"
                                            fullWidth={true}
                                            name="favoriteCat"
                                            value={myForm.favoriteCat || ''}
                                            onChange={(e) => handleChange(e, "favoriteCat")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>
                                    </div>
                                    <div>
                                        <Select
                                            label="API code promo unique"
                                            fullWidth={true}
                                            name="APICodePromo"
                                            value={myForm.APICodePromo || ''}
                                            onChange={(e) => handleChange(e, "APICodePromo")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>
                                    </div>
                                    <div>
                                        <Select
                                            label="Envoi données campagnes outil tiers"
                                            fullWidth={true}
                                            name="sendCampagnOutils"
                                            value={myForm.sendCampagnOutils || ''}
                                            onChange={(e) => handleChange(e, "sendCampagnOutils")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>
                                        {myForm.sendCampagnOutils === 'Oui' && (
                                            <div>
                                                <Select
                                                    label="si oui, méthode"
                                                    fullWidth={true}
                                                    name="sendMethodCampagn"
                                                    value={myForm.sendMethodCampagn || ''}
                                                    onChange={(e) => handleChange(e, "sendMethodCampagn")}
                                                    placeholder="Outil utilisé"
                                                    optionsList={[
                                                        {
                                                            label: 'GA',
                                                            value: 'GA'
                                                        }, {
                                                            label: 'Content Square',
                                                            value: 'Content Square'
                                                        }, {
                                                            label: 'Autre (à détailler)',
                                                            value: 'Autres methode'
                                                        }
                                                    ]}></Select>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <Select
                                            label="Géolocalisation"
                                            fullWidth={true}
                                            name="geolocalisation"
                                            value={myForm.geolocalisation || ''}
                                            onChange={(e) => handleChange(e, "geolocalisation")}
                                            placeholder="Votre choix"
                                            optionsList={[
                                                {
                                                    label: 'Oui',
                                                    value: 'Oui'
                                                }, {
                                                    label: 'Non',
                                                    value: 'Non'
                                                }
                                            ]}></Select>
                                    </div>
                                </div>
                            </div>

                            <hr className="tiret"></hr>

                            <div className='flex flex_justify_between flex_align_baseline w-100' style={{ columnGap: '3%' }}>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Données panier</p>
                                </div>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#00c6b5' }}>Panier - visité</p>
                                    <Select
                                        label="Récupération du panier"
                                        fullWidth={true}
                                        name="ShoppingCartRetrieval"
                                        value={myForm.ShoppingCartRetrieval || ''}
                                        onChange={(e) => handleChange(e, "ShoppingCartRetrieval")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                    {myForm.ShoppingCartRetrieval === 'Oui' && (
                                        <div>
                                            <Select
                                                label="Sur quelle page ?"
                                                fullWidth={true}
                                                name="shoppingCartMethod"
                                                value={myForm.shoppingCartMethod || ''}
                                                onChange={(e) => handleChange(e, "shoppingCartMethod")}
                                                placeholder="Votre choix"
                                                optionsList={[
                                                    {
                                                        label: 'Tout au long de la visite',
                                                        value: 'allAlong'
                                                    }, {
                                                        label: 'En page panier uniquement',
                                                        value: 'onBasketPage'
                                                    }
                                                ]}></Select>
                                        </div>
                                    )}

                                    <Select
                                        label="Cart amount"
                                        fullWidth={true}
                                        name="cartAmount"
                                        value={myForm.cartAmount || ''}
                                        onChange={(e) => handleChange(e, "cartAmount")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }, {
                                                label: 'HT avec frais de livraison',
                                                value: 'HT avec frais de livraison'
                                            }, {
                                                label: 'HT hors frais de livraison',
                                                value: 'HT hors frais de livraison'
                                            }, {
                                                label: 'TTC avec frais de livraison',
                                                value: 'TTC avec frais de livraison'
                                            }, {
                                                label: 'TTC hors frais de livraison',
                                                value: 'TTC hors frais de livraison'
                                            }
                                        ]}></Select>


                                    <Select
                                        label="Cart composition : ID Product"
                                        fullWidth={true}
                                        name="cartCompositionIdProduct"
                                        value={myForm.cartCompositionIdProduct || ''}
                                        onChange={(e) => handleChange(e, "cartCompositionIdProduct")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Cart composition : Product Price"
                                        fullWidth={true}
                                        name="cartCompositionProductPrice"
                                        value={myForm.cartCompositionProductPrice || ''}
                                        onChange={(e) => handleChange(e, "cartCompositionProductPrice")}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                </div>

                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#00c6b5' }}>Panier - acheté</p>
                                    <div className='w-100 mt-3'>
                                        {renderCheckboxes(conversionActive, 'defaultCheckboxes')}
                                    </div>
                                </div>
                            </div>

                            <hr className="tiret"></hr>

                            <div className='flex flex_justify_between flex_align_baseline w-100' style={{ columnGap: '3%' }}>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#0056C6' }}>Données de visite</p>
                                </div>
                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#00c6b5' }}>Données produits (en FP)</p>
                                    <Select
                                        label="Product ID"
                                        fullWidth={true}
                                        name="visitingDataProductID"
                                        value={myForm.visitingData.ProductID || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'ProductID', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Name"
                                        fullWidth={true}
                                        name="visitingDataName"
                                        value={myForm.visitingData.Name || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'Name', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Price"
                                        fullWidth={true}
                                        name="visitingDataPrice"
                                        value={myForm.visitingData.Price || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'Price', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Price discounted"
                                        fullWidth={true}
                                        name="visitingDataPriceDiscounted"
                                        value={myForm.visitingData.PriceDiscounted || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'PriceDiscounted', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Image"
                                        fullWidth={true}
                                        name="visitingDataImgUrl"
                                        value={myForm.visitingData.ImgUrl || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'ImgUrl', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Tags"
                                        fullWidth={true}
                                        name="visitingDataTags"
                                        value={myForm.visitingData.Tags || ''}
                                        onChange={(e) => handleVisitingDataChange(e, 'Tags', 'visitingData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                </div>

                                <div className='w-100'>
                                    <p style={{ fontWeight: 'bold', color: '#00c6b5' }}>Données catégories (en C)</p>
                                    <Select
                                        label="Category ID"
                                        fullWidth={true}
                                        name="categoriesDataProductID"
                                        value={myForm.categoriesData.categorieID || ''}
                                        onChange={(e) => handlecategoriesDataChange(e, 'categorieID', 'categoriesData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Name"
                                        fullWidth={true}
                                        name="categoriesDataName"
                                        value={myForm.categoriesData.Name || ''}
                                        onChange={(e) => handlecategoriesDataChange(e, 'Name', 'categoriesData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>

                                    <Select
                                        label="Level"
                                        fullWidth={true}
                                        name="categoriesDataLevel"
                                        value={myForm.categoriesData.Level || ''}
                                        onChange={(e) => handlecategoriesDataChange(e, 'Level', 'categoriesData')}
                                        placeholder="Votre choix"
                                        optionsList={[
                                            {
                                                label: 'Oui',
                                                value: 'Oui'
                                            }, {
                                                label: 'Non',
                                                value: 'Non'
                                            }
                                        ]}></Select>
                                </div>
                            </div>
                            <div className='w-100 mt_15'>
                                        <Textfield
                                            fullWidth={true}
                                            autogrow={true}
                                            label={`Set-up reporting r\u00E9alis\u00E9 par ${myForm.modifiedBy?myForm.modifiedBy: 'pas de responsable configuré'}`}
                                            type="textarea"
                                            name="setupReport"
                                            value={myForm.setupReport || ''}
                                            onChange={handleInputChange}
                                            placeholder="Renseigner les étapes du set-up client" />
                            </div>
                            <div className='SubmitBtn al_center mt_10 mb_10'>
                                <Btn type="submit">
                                    Mettre à jour le formulaire
                                </Btn>
                            </div>
                        </form>
                        {isPopupOpen && (
                            <Modal isOpen={isPopupOpen} onClose={closePopup}>
                                <ModalHeader title="Finalisation de la mise à jour" />
                                <ModalBody>
                                    <textarea
                                        className='form-control mb-2 border rounded-right'
                                        type="text"
                                        name="clientURL"
                                        value={comment || ''}
                                        onChange={handleCommentChange}
                                        placeholder="Merci de préciser les changement apportées au set-up du client" />
                                </ModalBody>
                                <ModalFooter
                                    primaryAction=
                                    {<Btn onClick={(e) =>handleSubmitAPIs(e)}>Envoyer</Btn>}
                                    secondaryAction=
                                    {< Btn style="ghost" color="secondary" onClick={closePopup} > Annuler </Btn>}></ModalFooter>
                                <ModalFooter />
                            </Modal>
                        )}
                        {isByDataOpen && (
                            <Modal isOpen={isByDataOpen} onClose={isByDataClose}>
                                <ModalHeader title="Aperçu du by_data en fiche produit" />
                                <ModalBody>
                                    <JsonEditor
                                        jsonValue={jsonValue}
                                        setJsonValue={setJsonValue}
                                        options={options}
                                    />
                                </ModalBody>
                                <ModalFooter
                                    primaryAction=
                                    {<Btn onClick={(e) => handleSubmitAPIs(e)   }>Envoyer</Btn>}
                                    secondaryAction=
                                    {< Btn style="ghost" color="secondary" onClick={isByDataClose} > Annuler </Btn>}></ModalFooter>
                                <ModalFooter />
                            </Modal>
                        )}
                    </div>
                </Article>
            </Section>
        )
    }