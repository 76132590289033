import React, { useState, useEffect } from 'react';
import './MenuSelection.css';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import InputCopyToClipBoard from '../Ui/InputCopyToClipBoard';
export default function MenuSelection({
	dataCampaign,
	linkToBo,
}) {
	const idTool = uuidv4();
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};
	return (
		<>
			<div className="menu-selection-campaign-container">
				<div className="header-infos-campaign infos-campaign">
					<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
					<h3 className="infos-title">Backoffice Quick Access</h3>
					<a className="link-campaign-backoffice" href={linkToBo} target="_blank" data-for={idTool} data-tip={'Open campaign in the back office'} rel="noreferrer">  <span className="operation-name">{dataCampaign.name.length > 33 ? dataCampaign.name.substring(0, 32) + '...' : dataCampaign.name}</span> </a>
				</div>
				<div className="infos-campaign">
					<h3 className="infos-title">Description</h3>
					<p>{dataCampaign.description}</p>
				</div>
				<div className="infos-campaign">
					<h3 className="infos-title">Campaign ID</h3>
					<InputCopyToClipBoard
						clipBoardContain={clipBoardContain}
						onClickFunction={(e) => copyToClipBoard(dataCampaign.id)}
						value={dataCampaign.id}
					/>
				</div>
				<div className="infos-campaign flex flex_justify_between">
					<h3 className="infos-title">Start date</h3>
					<p>{dataCampaign.startDate && moment(dataCampaign.startDate).format('YYYY/MM/DD HH:MM')}</p>
				</div>
				<div className="infos-campaign flex flex_justify_between">
					<h3 className="infos-title">End date</h3>
					<p>{dataCampaign.endDate ? moment(dataCampaign.endDate).format('YYYY/MM/DD HH:MM') : 'No end date'}</p>
				</div>
				<div className="infos-campaign flex flex_justify_between">
					<h3 className="infos-title">Last update</h3>
					<p>{moment(dataCampaign.modificationDate).format('YYYY/MM/DD HH:MM')}</p>
				</div>
			</div >
		</>
	);
}
