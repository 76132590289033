import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import Message from '../../Ui/Message';
import Btn from './../../Ui/Btn';
// import Modal from '../../Ui/Modal';
import {Modal} from 'ui-beyable';
import './PageTypeEditionContainer.css';
function PageTypeEditionContainer({ closeTheCreationForm, listPageTypes, env, accountId }) {

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();
	const [valueForm, setValueForm] = useState({
		tag: '',
		label: ''
	});
	const [tagToChange, setTagToChange] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');
	const [btnCreationIsDesabled, setBtnCreationIsDesabled] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [tagIsSelect, setTagIsSelect] = useState();
	const [sendForm, setSendForm] = useState(false);
	const [modalDeleteIsOppen, setModalDeleteIsOppen] = useState(false);
	const [tagToDelete, setTagToDelete] = useState();

	useEffect(() => {
		if (tagIsSelect) {
			setValueForm(tagIsSelect);
		}
	}, [tagIsSelect]);
	const handleChange = (e) => {
		e.persist();
		setValueForm(prevValues => ({
			...prevValues,
			[e.target.name]: e.target.value
		})
		);
	};

	const desabledTheCreationButton = () => {
		if (valueForm.tag.trim().length !== 0 && valueForm.label.trim().length !== 0) {
			const arrayOfTagsName = listPageTypes && listPageTypes.map(x => x.tag);
			if (arrayOfTagsName && arrayOfTagsName.includes(valueForm.tag)) {
				setBtnCreationIsDesabled(true);
			} else {
				setBtnCreationIsDesabled(false);
			}
		} else {
			setBtnCreationIsDesabled(true);
		}
	};
	useEffect(() => {
		desabledTheCreationButton();
	}, [valueForm]);
	useEffect(() => {
		const createNewPageType = async () => {
			setIsLoading(true);
			let arrayOfPageTypeToSave = listPageTypes ? [...listPageTypes, valueForm] : [valueForm];
			if (isEditMode) {
				arrayOfPageTypeToSave = arrayOfPageTypeToSave.filter(elem => elem.tag !== tagToChange.tag);
			}
			authAxios.put(`websitesetup/${env.toUpperCase()}/pagetype/${accountId}`, arrayOfPageTypeToSave)
				.then(() => {
					closeTheCreationForm(false);
				}
				).catch(e => {
					if (e) {
						setMessage('Un problème est survenu pendant la création du tag');
						setColor('alert-danger');
						setIsLoading(false);
						console.error(e);
						setTimeout(() => {
							setMessage();
						}, 2000);
					}
				});
		};
		isAuthenticated && sendForm && createNewPageType();
	}, [sendForm]);

	const deleteATag = async (e) => {
		setIsLoading(true);
		let arrayOfPageTypeToSave = [...listPageTypes];
		arrayOfPageTypeToSave = arrayOfPageTypeToSave.filter(elem => elem.tag !== e.tag);
		authAxios.put(`websitesetup/${env.toUpperCase()}/pagetype/${accountId}`, arrayOfPageTypeToSave)
			.then(() => {
				closeTheCreationForm(false);
			}
			).catch(e => {
				if (e) {
					setMessage('Un problème est survenu pendant la suppression du tag');
					setColor('alert-danger');
					setIsLoading(false);
					console.error(e);
					setTimeout(() => {
						setMessage();
					}, 2000);
				}
			});
	};

	return (
		<div className="container-pagetype-creation">
			{!isEditMode &&
                <>
                	<div>
                		<Btn
                			message="Edit mode"
                			icon="fas fa-exchange-alt"
                			onClickFunction={() => {
                				setIsEditMode(true); setValueForm({
                					tag: '',
                					label: ''
                				});
                			}}
                		/>
                	</div>
                	<h1>Creation of a new tag</h1>
                	<div className="pagetype-form">
                		{message &&
                            <Message
                            	msg={message}
                            	color={color}
                            />
                		}
                		<div className="input-goupe-pagetype">
                			<label htmlFor="label">Name</label>
                			<input
                				className="input-text"
                				name="label"
                				id="label"
                				type="text"
                				value={valueForm.label}
                				onChange={(e) => handleChange(e)}
                				placeholder="Tag name" />
                		</div>
                		<div className="input-goupe-pagetype">
                			<label htmlFor="value">Value</label>
                			<input
                				className="input-text"
                				name="tag"
                				id="tag"
                				type="text"
                				value={valueForm.tag}
                				onChange={(e) => handleChange(e)}
                				placeholder="Tag value" />
                		</div>

                	</div>
                	<div className="container-btn-pagetype-creation">
                		<div >
                			<Btn
                				message="Cancel"
                				color="warning"
                				onClickFunction={() => closeTheCreationForm(false)}
                			/>
                		</div>
                		{

                			<div >
                				<Btn
                					message="Validate"
                					disabled={btnCreationIsDesabled}
                					onClickFunction={
                						btnCreationIsDesabled ? () => console.log('') : (e) => {
                							e.preventDefault(); setSendForm(true);
                						}}
                				/>
                			</div>
                		}
                	</div>
                </>
			}
			{
				isEditMode &&
                <>
                	<Modal
                		isOpen={modalDeleteIsOppen}
                		onClose={() => setModalDeleteIsOppen(false)}
                		height="200"
                	>
                		<div className="center">
                			<h3>Do you want to delete this tag?</h3>
                		</div>
                		<div className="center ">
                			<div >
                				<Btn
                					message="Cancel"
                					color="alert"
                					onClickFunction={(e) => { e.preventDefault(); setModalDeleteIsOppen(false); }}
                				/>

                				<Btn
                					message="Delete"
                					onClickFunction={(e) => { e.preventDefault(); deleteATag(tagToDelete); }}
                				/>

                			</div>
                		</div>
                	</Modal>
                	<div >
                		<Btn
                			message="Create mode"
                			icon="fas fa-exchange-alt"
                			onClickFunction={() => {
                				setIsEditMode(false); setValueForm({
                					tag: '',
                					label: ''
                				}); setTagIsSelect();
                			}}
                		/>
                	</div>

                	<div className="pagetype-edition-modification-mode-container">
                		<h1>Modify an existing tag
                		</h1>
                		{!tagIsSelect && <>
                			<h3>Select the tag to modify</h3>
                			<ul>
                				{
                					listPageTypes.map((elem, i) => <li key={i}
                						onClick={() => { setTagIsSelect(elem); setTagToChange(elem); }}
                					> {elem.tag} - {elem.label}</li>)
                				}
                			</ul>
                		</>
                		}
                		{tagIsSelect && <>
                			<div className="pagetype-form">
                				{message &&
                                    <Message
                                    	msg={message}
                                    	color={color}
                                    />
                				}
                				<div
                					className="delete-pagetype"
                					title="Supprimer le tag"
                					onClick={() => { setModalDeleteIsOppen(true); setTagToDelete(valueForm); }}
                				>
                					<i className="fas fa-trash-alt"></i></div>
                				<div className="input-goupe-pagetype">
                					<label htmlFor="label">Name</label>
                					<input
                						className="input-text"
                						name="label"
                						id="label"
                						type="text"
                						value={valueForm.label}
                						onChange={(e) => handleChange(e)}
                						placeholder="Tag name" />
                				</div>
                			</div>
                			<div className="input-goupe-pagetype">
                				<label htmlFor="value">Value</label>
                				<input
                					className="input-text"
                					name="tag"
                					id="tag"
                					type="text"
                					value={valueForm.tag}
                					onChange={(e) => handleChange(e)}
                					placeholder="Tag value" />
                			</div>
                			<div className="container-btn-pagetype-creation">
                				<div >
                					<Btn
                						message="Validate"
                						color="warning"
                						onClickFunction={() => closeTheCreationForm(false)}
                					/>
                				</div>
                				{
                					<div >
                						<Btn
                							message="Valid"
                							disabled={btnCreationIsDesabled}
                							onClickFunction={
                								btnCreationIsDesabled ? () => console.log('') : (e) => {
                									e.preventDefault(); setSendForm(true);
                								}}
                						/>
                					</div>
                				}
                			</div>
                		</>
                		}
                	</div>
                </>
			}
			
			{
				isLoading &&
                <div className="spinner spinner_overlayed">
                	<div className="rect1"></div>
                	<div className="rect2"></div>
                	<div className="rect3"></div>
                	<div className="rect4"></div>
                	<div className="rect5"></div>
                </div>
			}
		</div>
	);
}
export default withRouter(PageTypeEditionContainer);