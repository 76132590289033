import Ajv from 'ajv';

const ajv = new Ajv({ allErrors: true });


export default function validate(data) {
	const schema =
    {
    	type: 'object',
    	properties: {
    		Name: { type: 'string' },
    		FriendlyName: { type: 'string' },
    		Editor: { type: 'object' }
    	},
    	required: ['Name', 'Editor', 'FriendlyName']
    };
	const test = ajv.compile(schema);
	let errors = [];
	if (data === null) {
		return;
	}

	Array.isArray(data) && data.map(d => {
		if (!test(d)) {
			errors.push(ajv.errorsText(test.errors));
		}
	}
	);
	return errors.length > 0 && errors;
}