import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../../Authentication/LoginButton';
import LogoutButton from '../../Authentication/LogoutButton';
import Profile from '../../Authentication/Profile';
import Btn from '../Btn';
import Dropdown from '../NewDropdown';
import {AppHeader, AppLogo, AppLinkWrapper} from 'ui-beyable';

import logo from '../../../Assets/beyable_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { actionUI } from '../../../store/ui-reducer';


export default function Header() {
	const { isAuthenticated, isLoading } = useAuth0();
	const dropDownIsOpen = useSelector(state => state.ui.dropDownIsOpen);
	const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
	const dispatch = useDispatch();
	const toggleDropDown = () => {
		dispatch(actionUI.toggle());
	};
	const closeDropDown = () => {
		dispatch(actionUI.toggleDropDownIsOpen(false));
	};
	const oppenDropDown = () => {
		dispatch(actionUI.toggleDropDownIsOpen(true));
	};

	return (
		<>
			{isAuthenticated &&
                <AppHeader
                	logo={
                		<AppLogo
                			name="Studio"
                			overlayLink={
                				<NavLink onClick={() => closeDropDown()} to="/Home" >Studio</NavLink>
                			}
                		/>
                	}
                	primaryLinks={
                		<>
                			{isBeyableAccount &&
                                <AppLinkWrapper>
                                	<NavLink onClick={() => closeDropDown()} exact to="/bespokes">SaaS Formats</NavLink>
                                </AppLinkWrapper>
                			}
                			{isBeyableAccount &&
                                <AppLinkWrapper>
                                	<NavLink onClick={() => closeDropDown()} exact to="/simplifyaAccountsList">Simplify</NavLink>
                                </AppLinkWrapper>
                			}
                		</>
                	}
                	secondaryLinks={
                		<>
                			<AppLinkWrapper isTab={false} theme="medium">
                				<a href="https://polite-moss-0dae7e710.1.azurestaticapps.net/" target='_blank' rel='noreferrer'>Documentation</a>
                			</AppLinkWrapper>
                			{!isLoading &&
                                <div>
                                	<Dropdown
                                		isOpen={dropDownIsOpen}
                                		onHide={() => closeDropDown()}
                                		setIsOpen={() => oppenDropDown()}
                                		button={<Btn
                                			messageToolTip={'New setup'}
                                			style="outline"
                                			icon="fas fa-plus"
                                			onClickFunction={() => { }}
                                		/>}
                                	>
                                		<ul className="listbox">
                                			<li className='listbox_item'>
                                				<NavLink onClick={() => toggleDropDown(!dropDownIsOpen)} className="" exact to="/creation">New SaaS Format</NavLink>
                                			</li>
                                			{isBeyableAccount &&
                                                <li className='listbox_item'>
                                                	<NavLink onClick={() => toggleDropDown(!dropDownIsOpen)} className="" exact to="/creationSimplifySetup">New Simplify Setup </NavLink>
                                                </li>
                                			}
                                			{isBeyableAccount &&
                                                <li className='listbox_item'>
                                                	<NavLink onClick={() => toggleDropDown(!dropDownIsOpen)} className="" exact to="/setupConfiguration">Check List </NavLink>
                                                </li>
                                			}
                                		</ul>
                                	</Dropdown>
                                	<Profile><LogoutButton /></Profile>
                                </div>
                			}
                		</>
                	}
                />
			}
		</>
	);
}

