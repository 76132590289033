import React, { useState } from 'react'
import './Bespoke.css'
import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import { format } from 'date-fns'
import { MdDeleteForever, MdClose } from 'react-icons/md'
import { IoMdWarning } from 'react-icons/io'
import ConfirmModal from '../Modale/ConfirmModal'
import { FaEyeSlash } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
export default function Bespoke({ data }) {
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
    const error = data.err && data.err[0].errors
    const [idChooseToDelete, setIdChooseToDelete] = useState()
    const [toggleModales, setToggleModales] = useState(false)
    const [toggleErrors, setToggleError] = useState(false)
    let envColor = null
    if (data.env === 'Dev') {
        envColor = 'colorEnvDev'
    }
    if (data.env === 'Prod') {
        envColor = 'colorEnvProd'
    }
    if (data.env === 'PreProd') {
        envColor = 'coloEnvPreProd'
    }

    const dateBegin = format(new Date(data.bespoke.creationDate), 'dd/MM/yyyy')
    const clickOnDelete = (e) => {
        setIdChooseToDelete(e)
        setToggleModales(!toggleModales)
    }
    const oppenCloseModale = (e) => {
        setToggleModales(!toggleModales)
    }
    const stopPropa = (e) => {
        e.stopPropagation()
    }
    const ChangetoggleErrors = () => {
        setToggleError(!toggleErrors)
    }

    const dataError = error && error.map((x, i) =>
        <div key={i} className='errors'>
            <div className='errors-close' onClick={ChangetoggleErrors}>
                <MdClose />
            </div>
            <p><strong>Identifier :</strong> {x.identifier}</p>
            <p><strong>Message :</strong> {x.message}</p>
        </div>
    )

    return (
        <>
            <div className="bespoke-item-img">
                <Link className="noLink"
                    to={{ pathname: `/bespokeEdition/${data.account.id}/${data.bespoke.bespokeId}/${data.env}/`, }}>
                    <img src={data.bespoke.thumbnailDisplayUrl ? data.bespoke.thumbnailDisplayUrl : 'https://via.placeholder.com/350'} alt="" />
                </Link>
            </div>
            <div className="bespoke-item-container">
                <div className="bespoke-item-detail">
                    {data.bespoke.publicationState === 1 ?
                        '' :
                        <span className="bespoke-item-visibility">
                            <FaEyeSlash />
                        </span>
                    }

                    <p className="bespoke-item-code">
                        {isBeyableAccount && <span className={`bespoke-item-env ${envColor}`}>{data.env}</span>}

                        <Link className="noLink"
                            to={{ pathname: `/bespokeEdition/${data.account.id}/${data.bespoke.bespokeId}/${data.env}/`, }}>
                            {data.bespoke.code}
                        </Link>
                    </p>
                    <p className="bespoke-item-account">@{data.account.name} <span className="bespoke-item-name">Displayed name : {data.bespoke.name}</span></p>
                    {data.bespoke.description ? <p className="bespoke-item-description">{data.bespoke.description}</p> : ''}
                    <p className="bespoke-item-key">{data.bespoke.bespokeId}</p>
                </div>
                <div className="bespoke-item-btn">
                    <button className="edit-bespoke">
                        <Link className="noLink"
                            to={{ pathname: `/bespokeEdition/${data.account.id}/${data.bespoke.bespokeId}/${data.env}/`, }}>
                            Edit <FiEdit />
                        </Link>
                    </button>
                    <button className="delete-bespoke" onClick={() => clickOnDelete(data.bespoke.bespokeId)} >
                        Delete <MdDeleteForever />
                    </button>
                </div>
                {error && error.length > 0 &&
                    <>
                        <div className="bespoke-item-errors" onClick={ChangetoggleErrors}>
                            <IoMdWarning />
                            <span className="numberErrors">
                                {error && error.length}
                            </span>
                        </div>
                    </>
                }

                <div className={toggleErrors ? 'errorsBespoke' : 'none'}>
                    {toggleErrors && dataError}
                </div>
                {toggleModales &&
                    <ConfirmModal
                        close={oppenCloseModale}
                        stopPropa={stopPropa}
                        idChooseToDelete={idChooseToDelete}
                        oppenCloseModale={oppenCloseModale}
                        id={data.bespoke.bespokeId}
                        env={data.env}
                        name={data.bespoke.name}
                    />}
            </div>
        </>
    )
}
