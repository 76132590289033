import React, { } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';

import BespokeAccountPropertiesItem from './BespokeAccountPropertiesItem ';

import SearchBarAccountProperty from './SearchBarAccountProperty';
export default function BespokeAccountPropertiesList() {

	const { valuesAccountProperties, valuesAccountPropertiesFiltered,
		valuesInBespokeProperties, propertyBespokeFiltered
	} = useSelector((state) => state.bespoke);
	const dispatch = useDispatch();
	let valuesFilterAccountProperties = valuesAccountPropertiesFiltered?.length > 0 ? valuesAccountPropertiesFiltered : valuesAccountProperties;
	const deleteTheKey = (reference, array) => {
		for (let i = 0; i < reference.length; i++) {
			let newReference = reference[i].propertyName;
			for (let z = 0; z < array.length; z++) {
				if (array[z].propertyName === newReference) {
					valuesFilterAccountProperties = valuesFilterAccountProperties.filter(x => x.propertyName !== newReference);
				}

			}
		}
		return valuesFilterAccountProperties;
	};

	deleteTheKey(valuesAccountProperties, valuesInBespokeProperties);

	return (
		<>
			<div className="properties_inner">
				<SearchBarAccountProperty />
				<ul className="properties_list">
					{valuesFilterAccountProperties && valuesFilterAccountProperties.map((x, index) =>
						<li className="list-group-item" key={index} >
							<BespokeAccountPropertiesItem
								key={index}
								data={x}
								onClick={() => dispatch(bespokeActions.addNewPropretiesToBespoke(x))}
							/>
						</li>)
					}
				</ul>
			</div>
		</>
	);
}
