import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import {AppRoot} from 'ui-beyable';
import Bespokes from './Components/Bespokes/Accueil/Bespokes';
import NotFound from './Components/Ui/NotFound';
import BespokeCreation from './Components/Bespokes/Creation/BespokeCreationContainer';
import BespokeEditionContainer from './Components/Bespokes/Edition/BespokeEditionContainer';

import HomeContainer from './Components/Home/HomeContainer';
import AccountApiContainer from './Components/AccountApi/AccountApiContainer';
import CampaignContainer from './Components/Campaign/CampaignContainer';
import ProtectedRoute from './Components/Authentication/protected-route';
import { useAuth0 } from '@auth0/auth0-react';

import ListAccount from './Components/SimplifySetup/ListSimplifyAccounts/ListAccounts';
import SetupSimplifyCreationContainer from './Components/SimplifySetup/Creation/SetupSimplifyCreationContainer';
import CheckListSetupHook from './Components/SetUp/CheckListSetupHook';
import simplifyEditionSetupContainer from './Components/SimplifySetup/Edition/SimplifyEditionSetupContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataSocket } from './store/socketActions';
import { actionUI } from './store/ui-reducer';
import Layout from './Components/layout/Layout';
import Home from './Components/PublicRoutes/Home/Home';
import Register from './Components/PublicRoutes/Signup/Register';
import CreationSetupSimplifyProvider from './Context/ContextCreationSetupSimplify';

import styles from './App.module.css';

// export function AddLibrary(urlOfTheLibrary) {
//   const script = document.createElement('script');
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }
function App() {
	//console.log(process.env, 'test');
	const [keyLocation, setkeyLocation] = useState();
	const socket = useSelector(state => state.socket.socket);

	const dispatch = useDispatch();
	const location = useLocation();
	const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

	const getTokenOfCurrentUser = async () => {
		const token = await getAccessTokenSilently();
		token && dispatch(fetchDataSocket(token));
	};

	useEffect(() => {
		if (location) {
			setkeyLocation(location.pathname);
		}
	}, []);

	useEffect(() => {
		if (keyLocation) {
			if (location && keyLocation !== location.pathname) {
				socket && socket.emit('leave-room');
			}
		}
	}, [location]);

	useEffect(() => {
		if (isAuthenticated) {
			getTokenOfCurrentUser();
			dispatch(actionUI.setIsAuthenticated(isAuthenticated));
		}
	}, [isAuthenticated]);

	return (
		<AppRoot>
			<div className={styles.app_root}>
				<div className={styles.app_header}>
					<Layout />
				</div>
				<div className={styles.app_body}>
					<CreationSetupSimplifyProvider>
						<Switch>
							<ProtectedRoute exact path="/" component={HomeContainer} user={user} restricted={false} />
							<ProtectedRoute exact path="/Home" component={HomeContainer} user={user} restricted={true} />
							<ProtectedRoute exact path="/bespokeEdition/:accountId/:bespokeId/:env/" component={BespokeEditionContainer} user={user} restricted={true} />
							<ProtectedRoute exact path="/bespokes" component={Bespokes} user={user} restricted={true} />
							<ProtectedRoute exact path="/accountApi/:env/:id/" component={AccountApiContainer} user={user} restricted={true} />

							<ProtectedRoute exact path="/creation" component={BespokeCreation} user={user} restricted={true} />

							<ProtectedRoute exact path="/creationSimplifySetup" component={SetupSimplifyCreationContainer} user={user} restricted={true} />
							<ProtectedRoute exact path="/setupConfiguration/:env/:id/:name" component={CheckListSetupHook} user={user} restricted={true} />
							<ProtectedRoute exact path="/simplifyaAccountsList" component={ListAccount} user={user} restricted={true} />
							<ProtectedRoute exact path="/simplifySetup/:accountId/:env/:isExistantSetup/:status" component={simplifyEditionSetupContainer} user={user} restricted={true} />

							<ProtectedRoute exact path="/bespokeEdition" component={BespokeEditionContainer} user={user} restricted={true} />
							<ProtectedRoute exact path="/campaign/:accountId/:env/:idCampaign" component={CampaignContainer} user={user} restricted={true} />
							<ProtectedRoute exact path="/public/home" component={Home} restricted={false} />
							<ProtectedRoute exact path="/public/register" component={Register} restricted={false} />

							<ProtectedRoute component={NotFound} restricted={false} />
						</Switch>
					</CreationSetupSimplifyProvider>
				</div>
			</div>
		</AppRoot>
	);
}

export default App;
