
import { actionUI } from './ui-reducer';
import { StatusAlertService } from 'react-status-alert';

export const fetchDataAccount = (authAxios, env, accountId) => {
	return async (dispatch) => {
		authAxios.get(`account/${env}/${accountId}`)
			.then(res => {
				// console.log("res", res.data);
				dispatch(actionUI.handleChangeAccountWorkingInformations(res.data));
			}
			)
			.catch(function (error) {
				console.log('error config', error.config);
				StatusAlertService.showError('An error occured');
			});
	};
};
