import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bespokeActions } from '../../../store/bespokeProperty-slice';
export default function SearchBarAccountProperty() {
	const dispatch = useDispatch();
	const { valuesAccountProperties

	} = useSelector((state) => state.bespoke);

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [noData, setNoData] = useState();
	// fonction de recherche dans le tableau
	const search = (table, word) => {
		if (word.length > 0) {
			dispatch(bespokeActions.handleValuesAccountPropertiesFiltered([]));
			let resultName = table.filter(
				line =>
					line.propertyName.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				resultName = 'No data found for this search';
				setNoData(resultName);
			}
			else {
				setNoData();
				dispatch(bespokeActions.handleValuesAccountPropertiesFiltered([...resultName]));
			}

		} else {
			dispatch(bespokeActions.handleValuesAccountPropertiesFiltered());
		}
	};
	const handleChange = (event) => {
		setValueInSearchBar(event.target.value);
		if (valuesAccountProperties) {
			search(valuesAccountProperties, event.target.value);
		}
	};

	return (
		<div className="properties_search">
			<input type="text"
				className="form-control col-md-12 p-2 m-auto"
				value={valueInSearchBar}
				placeholder="Find a property of account"
				onChange={handleChange}
			/>
		</div>
	);
}
