import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../utils/useAuthAxios'

import { Link } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi'
import { format } from 'date-fns'
import { MdDeleteForever, MdClose } from 'react-icons/md'
import { IoMdWarning } from 'react-icons/io'
import ConfirmModal from '../Modale/ConfirmModal'
import { FaEyeSlash } from "react-icons/fa"

export default function BespokeItemHome({ data,accountEnvErrors }) {
    const [idChooseToDelete, setIdChooseToDelete] = useState()
    const [toggleModales, setToggleModales] = useState(false)
    const [toggleErrors, setToggleError] = useState(false)
    const [errorArray, setErrorArray] = useState([])
    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios()
    const { loginWithRedirect } = useAuth0();
    useEffect(() => {
        if (accountEnvErrors) {
            const resultWithErrorsDev = accountEnvErrors.filter(y => y.bespokeId === data.item1.bespokeId)[0]
            setErrorArray(resultWithErrorsDev.errors)
        }
    }, [accountEnvErrors]);

    let envColor = null
    if (data && data.item3 === 'Dev') {
        envColor = 'colorEnvDev'
    }
    if (data && data.item3 === 'Prod') {
        envColor = 'colorEnvProd'
    }
    if (data && data.item3 === 'PreProd') {
        envColor = 'coloEnvPreProd'
    }

    const dateBegin = format(new Date(data && data.item1.creationDate), 'dd/MM/yyyy')
    const clickOnDelete = (e) => {
        setIdChooseToDelete(e)
        setToggleModales(!toggleModales)
    }
    const oppenCloseModale = (e) => {
        setToggleModales(!toggleModales)
    }
    const stopPropa = (e) => {
        e.stopPropagation()
    }
    const ChangetoggleErrors = () => {
        setToggleError(!toggleErrors)
    }

    const dataError = errorArray && errorArray.map((x, i) =>
        <div key={i} className='errors'>
            <div className='errors-close' onClick={ChangetoggleErrors}>
                <MdClose />
            </div>
            <p><strong>Identifier :</strong> {x.identifier}</p>
            <p><strong>Message :</strong> {x.message}</p>
        </div>
    )

    return (
        <>
            <div className="bespoke-item-img">
                <Link className="noLink"
                    to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                    <img src={data && data.item1.thumbnailDisplayUrl ? data.item1.thumbnailDisplayUrl : 'https://via.placeholder.com/350'} alt="" />
                </Link>
            </div>
            <div className="bespoke-item-container">
                <div className="bespoke-item-detail">
                    {data && data.item1.publicationState === 1 ?
                        '' :
                        <span className="bespoke-item-visibility">
                            <FaEyeSlash />
                        </span>
                    }

                    <p className="bespoke-item-code">
                        <span className={`bespoke-item-env ${envColor}`}>{data && data.item3}</span>

                        <Link className="noLink"
                            to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                            {data.item1.code}
                        </Link>
                    </p>
                    <p className="bespoke-item-account">@{data && data.item1.name} <span className="bespoke-item-name">Displayed name : {data && data.item1.name}</span></p>
                    {data && data.item1.description ? <p className="bespoke-item-description">{data && data.item1.description}</p> : ''}
                    <p className="bespoke-item-key">{data && data.item1.bespokeId}</p>
                </div>
                <div className="bespoke-item-btn">
                    <button className="edit-bespoke">
                        <Link className="noLink"
                            to={{ pathname: `/bespokeEdition/${data && data.item2.id}/${data && data.item1.bespokeId}/${data && data.item3}/`, }}>
                            Edit <FiEdit />
                        </Link>
                    </button>
                    <button className="delete-bespoke" onClick={() => clickOnDelete(data && data.item1.bespokeId)} >
                        Delete <MdDeleteForever />
                    </button>
                </div>
                {errorArray && errorArray.length > 0 &&
                    <>
                        <div className="bespoke-item-errors" onClick={ChangetoggleErrors}>
                            <IoMdWarning />
                            <span className="numberErrors">
                                {errorArray && errorArray.length}
                            </span>
                        </div>
                    </>
                }

                <div className={toggleErrors ? 'errorsBespoke' : 'none'}>
                    {toggleErrors && dataError}
                </div>
                {toggleModales &&
                    <ConfirmModal
                        close={oppenCloseModale}
                        stopPropa={stopPropa}
                        idChooseToDelete={idChooseToDelete}
                        oppenCloseModale={oppenCloseModale}
                        id={data.item1.bespokeId}
                        env={data.item3}
                        name={data.item1.name}
                    />}
            </div>
        </>
    )
}
