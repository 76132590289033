import { createSlice } from '@reduxjs/toolkit';
import { BespokeThunk } from './services/bespokeThunk';

const initialState = {
	// property
	valuesInBespokeProperties: [],
	propertiesBespokeLiquid: [],
	propertyBespokeFiltered: [],
	valuesAccountPropertiesFiltered: [],
	valuesBespokeDescription: {},
	selectedOptionCss: '',
	mobileVersionEnabled: true,
	seeConfiguration: false,
	seeUploadPicture: false,
	seeSearchProperty: false,
	seeSearchPropertyAccount: false,
	campaignMode: false,
	isCliked: false,
	isNewBespoke: true,
	publicationState: 0,
	valuesUrlUpload: '',
	isDeleteBespoke: false,
	pictureUrlCopy: '',
	valuesAccountProperties: [],
	idAccountOfBespokeToCopy: '',
	// creation
	env: 'choose',
	accountName: '',
	isCopy: false,
	valuesAccountPropertiesCopy: '',
	selectedOption: '',
	selectedCreationOptionCss: true,
	accountSelect: '',
	formIsNotEpty: false,
	valuesUrl: [],
	newDataBespokeCopy: {
		TargetAccountId: '',
		SourceBespokeId: '',
		SourceEnvironment: '',
	},
	// BespokeTemplateContextProvider
	desktopTemplate: {
		contentTemplate: ',',
		contentTemplateType: 2,
		css: '',
		javascript: ''
	},
	mobileTemplate: {
		contentTemplate: ',',
		contentTemplateType: 2,
		css: '',
		javascript: ''
	},
	wysiwygValues: {
		desktopTemplate: {
			template: '',
			templateType: 2
		},
		mobileTemplate: {
			template: '',
			templateType: 2
		}
	},
	JsonIsValid: true,
	isLiquid: false,
	pannelIsOpen: false
};
const bespokeSlice = createSlice({
	name: 'bespoke',
	initialState: initialState,

	reducers: {
		intiData(state, action) {
			state.data = action.payload;
		},
		JsonIsValidHandler(state, action) {
			state.JsonIsValid = action.payload;
		},
		handlePanelIsOpen(state, action) {
			state.pannelIsOpen = action.payload;
		},
		// property
		ascBespokeProperty(state, action) {
			let { a, b } = action.payload;
			if (a.propertyName && b.propertyName) {
				if (a.propertyName.toUpperCase() < b.propertyName.toUpperCase())
					return -1;
				if (a.propertyName.toUpperCase() > b.propertyName.toUpperCase())
					return 1;
			}
			return 0;
		},

		// mettre à jours les propriétés d'un bespoke avec les propriétés d'un compte

		addNewPropretiesToBespoke(state, action) {
			const ascBespokeProperty = (a, b) => {
				if (a.propertyName && b.propertyName) {
					if (a.propertyName.toUpperCase() < b.propertyName.toUpperCase())
						return -1;
					if (a.propertyName.toUpperCase() > b.propertyName.toUpperCase())
						return 1;
				}
				return 0;
			};
			const nexVal = {
				propertyName: `${action.payload.knownValues[0].propertyName}`,
				defaultValue: '',
				editorType: `${action.payload.knownValues[0].editorType}`,
				description: `${action.payload.knownValues[0].decription}`,
				knownValues: [...action.payload.knownValues],
			};

			const tab = state.valuesInBespokeProperties;
			tab.push(nexVal);
			tab && tab.sort(ascBespokeProperty);
			state.valuesInBespokeProperties = [...tab];
			state.valuesAccountPropertiesFiltered = [];
			state.seeSearchPropertyAccount = false;
		},

		addNewPropretiesToBespokeInput(state, action) {
			const nexVal = {
				propertyName: `${action.payload.propertyName}`,
				defaultValue: `${action.payload.defaultValue}`,
				editorType: `${action.payload.editorType}`,
				description: `${action.payload.description}`,
			};

			const tab = state.valuesInBespokeProperties;
			tab.unshift(nexVal);
			state.valuesInBespokeProperties = [...tab];
			state.isCliked = !state.isCliked;
		},

		addNewPropretiesToBespokeLiquidInput(state, action) {
			if (state.propertiesBespokeLiquid) {
				let prevState = [...state.propertiesBespokeLiquid];
				prevState = action.payload;
				state.propertiesBespokeLiquid = prevState;
			} else {
				let array = [];
				array.push(action.payload);
				state.propertiesBespokeLiquid = array;
			}

			state.isCliked = !state.isCliked;
		},
		deleteProp(state, action) {
			state.valuesInBespokeProperties = state.valuesInBespokeProperties.filter(
				(element) => element.propertyName !== action.payload.propertyName
			);
			state.propertyBespokeFiltered = [];
			state.seeSearchProperty = false;
		},

		deletePropLiquid(state, action) {
			let array = state.propertiesBespokeLiquid;
			array = array.filter((element) => element.Name !== action.payload.Name);
			state.propertiesBespokeLiquid = array;
			state.propertyBespokeFiltered = [];
			state.seeSearchProperty = false;
		},
		modifyBespokeProperties(state, action) {
			let { index, e } = action.payload;
			if (state.propertyBespokeFiltered?.length > 0) {
				const element = state.propertyBespokeFiltered[index];
				element.defaultValue = e.target.value;
				const arrayBeforeModify =
                    state.valuesInBespokeProperties.filter(
                    	(x) => x.propertyName !== element.propertyName
                    );
				arrayBeforeModify.push(element);
				state.valuesInBespokeProperties = [...arrayBeforeModify];
			} else {
				const element = state.valuesInBespokeProperties[index];
				element.defaultValue = e.target.value;
				const arrayBeforeModify = state.valuesInBespokeProperties;
				arrayBeforeModify.splice(index, 1, element);
				state.valuesInBespokeProperties = [...arrayBeforeModify];
			}
		},

		modifyBespokeLiquidProperties(state, action) {
			let { index, e } = action.payload;
			const ascBespokeProperty = (a, b) => {
				if (a.propertyName && b.propertyName) {
					if (a.propertyName.toUpperCase() < b.propertyName.toUpperCase())
						return -1;
					if (a.propertyName.toUpperCase() > b.propertyName.toUpperCase())
						return 1;
				}
				return 0;
			};
			if (state.propertyBespokeFiltered) {
				const element = state.propertyBespokeFiltered[index];
				element.defaultValue = e.target.value;
				const arrayBeforeModify = state.propertiesBespokeLiquid.filter(
					(x) => x.propertyName !== element.propertyName
				);
				arrayBeforeModify.push(element);
				arrayBeforeModify &&
                    arrayBeforeModify.sort(ascBespokeProperty);
				state.propertiesBespokeLiquid = [...arrayBeforeModify];
			} else {
				const element = state.propertiesBespokeLiquid[index];
				element.Value = e.target.value;
				const arrayBeforeModify = state.propertiesBespokeLiquid;
				arrayBeforeModify.splice(index, 1, element);
				state.propertiesBespokeLiquid = [...arrayBeforeModify];
			}
		},
		//base name : ModifyvaluesBespokeDescription
		modifyvaluesBespokeDescription(state, action) {
			state.valuesBespokeDescription = {
				...state.valuesBespokeDescription,
				[action.payload.target.name]: action.payload.target.value,
			};
		},
		addRandomV(state, action) {
			state.valuesBespokeDescription = {
				...state.valuesBespokeDescription,
				[action.payload.name]: action.payload.value,
			};
		},

		handleFilter(state, action) {
			const value = action.payload.target.value;
			state.selectedOptionCss = value;

			state.valuesBespokeDescription = {
				...state.valuesBespokeDescription
				,
				customCssEnabled: value,
			};
		},

		handleFilterMobile(state, action) {
			let value;
			if ('checked' in action.payload.target) {
				value = action.payload.target.checked;
			} else {
				value = action.payload.target.value;
			}
			state.mobileVersionEnabled = value;
			state.valuesBespokeDescription = {
				...state.valuesBespokeDescription,
				mobileVersionEnabled: value,
			};
		},
		handleStopMessageCopy(state, action) {
			state.pictureUrlCopy = '';
		},
		handleCopyPictureUrl(state, action) {
			state.pictureUrlCopy = 'Copy';
			navigator.clipboard.writeText(action.payload);
		},
		// prev name setIsNewBespoke
		handleIsNewBespoke(state, action) {
			state.isNewBespoke = action.payload;
		},
		// setValuesInBespokeProperties
		handleValuesInBespokeProperties(state, action) {
			state.valuesInBespokeProperties = action.payload;
		},

		handleValuesUrlUpload(state, action) {
			state.valuesUrlUpload = action.payload;
		},
		handleStopictureUrlCopy(state, action) {
			state.pictureUrlCopy = '';
		},
		//prev Name setPropertiesBespokeLiquid
		handlePropertiesBespokeLiquid(state, action) {
			state.propertiesBespokeLiquid = action.payload;
		},

		handleidAccountOfBespokeToCopy(state, action) {
			state.idAccountOfBespokeToCopy = action.payload;
		},

		handleCampaignMode(state, action) {
			state.campaignMode = action.payload;
		},
		handleValuesAccountPropertiesFiltered(state, action) {
			state.valuesAccountPropertiesFiltered = action.payload;
		},

		handlePropertyBespokeFiltered(state, action) {
			state.propertyBespokeFiltered = action.payload;
		},

		handleChangePublicationState(state, action) {
			state.publicationState = !state.publicationState;
			let conversion = 0;
			if (state.publicationState === true) {
				conversion = 1;
			}
			if (state.publicationState === false) {
				conversion = 0;
			}
			state.valuesBespokeDescription = {
				...state.valuesBespokeDescription,
				publicationState: conversion,
			};
		},
		handleValuesBespokeDescription(state, action) {
			state.valuesBespokeDescription = { ...state.valuesBespokeDescription, ...action.payload };
		},

		handleSelectedOptionCss(state, action) {
			state.selectedOptionCss = action.payload;
		},
		handleMobileVersionEnabled(state, action) {
			state.mobileVersionEnabled = action.payload;
		},

		setPublicationState(state, action) {
			state.publicationState = action.payload;
		},
		handleIsDeleteBespoke(state, action) {
			state.isDeleteBespoke = action.payload;
		},

		// récupération des propriétés d'un bespoke
		handleValuesBespokeProperties(state, action) {
			state.valuesInBespokeProperties = action.payload;
		},

		// récupération des propriétés d'un compte
		handleValuesAccountProperties(state, action) {
			state.valuesAccountProperties = action.payload;
		},
		toggleConfiguration(state, action) {
			state.seeConfiguration = action.payload;
		},

		toggleUploadPicture(state, action) {
			state.seeUploadPicture = action.payload;
		},

		toggleSearchProperty(state) {
			state.seeSearchProperty = !state.seeSearchProperty;
		},

		toggleSearchPropertyAccount(state) {
			state.seeSearchPropertyAccount = !state.seeSearchPropertyAccount;
		},
		// base name clicked
		toggleClicked(state) {
			state.isCliked = !state.isCliked;
		},

		// creation
		// prev name : ModifyvaluesBespokeDescription
		handleFilterFormat(state, action) {
			const value = action.payload.target.value;
			state.selectedOption = value;
		},
		filterCssCustom(state, action) {
			const value = action.payload.target.value;
			state.selectedCreationOptionCss = value;
		},
		handleFilterAccount(state, action) {
			const arrayOfValues =
                action.payload && action.payload.target.value.split(',');
			const val = action.payload && arrayOfValues[0];
			state.accountSelect = val;
			state.accountName = action.payload && arrayOfValues[1];
		},
		//prev name  = fetchValuesAccountPropertiesCopy
		handleValuesAccountPropertiesCopy(state, action) {
			state.valuesAccountPropertiesCopy = action.payload;
		},
		// prev name : setEnv
		handleEnv(state, action) {
			state.env = action.payload;
		},
		handleFilterEnv(state, action) {
			state.env = action.payload.target.value;
		},
		// prevName setIsCopy
		handleIsCopy(state, action) {
			state.isCopy = action.payload;
		},
		//prevName SetValuesUrl
		handleValuesUrl(state, action) {
			state.valuesUrl = action.payload;
		},
		// prevName setNewDataBespokeCopy
		handleNewDataBespokeCopy(state, action) {
			state.newDataBespokeCopy = {
				...state.newDataBespokeCopy, ...action.payload
			};

			// let key = Object.keys(object)
			// state.newDataBespokeCopy = {...state.newDataBespokeCopy, key : object[key]}
		},
		handleDesktopTemplate(state, action) {

			state.desktopTemplate = action.payload;
		},

		// BespokeTemplateContextProvider
		// prevName: setDesktopTemplate
		handleDesktopTemplateType(state, action) {
			state.desktopTemplate.contentTemplateType = parseInt(action.payload);
		},
		handleChangeTemplateTypeAllDevices(state, action) {
			state.desktopTemplate.contentTemplateType = parseInt(action.payload);
			state.mobileTemplate.contentTemplateType = parseInt(action.payload);
		},

		handleWysiwygValues(state, action) {

			state.wysiwygValues = action.payload;

		},
		handleWysiwygValuesTypeAllDevices(state, action) {
			state.wysiwygValues.desktopTemplate.templateType = action.payload;
			state.wysiwygValues.mobileTemplate.templateType = action.payload;
		},
		// prevName: setWysiwygValues
		handleWysiwygValuesType(state, action) {

			state.wysiwygValues[action.payload.key] = {
				...state.wysiwygValues[action.payload.key],
				templateType: action.payload.value
			};
		},
		handleMobileTemplateType(state, action) {
			state.mobileTemplate.contentTemplateType = parseInt(action.payload);
		},
		// prevName: setMobileTemplate
		handleMobileTemplate(state, action) {
			state.mobileTemplate = action.payload;
		},
		handleEditorChangeCssDesktop(state, action) {
			state.desktopTemplate = {
				...state.desktopTemplate,
				css: action.payload,
			};
		},
		handleEditorChangeTemplateDesktop(state, action) {
			state.desktopTemplate = {
				...state.desktopTemplate,
				contentTemplate: action.payload,
			};
		},
		handleEditorChangeJsDesktop(state, action) {
			state.desktopTemplate = {
				...state.desktopTemplate,
				javascript: action.payload,
			};
		},
		handleEditorChangeCssMobile(state, action) {
			state.mobileTemplate = {
				...state.mobileTemplate,
				css: action.payload,
			};
		},
		handleEditorChangeTemplateMobile(state, action) {
			state.mobileTemplate = {
				...state.mobileTemplate,
				contentTemplate: action.payload,
			};
		},
		handleEditorChangeJsMobile(state, action) {
			state.mobileTemplate = {
				...state.mobileTemplate,
				javascript: action.payload,
			};
		},
		handleEditorChangeWysiwygValuesMobile(state, action) {
			let templatWysiwygToChange = { ...state.wysiwygValues };
			templatWysiwygToChange.mobileTemplate.template = action.payload;
			state.wysiwygValues = templatWysiwygToChange;
		},
		handleEditorChangeWysiwygValuesDesktop(state, action) {
			let templatWysiwygToChange = { ...state.wysiwygValues };
			templatWysiwygToChange.desktopTemplate.template = action.payload;
			state.wysiwygValues = templatWysiwygToChange;
		},
		initSelectionBespoke(state, action) {
			state.isNewBespoke = false;
			state.isLiquid = true;
			state.propertyBespokeFiltered = [];
			state.valuesAccountPropertiesFiltered = [];
			state.valuesAccountProperties = [];
			state.valuesInBespokeProperties = [];
			state.propertiesBespokeLiquid = [];
			// state.newBespokeValues = [];
			state.valuesBespokeDescription = {};
			state.valuesInBespokeProperties = [];
		},
		initCreationBespoke(state, action) {
			state.env = 'choose';
			state.isNewBespoke = true;
			state.isLiquid = true;
			state.valuesInBespokeProperties = [];
			state.propertiesBespokeLiquid = [];
			state.valuesUrl = '';
			state.isDeleteBespoke = false;
			state.propertyBespokeFiltered = [];
			state.valuesAccountPropertiesFiltered = [];
			state.accountName = '';
			state.accountSelect = '';
			state.formIsNotEpty = false;
			bespokeSlice.actions.toggleSearchProperty();
			bespokeSlice.actions.toggleSearchPropertyAccount();

		},
		initCreationBespokeNonAdmin(state, action) {
			state.env = 'Prod';
			state.isNewBespoke = true;
			state.isLiquid = true;
			state.valuesInBespokeProperties = [];
			state.propertiesBespokeLiquid = [];
			state.valuesUrl = '';
			state.isDeleteBespoke = false;
			state.propertyBespokeFiltered = [];
			state.valuesAccountPropertiesFiltered = [];
			state.accountName = '';
			state.accountSelect = '';
			state.formIsNotEpty = false;
			bespokeSlice.actions.toggleSearchProperty();
			bespokeSlice.actions.toggleSearchPropertyAccount();

		},
		//not use
		initCreation(state, action) {
			state.env = 'choose';
			state.isNewBespoke = true;
			state.valuesInBespokeProperties = [];
			state.propertiesBespokeLiquid = [];
			state.isLiquid = true;
			state.valuesUrl = '';
			state.isDeleteBespoke = false;
			state.propertyBespokeFiltered = [];
			state.valuesAccountPropertiesFiltered = [];

		},
		onFilterChange(state, action) {
			bespokeActions.handleFilterAccount();
			state.propertiesBespokeLiquid = [];
		},
		handleAccountList(state, action) {
			bespokeSlice.actions.initCreationBespoke();
			bespokeSlice.actions.toggleSearchProperty();
			bespokeSlice.actions.toggleSearchPropertyAccount();
		},
		handleChangeFormIsNotEmpty(state, action) {
			state.formIsNotEpty = action.payload;
		},
		updateBespokeTemplate(state, action) {
			state.desktopTemplate =
                action.payload.activationTemplateModel.desktopTemplate;
			state.mobileTemplate =
                action.payload.activationTemplateModel.mobileTemplate;
			state.wysiwygValues = action.payload.wysiwyg;

		},
		handleIsLiquid(state, action) {
			state.isLiquid = action.payload;
		},

	},
	extraReducers: {
		[BespokeThunk.getBespokesList.fulfilled]: async (state, action) => {
			const result = await action.payload;

		},
		[BespokeThunk.getBespokesListWithInactive.fulfilled]: (
			state,
			action
		) => {
			const result = action.payload;
			state.isDeleteBespoke = false;

		},
		[BespokeThunk.getBespokesStatusByEnv.fulfilled]: (state, action) => {
			const result = action.payload;
			return result;
		},
		// impossible de recuperer la valeur dans le state accounts bespokecreationcontainer
		// [BespokeThunk.getAccountList.fulfilled]: (state, action) => {
		//     let resultSort = action.payload;
		//     resultSort && resultSort.sort(insensible_a_la_casse);
		//     bespokeSlice.actions.initCreationBespoke();
		//     bespokeSlice.actions.toggleSearchProperty();
		//     bespokeSlice.actions.toggleSearchPropertyAccount();
		//     return resultSort;
		// },
		[BespokeThunk.getBespokePropertyById.fulfilled]: (state, action) => {
			let finalArray = action.payload;

			finalArray.forEach((element) => {
				element.defaultValue =
                    element.knownValues[0].defaultValue.toString();
				delete element.defaultValues;
			});
			bespokeActions.handleValuesBespokeProperties(finalArray);
			// setCopyReady(true);

			// return result;
		},
		//creation
		[BespokeThunk.getAccountPropertiesCopy.fulfilled]: (state, action) => {
			const finalArray = action.payload;

			finalArray.forEach((element) => {
				element.defaultValue =
                    element.knownValues[0].defaultValue.toString();
			});
			// state.valuesAccountPropertiesCopy = finalArray
			state.valuesAccountProperties = finalArray;
		},
		[BespokeThunk.updateBespokeByEnv.fulfilled]: (state, action) => {
			const result = action.payload.data;
			state.desktopTemplate =
                result.activationTemplateModel.desktopTemplate;
			state.mobileTemplate =
                result.activationTemplateModel.mobileTemplate;
			state.wysiwygValues = result.wysiwyg;

		},
	},
});

export const bespokeActions = bespokeSlice.actions;

export default bespokeSlice;

//utils

