import { createSlice } from '@reduxjs/toolkit';
import { CampaignThunk } from './services/campaignThunk';

const initialState = {
	campaignSelected: {
		startDate: '',
		endDate: ''
	},
	isReloading: true,
	panelCampaignIsOpen: false,
	statusCampaign: '',
	modalIpsIsOpen: false,
	isEmptyState: true,
	originalCampaign: {},
	isSave: false
};
const campaignSlice = createSlice({
	name: 'campaign',
	initialState: initialState,
	reducers: {
		isReloading(state, action) {
			state.isReloading = action.payload;
		},
		openClosePanelCampaign(state, action) {
			state.panelCampaignIsOpen = action.payload;
		},
		refreshCampaignSelected(state, action) {
			state.campaignSelected = action.payload;
		},
		openCloseModalIps(state, action) {

			state.modalIpsIsOpen = action.payload;
		},
		handleChangeEmptyState(state, action) {
			state.isEmptyState = action.payload;
		},
		handleChangeOriginalCampaign(state, action) {
			state.originalCampaign = JSON.stringify(action.payload);
		},
		handleIsSave(state, action) {
			state.isSave = action.payload;
		},

	},
	extraReducers: {

		[CampaignThunk.PutCampaignStatus.fulfilled]: (
			state,
			action
		) => {
			const result = action.payload;
			state.statusCampaign = result;
		},

	},
});

export const campaignActions = campaignSlice.actions;

export default campaignSlice;