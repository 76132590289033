import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	panelBespokeIsOpen: false,
	isReloading: true,
	arrayOfBespokeOfAccount: [],
};
const createBespokeSlice = createSlice({
	name: 'createBespoke',
	initialState: initialState,
	reducers: {
		setIsReLoading(state, action) {
			state.isReloading = action.payload;
		},
		openClosePanelBespoke(state, action) {
			state.panelBespokeIsOpen = action.payload;
		},
		setArrayOfBespokeOfAccount(state, action) {
			state.arrayOfBespokeOfAccount = action.payload;
		},
	}
});

export const createBespoke = createBespokeSlice.actions;

export default createBespokeSlice;