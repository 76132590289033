import React from 'react';
import classes from './Status.module.css';
import { FaCircle } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import RestartCampaign from '../Campaign/Edition/RestartCampaign';

export default function Status({ onClickFunction = null, status = '', messageToolTip, campaignService, reloadCampaignList }) {

	const idTool = uuidv4();

	return (<>
		<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
		<RestartCampaign
			campaignService={campaignService}
			status={status}
			reloadCampaignList={reloadCampaignList}
			btn={<button
				data-for={idTool} data-tip={messageToolTip}
				onClick={(e) => onClickFunction(e)} className={`btnUi ${classes.status} ${classes[status]}`}>
				<IconContext.Provider value={{ color: '#5D606F', size: '13px', className: `${classes.icon}` }}>
					<FaCircle />
				</IconContext.Provider>
				{status &&
                    <span className="btn_txt">{status}</span>
				}
			</button>}
		/>
	</>
	);
}
