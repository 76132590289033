import React from 'react'
import './Switch.css'
export default function Switch({
    children,
    name,
    value,
    checked,
    onChange,
    disabled = false,
    forcedChecked,
    position = 'left',
    size = 'm',
    align = 'top'
}) {

    const change = (ev) => {
        if (typeof onChange === 'function') {
            onChange(ev);
        }
    };

    const labelClass = ['cb_label'];
    const switchClass = ['cb_label_switch', 'flex_item_fix'];

    if (align == 'top') {
        switchClass.push('flex_item_align_start');
    }

    if (position == 'right') {
        labelClass.push('flex');
    } else {
        labelClass.push('flex_inline');
    }

    if (disabled) {
        labelClass.push('disabled');
    }

    if (size) {
        switchClass.push(size);
    }

    const input = (
        <>
            {forcedChecked !== undefined ?

                <input type="checkbox"
                    name={name}
                    value={value}
                    onChange={(ev) => change(ev)}
                    checked={forcedChecked ? true : false}
                    disabled={disabled}
                    readOnly={true}
                />
                :
                <input type="checkbox"
                    name={name}
                    value={value}
                    onChange={(ev) => change(ev)}
                    defaultChecked={checked ? true : false}
                    disabled={disabled}
                />
            }
        </>
    );

    return (
        <label className={labelClass.join(' ')} onClick={(e) => e.stopPropagation()}>
            {position == 'right' && children &&
                <span className="cb_label_text flex_item_full mr_10">{children}</span>
            }
            {input}
            <span className={switchClass.join(' ')}></span>
            {position == 'left' && children &&
                <span className="cb_label_text flex_item_auto">{children}</span>
            }
        </label>
    )
}
