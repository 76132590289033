import React, {useRef,useState,useEffect,useContext} from 'react';
import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';

export default function ScreenShotUploadContainer({device}) {
	const [thumb, setThumb] = useState();
	const [file, setFile] = useState();
	const {
		screenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected,
		panelTypeIsOpen
	} = useContext(creationSetupSimplify);
	const fileInput = useRef(null);
    
	const [screen, setscreen] = useState();
	useEffect(() => {
		const screenToLoad = screenShotToChange.find(screenShot => screenShot.device === device);
		setscreen(screenToLoad);
		setFile();
	}, [screenShotToChange, deviceSelected, screenShotId,panelTypeIsOpen]);

	const handleChangeInput = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		if (!file) {
			return;
		}
		reader.onloadend = () => {
			setThumb(reader.result);
			setFile(file);
		};
		reader.readAsDataURL(file);
	};
	const changeThumNailDataToPut = () => {
		let dataToPutToMaj = [...screenShotToChange];
		let newData = dataToPutToMaj.find(x => x.device === device);

		newData.previewImgPath = thumb;
		newData.imagePath = file;
		let finalData = [...dataToPutToMaj.filter(x => x.device !== device), newData];
		setscreenShotToChange(finalData);
	};
	useEffect(() => {
		if (file) {
			changeThumNailDataToPut();
		}
	}, [file]);
	return (
		<div className="flex flex_justify_between mr_30 ml_30 mb_15" >
			<div style={{width : '35%'}}>
				<img src={thumb ? thumb : screen ?
					typeof(screen.imagePath) !== 'string' ? screen.previewImgPath: screen.imagePath : 'https://via.placeholder.com/150x150'} alt="Thumbnail" 
				style={{maxHeight : '200px', maxWidth : '200px'}}
				/>
			</div>
			<input id={device} ref={fileInput} type="file" name="file" onChange={(e) => handleChangeInput(e)} />
			<div className=""><h2>{device} screenshot</h2> </div>
			<label className="btn-style"  htmlFor={device}>Replace</label>
		</div>
	
	);
}
