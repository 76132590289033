import { useState, useEffect } from 'react';

export default function useSearchBespokePanel(data) {
	const [search, setSearch] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	useEffect(() => {
		setDataFiltered(data);
	}, [data]);
	const handleChangeSearchBar = (e) => {
		setSearch(newVal => e);
		handleSearch(data, e);
	};

	// fonction de recherche dans le tableau
	const handleSearch = (table, word) => {
		if (word.length > 0) {
			let resultName = table.filter(
				line =>
					line.item1.name !== null && line.item1.name.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let resultDesc = table.filter(
				line =>
					line.item1.description !== null && line.item1.description.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let resultId = table.filter(
				line =>
					line.item1.bespokeId !== null && line.item1.bespokeId.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let code = table.filter(
				line =>
					line.item1.code !== null && line.item1.code.toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
			);
			let dataFinal = [
				...resultName,
				...resultDesc,
				...resultId,
				...code
			];
			let uniq = [...new Set(dataFinal)];
			setDataFiltered(uniq);
			if (dataFinal.length === 0) {
				let result = 'Il n\'y a pas de résultats pour cette recherche';
				setNoData(result);
			}

			setNoData();

		} else {
			setDataFiltered(data);
		}
	};
	return { noData, dataFiltered, search, handleChangeSearchBar };
}
