import React, { } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from '../../../store/bespokeProperty-slice';
import BespokePropertiesItem from './BespokePropertiesItem';
import SearchBarPropertyBespoke from './SearchBarPropertyBespoke';

export default function BespokePropertiesList() {

	const {
		valuesInBespokeProperties, propertyBespokeFiltered
	} = useSelector((state) => state.bespoke);
	const dispatch = useDispatch();
	return (
		<>
			<div className="properties_inner">
				<SearchBarPropertyBespoke />
				<ul className="properties_list">

					{valuesInBespokeProperties && propertyBespokeFiltered?.length > 0 ? propertyBespokeFiltered.map((x, index) =>
						<li className="list-group-item" key={index}>
							<BespokePropertiesItem
								key={index}
								data={x}
								onClick={() => dispatch(bespokeActions.deleteProp(x))}
								onChange={(e) =>
									dispatch(bespokeActions.modifyBespokeProperties({
										index: index,
										e: e
									}))
								}
							/>
						</li>)
						:
						valuesInBespokeProperties.map((x, index) =>
							<li className="list-group-item" key={index}>
								<BespokePropertiesItem
									key={index}
									data={x}
									onClick={() => dispatch(bespokeActions.deleteProp(x))}
									onChange={(e) => dispatch(bespokeActions.modifyBespokeProperties({
										index: index,
										e: e
									}))}
								/>
							</li>)
					}
				</ul>
			</div>
		</>
	);
}
