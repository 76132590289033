
import { StatusAlertService } from 'react-status-alert';

export default function useAlert() {

	function createAlerte(type, message, params) {
		if (type === 'error') {
			StatusAlertService.showError(message, params);
		} else {
			StatusAlertService.showSuccess(message, params);
		}
	}

	return { createAlerte };
}
