import React, { useState, useEffect, Fragment } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../../utils/useAuthAxios';
import BespokeItem from '../../Bespokes/BespokeItem';
import './Bespokes.css';

import filterList from './filterList';
import { format } from 'date-fns';
import Btn from '../../Ui/Btn';
import BespokeService from '../../../Services/BespokeService';
import useAlert from '../../../Hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import { bespokeActions } from "../../../store/bespokeProperty-slice";
import { bespokeFormActions } from "../../../store/bespokeForm-slice";
// import matchSorter from 'match-sorter';
export default function Bespokes() {
    const dispatch = useDispatch()
    const [DataBespokes, setDataBespokes] = useState()
    const [selectedOption, setSelectedOption] = useState('All')
    const [DataFiltered, setDataFiltered] = useState()
    const [DataFilteredByEnv, setDataFilteredByEnv] = useState()
    const [searchBarValue, setSearchBarValue] = useState('')
    const [noData, setNoData] = useState()
    const [dataTrash, setDataTrash] = useState()
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [fetchMonitoring, setFetchMonitoring] = useState(false)

    const { isAuthenticated } = useAuth0()
    const { authAxios } = useAuthAxios()
    const bespokeService = new BespokeService(authAxios)
    const { loginWithRedirect } = useAuth0();
    const { createAlerte } = useAlert();
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
    // get bespoke list 
    useEffect(() => {

        dispatch(bespokeActions.initSelectionBespoke())
        dispatch(bespokeFormActions.initSelectionBespoke())
        const fetchData = async () => {
            // const result = await authAxios.get("bespoke")
            await bespokeService.getBespokesListWithInactive(
                (resultTrash) => {
                    const resultTrashFiltered = resultTrash.data.filter(y => y.bespoke.isActive === false)
                    const resultDev = resultTrash.data.filter(y => y.bespoke.isActive !== false).sort()
                    setDataBespokes([...resultDev])
                    setSelectedOption('Prod')
                    setDataTrash(resultTrashFiltered)
                    dispatch(bespokeActions.handleIsDeleteBespoke(false))
                    setIsButtonDisabled(false)
                    setFetchMonitoring(true)
                }, (err) => {
                    createAlerte('error', 'An error occured', { removeAllBeforeShow: false, })
                }

            );
        }

        if (isAuthenticated) {
            const timer = setTimeout(() => {
                fetchData()
            }, 700)
            return () => {
                clearTimeout(timer);
            }
        }
    }, [isAuthenticated]);

    // adding error to bespoke data 
    useEffect(() => {
        const fetchDataMonitoring = async () => {
            const monitoringDev = await bespokeService.getBespokesStatusByEnv("dev", (err) => createAlerte('error', 'An error occured', { removeAllBeforeShow: false, }));
            const monitoringProd = await bespokeService.getBespokesStatusByEnv("prod", (err) => createAlerte('error', 'An error occured', { removeAllBeforeShow: false, }));
            const monitoringPreProd = await bespokeService.getBespokesStatusByEnv("preprod", (err) => createAlerte('error', 'An error occured', { removeAllBeforeShow: false, }));
            const resultWithErrorsDev = DataBespokes.filter(y => y.env === 'Dev').map(x =>
                ({ ...x, err: monitoringDev?.data.filter(r => r.bespokeId === x.bespoke.bespokeId) })
            );
            const resultWithErrorsProd = DataBespokes.filter(y => y.env === 'Prod').map(x =>
                ({ ...x, err: monitoringProd?.data.filter(r => r.bespokeId === x.bespoke.bespokeId) })
            );
            const resultWithErrorsPreProd = DataBespokes.filter(y => y.env === 'PreProd').map(x =>
                ({ ...x, err: monitoringPreProd?.data.filter(r => r.bespokeId === x.bespoke.bespokeId) })
            );
            setDataBespokes([...resultWithErrorsDev, ...resultWithErrorsProd, ...resultWithErrorsPreProd]);
        }
        if (DataBespokes && isAuthenticated && fetchMonitoring) {
            fetchDataMonitoring();
        }
    }, [fetchMonitoring])


    const handleFilter = (e) => {
        if (e.target.value === 'Trash') {
            setDataFiltered(dataTrash)
            setSelectedOption(e.target.value);
        } else {
            setSelectedOption(e.target.value);
        }
    }

    useEffect(() => {
        const onFilterChange = () => {
            let filteredRowsByEnv = DataBespokes && DataBespokes.filter((row) => row.env === selectedOption);
            if (selectedOption === 'Trash') {
                filteredRowsByEnv = dataTrash
                dispatch(bespokeActions.handleIsDeleteBespoke(true))
            }
            if (selectedOption === 'All') {
                filteredRowsByEnv = DataBespokes
            }
            setDataFilteredByEnv(filteredRowsByEnv)

        }
        onFilterChange()
    }, [selectedOption])

    const handleChange = (e) => {
        setSearchBarValue(e.target.value)
        if (!DataFilteredByEnv) {
            search(DataBespokes, e.target.value)
        }
        else {
            search(DataFilteredByEnv, e.target.value)
        }
    }
    useEffect(() => {
        setSearchBarValue('')
        setDataFiltered()
    }, [selectedOption])

    // fonction de recherche dans le tableau
    const search = (table, word) => {

        if (word.length > 0) {
            // si le mot recherché n'est pas une chaine vide
            setDataFiltered(); // on vide le tableau à afficher
            let resultName = table.filter(
                // on fait un filter et on met le résultat dans la variable result
                line =>
                    line.bespoke.name.toUpperCase().toUpperCase().includes(word.toUpperCase()) // on compare les deux chaine mises en majuscules(pour que l'on soit sur de toujours comparer des chaines de meme type)
            );
            let resultRef = table.filter(
                line =>
                    line.bespoke.bespokeId.toUpperCase().toUpperCase().includes(word.toUpperCase())
            );
            let resultAccount = table.filter(
                line =>
                    line.account.name.toUpperCase().toUpperCase().includes(word.toUpperCase())
            );
            let resultCode = table.filter(
                line =>
                    line.bespoke.code.toUpperCase().toUpperCase().includes(word.toUpperCase())
            );
            let resultDate = table.map(newID => {
                const dateBegin = format(new Date(newID.bespoke.creationDate), 'dd/MM/yyyy')
                //.toString().split(/[/]/).join('')
                newID.creationDate = dateBegin
                return newID
            })
                .filter(
                    line =>
                        line.creationDate.toUpperCase().toUpperCase().includes(word.toUpperCase())
                    // includes(`${word}`)
                );
            let dataFinal = [
                ...resultName,
                ...resultRef,
                ...resultAccount,
                ...resultDate,
                ...resultCode
            ]
            let uniq = [...new Set(dataFinal)];
            setDataFiltered(uniq); //on rempli le tableau avec le resultat du filter
            if (dataFinal.length === 0) {
                let result = "Il n'y a pas de résultats pour cette recherche"
                setNoData(result)
            }
            else {
                setNoData()
            }
        } else {
            setDataFiltered()
        }
    };
    let results = null


    if (!DataFiltered) {
        results =
            DataBespokes && DataBespokes.map((x, id) =>
                <article key={id} className="bespoke-item">
                    <BespokeItem
                        data={x}
                        name={x.bespoke.name}
                    />
                </article>)

    }
    if (DataFiltered) {
        results =
            DataFiltered && DataFiltered.map((x, id) =>
                <article key={id} className='bespoke-item'>
                    <BespokeItem
                        data={x}
                    />
                </article>)

    }
    if (DataFilteredByEnv) {
        results =
            DataFilteredByEnv && DataFilteredByEnv.map((x, id) =>
                <article key={id} className='bespoke-item'>
                    <BespokeItem
                        data={x}
                    />
                </article>)

    }
    if (DataFilteredByEnv && DataFiltered) {
        results =
            DataFiltered && DataFiltered.map((x, id) =>
                <article key={id} className='bespoke-item'>
                    <BespokeItem
                        data={x}
                    />
                </article>)
    }

    if (!isAuthenticated) {
        results = "noConnect"
    }

    return (
        < Fragment >

            <div className='headerBar' >
                <div className="input-group search_bar">
                    <input
                        className="form-control"
                        onChange={handleChange}
                        value={searchBarValue}
                        type="text"
                        placeholder="Find a SaaS Format"
                        aria-label="Large"
                        aria-describedby="basic-addon2"
                        disabled={isButtonDisabled}
                    />
                    {isBeyableAccount &&
                        <select
                            className="form-controlmb-2 border rounded-right selectionCustom"
                            id="inputFilteredByEnv"
                            onChange={handleFilter}
                            value={selectedOption}>
                            {filterList.map(x => <option key={x.label} value={x.value}> {x.label}</option>)}
                        </select>
                    }
                </div>
            </div>
            {!DataBespokes && results !== "noConnect" ?
                <div className="spinner spinner_overlayed">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                </div>
                :
                <>
                    <h3 className="title-h3 title-list-bespoke">SaaS Formats</h3>

                    {results !== "noConnect" ?
                        noData ?
                            <div className="noData">
                                {noData}
                            </div> :
                            <section className="list-bespoke">
                                {results}
                            </section>
                        :
                        <div className="connection-message">
                            <div>
                                <p> You must connect to use BEYABLE Studio</p>
                                <Btn onClickFunction={() => loginWithRedirect()} style="">Connect</Btn>

                            </div>

                        </div>
                    }
                </>
            }


        </Fragment >
    )
}
