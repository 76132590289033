
import { actionSocket } from '../store/socket-reducer';
import socketIOClient from 'socket.io-client';
// import { REACT_APP_SOCKETIO } from '../env';
// import envConfig from '../env.js';
//const SOCKETIO = process.env.REACT_APP_SOCKETIO

const SOCKETIO = window.envConfig.SOCKETIO;
export const fetchDataSocket = (token) => {
	return async (dispatch) => {
		const fetchData = async () => {
			const res = await socketIOClient(SOCKETIO, {
				query: {
					application: 'studio',
					token: token
				},
				transports: ['websocket', 'polling'],
				upgrade: true
			});
			return res;
		};

		try {
			const sock = await fetchData();
			sock && dispatch(
				actionSocket.instanciateSocket(sock)
			);
		} catch (error) {

		}
	};
};
