/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import NewDropdown from "../Ui/NewDropdown";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { IconContext } from "react-icons";
import style from './SelectTemplate.module.css';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
export default React.memo(function SelectTemplate({ onSelectTemplateType, templateType, templateTypeSelected }) {
    const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
    const idTool = uuidv4();

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const button = (
        <div className="analytics_range">
            <div className="flex">
                <div className="flex_item_full">
                    <ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
                    <div className="flex" data-for={idTool} data-tip={'Select format'}>
                        <span>{templateTypeSelected}</span>
                        <IconContext.Provider value={{ color: "#FFFFFF", size: "16px", className: `${style.icon}` }}>
                            {
                                !dropdownIsOpen ?
                                    <FaCaretDown />
                                    :
                                    <FaCaretUp />
                            }
                        </IconContext.Provider>
                    </div>
                </div>
            </div>
            <ReactTooltip place="bottom" type="light" getContent={() => { templateType.map(el => el.label) }} />
        </div>
    );
    const handleSelectNewTemplateValue = (val) => {
        onSelectTemplateType(val)
        setDropdownIsOpen(false)
    }
    return (
        <>
            {isBeyableAccount &&
                <NewDropdown
                    isOpen={dropdownIsOpen}
                    setIsOpen={() => setDropdownIsOpen(true)}
                    onHide={() => setDropdownIsOpen(false)}
                    button={button}
                >

                    <ul className="listbox" >
                        {templateType && templateType.map((value) => {
                            const isSelected =
                                (templateTypeSelected &&
                                    templateTypeSelected) === value.value;
                            return (
                                <li key={value.value}>

                                    <a
                                        className={
                                            isSelected
                                                ? "listbox_item selected"
                                                : "listbox_item"
                                        }
                                        onClick={() =>
                                            handleSelectNewTemplateValue(value.value)
                                        }
                                    >
                                        {value.label}
                                        {isSelected && (
                                            <span
                                                className={'styles.indicator'}
                                            ></span>
                                        )}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>

                </NewDropdown >}
            {!isBeyableAccount &&
                <span>{templateTypeSelected}</span>
            }
        </>
    );
})
