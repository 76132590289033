import React from 'react';
import Btn from './Btn';
import style from './Nav.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from '../../store/campaign-reducer';
import SelectVersion from '../Campaign/SelectVersion';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { createBespoke } from '../../store/create-bespoke-reducer';
export default function Nav({
	env,
	isBespokePage = false,
	dataBespoke = false,
	campaignSelected = false,
	listofDisplayIdentifier = false,
	handleSelectVersion = false,
	displayIdentifier = false }) {
	const dispatch = useDispatch();
	const accountWorkingInformations = useSelector(state => state.ui.accountWorkingInformations);
	const isBeyableAccount = useSelector(state => state.ui.isBeyableAccount);
	const { valuesBespokeDescription, isNewBespoke } = useSelector(state => state.bespoke);


	const { name } = accountWorkingInformations;
	const openThePanel = () => {
		if (!isBespokePage) {
			dispatch(campaignActions.openClosePanelCampaign(true));
		} else {
			dispatch(createBespoke.openClosePanelBespoke(true));
		}
	};
	const idTool = uuidv4();
	return (
		<div className={style.nav}>
			<div className={style.breadcrumb}>
				{isBeyableAccount && <div className={`${style.env} ${style.breadcrumbItem}`}>#{env}</div>}
				<div className={`${style.title} ${style.breadcrumbItem}`}
					onClick={openThePanel}
				>{name}</div>
				{campaignSelected && !isBespokePage && <>
					<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
					<div className={`${style.campaign} ${style.breadcrumbItem}`} data-for={idTool} data-tip={'select campaign'}
						onClick={openThePanel} style={{ cursor: 'pointer' }}
					> {campaignSelected.name && campaignSelected.name.length > 33 ? campaignSelected.name.substring(0, 32) + '...' : campaignSelected.name}</div>
				</>
				}
				{!isBespokePage &&
                    <div className={`${style.abtest} ${style.breadcrumbItem}`}>
                    	<SelectVersion
                    		listofDisplayIdentifier={listofDisplayIdentifier}
                    		handleSelectVersion={handleSelectVersion}
                    		displayIdentifier={displayIdentifier}
                    	/>
                    </div>
				}
				{isBespokePage && !isNewBespoke && <>
					<ReactTooltip id={idTool} backgroundColor='black' effect='solid' />
					<div className={`${style.campaign} ${style.breadcrumbItem}`} data-for={idTool} data-tip={'select SaaS Format'}
						onClick={openThePanel} style={{ cursor: 'pointer' }}
					> {valuesBespokeDescription.name && valuesBespokeDescription.name.length > 33 ? valuesBespokeDescription.name.substring(0, 32) + '...' : valuesBespokeDescription.name}</div>
				</>
				}

			</div>
		</div>
	);
}