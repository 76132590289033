import { createAsyncThunk } from '@reduxjs/toolkit';

const CampaignThunk = {

	PutCampaignStatus: createAsyncThunk(
		'campaign/changeStatusCampaign',
		async (authAxios, env, accountId) => {
			const response = await authAxios.get(
				` account/${env}/${accountId}/{campaignId}/testmodestar`
			);
			return response.data;
		}
	),


};

export { CampaignThunk };
