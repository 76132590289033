import React from 'react';

export default function IconsContainer({ children, color }) {
	return (
		<div className="icon-group">
			<div className={`icon-container icon-${color}`}>
				{children}
			</div>
		</div>
	);
}
