import React, { useEffect, useRef } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import { FillSpinner as Loader } from 'react-spinners-kit';

export default function AccountIdeEditorDesktop({
	handleEditorReady,
	isEditorReady,
	isOpen,
	theme,
	isActive,
	language,
	value,
	changeValue
}) {

	const valueGetter = useRef();
	let resizeTimeout;

	function windowResize() {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(function(){
			handleEditorReady('restart');
		}, 200);
	}

	useEffect(() => {
		handleEditorReady('restart');
	}, [isOpen]);

	useEffect(() => {
		window.addEventListener('resize', windowResize);
		return function () {
			window.removeEventListener('resize', windowResize);
		};
	}, []);

	function handleEditorDidMount(event) {
		handleEditorReady(true);
		valueGetter.current = event;
	}

	return (
		<div className='flex_item_full editor_wrapper' style={{display: isActive ? '' : 'none'}}>
			{isEditorReady && 
                <ControlledEditor
                	theme={theme}
                	language={language}
                	loading={<Loader />}
                	value={value && value}
                	onChange={changeValue}
                	editorDidMount={handleEditorDidMount}
                	options={options}
                	automaticLayout={true}
                />
			}
		</div>
	);
}
