import { useState } from 'react';

export default function useDisplayname() {
	const [displayname, setDisplayname] = useState();
	const getTheDisplayNameOfUser = (user) => {
		if (!user) return;
		user.given_name = user.given_name || '';
		user.family_name = user.family_name || '';
		user.email = user.email || '';
		user.displayName = user.given_name + ' ' + user.family_name;

		if (user.displayName.trim() === '') {
			user.displayName = user.email ? user.email : '';
		}
		return user.displayName;
	};
	return { displayname, getTheDisplayNameOfUser };
}
