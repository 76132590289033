import React, { useState, useEffect } from "react";
import ListOfFormat from './ListOfFormat'
import { FaUpload } from 'react-icons/fa'
import UploadContainer from '../../UploadThumb/UploadContainer'
import IconsContainer from '../../IconsContainer';
import VignettePlaceholder from '../../../Assets/bespoke_vignette.png';
import uuid from 'react-uuid';
import HeaderEdition from "../../Ui/HeaderEdition";
import { fetchDataAccount } from '../../../store/uiActions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import useAuthAxios from '../../../utils/useAuthAxios';
import { bespokeActions } from "../../../store/bespokeProperty-slice";
import { bespokeFormActions } from "../../../store/bespokeForm-slice";
export default function BespokeEdition({ envColor, env, accountId, }) {
    const [toggleUpload, setToggleUpload] = useState(false)
    const {

        selectedOption,
        selectedCreationOptionCss,
        mobileVersionEnabled,
        valuesUrl,
        accountName,
        formIsNotEpty,
        desktopTemplate,
        wysiwygValues,

    } = useSelector((state) => state.bespoke);
    const {
        newBespokeValues,
    } = useSelector((state) => state.bespokeForm);
    const {
        isBeyableAccount
    } = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    const { authAxios } = useAuthAxios();
    const changetoggleUpload = () => {
        setToggleUpload(!toggleUpload)
    }

    const addRandom = (evt) => {
        evt.preventDefault();
        let random = uuid();
        dispatch(bespokeFormActions.handleNewBespokeValues({ code: random }))
    }
    const setAccountWorkingDatas = () => {
        dispatch(fetchDataAccount(authAxios, env, accountId))
    }
    useEffect(() => {
        if (isAuthenticated && accountId && env !== "choose") {
            const timer = setTimeout(() => {
                setAccountWorkingDatas();
            }, 700)
            return () => {
                clearTimeout(timer);
            }
        }
    }, [isAuthenticated])
    const handleChangeCustom = (e) => {
        // e.persist();
        dispatch(bespokeFormActions.modifyValuesBespokeCreationDescription(e))
    }
    const validateBespoke = (values) => {
        if (values.name.length >
            0 && values.description.length >
            0 && values.code.length > 0
            && values.format.length > 1) {
            dispatch(bespokeActions.handleChangeFormIsNotEmpty(true))
        } else {
            dispatch(bespokeActions.handleChangeFormIsNotEmpty(false))
        }
    }

    useEffect(() => {
        validateBespoke(newBespokeValues)
    }, [newBespokeValues])
    const enableMobile = (e) => {
        dispatch(bespokeFormActions.handleFilterMobile(e))
        dispatch(bespokeActions.handleFilterMobile(e))
    }
    const handleChangeFormatTemplate = (e) => {
        dispatch(bespokeActions.handleChangeTemplateTypeAllDevices(parseInt(e.target.value)))
    }

    const handleChangeFormatWysigiwyg = (e) => {
        const value = e.target.value
        const objectIsLiquid = {
            1: false,
            2: true
        }
        const newValuIsLiquid = objectIsLiquid[value];
        dispatch(bespokeActions.handleWysiwygValuesTypeAllDevices(parseInt(value)));
        dispatch(bespokeActions.handleIsLiquid(newValuIsLiquid));

    }
    return (
        <>
            <div className="col-sm-10  m-auto">
                <div className="card shadow mb-4 h-100">
                    <HeaderEdition
                        message='SaaS Format Edition'
                        env={env}
                    >
                        <h3>{newBespokeValues.name}</h3>
                    </HeaderEdition>

                    <div className="bespoke-detail-inner">
                        <div className="bespoke-detail">
                            <label htmlFor="bespokeName">SaaS Format name</label>
                            <input type="text" className="form-control" id="name"
                                name="name"
                                value={newBespokeValues.name || ''}
                                onChange={(e) => handleChangeCustom(e)}
                                placeholder="SaaS Format Name"
                                required />
                        </div>
                        <div className="bespoke-detail">
                            <label htmlFor="code">Identifier / Code</label>
                            <input type="text" className="form-control" id="code"
                                name="code"
                                onChange={(e) => handleChangeCustom(e)}
                                value={newBespokeValues.code}
                                placeholder="Code"
                                required />
                            <div>
                                <button onClick={(evt) => addRandom(evt)}>
                                    <i className="fas fa-random"></i> Generate a random code
                                </button>
                            </div>
                        </div>
                        <div className="bespoke-detail">
                            <label htmlFor="bespokeAccountName">Account</label>
                            <input
                                type="text"
                                className="form-control text-center" id="bespokeAccountName"
                                value={accountName || ''}
                                readOnly
                            />
                        </div>
                        <div className="bespoke-detail">
                            <label htmlFor="bespokeDescription">Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"
                                name="description"
                                onChange={(e) => handleChangeCustom(e)}
                                value={newBespokeValues.description || ''}
                                required />
                        </div>
                        <div className="bespoke-detail">
                            <label htmlFor="account">Format</label>
                            <select
                                className="form-control border  rounded-right selectionCustom"
                                id="account"
                                onChange={(e) => { dispatch(bespokeFormActions.handleFilterFormat(e)); dispatch(bespokeActions.handleFilterFormat(e)) }}
                                name="account"
                                value={selectedOption}
                                required
                            >
                                {ListOfFormat.map(x => <option key={x.label} value={x.value}> {x.label}</option>)}
                            </select>
                        </div>
                        <div className="bespoke-detail-2">
                            {isBeyableAccount && wysiwygValues.desktopTemplate.templateType === 1 &&
                            <>
                            <div className="bespoke-detail">
                                <label htmlFor="customCssEnabled">Css custom</label>
                                <select
                                    className="form-control border  rounded-right selectionCustom"
                                    id="customCssEnabled"
                                    onChange={(e) => {
                                        dispatch(bespokeFormActions.filterCssCustom(e));
                                        dispatch(bespokeActions.filterCssCustom(e))
                                    }}
                                    name="customCssEnabled"
                                    value={wysiwygValues.desktopTemplate.templateType === 2 || selectedCreationOptionCss}>
                                    <option value="true"> Activated </option>
                                    <option value="false"> Deactivated </option>
                                </select>
                            </div>
                            <div className="bespoke-detail">
                                <label htmlFor="customCssEnabled">Mobile version</label>
                                <select
                                    className="form-control border  rounded-right selectionCustom"
                                    name="mobileVersionEnabled"
                                    id="mobileVersionEnabled"
                                    onChange={(e) => {
                                        enableMobile(e)
                                    }}
                                    value={wysiwygValues.desktopTemplate.templateType === 2 || mobileVersionEnabled}
                                >
                                    <option value="true"> Activated </option>
                                    <option value="false"> Deactivated </option>
                                </select>
                            </div>
                            </>
                            }
                            {isBeyableAccount &&
                                <> <div className="bespoke-detail">
                                    <label htmlFor="Template format">Template format</label>
                                    <select
                                        className="form-control border  rounded-right selectionCustom"
                                        name="mobileVersionEnabled"
                                        id="mobileVersionEnabled"
                                        onChange={(e) => { handleChangeFormatTemplate(e) }}
                                        value={desktopTemplate.contentTemplateType}
                                    >
                                        <option value={1}>XSLT</option>
                                        <option value={2}>Liquid</option>
                                    </select>
                                </div>
                                    <div className="bespoke-detail">
                                        <label htmlFor="Wysiwyg format">Wysiwyg format</label>
                                        <select
                                            className="form-control border  rounded-right selectionCustom"
                                            name="mobileVersionEnabled"
                                            id="mobileVersionEnabled"
                                            onChange={(e) => { handleChangeFormatWysigiwyg(e) }}
                                            value={wysiwygValues.desktopTemplate.templateType}
                                        >
                                            <option value={1}>Angular</option>
                                            <option value={2}>Liquid</option>
                                        </select>
                                    </div>
                                </>}
                        </div>
                        {
                            !formIsNotEpty &&
                            <div className="bespoke-detail">
                                <pre className='text-danger mt-2 mx-auto'> All the fields are required</pre>
                            </div>
                        }
                        {newBespokeValues.thumbnailUrl ?
                            <div className="bespoke-detail">
                                <img src={valuesUrl ? valuesUrl[1] : newBespokeValues.thumbnailDisplayUrl} alt="bespoke img-thumbnail" className="img-thumbnail m-auto" style={{ width: '100%', height: '100%' }} />
                            </div>
                            :
                            <div className="bespoke-detail">
                                <img src={valuesUrl ? valuesUrl[1] : VignettePlaceholder} alt="bespoke img-thumbnail" className="img-thumbnail m-auto" style={{ width: '100%', height: '100%' }} />
                            </div>
                        }
                        {!toggleUpload ?
                            (<>
                                <div className="bespoke-detail">
                                    &nbsp; &nbsp; <span className="uploadLogo" onClick={changetoggleUpload}>
                                        <IconsContainer color="black">
                                            <FaUpload />
                                        </IconsContainer>
                                    </span>
                                    <label htmlFor="thumbnailUrl">Upload a thumbnail</label>
                                    <input type="text" className="form-control text-center" id="thumbnailUrl"
                                        placeholder="url de la vignette"
                                        name="thumbnailUrl"
                                        // onChange={ModifyvaluesBespokeDescription}
                                        value={newBespokeValues.thumbnailUrl || ''}
                                        readOnly />
                                </div>
                            </>
                            )
                            :
                            <UploadContainer
                                env={env}
                                accountId={accountId}
                                code={newBespokeValues.code}
                                changetoggleUpload={changetoggleUpload}

                            />}
                    </div>
                </div>
            </div>


        </>
    );
}