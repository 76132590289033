import React, { useEffect, useState, Fragment } from 'react';
import Portal from './Portal';
import './panel.css';
export default function Panel({
	isOpen = false,
	onClose,
	children,
	width,
	noClose = false,
	side = 'right',
	overlay = true,
	closeOnOverlay = false,
}) {
	const [shouldRender, setRender] = useState(isOpen);
	const style = {};
	const panelClass = ['panel_inner', 'section_root'];
	const overlayClass = ['panel_overlay'];

	const hide = (ev) => {
		if (typeof onClose === 'function') {
			onClose(ev);
		}
	};

	useEffect(() => {
		if (overlay) {
			document.body.style.overflow = isOpen ? 'hidden' : '';
		}
		if (isOpen) {
			setRender(true);
		}
	}, [isOpen]);

	const onAnimationEnd = () => {
		if (!isOpen) {
			setRender(false);
		}
	};

	const overlayClick = (ev) => {
		if (closeOnOverlay) {
			hide(ev);
		}
	};

	if (width) {
		style.width = width + 'px';
	}
	if (side == 'left') {
		panelClass.push(side);
	}
	if (overlay) {
		panelClass.push('has_overlay');
	}
	if (isOpen) {
		panelClass.push('anim_show');
		overlayClass.push('anim_show');
	} else {
		panelClass.push('anim_hide');
		overlayClass.push('anim_hide');
	}

	if (!shouldRender) return '';

	return (
		<Portal>
			{overlay &&
                <div className={overlayClass.join(' ')} onClick={(ev) => overlayClick(ev)}></div>
			}
			<div className={panelClass.join(' ')} style={style} tabIndex="-1" onAnimationEnd={onAnimationEnd}>
				{!noClose &&
                    <a onClick={(ev) => hide(ev)} className="modal_close" title="Close">
                    	<i className="fas fa-times"></i>
                    </a>
				}
				{children}

			</div>
		</Portal>
	);
}
