import React from 'react';

export default function TestTs({name, age}:{name: string, age: number}) {
	console.log('🚀--** ~ file: TestTs.tsx:4 ~ TestTs ~ age:', age);
	console.log('🚀--** ~ file: TestTs.tsx:4 ~ TestTs ~ name:', name);
	const test:string = '';
	console.log('🚀--** ~ file: TestTs.tsx:7 ~ TestTs ~ test:', test);
	return (
		<div>TestTs</div>
	);
}
