import { createSlice, current } from '@reduxjs/toolkit';
import { BespokeThunk } from './services/bespokeThunk';

const initialState = {
	// property

	newBespokeValues: {
		name: '',
		description: '',
		code: '',
		accountId: '',
		format: '',
		customCssEnabled: false,
		mobileVersionEnabled: false,
		thumbnailUrl: '',
		thumbnailDisplayUrl: '',
	},
};
const bespokeFormSlice = createSlice({
	name: 'bespokeForm',
	initialState: initialState,

	reducers: {
		// creation
		// prev name : ModifyvaluesBespokeDescription
		modifyValuesBespokeCreationDescription(state, action) {
			const value = action.payload.target.value;
			const name = [action.payload.target.name];
			state.newBespokeValues = {
				...state.newBespokeValues,
				[name]: value,
			};
		},
		// prev name : setNewBespokeValues
		handleNewBespokeValues(state, action) {
			state.newBespokeValues = { ...state.newBespokeValues, ...action.payload };
		},
		handleFilterFormat(state, action) {
			const value = action.payload.target.value;
			// state.selectedOption = value;
			state.newBespokeValues = {
				...state.newBespokeValues,
				format: value,
			};
		},
		filterCssCustom(state, action) {
			const value = action.payload.target.value;
			// state.selectedCreationOptionCss = value;
			state.newBespokeValues = {
				...state.newBespokeValues,
				customCssEnabled: value,
			};
		},
		handleFilterAccount(state, action) {
			const arrayOfValues =
                action.payload && action.payload.target.value.split(',');
			const val = action.payload && arrayOfValues[0];
			// state.accountSelect = val;
			// state.accountName = action.payload && arrayOfValues[1];

			state.newBespokeValues = {
				...state.newBespokeValues,
				accountId: val,
			};
		},

		handleFilterMobile(state, action) {
			let value;
			if('checked' in action.payload.target){
				value = action.payload.target.checked;
			} else{ 
				value = action.payload.target.value;
			}
			// state.mobileVersionEnabled = value;
			state.newBespokeValues = {
				...state.newBespokeValues,
				mobileVersionEnabled: value,
			};
		},

		initSelectionBespoke(state, action) {
			// state.isNewBespoke = false;
			// state.propertyBespokeFiltered = [];
			// state.valuesAccountPropertiesFiltered = [];
			// state.valuesAccountProperties = []
			// state.valuesInBespokeProperties = [];
			// state.propertiesBespokeLiquid = [];
			state.newBespokeValues = [];
			// state.valuesBespokeDescription = {}
			// state.valuesInBespokeProperties = []
		},

		//not use

		onFilterChange(state, action) {
			// bespokeActions.handleFilterAccount();
			state.newBespokeValues = {
				name: '',
				description: '',
				code: '',
				accountId: '',
				format: '',
				customCssEnabled: false,
				thumbnailUrl: null,
			};

			// state.propertiesBespokeLiquid = []
		},

	}
});

export const bespokeFormActions = bespokeFormSlice.actions;

export default bespokeFormSlice;

//utils

