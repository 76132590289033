
import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import SpinnerOverlay from '../Ui/SpinnerOverlay';
import LoginError from '../Authentication/LoginError';
import md5 from 'js-md5';
import { useDispatch, useSelector } from 'react-redux';
import { actionUI } from '../../store/ui-reducer';

const ProtectedRoute = ({ component: Component, user, restricted, ...args }) => {


  const email = user?.email || 'notAth@notAth.com';
  const res = email.split('@');
  const myMd5 = md5(res[1]);
  const restrictedUrl = [
    // '/bespokes',
    // "/bespokeEdition/:accountId/:bespokeId/:env/",
    "/creationSimplifySetup",
    "/setupConfiguration",
    "/simplifyaAccountsList",
    "/simplifySetup/:accountId/:env/:isExistantSetup/:status",
    // 'creation',
    '/accountApi/:env/:id/'
  ]
  const dispatch = useDispatch();

  if (myMd5 === '827e76d6f3d71300eb2d377ed6c4d428' || myMd5 === '1df073a067b9da40196043a08043e0d3') {
    dispatch(actionUI.handleIsBeyableAccount(true))
  } else {
    dispatch(actionUI.handleIsBeyableAccount(false))
  }
  if (myMd5 !== ('827e76d6f3d71300eb2d377ed6c4d428' || myMd5 === '1df073a067b9da40196043a08043e0d3') && restrictedUrl.includes(args.path)) {
    return <LoginError />
  }
  if (args.location.search && args.location.search.includes("?error=unauthorized")) {
    return <LoginError />
  }
  else {
    return (<>
      {restricted ?
        <Route
          component={withAuthenticationRequired(Component, {
            onRedirecting: () => <SpinnerOverlay />,
            // returnTo: ''
          })}
          {...args}
        />
        :
        <Route
          component={Component}
          {...args}
        />}
    </>
    );
  }
}
export default ProtectedRoute;
