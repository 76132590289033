import React from 'react';
import { DiffEditor as MonacoDiffEditor } from '@monaco-editor/react';
import optionsForDiff from './optionsForDiff';
export default function MonacoDiffEditorCampaign({ val, theme, language, toggleState }) {

	const valueToShow = val.find(elem => elem.id === language);
    
	return (
		<div className='flex_item_full flex flex_v'>
			<div className="title_editor_code_preview">
				<p>Desktop</p>
				<p>Mobile</p>
			</div>
			<MonacoDiffEditor
				height="80vh"
				options={optionsForDiff}
				language={language}
				original={valueToShow.original}
				modified={valueToShow.preview}
				theme={theme}
			/>
		</div>
	);
}
