import React from 'react';
import {Panel} from 'ui-beyable';
import Btn from '../../Ui/Btn';
import PageType from './PageType';
import Thumbnail from './Thumbnail';
import ScreenShotUploadContainer from './ScreenShotUploadContainer';
export default function PanelPageTypeEdition({ panelTypeIsOpen,
	setPanelTypeIsOpen, env, name, accountId,
	isExistantSetup,newPageTypeIsOppen,screenShotToChange,
	setNewPageTypeIsOppen,children}) {
	return (
		<Panel
			isOpen={panelTypeIsOpen}
			onClose={() => setPanelTypeIsOpen(false)}
			width={800}
			side={'right'}
			hasClose={false}
			closeOnOverlay={true}
		>
			<>
				<div className="modal_header has_border flex flex_justify_between" >
					<div>
      Page type 
					</div>
					<div>
						<Btn
							onClickFunction={() => setPanelTypeIsOpen(false)}
							message="Cancel"
							color="alert"
						/>
						<Btn
							onClickFunction={() => setPanelTypeIsOpen(false)}
							message="Ok"
							color='primary'
						/>
					</div>
				</div>
				{children}
				<PageType
      		accountId={accountId}
      		name={name}
      		env={env}
      		isExistantSetup={isExistantSetup}
      		newPageTypeIsOppen={newPageTypeIsOppen}
      		setNewPageTypeIsOppen={(e) => setNewPageTypeIsOppen(e)}
      	/>
				{screenShotToChange &&
                screenShotToChange.length > 0 &&
      <div className="container-zoning" style={{overflow : 'auto'}}>
      	{screenShotToChange && screenShotToChange.length > 0 && <Btn
      		message="Editing tags"
      		onClickFunction={() => setNewPageTypeIsOppen(true)} />}
      	<br />
      	<h3>Image</h3>
      	<Thumbnail />
      	<ScreenShotUploadContainer 
      		device="desktop"
      	/>
      	<ScreenShotUploadContainer 
      		device="mobile"
      	/>
             
      </div>
				}
			</>
		</Panel>
	);
}
