import React from 'react';
import env from './env';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-status-alert/dist/status-alert.css';
import './index.css';
import './style/var.css';
import './style/utils.css';
import './style/flex.css';
import './style/listbox.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import StatusAlert from 'react-status-alert';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';
import Auth0ProviderWithHistory from './Components/Authentication/auth0-provider-with-history';
console.log(React.version, 'RVersion');
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Router >
	<Auth0ProviderWithHistory>
		<Provider store={store}>
			<StatusAlert />
			<App />
		</Provider>
	</Auth0ProviderWithHistory>
</Router >);

// serviceWorker.unregister();
