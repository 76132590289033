import React from 'react';
import styles from './SearchBar.module.css';
export default function SearchBar({
	onChangeValue,// DEPRECATED
	onChange,
	valueInSearchBar,
	placeholder,
	name,
	style = '',
	fullWidth = true,
	autoFocus = false,
	selectOnFocus = false
}) {
	const cssClass = [styles.search_bar];
	let canReset = false;

	function changeInput(ev) {
		if (typeof onChangeValue === 'function') {
			onChangeValue(ev);
		}
	}

	function change(value) {
		if (typeof onChange === 'function') {
			onChange(value);
		}
	}

	const onFocus = (e) => {
		if (selectOnFocus) {
			e.target.select();
		}
	};

	if (typeof onChange === 'function') {
		canReset = true;
	}

	if (style) {
		if (style == 'grey') {
			cssClass.push(styles.search_bar_grey);
		}
		if (style == 'bottom_border') {
			cssClass.push(styles.search_bar_bottom_border);
		}
	} else {
		cssClass.push(styles.custom_input);
	}

	if (fullWidth) {
		cssClass.push(styles.full_w);
	}

	return (
		<label className={cssClass.join(' ')}>
			<i className={'fas fa-search ' + styles.search_bar_icon}></i>
			<input type="text"
				onChange={(ev) => { changeInput(ev); change(ev.target.value); }}
				value={valueInSearchBar}
				placeholder={placeholder}
				name={name ? name : ''}
				autoFocus={autoFocus}
				onFocus={(e) => onFocus(e)}
			/>
			{canReset && valueInSearchBar && valueInSearchBar.length > 0 &&
                <a className="custom_input_reset" onClick={(ev) => change('')}>
                	<i className="fas fa-times-circle"></i>
                </a>
			}
		</label>
	);
}