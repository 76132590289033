import React, { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import './ConfirmModal.css';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../utils/useAuthAxios';
import MessageAlert from '../MessageAlert';
export default function ConfirmModal({ close, stopPropa, id, env, name }) {
	const location = useLocation();

	const [redirect, setRedirect] = useState(false);
	const [message, setMessage] = useState('');
	const [color, setColor] = useState('');

	const {isAuthenticated} = useAuth0();
	const {authAxios} = useAuthAxios();

	const deleteBespoke = () => {
		if (!isAuthenticated)
			return;
		authAxios.delete(`bespoke/${env}/${id}`)
			.then(res => {
				setMessage('Supression Ok');
				setColor('alert-success');
				setTimeout(() => {
					setMessage();
					// document.location.reload(true)
					setRedirect(true);
				}, 1000);
			}).catch(e => {
				console.error(e);
				setMessage('Un problème est survenu pendant la supression');
				setColor('alert-danger');
				setTimeout(() => {
					setMessage();
				}, 4000);
			});
	};
	if (redirect) {
		if (location.pathname !== '/bespokes') {
			return <Redirect to={'/bespokes'} />;
		}
		else {
			document.location.reload(true);
		}

	}
	return (
		<div onClick={(e) => close(e)} className="containerConfirmModalDelete">
			<div onClick={(e) => stopPropa(e)} className={location.pathname !== '/bespokes' ? 'contenuModalDelete' : 'contenuModalDeleteAcc'}>
				<div className="btn-closeModalBespokeDelete" onClick={(e) => close(e)}>Close <i className="fas fa-times"></i></div>
				<div className="textModal">
                    Do you really want to delete : {name}
				</div>
				{message ? <MessageAlert msg={message} color={color} /> : null}
				<div className='btnGroup'>
					<div className="btnCancelModal " onClick={(e) => close(e)} >Cancel</div>
					<div className="btnDeleteModal" onClick={() => deleteBespoke()}>Delete</div>
				</div>
			</div>
		</div>
	);
}
