import React, { useContext, useState, useEffect, useRef } from 'react';

import { creationSetupSimplify } from '../../../Context/ContextCreationSetupSimplify';
import './Thumbnail.css';

export default function Thumbnail() {
	const {
		screenShotId,
		screenShotToChange,
		setscreenShotToChange,
		deviceSelected
	} = useContext(creationSetupSimplify);

	const [thumb, setThumb] = useState();
	const [file, setFile] = useState();
	const fileInput = useRef(null);
	const [screen, setscreen] = useState();
	const checkIfThumbExist = () => {
		const dataPut = screenShotToChange.find(x => x.device === deviceSelected);
		if (dataPut && dataPut.previewThumbnail) {
			setThumb(dataPut.previewThumbnail);
		}
		else {
			setThumb();
		}
	};
	useEffect(() => {
		checkIfThumbExist();
	}, [deviceSelected, screenShotToChange, screenShotId]);

	useEffect(() => {
		if (file) {
			changeThumNailDataToPut();
		}
	}, [file]);

	const handleChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];
		if (!file) {
			return;
		}
		reader.onloadend = () => {
			setThumb(reader.result);
			setFile(file);
		};
		reader.readAsDataURL(file);
	};

	const changeThumNailDataToPut = () => {
		let newDataMobile = screenShotToChange.find(x => x.device === 'mobile');
		let newDataDesktop = screenShotToChange.find(x => x.device === 'desktop');

		newDataDesktop.previewThumbnail = thumb;
		newDataDesktop.thumbnailPath = file;
		newDataMobile.previewThumbnail = thumb;
		newDataMobile.thumbnailPath = file;
		setscreenShotToChange([ newDataDesktop, newDataMobile]);
	};

	useEffect(() => {
		const screenToLoad = screenShotToChange.find(screenShot => screenShot.screenshotId === screenShotId);
		setscreen(screenToLoad);
		setFile();
		fileInput.current.value = '';
	}, [screenShotToChange, deviceSelected, screenShotId]);

	return (
		<div className="flex flex_justify_between mr_30 ml_30 mb_15">
			<div style={{width : '35%'}}>
				<input ref={fileInput} id='input' type="file" name="files" onChange={(e) => handleChange(e)} />
				<img 
					style={{maxHeight : '200px', maxWidth : '200px'}}
					src={thumb ? thumb : screen && screen.thumbnailPath.length > 0 ?
						screen.thumbnailPath : 'https://via.placeholder.com/150x150'} alt="Thumbnail" />
			</div>
			<span>Thumbnail</span>
			<label className="btn-style" htmlFor="input">
				Select a file
			</label>

		</div>
	);
}
