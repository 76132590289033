import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthAxios from '../../../utils/useAuthAxios';
import filterList from './filterList';


import Btn from '../../Ui/Btn';


export default function SetupSimplifyCreationContainer() {


	const [accounts, setAccounts] = useState();
	const [listOfAccountByEnv, setListOfAccountByEnv] = useState();
	const [toggleState, setToggleState] = useState(1);
	const [creationMode, setCreationMode] = useState();
	const [redirect, setRedirect] = useState(false);

	const { isAuthenticated } = useAuth0();
	const { authAxios } = useAuthAxios();

	const [accountSelect, setAccountSelect] = useState('');
	const [env, setEnv] = useState('choose');
	const [accountId, setaccountId] = useState();

	function insensible_a_la_casse(a, b) {
		if (a.account.name && b.account.name) {
			if (a.account.name.toUpperCase() < b.account.name.toUpperCase()) return -1;
			if (a.account.name.toUpperCase() > b.account.name.toUpperCase()) return 1;
		}
		return 0;
	}

	useEffect(() => {
		const fetchData = async () => {

			const result = await authAxios.get('websitesetup/dev/accounts ');
			const dev = result.data.map(x => ({ id: x.accountId, isActive: x.isActive, name: x.name, status: x.status, env: 'dev' }));
			const result2 = await authAxios.get('websitesetup/prod/accounts ');
			const prod = result2.data.map(x => ({ id: x.accountId, isActive: x.isActive, name: x.name, status: x.status, env: 'prod' }));
			const result3 = await authAxios.get('websitesetup/preprod/accounts ');
			const preprod = result3.data.map(x => ({ id: x.accountId, isActive: x.isActive, name: x.name, status: x.status, env: 'preprod' }));
			const dataToMap = [...prod, ...preprod, ...dev];
			const resultAllAccount = await authAxios.get('account');
			const resultSort = resultAllAccount.data;
			let newArray1 = dataToMap && dataToMap.map(x => x.id);
			let newArray = [];
			resultSort && resultSort.forEach(x => {
				if (!newArray1.includes(x.account.id)) {
					newArray.push(x);
				}
				return newArray;
			});
			newArray && newArray.sort(insensible_a_la_casse);
			setAccounts(newArray);
		};
		isAuthenticated && fetchData();
	}, [isAuthenticated]);
	useEffect(() => {
		const onFilterChange = () => {
			setListOfAccountByEnv([]);
			setCreationMode();
			let filteredRowsByEnv = accounts && accounts.filter((ac) => ac.env == env);
			setListOfAccountByEnv(filteredRowsByEnv);
		};
		accounts !== 'choose' && onFilterChange();
	}, [env]);

	let envColor = null;
	if (env === 'Dev') {
		envColor = 'colorEnvDev';
	}
	if (env === 'Prod') {
		envColor = 'colorEnvProd';
	}
	if (env === 'PreProd') {
		envColor = 'coloEnvPreProd';
	}

	const create = () => {
		setRedirect(true);
	};
	if (redirect) {
		return <Redirect to={`/simplifySetup/${accountId}/${env.toLowerCase()}/${false}/draft`} />;
	}
	const handleFilterEnv = (e) => {
		setEnv(e.target.value);
	};
	const handleFilterAccount = (e) => {
		const arrayOfValues = e && e.target.value.split(',');
		setAccountSelect(arrayOfValues[1]);
		setaccountId(arrayOfValues[0]);
	};


	return (
		<div className="col-sm-10 m-auto">
			<div className="card shadow mb-4 ">
				<div className={`card-header py-3 d-flex flex-row align-items-center justify-content-center bg-lightgray  ${envColor}`}>
					<h1 className='title-h1'> Creation of a new setup Simplify </h1>
				</div>
				{toggleState === 1 &&
                    < div className="card-body overflow-auto ">

                    	<div className="form-group m-auto col-md-6 ">
                    		<label htmlFor="account">Environment </label>
                    		<select
                    			className="form-control mb-2 border rounded-right "
                    			id="inputFilteredByEnv"
                    			onChange={(e) => handleFilterEnv(e)}
                    		>
                    			{filterList.map(x => <option key={x.label} value={x.value}> {x.label}</option>)}
                    		</select>
                    	</div>
                    	{env !== 'choose' &&

                            (< div className="form-group m-auto col-md-6 ">
                            	<label htmlFor="account">Account </label>
                            	<select
                            		className="form-control mb-2 border  rounded-right "
                            		id="account"
                            		name="account"
                            		onChange={(e) => handleFilterAccount(e)}
                            	>
                            		<option value=''>Select account</option>
                            		{listOfAccountByEnv && listOfAccountByEnv.map((x, i) => <option key={i}
                            			value={[x.account.id, x.account.name]}> {x.account.name === null ? x.account.id : x.account.name}</option>)}

                            	</select>
                            </ div>
                            )}

                    	{
                    		accountId !== '' &&
                            <div className="flex flex_v flex_align_center ">

                            	< Btn
                            		message="Create setup"
                            		onClickFunction={() => create()}
                            	/>
                            </div>
                    	}
                    </ div>}
			</div>
		</div >

	);

}