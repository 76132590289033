import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import uiSlice from './ui-reducer';
import createBespokeSlice from './create-bespoke-reducer';
import socket from './socket-reducer';
import campaignSlice from './campaign-reducer';
import bespokeSlice from './bespokeProperty-slice';
import bespokeForm from './bespokeForm-slice';
const store = configureStore({
	reducer: {
		ui: uiSlice.reducer,
		createBespoke: createBespokeSlice.reducer,
		socket: socket.reducer,
		campaign: campaignSlice.reducer,
		bespoke: bespokeSlice.reducer,
		bespokeForm: bespokeForm.reducer,
	},
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false
		}),
	],
});

export default store;
